export default (theme, themeConfig, themeColors) => {
    return {

appBar: {
    boxShadow: 'none',
    backgroundColor: themeColors.primaryPalette.white,
    borderBottom: `1px solid ${themeColors.primaryPalette.white}`,
    color: themeColors.primaryPalette.primary,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '& > div': {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: themeColors.primaryPalette.white,
        boxShadow: themeConfig.customShadow
      }
    },
    
  },
  desktopAppBarShift: {
    width: `calc(100% - ${themeConfig.drawerWidthLevel_1}px)`,
      marginLeft: themeConfig.drawerWidthLevel_1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarShiftLevel_2: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${themeConfig.drawerWidthLevel_2}px)`,
      marginLeft: themeConfig.drawerWidthLevel_2,
    }
  },
  appBarShiftLevel_3: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${themeConfig.drawerWidthLevel_3}px)`,
      marginLeft: themeConfig.drawerWidthLevel_3,
    }
  },
}
}