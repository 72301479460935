
export default (theme, themeConfig, themeColors, themeFonts) => {
  return {
root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      minHeight: `calc(100vh - ${themeConfig.toolbarMinHeight}px)`,
    },
  },
  hide: {
    display: "none"
  },
  success: {
    backgroundColor: themeColors.primaryPalette.success,
    color: themeColors.primaryPalette.white
  },
  error: {
    backgroundColor: themeConfig.setImportant(themeColors.primaryPalette.error),
    color: themeConfig.setImportant(themeColors.primaryPalette.white)
  },
  warning: {
    backgroundColor: themeColors.primaryPalette.warning,
    color: themeColors.primaryPalette.black
  },
  successText: {
    color: themeColors.primaryPalette.success
  },
  errorText: {
    color: themeColors.primaryPalette.error
  },
  warningText: {
    color: themeColors.primaryPalette.warning
  },
  textCenter: {
    textAlign: 'center'
  },
  textRight: {
    [theme.breakpoints.up("sm")]: {
      textAlign: 'right',
    }
  },
  textRightStrong: {
    textAlign: 'right'
  },
  tabsWrapper: {
    borderBottom: '1px solid '+themeColors.primaryPalette.secondary,
    '& button':{
      textTransform: 'none',
      minWidth: 'auto',
      '&[aria-selected="true"]':{
        color: themeColors.primaryPalette.primary
      }
    }
  },
  textRightOnlyXs:{
    [theme.breakpoints.only("xs")]: {
      textAlign: 'right',
    }
  },
  hiddenXs:{
    [theme.breakpoints.only("xs")]: {
      display: 'none !important',
    }
  },
  
  
  inputLabelColumnsComponentControlWrap: {
    backgroundColor: themeColors.primaryPalette.primary,
    padding: theme.spacing(1)*2,
    borderRadius: theme.spacing(1)/2,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)*1.5,
    },
    '& .gridItem':{
      position: 'relative',
    },
    '& .labelWrap':{
      color: themeConfig.setImportant(themeColors.primaryPalette.white),
    },
    
    '& .inputLabelColumnsInputWrap':{
      display: 'flex',
      padding: 0
    },
    '& input[type="text"], & textarea':{
      backgroundColor: themeColors.primaryPalette.white,
      borderRadius: theme.spacing(1)/2,
      width: '100%',
      padding: theme.spacing(1)
    }
  },
  previousValue:{
    display: 'inline-block',
    backgroundColor: themeColors.primaryPalette.primary,
    padding: theme.spacing(1)*2,
    borderRadius: theme.spacing(1)/2,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)*1.5,
    },
    '& span, & div': {
      color: themeColors.primaryPalette.white,
      fontStyle: 'italic',
      textTransform: 'none'
    },
    '& div':{
      fontWeight: 600
    },
  },
  previousComment:{
    cursor: 'pointer',
    display: 'block',
    
    '& .btnWrap span':{
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '80%'
    },
    '&.compact':{
    }
  },
  
  formDialogWrap: {
    '& .roundedDialogIcon':{
      width: 48,
      height: 48,
      marginLeft: theme.spacing(1),
      border: '1px solid '+themeColors.primaryPalette.grey,
      '&:not(:hover)':{
        backgroundColor: 'transparent',
      },
      "& svg":{
        color: themeColors.primaryPalette.primary
      }
    },
    '&.dialogWithArrows .paper':{
      '& .dialogFormContent':{
        backgroundColor: 'transparent'
      },
      [theme.breakpoints.up("sm")]: {
        position: 'relative',
        paddingTop: theme.spacing(1)*2,
        paddingLeft: theme.spacing(1)*5,
        paddingRight: theme.spacing(1)*5,
        '& button.arrow':{
          position: 'absolute',
          top: '45%',
          left: 4,
          '&.arrowRight':{
            left: 'auto',
            right: 4,
          }
        }
      }
    },
    '& h5':{
      position: 'relative',
      paddingRight: theme.spacing(1)*6,
      '& button': {
        position: 'absolute',
        right: theme.spacing(1),
        top: -theme.spacing(1)/2,
        padding: theme.spacing(1)/2
      },
    },
    '&.fullScreen':{
      '& > div > div':{
        
        overflow: themeConfig.setImportant('visible')
      },
      '& .formDialogTitle': {
        backgroundColor: themeColors.primaryPalette.white,
        '& h6': {
          fontWeight: 900,
          fontFamily: themeFonts.headingFont
        }
      },
    },
  },

  ImageList:{
    '& .avatarWrap, .primaryListText, .secondaryListText':{
      cursor: "pointer"
    },
    '& .primaryListText':{
      fontSize: themeConfig.smallFontSize,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .secondaryListText':{
        fontSize: (themeConfig.smallFontSize-1)
    }
  }
  
  
}
}