
import ValidationUtil from '../../../utils/ValidationUtil';
import objectPath from 'object-path';
import TranslatorUtil from '../../../utils/TranslatorUtil';


const validate = values => {
  let errors = {};
  const requiredFields = [
    "email",
    "password",
    "confirmPassword"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);


  errors = ValidationUtil.isEmailFormat(['email'],values,errors);
  errors = ValidationUtil.checkStringMinLength(['password'],values,errors,6);

  //compare passwords
  if(objectPath.get(values, "password") && objectPath.get(values, "password")
  !==
  objectPath.get(values, "confirmPassword")
  ){
    objectPath.set(errors,"confirmPassword", TranslatorUtil.t("Passwords do not match"));
  }

  return errors;
};

export default validate;
