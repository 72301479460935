import * as types from '../constants/actionTypes';


export function getAccess(state = [], action) {
    if (action.type === types.GET_ACCESS) {
        console.log(types.GET_ACCESS+' set in store');
        return action.data;
    }
    return state;
}


export function getCompanyAccess(state = [], action) {
    if (action.type === types.GET_COMPANY_ACCESS_ITEMS) {
        console.log(types.GET_COMPANY_ACCESS_ITEMS+' set in store');
        return action.data;
    }
    return state;
}






