import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, ListItemAvatar, Paper, ListItemText, Grid, IconButton } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import UserAvatar from '../user/UserAvatar';
import FeedbackIcon from '@material-ui/icons/Feedback';
import objectPath from 'object-path';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';


class ChatMessageItem extends React.Component {


    renderListText = (message) =>{
        const { classes, showDialogMessagePreview, currentUserId } = this.props;
        const notCurrent = currentUserId !== message.byUserId;
        var reaction = objectPath.get(message,'aboutMessageInit',false) && this.getReaction(message);

        return <ListItemText
        secondaryTypographyProps={{ component: "div" }}
              secondary={
                <Paper elevation={1} className={"gridItem chatMessageItem" + (notCurrent ? " otherMessage" : " myMessage")} component="span" style={{display:"block"}}>
                  <Typography component="span" className={classes.inline} color="textPrimary">
                    
                    {message.message}
                  </Typography>
                  <Grid container>
            <Grid item xs={6}>
              <small className="messageDate">{DateUtil.displayTimeFromNow(message.createdTime, TranslatorUtil.getUserLangFromStore())}</small>
            </Grid>
            <Grid item xs={6} className="likeBtns">
              {reaction && showDialogMessagePreview ? <IconButton size="small" onClick={() => showDialogMessagePreview(reaction.projectId,reaction.discussionId,reaction.messageId)}>
                  <FeedbackIcon />
              </IconButton> : null}
            </Grid>
          </Grid>
                  </Paper>
              }
            />
      }
    
      renderAvatar = (message) =>{
        const { currentUserId } = this.props;
        return <ListItemAvatar>
          <UserAvatar userId={message.byUserId} menuItems={{
            withDialog: currentUserId !== message.byUserId && AccessUtil.isAtLeastObserver(),
            withSupport: (currentUserId !== message.byUserId && message.projectId && AccessUtil.isAtLeastModeratorForProject(message.projectId, false))
          }} />
      </ListItemAvatar>
      }

      getReaction = (message) => {
          return objectPath.get(message,'messageId',null) && objectPath.get(message,'discussionId',null) && objectPath.get(message,'projectId',null) ? 
          {
            messageId : objectPath.get(message,'messageId',null),
            discussionId : objectPath.get(message,'discussionId',null),
            projectId : objectPath.get(message,'projectId',null)

          } : null;
      }


  render () {
  const { message, currentUserId } = this.props;

  return message && currentUserId ? (<React.Fragment>
            
            {this.renderAvatar(message)}
          {this.renderListText(message)}
          
          </React.Fragment>
          
        ) : null
}
}


export default withRouter(withStyles(styles, { withTheme: true })(ChatMessageItem));