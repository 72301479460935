import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import objectPath from "object-path";

const collectionName = collections.USER_ACCESS;

function getAccessId(data){
  return [objectPath.get(data, "projectId", ""),objectPath.get(data, "discussionId", ""),objectPath.get(data, "roleType", "")].join("_");
}

export function getAccessItem(companyId, projectId, discussionId, roleType) {
  return function(dispatch) {
    

    var query = firestoreApi.collection(collectionName)
    .where("companyId", "==", companyId)
    .where("projectId", "==", projectId);
    
    if(roleType){
      query = query.where("roleType", "==", roleType);
    }
    if(discussionId){
      query = query.where("discussionId", "==", discussionId);
    }

    // console.log('getAccess called '
    // ,{query,companyId,projectId,discussionId,roleType}
    // );

    return query
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseFirstItemFromCollectionData(response), types.GET_ACCESS_ITEM)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_ACCESS_ITEM))
      );
  };
}

export function getCompanyProjectAccessItems(companyId,projectId) {
  return function(dispatch) {
    // console.log('getProjectAccessItems called ',
    // {companyId,projectId}
    // );

    var query = firestoreApi.collection(collectionName)
    .where("companyId", "==", companyId)
    .where("projectId", "==", projectId);

    return query
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_COMPANY_PROJECT_ACCESS_ITEMS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_PROJECT_ACCESS_ITEMS))
      );
  };
}

export function getCompanyAccessItems(companyId) {
  return function(dispatch) {

    var query = firestoreApi.collection(collectionName)
    .where("companyId", "==", companyId);

    return query
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_COMPANY_ACCESS_ITEMS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_ACCESS_ITEMS))
      );
  };
}

export function getUserAccess(userEmail) {
  return function(dispatch) {
    console.log('getAccess called ',userEmail);
    return firestoreApi.collection(collectionName)
    .where("userEmails", "array-contains", userEmail)
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_ACCESS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_ACCESS))
      );
  };
}
  
  export function listenUserAccess(userEmail) {
      return function(dispatch) {
        console.log('listenAccess called ',userEmail);
        return firestoreApi.collection(collectionName)
        .where("userEmails", "array-contains", userEmail)
        .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_ACCESS)))
      };
    }
  
  
  export function postUserAccess(data) {
    return function(dispatch) {
      console.log('postAccess');
      return firestoreApi.collection(collectionName).doc(getAccessId(data)).set(data)
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_ACCESS)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_ACCESS))
        );
    };
  }

  export function putUserAccess(data) {
    return function(dispatch) {
      console.log('putAccess');
      return firestoreApi.collection(
        collectionName
      ).doc(data.id).set(FirestoreCollectionUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_ACCESS)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_ACCESS))
        );
    };
  }
 