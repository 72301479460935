import GoogleTagManagerUtil from "../utils/projectBased/GoogleTagManagerUtil";


export function getRouterLocations(state = [], action) {
    switch (action.type) {
      case "@@router/LOCATION_CHANGE":
        GoogleTagManagerUtil.trackPageView(action.payload);
        return [...state, action.payload]
      default:
        return state;
    }
  }