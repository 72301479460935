import scrollIntoView from 'scroll-into-view-if-needed'


const rerenderDelay = 300;

export default class UiHelperUtil {
  static scrollToFirstErrorMessage() {
    
    setTimeout(() => {// needs delay to render targeted classes
      UiHelperUtil.scrollToFirstIfNeeded(document.querySelectorAll('p.hasError'));
    },rerenderDelay);
  }

  static truncateIfNeeded(text, truncateIfOver){
    text = UiHelperUtil.stripHtml(text);
    if(text && text.length >= truncateIfOver){
      return text.toString().substring(0,truncateIfOver)+'...';
    }
    return text;
  }

  static isTruncateNeeded(text, truncateIfOver){
    text = UiHelperUtil.stripHtml(text);
    if(text && text.length >= truncateIfOver){
      return true
    }
    return false;
  }

  static stripHtml(text, strict = false){
    var stripText = text.replace(/<(?:.|\n)*?>/gm, ' ');
    
    if(strict){
      
      stripText = stripText.replace(/(&.+;)/ig,' ');//remove HTML entities
      stripText = stripText.replace('&',' ');//remove &
      stripText = stripText.replace(/ +(?= )/g,'');//remove multiple spaces
      
    }
    return stripText;
  }

  static isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  static scrollToFirstFormOnPage(){
    setTimeout(() => {// needs delay to render targeted classes
      UiHelperUtil.scrollToFirstIfNeeded(document.querySelectorAll('form'));
    },rerenderDelay);
  }

  static scrollToFirstIfNeeded(element) {
    
        if(element && element.length){
          scrollIntoView(element[0], {
            scrollMode: 'if-needed',
            behavior: "smooth", 
            block: "center"
          });
        }
      
  }
}
