import * as types from '../constants/actionTypes';
import initialState from './initialState';



function showHideDrawer(state = initialState.drawerOpen, action) {
    if (action.type === types.CLOSE_DRAWER) {
        return false;
    } else if (action.type === types.OPEN_DRAWER) {
        return true;
    }
    return state;
}

export {
    showHideDrawer
}
