import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//import * as constants from '../../constants/constants';
import * as notificationActions from "../../actions/notification";
import ComponentLoading from "../../components/ComponentLoading";
import Masonry from 'react-masonry-component';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../theme/styles";
import {  Avatar, Box, Button, Card, Hidden, Paper, Table, TableBody, TableContainer } from "@material-ui/core";
import TranslatorUtil from "../../utils/TranslatorUtil";
import NotificationCardContent from "../../components/notifications/NotificationCardContent";
import { Alert, AlertTitle } from "@material-ui/lab";
import NotificationRowContent from "../../components/notifications/NotificationRowContent";
import objectPath from "object-path";
import FormatterUtil from "../../utils/FormatterUtil";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }




  componentDidMount() {
  }

  markAsRead = (itemId) =>{
    const { actions } = this.props;
    return actions.markNotificationAsRead(itemId);
  }


  render() {
    const { dataLoading } = this.state;
    const { classes,listingDataItems, projects } = this.props;


    const masonryOptions = {
      itemSelector: '.masonry-item',
      columnWidth: '.masonry-item-sizer',
      percentPosition: true,
      horizontalOrder: true
    };

    return <div>
      
      {dataLoading ? <ComponentLoading /> :
        <div className={classes.contentWithoutHeader}>
          {projects.length  ? <Alert severity="info">
        <AlertTitle>{TranslatorUtil.t("Active projects")}</AlertTitle>
        <Box className="active-projects-list">
        {projects.map((project, index) => <Button variant="text" 
        onClick={() => this.props.history.push(UrlBuilderUtil.getProjectDashboard(project.id))} 
        disableRipple key={index} className="project-button" startIcon={
          <Avatar className="project-avatar" style={{backgroundColor:objectPath.get(project,'color'), width: 20, height: 20}}>
            <small>{FormatterUtil.getIconText(project.name)}</small></Avatar>
        }>
            {project.name}
            </Button>)}
        </Box>
      </Alert> : null}
          <Hidden smUp>
          <Masonry
            //ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
            className={'masonry-grid-wrap'} // default ''
            options={masonryOptions} // default {}
          >
            <div className="masonry-item-sizer"></div>
            {listingDataItems.map((item, index) => {

                return <div className="masonry-item" key={index}>
                  <Card elevation={4}>
                  <NotificationCardContent item={item} onMarkAsReadClicked={() => this.markAsRead(item.id)}/>
            </Card>
                </div>;
            })}
          </Masonry>
          </Hidden>
          <Hidden xsDown>
          <TableContainer component={Paper}>
            <Table>
              {/* <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>{TranslatorUtil.t("When")}</TableCell>
                  <TableCell>{TranslatorUtil.t("What")}</TableCell>
                  <TableCell>{TranslatorUtil.t("Read")}</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
              {listingDataItems.map((item, index) => {
                return <NotificationRowContent item={item} key={index} onMarkAsReadClicked={() => this.markAsRead(item.id)}/>;
              })}
              </TableBody>
            </Table>
          </TableContainer>
          </Hidden>
          
          { dataLoading &&  listingDataItems.length > 0 ? <ComponentLoading /> : null }
          { !dataLoading &&  listingDataItems.length < 1 ? <div className={classnames(classes.textCenter, classes.contentWithoutHeader)}><Alert variant="filled" severity="success">
  {TranslatorUtil.t("No notifications found")}
</Alert></div> : null }
          
          
          </div>}
    </div>
  }
}


function mapStateToProps(state) {
  return {
    listingDataItems: state.notifications,
    projects: state.projects
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...notificationActions,
      },
      dispatch
    )
  };
}


export default withRouter(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)));
