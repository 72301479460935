import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import * as formNames from "../../constants/forms";
import * as constants from '../../constants/constants';
import { 
  SelectWithCreateAndSuggestions
} from "../../components/inputs";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Tooltip, Divider, Typography, Button, TextField, Box, } from "@material-ui/core";
import warnAboutUnsavedForm from "../../hoc/withWarnAboutUnsavedChanges";
import TranslatorUtil from "../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";
import CompanyUtil from "../../utils/projectBased/CompanyUtil";
import objectPath from "object-path";


class ProjectUsersForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      invitationLinkCopied: false
    }
  }

  renderInvitationPart = () => {
    const {
      projectId, project
    } = this.props;
    const {
      invitationLinkCopied
    } = this.state;

    return objectPath.get(project,'byEmailInvitationOnly')
      ? <Box pt={1}>
        <div>
      <Typography variant="caption">{TranslatorUtil.t('Only email invitation is allowed for this project')} </Typography>
      <br/>
    <CopyToClipboard text={UrlBuilderUtil.getAbsoluteProjectLink(projectId)}
                  onCopy={() => this.setState({invitationLinkCopied: true})}>
    <Button variant={invitationLinkCopied ? "text" : "contained"} size="small" > {invitationLinkCopied ? TranslatorUtil.t('Link copied') : TranslatorUtil.t('Copy link')}</Button>
                </CopyToClipboard></div>
                <FormGroup>
    <TextField value={UrlBuilderUtil.getAbsoluteProjectLink(projectId)}/></FormGroup>
      </Box>
      :
      <Box pt={1}>
      <div><Typography variant="caption">{TranslatorUtil.t('Invite users via link (users not listed within any role will be created as respondents)')} </Typography>
    <br/>
    <CopyToClipboard text={UrlBuilderUtil.createInvitationLink(CompanyUtil.getId(),projectId)}
                  onCopy={() => this.setState({invitationLinkCopied: true})}>
    <Button variant={invitationLinkCopied ? "text" : "contained"} size="small" > {invitationLinkCopied ? TranslatorUtil.t('Link copied') : TranslatorUtil.t('Copy link')}</Button>
                </CopyToClipboard></div>
                <FormGroup>
    <TextField value={UrlBuilderUtil.createInvitationLink(CompanyUtil.getId(),projectId)}/></FormGroup>
    </Box>
      
      
  }
  

  render(){
    const {
      handleSubmit,
      //pristine,
      submitting,
      classes,
      proceedSubmit,
      usersOptions,
      validateBeforeSelect,
      validateNewOption
    } = this.props;
    const disableSubmit = submitting;
    const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><ArrowForwardIcon /></Fab>;


    


  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container>
            
            
          
          
          <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
              {this.renderInvitationPart()}
              </FormGroup>
              
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="respondents" component={SelectWithCreateAndSuggestions} isSearchable={true} isMulti={true} options={usersOptions} label={TranslatorUtil.t("respondents")} onBeforeSelectionChange={validateBeforeSelect} validateNewOption={validateNewOption} />
              </FormGroup>
              
            </Grid>
            
            <Grid item className="gridItem" xs={12} lg={7}>
              <Box mt={6} component="div"></Box>
              <Divider />
            </Grid>
            
            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="observers" component={SelectWithCreateAndSuggestions} isSearchable={true} isMulti={true} options={usersOptions} label={TranslatorUtil.t("observers")}  onBeforeSelectionChange={validateBeforeSelect} validateNewOption={validateNewOption} />
              </FormGroup>
            </Grid>
            <Grid item className="gridItem" xs={12} lg={7}>
              <Box mt={6} component="div"></Box>
              <Divider />
            </Grid>
            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="moderators" component={SelectWithCreateAndSuggestions} isSearchable={true} isMulti={true} options={usersOptions} label={TranslatorUtil.t("moderators")}  onBeforeSelectionChange={validateBeforeSelect} validateNewOption={validateNewOption} />
              </FormGroup>
            </Grid>
            
            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12} lg={7}>
              { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("OK")}>{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
  }

  
};


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.PROJECT_USERS_FORM_NAME, // a unique identifier for this form
  validate,
  //asyncValidate
})(ProjectUsersForm)),formNames.PROJECT_USERS_FORM_NAME);
