import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, Button, Divider } from '@material-ui/core';
import TranslatorUtil from '../../utils/TranslatorUtil';
import { withRouter } from "react-router-dom";
import ProjectPreviewCard from '../project/ProjectPreviewCard';


class InvitationContent extends React.Component {

  

  render () {
  const {  acceptInvitation, goToAuth, project, company
  //  ,discussion 
  } = this.props;

  return <div className="invitationContentWrapper">
      
      <ProjectPreviewCard company={company} project={project} hideTerms={false} />
      <br />
      <Divider />
      <br />
      <Typography variant="body1"><strong>{TranslatorUtil.t("By accepting this invitation you agree with terms and conditions above")}</strong></Typography>
      <br />
    <div>
        { acceptInvitation ? <Button variant="contained" color={"secondary"} onClick={acceptInvitation}>{TranslatorUtil.t("Accept invitation")}</Button> : null }
        { goToAuth ? <Button variant="contained" color={"secondary"} onClick={goToAuth}>{TranslatorUtil.t("Login first please")}</Button> : null }
    </div>
    </div>;
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(InvitationContent));