import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import ScrollToTop from 'react-scroll-up';
import objectPath from 'object-path';
//actions

import * as accessActions from "../actions/access";
import * as profileActions from "../actions/profile";
import * as firebaseUserActions from "../actions/firebaseUser";
import * as drawerActions from "../actions/drawer";
import * as translationActions from "../actions/translations";
import * as projectActions from "../actions/project";
import * as userTagActions from "../actions/userTags";
import * as notificationActions from "../actions/notification";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpwardOutlined';

//styles
import styles from "../theme/styles";
import CustomTheme from "../theme/custom";
import { MuiThemeProvider } from "@material-ui/core/styles";

//components
import AppRoutes from "../routes/AppRoutes";
import PublicAppRoutes from "../routes/PublicAppRoutes";

import withFirebaseAuth from "../hoc/withFirebaseAuth";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Hidden } from "@material-ui/core";
import DesktopDrawer from "../components/menu/desktop/DesktopDrawer";
import MobileDrawer from "../components/menu/mobile/MobileDrawer";
import MobileNavBar from "../components/menu/mobile/MobileNavBar";
import AppLoading from "../components/AppLoading";
import StoreUtil from "../utils/StoreUtil";
import scrollToTopStyle from "../theme/components/scrollToTop";
import GetErrorsListener from "../components/GetErrorsListener";
import SnackBarDismissAction from "../components/SnackBarDismissAction";
import LocalStorageUtil from "../utils/LocalStorageUtil";
import CompanyUtil from "../utils/projectBased/CompanyUtil";
import env from "../config/env";
import { Alert } from "@material-ui/lab";
import HelpMessanger from "../components/help/HelpMessanger";
import AccessUtil from "../utils/projectBased/AccessUtil";

class App extends React.Component {
  

  constructor (props) {
    super(props);
    this.state = {
      appInitLoading: true
    }   
  }

  
  componentDidMount() {
    let _this = this;
      Promise.all([
        
      ]).then(() => {
          console.log('app init loading done');
          _this.setState({appInitLoading: false});

      }).catch(function(err) {
        _this.appLoadingFailed(err);
      });

    
    this.listenRouteChanges();
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;
    let _this = this;
    //console.log('app next props ',nextProps);
    var userEmail = objectPath.get(nextProps,'user.email');
    
		if (userEmail && userEmail !== objectPath.get(this,'props.user.email')) {
      
      
      _this.setState({appInitLoading: true});
      return actions.getUserAccess(userEmail).then(access => {
        //TODO make sure to setup access for this user
        return Promise.all([
          actions.listenProfiles(),
          actions.setUserInfo(objectPath.get(nextProps,'user')),
          actions.setTranslationMessagesByLang(objectPath.get(nextProps,'user.profile.langCode')),
          actions.listenProjects(),
          actions.listenNotificationsByEmail(userEmail),
          actions.listenUserAccess(userEmail),
          actions.listenCompanyUserTags(),
          actions.getCompanyAccessItems(CompanyUtil.getId())
        ]).then(()=> {
          _this.setState({appInitLoading: false});
          _this.redirectToInvitationIfExist();
        });
      });

      
		}
  }

  redirectToInvitationIfExist = () => {
    const { history } = this.props;
    if(LocalStorageUtil.get('invitationCompanyId') && LocalStorageUtil.get('invitationProjectId')){
        history.push("/invitation/active");
    }
  }
  


  listenRouteChanges = () => {
    const { history, actions } = this.props;
    
    history.listen((location, action) => {
      console.log('route changed');
      //hide menu on redirect
      var isDrawerOpen = StoreUtil.getValueByKey('drawerOpen');
      if(isDrawerOpen){
        actions.closeDrawer();
      }
    });
  }

  appLoadingFailed = (err) => {
    const { history } = this.props;
    console.log(err);
    this.setState({appInitLoading: false}, () => {
      history.push('/500');
    });
  }


  logout = () => {
    const { actions, history } = this.props;
    actions.logoutUser().then(() => history.push('/'));
    
  };

  render() {
    const { classes, actions, drawerOpen, menu, user, userLoading, notifications, selectedProject } = this.props;
    const { appInitLoading } = this.state;
    

    return (
      <MuiThemeProvider theme={CustomTheme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          classes={{
            variantSuccess: classnames(classes.snackbarWrap, classes.success),
            variantError: classnames(classes.snackbarWrap,classes.error),
            variantWarning: classnames(classes.snackbarWrap,classes.warning),
            variantInfo: classnames(classes.snackbarWrap,classes.info)
          }}
          action={key => <SnackBarDismissAction id={key} />}
        >
        <div>
        { appInitLoading || userLoading ? <AppLoading /> : user ? <div>
          <div className={classes.root} id="fullScreenDialogContainer">
            <CssBaseline />
            <Hidden xsDown>
            <ScrollToTop showUnder={500} style={scrollToTopStyle}>
                <div className="scrollTopIconWrap hideForPrint"><ArrowUpwardIcon /></div>
            </ScrollToTop>
            </Hidden>
            <Hidden smDown>
              
              {/* <DesktopNavBar
              /> */}

              <DesktopDrawer
                menu={menu}
                // userInfo={userInfo}
                // selectedProject={selectedProject}
                handleLogout={this.logout}
              />
            </Hidden>

            <Hidden mdUp>
              <MobileNavBar
                drawerOpen={drawerOpen}
                notifications={notifications}
                handleDrawerOpen={actions.openDrawer}
                handleDrawerClose={actions.closeDrawer}
                selectedProject={selectedProject}
              />
              <MobileDrawer
                menu={menu}
                drawerOpen={drawerOpen}
                handleDrawerClose={actions.closeDrawer}
                handleLogout={this.logout}
              />
              
            </Hidden>

            <main
              className={classnames(classes.content, classes.contentShift, {
                [classes['contentShiftLevel_1']] : true
              },selectedProject ? ('selectedProject role-'+AccessUtil.getUserRoleForProject(selectedProject.id, user.email, true)) : '')}
            >
            
              {/* <PageDrawerHeader /> */}

              <AppRoutes />
              
              {/* <AppFooter /> */}
            </main>
            <HelpMessanger />
          </div>
          </div> : <div className="publicContentWrapper">
          <div className={classes.root} id="fullScreenDialogContainer"><main
              
            >
            
              <PublicAppRoutes />
               
            </main>
            
          </div>
          </div> }
          <GetErrorsListener />
          </div>
          { env.CURRENT !== env.PROD ? <div className="envBadge"><Alert severity="error">{env.CURRENT}</Alert></div> : null }
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    drawerOpen: state.drawerOpen,
    infoText: state.infoText,
    selectedProject: state.selectedProject,
    userInfo: state.userInfo,
    notifications: state.notifications
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...accessActions,
        ...profileActions,
        ...firebaseUserActions,
        ...drawerActions,
        ...translationActions,
        ...projectActions,
        ...userTagActions,
        ...notificationActions
      },
      dispatch
    )
  };
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const StyledApp = withStyles(styles, { withTheme: true })(App);

const ReduxApp = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StyledApp)
);

const AuthedApp = withFirebaseAuth(ReduxApp);

export default AuthedApp;
