import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from './Logo';
import styles from '../theme/styles';
import { Typography } from '@material-ui/core';
import TranslatorUtil from '../utils/TranslatorUtil';


function AppLoading(props) {
  const { classes } = props;
  return (
    <div className={classes.appLoader}>
      <Logo />
      <div className={classes.textCenter}>
      
      <CircularProgress className={classes.progress} />
      <div>
      <br />
      <br />
      <Typography className={classes.pleaseWait}>{TranslatorUtil.t("Please wait")}</Typography>
      </div>
      </div>
      
    </div>
  );
}

AppLoading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AppLoading);