

const primaryPalette = {
  primary: "#BD8DBD",
  secondary: "#5FBD5A",
  white: "#ffffff",
  black: "#000000",
  textPrimary: "#4A4A4A",
  grey: "#83878D",
  warning: "#f4a442",
  error: "#f44141",
  success: "#278724",
  lightGrey: "#f2f9fb"
};

export { primaryPalette };
