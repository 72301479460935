export default class FirestoreCollectionUtil {



    static parseCollectionData(items){
      if(items && items.docs){
        
        return items.docs.map(item => Object.assign(item.data(), FirestoreCollectionUtil.addMetaData(item)));
      }
      return [];
      
    }

    static parseFirstItemFromCollectionData(items){
      return items && items.docs && items.docs.length > 0 ? FirestoreCollectionUtil.parseCollectionItemData(items.docs[0]) : null;
      
    }

    static addMetaData(item){
      return {
        id: item.id,
      }
    }

    static removeMetaData(item){
      delete item.id;
      return item;
    }

    static parseCollectionItemData(item){
      if(item.exists){
        return Object.assign(item.data(), FirestoreCollectionUtil.addMetaData(item))
      }
      return null;
      
    }


}
  