import TranslatorUtil from "../utils/TranslatorUtil";
const values = [
    "15-18",
    "19-25",
    "26-40",
    "41-55",
    "55+",
];

export default values.map(function (age) {return {
    "value": age, "label": TranslatorUtil.t(age)
}});