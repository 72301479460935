import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";  
import styles from '../../theme/styles';
import UserCommentForm from '../../forms/userComment/form';
import UserTagsForm from '../../forms/userTags/form';
import { Typography, DialogContent, Dialog, AppBar, Toolbar, IconButton, Box, Grid, Button } from '@material-ui/core';
import ComponentLoading from '../ComponentLoading';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userCommentsActions from '../../actions/userComments';
import * as userTagsActions from '../../actions/userTags';
import TranslatorUtil from '../../utils/TranslatorUtil';
import CloseIcon from '@material-ui/icons/Close';
import { withSnackbar } from "notistack";
import Slide from '@material-ui/core/Slide';
import UserInfo from './UserInfo';
import CommentsList from '../comments/CommentsList';
import objectPath from 'object-path';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import CompanyUtil from '../../utils/projectBased/CompanyUtil';
import UsersUtil from '../../utils/projectBased/UsersUtil';
import { Alert, AlertTitle } from '@material-ui/lab';
import UserProjectReactions from './UserProjectReactions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UserDialog extends React.Component {


      constructor(props){
        super(props);
        this.state={
          comments: [],
          dataLoading: true,
          allUserTags: [],
          currentUserTags: [],
          loadUserProjectReactions: false
        }
      }


      componentDidMount(){
          Promise.all([
            this.mountComments(),
            this.mountTags()
          ]).then(() =>
            this.setState({
              dataLoading: false
            })
            );
      }

      mountComments = () => {
        const { actions, userId, selectedProject } = this.props;
        if(selectedProject){
          return actions.getUserCommentsByUserId(userId, selectedProject.id).then((response) => 
            this.setState({comments: objectPath.get(response, "data",[])}));
        } else {
          this.setState({comments: []});
          return Promise.resolve();
        }
        
      }


      mountTags(){
        const { actions, userId, selectedProject } = this.props;
        const allTags = CompanyUtil.getCompanyUserTags(selectedProject ? selectedProject.id : null, true);
        if(selectedProject){
          return actions.getUserTagsByUserId(userId+"_p_"+selectedProject.id).then((response) => {
            this.setState({
              currentUserTags: objectPath.get(response, "data",[]),
              allUserTags : allTags
            })
          });
        } else {
          this.setState({
            currentUserTags: [],
            allUserTags : allTags
          })
          return Promise.resolve();
        }
        
      }

      handleSubmitComment = (data) => {
        const { actions, userId, enqueueSnackbar, selectedProject } = this.props;
        data.userId = userId;
        data.byUserId = UsersUtil.getCurrentUserUid();
        data.projectId = selectedProject.id;
        return actions.postUserComment(data).then(() => {
          this.mountComments();
          enqueueSnackbar(TranslatorUtil.t("Comment added"), { variant: "success"});
        });
      }

      handleSubmitTags = (data) => {
        const { actions, userId, enqueueSnackbar, selectedProject } = this.props;
        data.id = userId+"_p_"+selectedProject.id;
        data.projectId = selectedProject.id;
        var submittedTags = JSON.parse(JSON.stringify(data));
        return actions.putUserTag(data).then(() => {
          this.setState({ currentUserTags: submittedTags });
          enqueueSnackbar(TranslatorUtil.t("User tags updated"), { variant: "success"});
        });
      }

      validateNewOption = (option) => {
        const { allUserTags } = this.state;
           this.setState({
            allUserTags: allUserTags.concat([option.label])
          });
        return true;
      }
      


      
        render() {
            const { userId, onClose, selectedProject } = this.props;
            const { comments, dataLoading, currentUserTags, allUserTags, loadUserProjectReactions } = this.state;

            
            return <Dialog fullScreen onClose={onClose} open={true} 
            TransitionComponent={Transition}
            classes={{
              paper: "dialogPaperWithForm"
            }}
            >
              <AppBar style={{position:"relative"}}>
          <Toolbar  className="fullscreenDialogWrapBar">
            
            <Typography style={{
              flex: 1,
            }} variant="h5">
            {TranslatorUtil.t("User profile")+(selectedProject ? ` - ${selectedProject.name}` : "")}
            </Typography>
            <IconButton color="inherit" onClick={() => onClose()} aria-label={TranslatorUtil.t("close")}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className="dialogFormContent">
          <Grid container>
            <Grid item xs={12} sm={6} md={5}>
              {
                userId ? <UserInfo userId={userId} selectedProject={selectedProject} withDialog={false} closeDialog={() => onClose()} /> : TranslatorUtil.t("User not found")
              }
      
              { dataLoading ? <div><ComponentLoading /></div> : 
                <div>
                  {
                    AccessUtil.canEditUserTags(userId) && selectedProject ? <UserTagsForm 
                    initialValues={currentUserTags}
                    userTags={allUserTags.map(x => ({
                      label: x,
                      value: x
                    }))}
                    validateNewOption={this.validateNewOption}
                    onCancel={onClose}
                    proceedSubmit={this.handleSubmitTags}
                  /> : null
                  }
                  {
                    AccessUtil.canEditUserComments(userId) && selectedProject ? <UserCommentForm 
                    onCancel={onClose}
                    proceedSubmit={this.handleSubmitComment}
                  /> : null
                  }
                  {
                    AccessUtil.canViewUserComments(userId) ? <CommentsList items={comments} /> : null
                  }
                  
                  {!selectedProject && <Box pt={2}><Alert severity="info">
                          <AlertTitle>
                            {TranslatorUtil.t("Project is not selected")}
                          </AlertTitle>
                        </Alert></Box>}
                </div>
              }
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Box p={2} pt={0}>{AccessUtil.isAtLeastObserverForProject(selectedProject?.id, false) ? 
              loadUserProjectReactions
              ? <UserProjectReactions userId={userId} selectedProject={selectedProject} /> : (selectedProject && AccessUtil.isUserRespondentForProject(selectedProject.id, UsersUtil.getEmailFromStoreById(userId)) ? <Box className='text-center'>
              <Button variant="contained" size='large' onClick={() => this.setState({loadUserProjectReactions: true})}>{TranslatorUtil.t("Load user reactions")}</Button>
              </Box>: null) : null}</Box>
              
            </Grid>
          </Grid>
          
          
          
        </DialogContent></Dialog>
        }
}


function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    profiles: state.profiles,
    companyUsersTags: state.userTags,
    selectedProject: state.selectedProject
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...userCommentsActions,
        ...userTagsActions
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UserDialog))));

