import deepEqual from 'deep-equal'
import queryString from 'query-string'
import objectPath from 'object-path'
import LocalStorageUtil from '../LocalStorageUtil';
import FormatterUtil from '../FormatterUtil';

export default class FilterUtil {

    static getDefaultFilterValues (filter = {}) {
        return {
            myitems: objectPath.get(filter, 'myitems',false),
            due: objectPath.get(filter, 'due',false),
            keyword: FormatterUtil.keywordDecodeURI(objectPath.get(filter, 'keyword','')),
            activityids: objectPath.get(filter, 'activityids',[]),
            positionids: objectPath.get(filter, 'positionids',[]),
        }
    }


    static getLinkTitleByUrl ( url ) {
        //var filter = FilterUtil.getRequestedFilterFromUrl({search: url});
        return '';
    }


    static addToLocalStorage (location) {
        var storedDashboardFilters = LocalStorageUtil.get('dashboardFilters', [], false);
        
        var dashboardLastSearch = LocalStorageUtil.get('dashboardLastSearch');
        var currentSearchQuery = objectPath.get(location,'search', '');
        if(dashboardLastSearch !== currentSearchQuery){
            LocalStorageUtil.set('dashboardLastSearch',currentSearchQuery);    
        }
        
        var currentDashboardFilter = FilterUtil.getRequestedFilterFromUrl(location);
        
        var activityids = objectPath.get(currentDashboardFilter,'activityids', []);
        var positionids = objectPath.get(currentDashboardFilter,'positionids', []);
        if(activityids.length || positionids.length){
            //check if already stored
            var currentStoreFilterUrl = FilterUtil.constructUrlParams(currentDashboardFilter, false);
            if(!storedDashboardFilters.includes(currentStoreFilterUrl)){
                storedDashboardFilters.unshift(currentStoreFilterUrl);
                LocalStorageUtil.set('dashboardFilters', storedDashboardFilters, false);
            }
        }
    }

    static constructUrlParams (filter = {}, includeNonStoredFilters = true) {
        var queryStringParams = [];
        var myitems = objectPath.get(filter,'myitems',false);
        if(myitems && includeNonStoredFilters){
        queryStringParams.push('myitems=1');
        }
        var due = objectPath.get(filter,'due',false);
        if(due && includeNonStoredFilters){
        queryStringParams.push('due=1');
        }
        var keyword = objectPath.get(filter,'keyword','');
        if(keyword.length && includeNonStoredFilters){
        queryStringParams.push('keyword='+FormatterUtil.keywordEncodeURI(keyword));
        }
        var activityids = objectPath.get(filter,'activityids',[]);
        if(activityids.length){
        queryStringParams.push('activityids='+activityids.join(','));//sort before join not needed
        }
        var positionids = objectPath.get(filter,'positionids',[]);//sort before join not needed
        if(positionids.length){
        queryStringParams.push('positionids='+positionids.join(','));
        }

        return ( queryStringParams.length ? ('?' + queryStringParams.join('&') ) : '' );
    }

    
    static getRequestedFilterFromUrl (localtionObject = null) {
        var locationSearch = localtionObject ? objectPath.get(localtionObject,'search','') : window.location.search.toString();// IE bug needs to have to string
        let searchParams = queryString.parse(locationSearch);

        //check arrays
        var activityids = objectPath.get(searchParams, 'activityids','');
        var positionids = objectPath.get(searchParams, 'positionids','');
        if(activityids.indexOf(',') !== -1){
            searchParams.activityids = activityids.split(','); 
        } else if(activityids.length){ // one option selected
            searchParams.activityids = [activityids];
        }
        if(positionids.indexOf(',') !== -1){
            searchParams.positionids = positionids.split(','); 
        } else if(positionids.length){ // one option selected
            searchParams.positionids = [positionids];
        }

        return FilterUtil.getDefaultFilterValues(searchParams);
    }

    static getRequestedKeywordFromUrl (localtionObject = null) {
        var locationSearch = localtionObject ? objectPath.get(localtionObject,'search','') : window.location.search.toString();// IE bug needs to have to string
        let searchParams = queryString.parse(locationSearch);
        return objectPath.get(searchParams, 'keyword','');
    }

    static areEqual(f1,f2){
        return deepEqual(f1, f2, {});
    }

}
  