

export const PROJECTS = "projects";
export const COMPANIES = "companies";
export const PROFILES = "profiles";
export const DISCUSSIONS = "discussions";
export const MESSAGES = "messages";
export const LIKES = "likes";
export const USER_COMMENTS = "userComments"
export const CHAT = "chat";
export const USER_ACCESS = "userAccess";
export const USER_TAGS = "userTags";
export const MAIL = "mail";
export const NOTIFICATION = "notification";
export const SUPPORT = "support";
export const READ_MESSAGES = "readMessages";
