import * as types from '../constants/actionTypes';


function getRequestErrors(state = [], action) {
    switch (action.type) {
      case types.AJAX_CALL_ERROR:
        return [...state, action.data.response]
      default:
        return state;
    }
  }

function getLastRequestError(state = null, action) {
    switch (action.type) {
      case types.AJAX_CALL_ERROR:
        return {actionName: action.actionName,...action.data.response}
      default:
        return state;
    }
  }


  export {
    getRequestErrors,
    getLastRequestError
}