import React from "react";
import ReactSelect from 'react-select/creatable'
import { FormControl, MenuItem, Paper, Typography, TextField } from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import objectPath from 'object-path';
import { primaryPalette } from "../../theme/constants/colors";
import LabelTooltip from "./LabelTooltip";
import TranslatorUtil from "../../utils/TranslatorUtil";


const styles = theme => ({
  
  input: {
    display: 'flex',
    padding: 0,
    
    '& > div > span': {
      display: 'none'
    }
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflowX: 'hidden',
    '& p':{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      //overflow: 'hidden',
    },
    '& > div':{
      [theme.breakpoints.down("md")]: {
        //maxWidth: 195 //mobile solution
      }
      //position: 'absolute'//when this goes to display none then not clickable
    },
    '& input':{
      boxSizing: 'border-box !important',
      fontSize: '13px !important'
    }
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  placeholder: {
    fontSize: 13
  },
  paper: {
    position: 'absolute',
    zIndex: 3,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    '& .disabledDropdownOptionGroupLevel_0': {
      color: primaryPalette.white,
      backgroundColor: primaryPalette.black,
      fontWeight: '900 !important'
    },
    '& .disabledDropdownOptionGroupLevel_1': {
      color: primaryPalette.white,
      backgroundColor: primaryPalette.black,
      paddingLeft: theme.spacing(1)*3
    },
    '& .DropdownOptionGroupLevel_2': {
      paddingLeft: theme.spacing(1)*4
    }
    
  },
  divider: {
    height: 0,
  },
  singleValue: {
    fontSize: 13
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} className={classnames('suggestionInputComponent', props.className)} />;
}


function Control(props) {
  return (
    <TextField
      fullWidth
      className="suggestionInputComponentWrapper"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  var levelGroupClass = props.isDisabled ? "disabled" : "";
  levelGroupClass += "DropdownOptionGroupLevel_"+objectPath.get(props,'data.level',0);


  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      disabled={props.isDisabled}
      className={levelGroupClass}
      style={{
        fontWeight: props.isSelected ? 600 : 400,
        fontSize: 12,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}


function SingleValue(props) {
  return (
    <Typography className={classnames(props.selectProps.classes.singleValue,props.selectProps.isDisabled && 'disabled muted')} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};



export default withStyles(styles)(({
  input,
  label,
  meta: { touched, error, invalid, form },
  children,
  isMulti,
  sortAlphabetically,
  isSearchable,
  options,
  helpText,
  onBeforeSelectionChange,
  validateNewOption,
  ...custom
}) => {
  

  const handleBlur = () => {
    input.onBlur(input.value)
  };

  const doChange = selectedOption => {
    if(isMulti){
      
      if(sortAlphabetically && Array.isArray(selectedOption)){
        selectedOption.sort(
          (a, b) => objectPath.get(a,'label','').localeCompare(objectPath.get(b,'label',''))
        );
      }
      const seletedOptions = Array.isArray(selectedOption) ? selectedOption.map(x => objectPath.get(x, 'value', [])) : [];
      input.onChange(seletedOptions);
    } else {
      input.onChange(objectPath.get(selectedOption, 'value', null));
    }
  }

  const handleChange = selectedOption => {
    if(onBeforeSelectionChange){
      if(onBeforeSelectionChange(input.name,selectedOption)){
        doChange(selectedOption)
      }
    } else {
      doChange(selectedOption);
    }
  };

  const handleCreate = (createValue) => {
    var newValue = {
      label: createValue,
      value: createValue,
    };
    if(validateNewOption(newValue)){
      if(isMulti){
        newValue = Array.isArray(input.value) ? options.filter(x => input.value.indexOf(x.value) !== -1).concat(newValue) : [newValue];
      }
      return handleChange(newValue);
    }
    
  };

  const getValue = (opts, val) => {

    if(isMulti){
      return (Array.isArray(opts) && Array.isArray(val)) ? opts.filter(o => val.indexOf(o.value) !== -1) : [];
    }
    return Array.isArray(opts) ? opts.find(o => o.value === val) : null;
  };

  

  const getWrapperClassName = () => {
    return "selectSuggestionWrap "+((touched && error) ? "error" : '')+((isMulti) ? " multi" : '')
  }

  if(sortAlphabetically){
    options.sort(
      (a, b) => objectPath.get(a,'label','').localeCompare(objectPath.get(b,'label',''))  
    );
  }
  

  return (
    <FormControl error={(touched && error) ? true : false} className={getWrapperClassName()}  >
      <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
      <div className="selectSuggestion">
        <ReactSelect {...input} {...custom}
            isClearable={true}
            classes={custom.classes}
            value={getValue(options, input.value)}
            isDisabled={custom.disabled}
            isSearchable={isSearchable}
            isMulti={isMulti}
            //inputValue={input.value}
            onCreateOption={handleCreate}
            onChange={handleChange}
            onBlur={handleBlur}
            onBlurResetsInput={false}
            placeholder={TranslatorUtil.t('Select')+'...'}
            blurInputOnSelect={false}
            options={options}
            components={components} /></div>
      {InputHelperText({ touched, error })}
    </FormControl>
  );
});
