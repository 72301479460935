import React from 'react';
import { Tooltip, Fab } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router-dom";  
import * as constants from '../../constants/constants';
import TranslatorUtil from '../../utils/TranslatorUtil';

function HeadingAddBtn(props) {

  const { redirectToUrl, history } = props;

  return (
    <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Add new item")}>
        <Fab color="secondary" size="small" onClick={() => history.push(redirectToUrl)}>
            <AddIcon />
        </Fab>
        </Tooltip>
  );
}

export default withRouter(HeadingAddBtn);