import React, {Component} from 'react';

class RichTextRenderer extends Component {

    static defaultProps = {
        trim: true
    };


    createContent() {
        let text = this.props.text;

        if (this.props.trim && text) {
            text = text.trim().replace(/(<? *script)/gi, 'illegalscript');
        }

        return text;
    }

    render() {
        return <div className="rte" dangerouslySetInnerHTML={{__html: this.createContent()}} />;
    }
}

export default RichTextRenderer;