import * as constants from "../constants/constants";
import FormatterUtil from "./FormatterUtil";
import objectPath from 'object-path';


export default class ServerErrorUtil {
    static isErrorPage() {
        var url = window.location.href.replace(',', '');
        //console.log('ServerError');
        return constants.REDIRECTED_ERROR_STATUSES.includes(url);
    }

    static isGetErrorWithoutCustomHandler(error){
        const isGetError = objectPath.get(error, 'config.method',null);
        var errorActionName = objectPath.get(error, "actionName", null);
        return (
            isGetError === 'get' 
            && errorActionName && errorActionName.startsWith('GET_') 
            && !constants.ERROR_CUSTUM_HANDLED_GET_REQUESTS.includes(errorActionName)) ? true : false; 
    }

    static getErrorMessageFromStore(error) {
        
        var errorActionName = objectPath.get(error, "actionName", '');
        errorActionName = FormatterUtil.formatActionNameForErrorMessage(errorActionName);
        errorActionName = "A system error has occurred";
        // var errorStatus = objectPath.get(error, "status", '');
        // var errorStatusText = objectPath.get(error, "statusText", '');
        
        
        return errorActionName.join(' ');
    }

    static getErrorMessageFromResponse(response, customMessage) {
        var errorMessage = objectPath.get(response, "data.message", "A system error has occurred.");
        if (customMessage) {
            errorMessage = customMessage;
        }
        return errorMessage;
    }

}
