import objectPath from 'object-path';

export default class GoogleTagManagerUtil {

  static trackPageView(data) {
    GoogleTagManagerUtil.defineDataLayerIfNeeded();
    let locationObject = objectPath.get(data, 'location');
    if (locationObject) {

      let locationPathname = objectPath.get(data, 'location.pathname');
      let locationSearch = objectPath.get(data, 'location.search');

      let newDataLayerObject = {
        'event': 'Pageview',
        'pathname': locationPathname,
        'search': locationSearch
      };

      // console.log('GoogleTagManagerUtil trackPageView', newDataLayerObject)
      window.dataLayer.push(newDataLayerObject);
    }
  }


  static defineDataLayerIfNeeded() {
    if (objectPath.get(window, 'dataLayer', null) === null) {
      window.dataLayer = window.dataLayer || [];
    }
  }

}
