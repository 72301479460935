import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import { firestorageApi } from '../firebase.js';


export function postFile(file) {
    return function(dispatch) {
      console.log('postFile', file);
      return firestorageApi.ref('/'+file.folder+'/' + file.remoteName).putString(file.base64String, 'data_url')
        .then(
          response => {
            console.log('postFile', response)
            return dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_FILE_TO_STORAGE))
          },
          error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_FILE_TO_STORAGE))
        );
    };
  }