import React from "react";
import {FormControl } from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import LabelTooltip from "./LabelTooltip";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import ConsoleUtil from "../../utils/ConsoleUtil";

export default ({
  props,
  label,
  input,
  placeholder,
  type,
  helpText,
  disabled,
  hasCopyPrevious,
  meta: { touched, invalid, error, form },
  ...custom
}) => {

  const isError = (touched && error) ? true : false;
  let classNames = 'textFieldWrap';
  classNames = isError ? 'hasError '+classNames : classNames;
  classNames = hasCopyPrevious ? 'hasCopyPrevious '+classNames : classNames;

  return (
    <FormControl error={isError} className={classNames} >
      <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
      { disabled ? <div className="rteWrap">{input.value}</div> : 
       <CKEditor
       {...input}
       config={
         {//'underline',
          toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'insertTable', 'undo', 'redo' ],
          table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
         }
         
       }
       editor={ ClassicEditor }
       data={input.value}
       onInit={ editor => {
           // You can store the "editor" and use when it is needed.
           //console.log( 'Editor is ready to use!', editor );
       } }
       onChange={ ( event, editor ) => {
           const data = editor.getData();
           input.onChange(data);
           //console.log( { event, editor, data } );
       } }
       onBlur={ editor => {
        //console.log( 'Blur.', editor );
       } }
       onFocus={ editor => {
        //console.log( 'Focus.', editor );
       } }
   />
      }



      {InputHelperText({ touched, error })}
    </FormControl>
    
  )
};

