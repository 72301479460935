import * as types from '../constants/actionTypes';


export function getSupportConversations(state = [], action) {
    if (action.type === types.GET_SUPPORT_CONVERSATIONS) {
        console.log(types.GET_SUPPORT_CONVERSATIONS+' set in store');
        return action.data;
    }
    return state;
}

export function getSupportConversationMessages(state = [], action) {
    if (action.type === types.GET_SUPPORT_CONVERSATION_MESSAGES) {
        console.log(types.GET_SUPPORT_CONVERSATION_MESSAGES+' set in store');
        return action.data;
    }
    return state;
}


export function getSupportConversation(state = null, action) {
    if (action.type === types.GET_SUPPORT_CONVERSATION) {
        console.log(types.GET_SUPPORT_CONVERSATION+' set in store');
        return action.data;
    }
    return state;
}



