import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import App from "./containers/App";
import ProgressLoaderUtil from "./utils/ProgressLoaderUtil";
import "sanitize.css/sanitize.css";
import "./theme/css/main.css";


const rootNode = document.getElementById("root");
const rootComponent = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(rootComponent, rootNode);
ProgressLoaderUtil();
