import React from "react";
import { Typography, Button, Box } from "@material-ui/core";
import TranslatorUtil from "../../utils/TranslatorUtil";
import Error404Svg from "./svgs/Error404Svg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeftRounded";
import UsersUtil from "../../utils/projectBased/UsersUtil";
import { Alert } from "@material-ui/lab";
import UserLoginWrap from "../../components/user/UserLoginWrap";

class NoMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
  }

  render() {
    const { location } = this.props;
    let redirectAferLogin = null;
    console.log("location", location);
    if (
      location &&
      location.pathname &&
      location.pathname.indexOf("/404") === -1 &&
      UsersUtil.getCurrentUserUid() === null
    ) {
      redirectAferLogin = location.pathname;
    }
    return (
      <div className="p-4">
        {UsersUtil.getCurrentUserUid() ? <React.Fragment>
          <Typography color="primary" variant="h1">
          404 - {TranslatorUtil.t("Page not found")}
        </Typography>
        <Typography variant="body2" className="py-2">
            Ujistěte se,že jste přihlášeni pod svým uživatelským účtem.
          </Typography>
          </React.Fragment> : (
          <Box className="py-2">
            {this.state.showForm ? (
              <UserLoginWrap redirectAfterLoginUrl={redirectAferLogin} />
            ) : (
              <React.Fragment>
                <Box className="py-2">
                  <Alert severity="error">Nejste přihlášen</Alert>
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.setState({ showForm: true })}
                >
                  {TranslatorUtil.t("Login")}
                </Button>
              </React.Fragment>
            )}
          </Box>
        )
        }
        <Typography variant="body2" className="py-2">
          V případě nejasností pište na
          <br /> <strong>info@contentwizards.cz</strong> <br />
          nebo volejte na
          <br /> <strong>+420 731 369 250</strong>.
        </Typography>
        <Button
          href="/"
          variant="text"
          startIcon={<ChevronLeftIcon />}
          color="default"
          className="pl-0"
        >
          {TranslatorUtil.t("Home page")}
        </Button>
        {this.state.showForm ? null : <div
          className="svgWrap p-4"
          style={{ maxWidth: "100%", width: "500px" }}
        >
          <Error404Svg />
        </div>}
      </div>
    );
  }
}
export default NoMatch;
