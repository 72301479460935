
export default (theme, themeConfig, themeColors) => {
  return {

    dashboardCardHeader: {
      paddingBottom: 10,
      '& .personIcon': {
        fontSize: 14
      },
      '& .calendarIcon': {
        fontSize: 12
      },
      '& svg': {
        marginTop: -3,
        color: themeColors.primaryPalette.primary
      },
    },
    cardBottomActions: {
      paddingTop: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      "& .leftTextWrap": {
        marginRight: 'auto',
      },
      '& > div': {
        width: '100%',
        display: 'flex'
      },
      '& button': {
        '& svg': {
          color: themeColors.primaryPalette.primary
        },
      },
      '& .smallIconBtn, .chipBtnWrap > div[role="button"]': {
        padding: 6,
        borderWidth: 1,
        borderStyle: 'solid'
      },
    },
    dashboardCard: {
      position: 'relative',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
      },
      '& a.cardMainActionElement':{
        textDecoration: 'none',
        color: themeColors.primaryPalette.black
      },
      '& .chipBtnWrap': {
        borderRadius: 16,
        fontWeight: 600
      },
      '& .smallIconBtn.imageBtnInputWrap':{
        fontSize: 16
      },
      '& .smallIconBtn, .chipBtnWrap > div[role="button"]': {
        borderColor: themeColors.primaryPalette.grey
      },
      '& .chipBtnWrap > div[role="button"]':{
        fontSize: 14,
        height: 34,
        '& > span':{
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        }

      },


    },

    timelineIntroWrap: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      '& strong': {
        paddingRight: theme.spacing(2),
        display: 'inline-block',
        minWidth: '200px',
      },
      '& .labelLike': {
        [theme.breakpoints.down("md")]: {
          display: 'block',
        }
      }
    },


    cardGeneralItemLink: {
      paddingLeft: theme.spacing(2),
      position: 'relative',
      marginTop: theme.spacing(1),
      '& a': {
        textDecoration: 'none',
        '& p, & span': {
          textDecoration: 'none',
        },
        '& span': {
          opacity: 0.5,
        }
      },
      '& .cardRIstateIndicator': {
        position: 'absolute',
        display: 'block',
        top: 0,
        left: -2,
        '& svg': {
          fontSize: 16
        }
      }
    }
  }
}