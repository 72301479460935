import React from 'react'
import { Typography } from '@material-ui/core';
import TranslatorUtil from '../../utils/TranslatorUtil';
import Error500Svg from './svgs/Error500Svg';

const ServerError = ({ location }) => (
  
   <div className='p-4'>
    
   <Typography color="primary" variant="h1">500 - {TranslatorUtil.t("Internal Server Error")}</Typography>
   <br />
   <Typography variant="body2">
          {TranslatorUtil.t("Sorry, something went wrong Please try again later")}
          </Typography>
   <div className="svgWrap" style={{maxWidth:"100%",width:"500px"}}>
   <Error500Svg />
   </div>
   
</div>
)

export default ServerError
