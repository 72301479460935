import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as previewActions from "../../../actions/preview";
import * as accessActions from "../../../actions/access";
import * as constants from '../../../constants/constants';
import ComponentLoading from "../../../components/ComponentLoading";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../../theme/styles";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import objectPath from "object-path";
import { Alert, AlertTitle } from "@material-ui/lab";
import classnames from 'classnames';
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import ProjectPreviewCard from "../../../components/project/ProjectPreviewCard";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import PageHeading from "../../../components/PageHeading";
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import ListUserCardsByRole from "../../../components/user/ListUserCardsByRole";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import LockIcon from '@material-ui/icons/Lock';
import DashboardIcon from '@material-ui/icons/Dashboard';

const entityListUrl = "/projects/";

class ProjectPreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        project: null,
        dataLoading: true,
        projectAccess: []
    }
  }


  getProject = () => {
      const { actions, match } = this.props;
    return actions.getProjectPreview(
        CompanyUtil.getId(),
      objectPath.get(match,'params.projectId',null)
      ).then((response) => this.setState({project: objectPath.get(response,'data')}));
    }


  componentDidMount() {
    var _this = this;
      const { userInfo } = this.props;
      if(userInfo){
        Promise.all([
          this.getProject(),
          this.getProjectAccessItems()
        ]).then(()=> _this.setState({dataLoading: false}));
      }
  }

  componentWillUnmount(){
      
  }

  getProjectAccessItems = () => {
    const { actions, match } = this.props;
        return actions.getCompanyProjectAccessItems(
            CompanyUtil.getId(),
            objectPath.get(match,'params.projectId',null)
            ).then((response) => this.setState({projectAccess: objectPath.get(response,'data')}));
  }

  



getUserEmail = () => {
  const { userInfo } = this.props;
  return objectPath.get(userInfo,'firebase.email',null);
}


goTo = () => {
  const { history } = this.props;
  history.push(UrlBuilderUtil.getRegistration())
}

  render() {
    const { dataLoading, project, projectAccess } = this.state;
    const { classes, history, profiles } = this.props;
    const projectID = objectPath.get(project,'id',null);


    return <div>
        <PageHeading heading={(TranslatorUtil.t("Preview"))+" - "+(dataLoading ? '...' : project ? project.name : TranslatorUtil.t("Not found"))} actions={
          <div>
            {AccessUtil.isAtLeastModeratorForProject(projectID) ? <React.Fragment>
                                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Discussions")}>
                                                          <IconButton  aria-label={TranslatorUtil.t("Discussions")} onClick={() => history.push(UrlBuilderUtil.getProjectDashboard(projectID))}>
                                                              <DashboardIcon />
                                                          </IconButton>
                                                      </Tooltip>
                                <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Notify users")}>
                                                          <IconButton  aria-label={TranslatorUtil.t("Notify users")} onClick={() => history.push(UrlBuilderUtil.getProjectNotifyUsers(projectID))}>
                                                              <NotificationsActiveIcon />
                                                          </IconButton>
                                                      </Tooltip>
                      
                                                      <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Users")}>
                                                          <IconButton  aria-label={TranslatorUtil.t("Users")} onClick={() => history.push(UrlBuilderUtil.getProjectUsersEdit(projectID))}>
                                                              <GroupIcon />
                                                          </IconButton>
                                                      </Tooltip>
                                                          <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Edit")}>
                                                        <IconButton  aria-label={TranslatorUtil.t("Edit")} onClick={() => history.push(UrlBuilderUtil.getProjectEdit(projectID))}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        </Tooltip>
                              </React.Fragment> : null }
                                                      
            {AccessUtil.isProjectLocked(project) ? <LockIcon color="disabled" /> : null }
            <HeadingBackBtn redirectToUrl={entityListUrl} />
        </div>
        } />
        <div className={classnames(classes.contentWithoutHeader,"narrowMaxMediumWidthPart")}>
            {dataLoading ? <ComponentLoading /> : (project ? <div>
                <ProjectPreviewCard 
                 hideTerms={false} 
                company={CompanyUtil.get()}
                project={project} />
                {
                    AccessUtil.isAtLeastObserverForProject(project.id) ? 
                    <div>
                        <ListUserCardsByRole users={AccessUtil.getProjectProfiles(constants.RESPONDENTS, projectAccess, profiles)} role={constants.RESPONDENTS} projectId={project.id} />
                        <ListUserCardsByRole users={AccessUtil.getProjectProfiles(constants.OBSERVERS, projectAccess, profiles)} role={constants.OBSERVERS} projectId={project.id} />
                        <ListUserCardsByRole users={AccessUtil.getProjectProfiles(constants.MODERATORS, projectAccess, profiles)} role={constants.MODERATORS} projectId={project.id} />
                    </div> : null
                }
                </div> : <div><Alert severity="error">
                <AlertTitle>{TranslatorUtil.t("Error")}</AlertTitle>
                      {TranslatorUtil.t("Unfortunatelly something went wrong and invitation has expired or is not valid anymore")}
                          
                    </Alert>
                    <br />
                    <NavLink className="linkBtn" to={"/"}>{TranslatorUtil.t('Continue here')}</NavLink>
                    </div>)
        
        }
          </div>
    </div>
  }
}


function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    profiles: state.profiles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...previewActions,
        ...accessActions
      },
      dispatch
    )
  };
}


export default withRouter(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectPreview)));
