import React from "react";
import { FormControl, List, ListItem, ListItemSecondaryAction, IconButton, Grid, Typography, Tooltip } from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import LabelTooltip from "./LabelTooltip";
//import Calendar from "react-calendar/dist/entry.nostyle";
import Calendar from "react-calendar";
import * as constants from "../../constants/constants";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import ArrayUtil from '../../utils/ArrayUtil';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles'
import DateUtil from "../../utils/DateUtil";
import classnames from 'classnames';


class DateInput extends React.Component {


  constructor(props) {
    super(props);
    var initSelectedValue = null;
    var initValues = props.input.value;// ensure it is init value
    //console.log('DateInput initValues',initValues);
    if(props.multiple){
      initSelectedValue = Array.isArray(initValues) ? initValues.map(x => DateUtil.backendDateFormat(x)) : [];
    } else if(initValues) {
      initSelectedValue = DateUtil.backendDateFormat(initValues);
    }
    this.state = {
      selectedValue: initSelectedValue
    }

  }

  renderDateListItem = (date, index) => {
    return date ? <ListItem key={index} divider>
              <Typography body="body2">{DateUtil.basicDateWithWeekday(date)}</Typography>
              <ListItemSecondaryAction>
                <Tooltip title="Remove Date">
                <IconButton aria-label="Delete" onClick={() => this.handleChange(date, false)}>
                  <DeleteIcon />
                </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem> : null
  }

  handleChange = (newValue, add = true) => {
    newValue = DateUtil.backendDateFormat(newValue); 
    const {
      input,
      multiple,
    } = this.props;
    
    let {
      selectedValue 
    } = this.state;


    if(multiple){
      selectedValue = Array.isArray(selectedValue) ? selectedValue : [];
      if(!add){
        selectedValue = ArrayUtil.removeFromArrayIfExist(selectedValue, newValue);
      } else {
        selectedValue = ArrayUtil.addToArrayIfNotExist(selectedValue, newValue);
      }
      //sort array
      selectedValue = DateUtil.sortArrayASC(selectedValue);
    } else {
      selectedValue = add ? newValue : null;
    }
    
    this.setState({
      selectedValue
    }, () => {
      input.onChange(selectedValue);
    });
    
    
  };

  render() {
    const {
      input,
      label,
      meta: { touched, error, form },
      helpText,
      multiple,
      classes
    } = this.props

    const { selectedValue } = this.state;

    return (
      <FormControl error={touched && error ? true : false} className={classes.calendarInputWrap}>
      <Grid container>
        <Grid item xs={12}>
          <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
          <Grid item xs={12}>
            {InputHelperText({ touched, error })}
          </Grid>
        </Grid>



      <Grid item xs={12} sm={6} md={7}>
        <Calendar
          locale={constants.DEFAULT_LOCALE}
          onChange={this.handleChange}
          tileContent={
            ({ date, view }) => view === 'month' && DateUtil.isDateSelected(date, selectedValue) ? <span className={classnames(classes.successIndicator,"indicator")}></span> : null
          }
          tileDisabled={({activeStartDate, date, view }) => !DateUtil.isPresentOrFuture(date)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
      <List>
              {multiple ? selectedValue.map((x,i) =>
                this.renderDateListItem(x, i)
              ) : 
              this.renderDateListItem(selectedValue, 1)
              }
            </List>
      </Grid>
      
      </Grid>
      
      
      
    </FormControl>
    )
  }
}


export default withStyles(styles, { withTheme: true })(DateInput)
