//import * as actionTypes from './actionTypes'
import React from "react";
import { ListItem, ListItemAvatar, Typography } from "@material-ui/core";
import IconUtil from "../utils/projectBased/IconsUtil";
import TranslatorUtil from "../utils/TranslatorUtil";

//APP
export const APP_NAME = "ChatLab by Content Wizards";
export const APP_NAME_SHORT = "ChatLab";
export const APP_DOMAIN = "https://chatlab.app/";
export const IMAGE_LOADER_PATH = "/assets/images/image_loader.svg";
export const BROKEN_IMAGE_PATH = "/assets/images/broken_image.svg";
export const DEFAULT_LISTING_PAGE = 0;
export const DEFAULT_LISTING_PAGE_SIZE = 25;
export const LISTING_PAGE_PARAM = 'page';
export const LISTING_PAGE_SIZE_PARAM = 'size';

export const LISTING_OBJECT_PAGE_PARAM = 'number';
export const LISTING_OBJECT_PAGE_SIZE_PARAM = 'size';

export const DEFAULT_LOCALE = 'cs-CZ';
export const DEFAULT_TRANSLATION_LANG = 'cs';
export const DEFAULT_CURRENCY = 'CZK';


export const DEV_HOSTS = ['localhost'];

export const YES_NO_OPTIONS = [{value:"true",label:"Yes"}, {value:"false",label:"No"}];

//UI
export const TOOLTIP_MENU_DELAY = 3000;
export const TOOLTIP_CARDS_DELAY = 1500;

//ERRORS
export const REDIRECTED_ERROR_STATUSES = [500];
export const ERROR_CUSTUM_HANDLED_GET_REQUESTS = [];

// USER ROLES
export const ADMIN = "ADMIN";
export const USER = "USER";

export const MODERATORS = "moderators";
export const OBSERVERS = "observers";
export const RESPONDENTS = "respondents";

// REPORTING ITEM FIELDS TO OVERWRITE
//UI
export const UI_BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl'];


export const ALL_ROLES_SELECT_OPTIONS = () => ["",RESPONDENTS,OBSERVERS,MODERATORS].map(function (role) {return {
    "value": role, "label": role === "" ? <Typography component="span">{TranslatorUtil.t("All project users")}</Typography> : <ListItem component="span" disableGutters={true} divider={false}>
    <ListItemAvatar>{IconUtil.getRoleAvatar(role)}</ListItemAvatar><Typography component="span">{TranslatorUtil.t(role)}</Typography>
    </ListItem> 
}});
