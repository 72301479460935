


import React from 'react';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
import ForumIcon from '@material-ui/icons/Forum';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AddCommentIcon from '@material-ui/icons/AddComment';

import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';
import ChatIcon from '@material-ui/icons/Chat';

import { Avatar } from '@material-ui/core';
import * as constants from '../../constants/constants';

export default class IconUtil {



      static getRoleAvatar = (role) => {
          var icon = IconUtil.getRoleIcon(role);
        if (role === constants.RESPONDENTS){
          return <Avatar className="primary-bg">{icon}</Avatar>;
        } else if(role === constants.OBSERVERS) {
          return <Avatar className="primary-bg">{icon}</Avatar>;
        } else if(role === constants.MODERATORS) {
          return <Avatar className="primary-bg">{icon}</Avatar>;
        }
        return null;
      }
    
      static getRoleIcon = (role) => {
        if (role === constants.RESPONDENTS){
          return <PeopleIcon />;
        } else if(role === constants.OBSERVERS) {
          return <VisibilityIcon />;
        } else if(role === constants.MODERATORS) {
          return <HeadsetMicIcon />;
        }
        return null;
      }
    
      static getNotificationIcon(type){
            
        switch (type) {
          case 1:
            return <ContactMailIcon />
          case 2:
            return <RecordVoiceOverIcon />
          case 3:
            return <PersonAddIcon />
          case 4:
            return <ForumIcon />
          case 5:
            return <AddCommentIcon />
          case 6:
            return <AssignmentIndIcon />
          case 8:
            return <ChatIcon />
          default:
            return <NotificationImportantIcon />
        }
      }

}

