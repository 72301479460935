
export default (theme, themeConfig, themeColors, themeFonts) => {
    return {
        timelineRow: {
            padding: themeConfig.setImportant(0),
            borderBottom: '1px solid '+themeColors.primaryPalette.grey,
            '&.reportUpdate':{
                '& .timelineRecordWrap':{
                    alignSelf: 'center',
                    [theme.breakpoints.down("xs")]: {
                        paddingTop: theme.spacing(1)*2,
                        paddingBottom: theme.spacing(1)*2
                    }
                }
            },
            '& .timelineRecordWrap':{
                padding: theme.spacing(1),
                [theme.breakpoints.up("sm")]: {
                    padding: theme.spacing(1)*3
                }
            },
            "& .personIcon, & .calendarIcon":{
                fontSize: 16,
                marginRight: 2,
                marginTop: -4
            },
            "& .dateWrap": {
                paddingTop: theme.spacing(1)*2,
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                textAlign: 'left',
                '& .calendarIcon':{
                    fontSize: 14
                },
                '& .date':{
                    display: 'inline-block',
                    paddingLeft: theme.spacing(1)*2,
                },
                '& .iconWrap':{
                    border: '1px solid '+themeColors.primaryPalette.primary,
                    borderRadius: '100%',
                    padding: theme.spacing(1),
                    display: 'inline-block',
                    '& svg':{
                        fontSize: 20
                    }
                },
                [theme.breakpoints.up("sm")]: {
                    textAlign: 'center',
                    paddingTop: 60,
                    paddingBottom: 60,
                    backgroundColor: themeColors.primaryPalette.white,
                    '& .date':{
                        display: 'block',
                        paddingLeft: 0
                    },
                    '& .iconWrap':{
                        marginBottom: theme.spacing(1)*3,
                        padding: theme.spacing(1)*2,
                        '& svg':{
                            fontSize: 28
                        }
                    }
                }
            }
        },
    }
}