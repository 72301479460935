import StoreUtil from '../StoreUtil';
import * as constants from '../../constants/constants';
import CompanyUtil from './CompanyUtil';
import UsersUtil from './UsersUtil';
import objectPath from 'object-path';
import DateUtil from '../DateUtil';
import ArrayUtil from '../ArrayUtil';

export default class AccessUtil {
  
  //PROJECT

  static logAccess(redirect){
    if(redirect){
      console.log('logAccess redirect from ' + window.location.href);
      return true;
    }
    return false;
  }

  static getAccess(){
    var access = StoreUtil.getValueByKey('access',[]);
    return access;
  }

  static getCompanyAccess(){
    var access = StoreUtil.getValueByKey('companyAccess',[]);
    return access;
  }

  static hasAccessToProject(companyId,projectId,email){
    return AccessUtil.isCompanyAdmin(false) || AccessUtil.getCompanyAccess().find(x => x.projectId === projectId && x.userEmails.includes(email)) !== undefined;
  }

  static getProjectIdsUserHasAccessToAsRepondent(email){
    return AccessUtil.getCompanyAccess().filter(x => x.roleType === constants.RESPONDENTS && x.userEmails.includes(email)).map(x => x.projectId);
  }

  static getRespondentsAccessByProject(projectId){
    var projectRoleAccess = AccessUtil.getCompanyAccess().find(x => x.projectId === projectId &&  x.roleType === constants.RESPONDENTS);
    return objectPath.get(projectRoleAccess,'userEmails',[]);
  }

  static getObserversAccessByProject(projectId){
    var projectRoleAccess = AccessUtil.getCompanyAccess().find(x => x.projectId === projectId && x.roleType === constants.OBSERVERS);
    return objectPath.get(projectRoleAccess,'userEmails',[]);
  }

  static getModeratorsAccessByProject(projectId){
    var projectRoleAccess = AccessUtil.getCompanyAccess().find(x => x.projectId === projectId && x.roleType === constants.MODERATORS);
    return objectPath.get(projectRoleAccess,'userEmails',[]);
  }

  static getProjectRoleProfiles(projectId, profiles){
    const moderatorEmails = AccessUtil.getModeratorsAccessByProject(projectId);
    if(moderatorEmails.length > 0 && profiles && profiles.length){
      const moderator = profiles.find(x => x.email === moderatorEmails[0]);
      if(moderator){
        return moderator.email;
      }
    }
    return null;
  }

  static getProjectModeratorEmail(projectId, profiles){
    const moderatorEmails = AccessUtil.getModeratorsAccessByProject(projectId);
    if(moderatorEmails.length > 0 && profiles && profiles.length){
      const moderator = profiles.find(x => x.email === moderatorEmails[0]);
      if(moderator){
        return moderator.email;
      }
    }
    return null;
  }

  

  static getProjectModeratorUid(projectId, profiles){
    const moderatorEmails = AccessUtil.getModeratorsAccessByProject(projectId);
    if(moderatorEmails.length > 0 && profiles && profiles.length){
      const moderator = profiles.find(x => x.email === moderatorEmails[0]);
      if(moderator){
        return moderator.uid;
      }
    }
    return null;
  }

  static getRoleAccessByProject(projectId,roleType){
    var projectRoleAccess = AccessUtil.getCompanyAccess().find(x => x.projectId === projectId && x.roleType === roleType);
    return objectPath.get(projectRoleAccess,'userEmails',[]);
  }

  static getRespondentsAccess(){
    var projectRoleAccess = AccessUtil.getCompanyAccess().find(x => x.roleType === constants.RESPONDENTS);
    return objectPath.get(projectRoleAccess,'userEmails',[]);
  }

  static getObserversAccess(){
    var projectRoleAccess = AccessUtil.getCompanyAccess().find(x => x.roleType === constants.OBSERVERS);
    return objectPath.get(projectRoleAccess,'userEmails',[]);
  }

  static getModeratorsAccess(){
    var projectRoleAccess = AccessUtil.getCompanyAccess().find(x => x.roleType === constants.MODERATORS);
    return objectPath.get(projectRoleAccess,'userEmails',[]);
  }

  static getUserRoleForProject(projectId, userEmail, includeAdmin = true){
    if(includeAdmin && CompanyUtil.getCurrentCompanyAdminEmails().includes(userEmail)){
      return "admin";
    } else if (AccessUtil.getModeratorsAccessByProject(projectId).includes(userEmail)){
      return constants.MODERATORS.substring(0, constants.MODERATORS.length - 1);
    } else if (AccessUtil.getObserversAccessByProject(projectId).includes(userEmail)){
      return constants.OBSERVERS.substring(0, constants.OBSERVERS.length - 1);
    } else if (AccessUtil.getRespondentsAccessByProject(projectId).includes(userEmail)){
      return constants.RESPONDENTS.substring(0, constants.RESPONDENTS.length - 1);
    }
      return null;
  }

  static isAtLeastRespondentForProject(projectId,redirect){
    var userEmail = UsersUtil.getCurrentUserEmail();
    var pass = AccessUtil.getRespondentsAccessByProject(projectId).includes(userEmail) 
    || AccessUtil.getObserversAccessByProject(projectId).includes(userEmail) 
    || AccessUtil.getModeratorsAccessByProject(projectId).includes(userEmail) 
    || AccessUtil.isCompanyAdmin();
    if(AccessUtil.logAccess(redirect)){
      console.log('isAtLeastRespondentForProject',[projectId,userEmail,pass]);
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isAtLeastObserverForProject(projectId,redirect){
    var userEmail = UsersUtil.getCurrentUserEmail();
    var pass = AccessUtil.getObserversAccessByProject(projectId).includes(userEmail) || AccessUtil.getModeratorsAccessByProject(projectId).includes(userEmail) || AccessUtil.isCompanyAdmin();
    if(AccessUtil.logAccess(redirect)){
      console.log('isAtLeastObserverForProject',[projectId,userEmail,pass]);
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isObserverForProject(projectId,redirect){
    var userEmail = UsersUtil.getCurrentUserEmail();
    var pass = AccessUtil.getObserversAccessByProject(projectId).includes(userEmail);
    if(AccessUtil.logAccess(redirect)){
      console.log('isObserverForProject',[projectId,userEmail,pass]);
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isRespondentForProject(projectId,redirect){
    var userEmail = UsersUtil.getCurrentUserEmail();
    var pass = AccessUtil.getRespondentsAccessByProject(projectId).includes(userEmail);
    if(AccessUtil.logAccess(redirect)){
      console.log('isRespondentForProject',[projectId,userEmail,pass]);
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isAtLeastModeratorForProject(projectId,redirect){
    var userEmail = UsersUtil.getCurrentUserEmail();
    var pass = AccessUtil.getModeratorsAccessByProject(projectId).includes(userEmail) || AccessUtil.isCompanyAdmin();
    if(AccessUtil.logAccess(redirect)){
      console.log('isAtLeastModeratorForProject',[projectId,userEmail,pass]);
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isModeratorForProject(projectId,redirect){
    var userEmail = UsersUtil.getCurrentUserEmail();
    var pass = AccessUtil.getModeratorsAccessByProject(projectId).includes(userEmail);
    if(AccessUtil.logAccess(redirect)){
      console.log('isRespondentForProject',[projectId,userEmail,pass]);
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isAtLeastForProject(projectId, roleType, redirect){
    var pass = false;
    if(roleType === constants.RESPONDENTS){
      pass = AccessUtil.isAtLeastRespondentForProject(projectId);
    } else if(roleType === constants.OBSERVERS){
      pass = AccessUtil.isAtLeastObserverForProject(projectId);
    } else if(roleType === constants.MODERATORS){
      pass = AccessUtil.isAtLeastModeratorForProject(projectId);
    }
    if(AccessUtil.logAccess(redirect)){
      console.log('isAtLeastForProject',[projectId,roleType,pass]);
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isUserAtLeastModeratorForProject(projectId, userEmail){
    var pass = AccessUtil.getModeratorsAccessByProject(projectId).includes(userEmail) || CompanyUtil.getCurrentCompanyAdminEmails().includes(userEmail);
    if(AccessUtil.logAccess(false)){
      console.log('isUserAtLeastModeratorForProject',[projectId,userEmail,pass]);
    }
    return pass;
  }

  static isUserRespondentForProject(projectId, userEmail){
    var pass = AccessUtil.getRespondentsAccessByProject(projectId).includes(userEmail);
    if(AccessUtil.logAccess(false)){
      console.log('isUserRespondentForProject',[projectId,userEmail,pass]);
    }
    return pass;
  }

  static canEditProject(projectId,redirect){
    var pass = AccessUtil.isAtLeastModeratorForProject(projectId);
    if(redirect && AccessUtil.logAccess(redirect)){
      console.log('canEditProject');
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  //USERS

  static canViewUserTags(userId){//is in some project of company as at leat observer
    return AccessUtil.isAtLeastObserver();
  }

  static canViewUserComments(userId){//is in some project of company as at leat observer
    return AccessUtil.isAtLeastObserver();
  }

  static canEditUserTags(userId){//is in some project of company as at leat moderator
    return AccessUtil.isAtLeastModerator();
  }

  static canEditUserComments(userId){//is in some project of company as at leat moderator
    return AccessUtil.isAtLeastModerator();
  }

  

  //GENERAL

  static redirect(){
    return document.location.replace('/401');
  }

  static isAtLeastRespondent(redirect){
    var pass = AccessUtil.isRespondent() || AccessUtil.isObserver() || AccessUtil.isModerator() || AccessUtil.isCompanyAdmin();
    if(redirect && AccessUtil.logAccess(redirect)){
      console.log('isAtLeastRespondent');
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isAtLeastObserver(redirect){
    var pass = AccessUtil.isObserver() || AccessUtil.isModerator() || AccessUtil.isCompanyAdmin();
    if(redirect && AccessUtil.logAccess(redirect)){
      console.log('isAtLeastObserver');
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isAtLeastModerator(redirect){
    var pass = AccessUtil.isModerator() || AccessUtil.isCompanyAdmin();
    if(redirect && AccessUtil.logAccess(redirect)){
      console.log('isAtLeastModerator');
    }
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }


  static is(roleType){
    var userEmail = UsersUtil.getCurrentUserEmail();
    if(AccessUtil.logAccess(false)){
      console.log('is',[roleType,userEmail,AccessUtil.getAccess()]);
    }
    var found = false;
    AccessUtil.getAccess().filter(x => roleType === x.roleType).forEach(access => {
      if(!found && objectPath.get(access,'userEmails',[]).includes(userEmail)){
        found = true;
      }
    });
    return found;
  }

  static isRespondent(redirect){
    var pass = AccessUtil.is(constants.RESPONDENTS);
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isObserver(redirect){
    var pass = AccessUtil.is(constants.OBSERVERS);
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isModerator(redirect){
    var pass = AccessUtil.is(constants.MODERATORS);
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isCompanyAdmin(redirect){
    var pass = CompanyUtil.getCurrentCompanyAdminEmails().includes(UsersUtil.getCurrentUserEmail());
    return !pass && redirect ? AccessUtil.redirect() : pass;
  }

  static isAtLeast(roleType){
    var pass = false;
    if(roleType === constants.RESPONDENTS){
      pass = AccessUtil.isAtLeastRespondent();
    } else if(roleType === constants.OBSERVERS){
      pass = AccessUtil.isAtLeastObserver();
    } else if(roleType === constants.MODERATORS){
      pass = AccessUtil.isAtLeastModerator();
    }
    if(AccessUtil.logAccess(false)){
      console.log('isAtLeast',[roleType,pass]);
    }
    return pass;
  }

  static isProjectLocked(project){
    return objectPath.get(project,'locked',false) || DateUtil.isBeforeNow(objectPath.get(project,'lockDate',null),objectPath.get(project,'lockTime',null));
  }

  static isDiscussionLocked(discussion){
    return objectPath.get(discussion,'locked',false) || DateUtil.isBeforeNow(objectPath.get(discussion,'lockDate',null),objectPath.get(discussion,'lockTime',null));
  }

  static isDiscussionPublished(discussion){
    return objectPath.get(discussion,'published',false)
     //|| DateUtil.isAfterNow(objectPath.get(discussion,'publishDate',null),objectPath.get(discussion,'publishTime',null))
     ;
  }

  static getProjectProfiles(roleType, projectAccess, profiles) {
    let projectRoleProfiles = [];
    if (
      ArrayUtil.isNonEmptyArray(projectAccess) &&
      ArrayUtil.isNonEmptyArray(profiles)
    ) {
      let roleEmails = [];
      projectAccess.filter((x) => x.roleType === roleType).forEach(x => {
        roleEmails = roleEmails.concat(objectPath.get(
          x,
          "userEmails",
          []
        ));
      });
      if (ArrayUtil.isNonEmptyArray(roleEmails)) {
        projectRoleProfiles = profiles.filter((profile) =>
          roleEmails.includes(profile.email)
        );
      }
    }
    let uniqueProjectRoleProfiles = projectRoleProfiles.filter(
      (item, index, array) =>
        array.map((x) => x.email).indexOf(item.email) === index
    );
    // console.log("uniqueProjectRoleProfiles",uniqueProjectRoleProfiles.map(x => { return {email: x.email,id: x.id}}));
    return uniqueProjectRoleProfiles;
  }

  static getProjectInvitationsWithoutProfiles(roleType, projectAccess, profiles) {
    let projectRoleProfileEmails = [];
    let roleEmails = [];
    if (
      ArrayUtil.isNonEmptyArray(projectAccess)
    ) {
      
      projectAccess.filter((x) => x.roleType === roleType).forEach(x => {
        roleEmails = roleEmails.concat(objectPath.get(
          x,
          "userEmails",
          []
        ));
      });
      if (ArrayUtil.isNonEmptyArray(roleEmails) && ArrayUtil.isNonEmptyArray(profiles)) {
        projectRoleProfileEmails = profiles.filter((profile) =>
          roleEmails.includes(profile.email)
        ).map(x => x.email);
      }
    }
    let uniqueInvitationsWithoutProfiles = roleEmails.filter(x => !projectRoleProfileEmails.includes(x)).filter(
      (item, index, array) => array.indexOf(item) === index
    );
    return uniqueInvitationsWithoutProfiles;
  }



}
