import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";
import ProjectUsersForm from "../../../forms/projectUsers/form";
import * as accessActions from "../../../actions/access";
import * as notificationActions from "../../../actions/notification";
import * as discussionActions from "../../../actions/discussion";
import * as projectActions from "../../../actions/project";
import * as formNames from "../../../constants/forms";
import * as constants from "../../../constants/constants";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import FormatterUtil from "../../../utils/FormatterUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import StoreUtil from "../../../utils/StoreUtil";
import ArrayUtil from "../../../utils/ArrayUtil";
import ValidationUtil from "../../../utils/ValidationUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import ListUserCardsByRole from "../../../components/user/ListUserCardsByRole";
import firestoreApi from "../../../firebase";
import * as firebaseCollections from '../../../constants/firebaseCollections';
import { MAIL_TYPES } from "../../../constants/mails";
import { Box, Button, Hidden, Paper, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../../components/TabPanel";
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from "@material-ui/icons/Add";
import RespondentsProjectStats from "../../../components/user/RespondentsProjectStats";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { Alert } from "@material-ui/lab";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";

const entityName = "project";
const entityFormName = formNames.PROJECT_USERS_FORM_NAME;


class EditProjectUsers extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true,
      usersOptions: [],
      tabId : 0,
      discussions: [],
    }
  }

  componentDidMount(){
    this.mountItems();
  }


  mountProjectAccess = (updateLoading) => {
    if(updateLoading){
      this.setState({
        dataLoading: true
      });
    }
    return Promise.all([
      this.mountItem(constants.MODERATORS),
      this.mountItem(constants.OBSERVERS),
      this.mountItem(constants.RESPONDENTS),
    ]).finally(() => {
      if(updateLoading){
        this.setState({
          dataLoading: false
        });
      }
    });
  }

  mountItems = () => {
    this.setState({
      dataLoading: true
    })
    Promise.all([
      this.getProject(),
      this.mountProjectAccess(false),
      this.mountDiscussions()
    ]).then(() => {

      this.mountUsersOptions();
      this.setState({
        dataLoading: false
      })
    });
  }

  mountDiscussions = () => {
    const { actions, match } = this.props;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    AccessUtil.isAtLeastModeratorForProject(projectId,true);
    actions.getDiscussions(projectId).then((response) => {
      return this.setState({
        discussions: response.data
      });
    });
  }

  mountItem = (roleType) => {
    const { actions, userInfo, match } = this.props;
    const _this = this;
    var companyId = objectPath.get(userInfo, "company.id");
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    if(companyId && projectId && roleType){
      AccessUtil.isAtLeastModeratorForProject(projectId,true);
      return actions.getAccessItem(companyId,projectId,null,roleType).then( response => {
//        console.log('mountItem', companyId , projectId, roleType, objectPath.get(response,'data.userEmails') );
        if(objectPath.get(response, "data")){
          _this.setState(prevState => {
            let item = Object.assign({}, prevState.item);  
            item[roleType] = objectPath.get(response, "data.userEmails",[])
            item[roleType+'Id'] = objectPath.get(response, "data.id");    
            return { item };                                 
          })
        }else {
          return Promise.resolve();
        }
        
      }
      );
    }
    return Promise.resolve();
  }



  mountUsersOptions = () => {

    var roles = [constants.MODERATORS,constants.OBSERVERS,constants.RESPONDENTS];
    const { profiles } = this.props;
    const { item } = this.state;

    var profilesOptions = UsersUtil.getProfilesOptions(profiles);
    var nonProfileValues = [];
    roles.forEach(role => {
      if(objectPath.get(item,role)){
        item[role].forEach(email => {
          //if does not exist in profiles and not in nonProfilesValues
          if(profilesOptions.findIndex(x => x.value === email) === -1 
          && nonProfileValues.findIndex(x => x.value === email) === -1){
            nonProfileValues.push({
              label: email,
              value: email
            });
          }
        });
      }
      
    });

    this.setState({
      usersOptions: profilesOptions.concat(nonProfileValues)
    });
    // this.setState(prevState => {
    //   let usersOptions = Object.assign({}, prevState.usersOptions);  
    //   usersOptions[roleType] = newOptionValues;   
    //   return { usersOptions };                                 
    // });
  }

  validateNewOption = (option) => {
    const { enqueueSnackbar } = this.props;
    const { usersOptions } = this.state;
    var isValid = ValidationUtil.isValidEmail(objectPath.get(option,'value'));
    if(!isValid){
      enqueueSnackbar(TranslatorUtil.t("Email badly formatted"), {
        variant: "error"
      });
    } else {
      this.setState({
        usersOptions: usersOptions.concat([option])
      });
    }
    return isValid;
  }

  validateBeforeSelect = (name, selection) => {
    const { enqueueSnackbar } = this.props;
    var roles = [constants.MODERATORS,constants.OBSERVERS,constants.RESPONDENTS];
    var isValid = true;
    roles.splice(roles.indexOf(name),1);
    roles.forEach(role => {
      var formValues = StoreUtil.getValueByKey('form.'+entityFormName+'.values.'+role,[]);
      if(selection && isValid && ArrayUtil.arraysHaveMatch(formValues, selection.map(x => x.value))){
        isValid = false;
      }
    });
    if(!isValid){
      enqueueSnackbar(TranslatorUtil.t("User already exists in another role"), {
        variant: "error"
      });
    }
    return isValid;
  }


  handleResponse = (response, roleType) => {
    const { enqueueSnackbar } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      console.log('handleResponse',response);
      
    }
  }


  updateRole = (values,roleType) => {
    const { actions } = this.props;
    const { item } = this.state;
    var modifiedValues = {
      companyId: objectPath.get(values, 'companyId', null),
      projectId: objectPath.get(values, 'projectId', null),
      discussionId: objectPath.get(values, 'discussionId', null),
      userEmails: objectPath.get(values, roleType,[]),
      roleType
   };

   var emailsToNotify = modifiedValues.userEmails.filter(x => !objectPath.get(item,roleType,[]).includes(x));
   

   var updateObjectId = objectPath.get(item, roleType+"Id");
   //console.log('updateRole',modifiedValues,roleType)
   if(updateObjectId){
    modifiedValues.id = updateObjectId;
      return actions.putUserAccess(modifiedValues).then((response)=>{
        this.sendInvitationEmails(emailsToNotify,roleType).then(()=>this.handleResponse(response, roleType));
      });
   } else {
      return actions.postUserAccess(modifiedValues).then((response)=>{
        this.sendInvitationEmails(emailsToNotify,roleType).then(()=>this.handleResponse(response, roleType));
      });
   }
    
    
  }


  sendInvitationEmails = async (userEmails,roleType) => {
    const { discussions } = this.state;
    const { userInfo, actions } = this.props;
    var companyId = objectPath.get(userInfo, 'company.id');
    let project = this.getProject();
    const publishedDiscussions = ArrayUtil.isNonEmptyArray(discussions) ? discussions.filter(x => objectPath.get(x, 'published', false)) : [];
    if(publishedDiscussions.length){
      //skip exisiting notifications
      let invitationNotifications = await firestoreApi.collection(firebaseCollections.NOTIFICATION).where('type', '==', MAIL_TYPES.INVITATION.ID).where('projectId', '==', project.id).get();
      let invitationNotificationsEmails = invitationNotifications.empty ? [] : invitationNotifications.docs.map(x => x.data().to);
      userEmails = userEmails.filter(x => !invitationNotificationsEmails.includes(x));
      userEmails = userEmails.filter((x, i, a) => a.indexOf(x) === i);
      if(project && ArrayUtil.isNonEmptyArray(userEmails)){
        return Promise.all(userEmails.map(userEmail => {
          return actions.postNotification(EmailUtil.getInvitationEmailObject(companyId,project,userEmail,roleType));
        }))
      }
    }
    return Promise.resolve();
  }

  updateAccess = (values) => {
    const { actions, enqueueSnackbar } = this.props;
    return Promise.all([
      this.updateRole(values,constants.MODERATORS),
      this.updateRole(values,constants.OBSERVERS),
      this.updateRole(values,constants.RESPONDENTS),
    ]).finally(() => {
      var successMessage = //"\""+TranslatorUtil.t(roleType)+"\" "+
      TranslatorUtil.t("Roles for this project has been updated");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      actions.getCompanyAccessItems(CompanyUtil.getId());
    });
  }

  handleSubmit = values => {
    const { userInfo, match } = this.props;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = objectPath.get(match, "discussionId", 'all');
    var companyId = objectPath.get(userInfo, 'company.id');
    if(companyId && projectId && discussionId){
      return this.updateAccess(
        {
          ...values,
          projectId,
          discussionId,
          companyId
        }
      ).then(() => {
        this.mountProjectAccess(true);
      });
    }
    
    
  };


  getPageHeading = () => {
    const { selectedProject } = this.props;
    return TranslatorUtil.t("Manage access for ")
    +" "+TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName))
    +" \""+objectPath.get(selectedProject,'name','')+"\"";
  }

  getProject = () => {
    const { match, projects, selectedProject, actions } = this.props;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    if(selectedProject === null || (selectedProject && selectedProject.id !== projectId)){
      actions.getProject(projectId);
    }
    return projects.find(x => x.id === projectId);
  }

  getProjectProfiles = (roleType) => {
    const { item } = this.state;
    const { profiles } = this.props;
    var projectRoleProfiles = [];
    if(ArrayUtil.isNonEmptyArray(profiles)){
        var roleEmails = objectPath.get(item,roleType,[]);
        if(ArrayUtil.isNonEmptyArray(roleEmails)){
            projectRoleProfiles = profiles.filter(profile => roleEmails.includes(profile.email));
        } 
    }
    return projectRoleProfiles.filter((item, index, array) => array.map(x => x.email).indexOf(item.email) === index);
    
    
  }


  render() {
    const { match, profiles, selectedProject } = this.props;
    const { item, dataLoading, usersOptions, tabId, discussions } = this.state;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    const respondentEmails = objectPath.get(item,constants.RESPONDENTS,[]);
    console.log('projectAccess',item);

    return (
      <div>
        <PageHeading heading={this.getPageHeading() } actions={
          <div>
            <HeadingBackBtn redirectToUrl={UrlBuilderUtil.getProjectDashboard(projectId)} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : <React.Fragment> {}
        <Tabs value={tabId} onChange={(event, tabId) => this.setState({tabId: tabId })} className="tabs-wrap" color="primary" indicatorColor="primary">
    <Tab color="primary" label={<Box><PeopleIcon /><Hidden xsDown> {TranslatorUtil.t("Respondents")}</Hidden></Box>} />
    <Tab color="primary" label={<Box><ContactMailIcon /><Hidden xsDown> {TranslatorUtil.t("User profiles")}</Hidden></Box>} />
    <Tab color="primary" label={<Box><SettingsIcon /><Hidden xsDown> {TranslatorUtil.t("Settings")}</Hidden></Box>}  />
  </Tabs>
<TabPanel value={tabId} index={0}>
        {
          this.getProjectProfiles(constants.RESPONDENTS).length || respondentEmails.length ?  
          <Paper><RespondentsProjectStats discussions={discussions} profiles={this.getProjectProfiles(constants.RESPONDENTS)} invitationsWithoutProfiles={AccessUtil.getProjectInvitationsWithoutProfiles(constants.RESPONDENTS, [
            {
              roleType: constants.RESPONDENTS,
              userEmails: respondentEmails
            }
          ],profiles)} projectId={projectId} /></Paper> : <Box>
            <Alert severity="info">{TranslatorUtil.t("No respondents found")}</Alert>
            <Box pt={4}>
            <Button color="secondary" variant="contained" onClick={() => this.setState({tabId: 2})} startIcon={<AddIcon />}>{TranslatorUtil.t("Add respondents")}</Button>
            </Box>
          </Box>
        }
</TabPanel>
<TabPanel value={tabId} index={1}>
                    <div>
                        <ListUserCardsByRole users={this.getProjectProfiles(constants.RESPONDENTS)} role={constants.RESPONDENTS} projectId={projectId} />
                        <ListUserCardsByRole users={this.getProjectProfiles(constants.OBSERVERS)} role={constants.OBSERVERS} projectId={projectId} />
                        <ListUserCardsByRole users={this.getProjectProfiles(constants.MODERATORS)} role={constants.MODERATORS} projectId={projectId} />
                    </div>
</TabPanel>
<TabPanel value={tabId} index={2}>
 {AccessUtil.isProjectLocked(selectedProject) ? <Alert severity="warning">{TranslatorUtil.t("Project is locked")}</Alert> : <ProjectUsersForm 
        proceedSubmit={this.handleSubmit} 
        initialValues={item}
        usersOptions={usersOptions.filter((item, index, array) => array.map(x => x.value).indexOf(item.value) === index)}
        validateNewOption={this.validateNewOption}
        validateBeforeSelect={this.validateBeforeSelect}
        projectId={projectId}
        project={this.getProject()}
      /> }

       
</TabPanel>
        
      </React.Fragment>
       }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profiles: state.profiles,
  userInfo: state.userInfo,
  access: state.access,
  projects: state.projects,
  selectedProject: state.selectedProject
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...accessActions,
      ...notificationActions,
      ...discussionActions,
      ...projectActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProjectUsers)
));