

export default (theme, themeConfig, themeColors) => {
    return {
        logo: {
            display: 'block',
            
            position: 'absolute',
            zIndex: 100,
            top: 0,
            left: themeConfig.commonPadding,
            backgroundImage: "url(/assets/logo.svg)",
            backgroundSize: "contain",
            lineHeight: "47px",
            paddingLeft: "55px",
            fontWeight: 900,
            height: 45,
            margin: "10px 15px",
            [theme.breakpoints.up("md")]: {
                margin: "10px auto",
            }
            

        },
        navbarLogo: {
            position: 'relative',
            left: -themeConfig.commonPadding,
            [theme.breakpoints.up("md")]: {
            left: -(3*theme.spacing(1))
            }
          },
    }
}