import React from 'react'
import AppCard from '../../components/AppCard';
import { Typography, Button } from '@material-ui/core';

const ConnectionError = ({ location }) => (
  <div>
    <AppCard type="error">
          <Typography variant="h1">Network error occured</Typography>

          <br />
          <Typography variant="body1">
          Network connection error. Please try again later.
          </Typography>
          <br />
          <br />
          <Button href="/" variant="contained" color="default">Home page</Button>

    </AppCard>
  </div>
)

export default ConnectionError
