import objectPath from "object-path";
import StoreUtil from "../StoreUtil";
import * as collections from "../../constants/firebaseCollections";

export default class CompanyUtil {
  static getCollectionPathWithCompanyPrefix(name, appended = []) {
    return [collections.COMPANIES, CompanyUtil.getId(), name]
      .concat(appended)
      .join("/");
  }

  static get() {
    var userInfo = StoreUtil.getValueByKey("userInfo", null);
    return objectPath.get(userInfo, "company", null);
  }

  static getId() {
    var userInfo = StoreUtil.getValueByKey("userInfo", null);
    return objectPath.get(userInfo, "company.id", null);
  }

  static getName() {
    var userInfo = StoreUtil.getValueByKey("userInfo", null);
    return objectPath.get(userInfo, "company.name", null);
  }

  static getMockedCompany() {
    return {
      id: "ieHj3XWnXNTU9feY1Zuo",
      name: "ChatLab",
      adminEmails: [
        "hynek@flexmate.cz",
        "anna.salova@contentwizards.cz",
        "karel.sal@institutstatistickehovyzkumu.gmail.com",
      ],
    };
  }

  static getCurrentCompanyAdminEmails() {
    return objectPath.get(
      StoreUtil.getValueByKey("userInfo", null),
      "company.adminEmails",
      []
    );
  }

  static getCompanyUserTags(projectId = null, excludeProfileHardTags = false) {
    var companyUserTags = StoreUtil.getValueByKey("userTags", []);
    var tags = [];
    companyUserTags.forEach(function(userTagsDoc) {
      var userTags = projectId
        ? objectPath.get(userTagsDoc, "id", "").includes("_p_" + projectId)
          ? objectPath.get(userTagsDoc, "tags", [])
          : []
        : objectPath.get(userTagsDoc, "tags", []);
      userTags.forEach(function(tag) {
        if (!tags.includes(tag)) {
          tags.push(tag);
        }
      });
    });
    if(excludeProfileHardTags){
      tags = tags.filter(x => !x.includes("__profile__age_") && !x.includes("__profile__gender_"));
    }
    return tags;
  }
}
