import { Button, Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import objectPath from "object-path";
import React from "react";
import { withRouter } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import * as collections from "../../constants/firebaseCollections";
import firestoreApi from "../../firebase";
import styles from "../../theme/styles";
import ArrayUtil from "../../utils/ArrayUtil";
import DateUtil from "../../utils/DateUtil";
import FirestoreCollectionUtil from "../../utils/FirestoreCollectionUtil";
import FormatterUtil from "../../utils/FormatterUtil";
import AccessUtil from "../../utils/projectBased/AccessUtil";
import CompanyUtil from "../../utils/projectBased/CompanyUtil";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";
import UsersUtil from "../../utils/projectBased/UsersUtil";
import TranslatorUtil from "../../utils/TranslatorUtil";
import ComponentLoading from "../ComponentLoading";

class ExportProjectData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      discussion: null,
      dataLoading: true,
      selectedTags: [],
      discussionsMessages: {},
      discussionsLikes: {},
      projectRelatedDirectMessages: {}
    };
  }

  componentDidMount() {
    this.loadAllData();
  }

  loadAllData = async () => {
    this.setState({
      dataLoading: true,
    });
    const { match, discussions } = this.props;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    const discussionsMessages = {};
    const discussionsLikes = {};
    await Promise.all(
      discussions.map(async (d) => {
        discussionsMessages[d.id] = FirestoreCollectionUtil.parseCollectionData(
          await firestoreApi
            .collection(
              CompanyUtil.getCollectionPathWithCompanyPrefix(
                collections.PROJECTS,
                [projectId, collections.DISCUSSIONS, d.id, collections.MESSAGES]
              )
            )
            .get()
        );
        discussionsLikes[d.id] = FirestoreCollectionUtil.parseCollectionData(
          await firestoreApi
            .collection(
              CompanyUtil.getCollectionPathWithCompanyPrefix(
                collections.PROJECTS,
                [projectId, collections.DISCUSSIONS, d.id, collections.LIKES]
              )
            )
            .get()
        );
      })
    );

    const projectRelatedDirectMessages = FirestoreCollectionUtil.parseCollectionData(
      await firestoreApi
        .collectionGroup(collections.MESSAGES)
        .where("directChatMessage", "==", true)
        .where("projectId", "==", projectId)
        .orderBy("createdTime", "asc")
        .get()
    );
    this.setState({
      dataLoading: false,
      discussionsMessages: discussionsMessages,
      discussionsLikes: discussionsLikes,
      projectRelatedDirectMessages: projectRelatedDirectMessages,
    });
  };

  getSelectedDiscussion = () => {
    const { discussions, discussionId } = this.props;
    const discussion = discussions.find((x) => x.id === discussionId);
    return discussion !== undefined ? discussion : null;
  };

  getSelectedDiscussionMessages = () => {
    const { discussions, discussionId, onlyStarredMessages } = this.props;
    const { discussionsMessages } = this.state;
    const discussion = this.getSelectedDiscussion();
    if (discussion) {
      return ArrayUtil.sortByDateCreated(objectPath.get(discussionsMessages, discussionId, []), false).filter((x) => !onlyStarredMessages || objectPath.get(x, "isStarred", false));
    } else {
      const allMessages = [];
      discussions.forEach((d) => {
        allMessages.push(...ArrayUtil.sortByDateCreated(
          objectPath.get(discussionsMessages, d.id, []),
          false
        ));
      });
      return allMessages.filter((x) => !onlyStarredMessages || objectPath.get(x, "isStarred", false));
    }
  };

  getSelectedDiscussionDirectMessages = () => {
    const { discussionId } = this.props;
    const { projectRelatedDirectMessages } = this.state;
    if (discussionId) {
      return projectRelatedDirectMessages.filter((x) => x.discussionId === discussionId);
    } else {
      return projectRelatedDirectMessages;
    }
  };

  getSelectedDiscussionLikes = () => {
    const { discussions, discussionId } = this.props;
    const { discussionsLikes } = this.state;
    const discussion = this.getSelectedDiscussion();
    if (discussion) {
      return objectPath.get(discussionsLikes, discussionId, []);
    } else {
      const allLikes = [];
      discussions.forEach((d) => {
        allLikes.push(...objectPath.get(discussionsLikes, d.id, []));
      });
      return allLikes;
    }
  };

  getModeratorIds = () => {
    const { profiles, selectedProject } = this.props;
    return profiles.filter((x) => AccessUtil.getModeratorsAccessByProject(selectedProject.id).includes(x.email)).map((x) => x.uid);
  };

  getFilteredUsers = () => {
    const {
      match,
      profiles,
      selectedProject,
      messagesFilterRespondentId,
      filteredUserIdsByTags,
    } = this.props;
    const { selectedTags } = this.state;
    const discussionsMessages = this.getSelectedDiscussionMessages();
    const discussionsLikes = this.getSelectedDiscussionLikes();
    // console.log("discussionsMessages", discussionsMessages);
    // console.log("discussionsLikes", discussionsLikes);
    var projectId = selectedProject
      ? selectedProject.id
      : UrlBuilderUtil.getRequestedParam(match, "projectId");

    let respondentEmails = AccessUtil.getRespondentsAccessByProject(projectId);
    let projectUserEmails = respondentEmails.concat(
      AccessUtil.getModeratorsAccessByProject(projectId)
    );

    // console.log("projectUserEmails", projectUserEmails);

    let uniqueUserProfiles = profiles.filter(
      (item, index, array) =>
        projectUserEmails.includes(item.email) &&
        array.map((x) => x.email).indexOf(item.email) === index
    );

    if (messagesFilterRespondentId) {
      uniqueUserProfiles = uniqueUserProfiles.filter(
        (x) => x.id === messagesFilterRespondentId
      );
    }

    if (ArrayUtil.isNonEmptyArray(filteredUserIdsByTags)) {
      uniqueUserProfiles = uniqueUserProfiles.filter((x) =>
        filteredUserIdsByTags.includes(x.id)
      );
    }

    let users = uniqueUserProfiles.map((x) => {
      var cloned = Object.assign({}, x);
      cloned.messagesCount = discussionsMessages.filter(
        (x) => x.byUserId === cloned.uid
      ).length;
      cloned.responsesCount = discussionsMessages.filter(
        (x) =>
          x.byUserId === cloned.uid && objectPath.has(x, "replyToMessageId")
      ).length;
      cloned.localized_gender = objectPath.has(cloned, "gender")
        ? TranslatorUtil.t(cloned.gender)
        : "";
      cloned.isRespondentOnly = respondentEmails.includes(cloned.email) ? 1 : 0;
      cloned.userTags = UsersUtil.getUserTagsByUserId(cloned.uid);
      cloned.userLikesCount = discussionsLikes.filter((x) =>
        objectPath.get(x, "likes",[]).includes(cloned.uid)
      ).length;
      cloned.userDislikesCount = discussionsLikes.filter((x) =>
        objectPath.get(x, "dislikes",[]).includes(cloned.uid)
      ).length;
      Object.assign(cloned, this.getUserTagsColumns(cloned));
      return cloned;
    });

    //apply filters
    if (selectedTags && selectedTags.length) {
      users = users.filter((x) => {
        var matchesNeeded = selectedTags.length;
        var matchesFound = 0;
        selectedTags.forEach((t) => {
          var tagValue = t.value;
          if (tagValue.indexOf("__profile__") !== -1) {
            if (
              tagValue.indexOf("__profile__age__") !== -1 &&
              x.age === tagValue.replace("__profile__age__", "")
            ) {
              matchesFound++;
            }
            if (
              tagValue.indexOf("__profile__gender__") !== -1 &&
              x.gender === tagValue.replace("__profile__gender__", "")
            ) {
              matchesFound++;
            }
          } else if (x.userTags.includes(tagValue)) {
            matchesFound++;
          }
        });
        return matchesFound === matchesNeeded;
      });
    }

    return users;
  };

  getUserTagsColumns = (user) => {
    var userTags = {};

    this.getProjectTags().forEach((tag) => {
      userTags[
        "tag_" + FormatterUtil.getSlugVersion(tag)
      ] = user.userTags.includes(tag) ? 1 : 0;
    });
    return userTags;
  };

  mapMessageForExport = (m, users, directReactionsCount, level) => {
    const { match, discussions } = this.props;

    const discussionLikes = this.getSelectedDiscussionLikes();
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = objectPath(m, "discussionId");
    var cloned = Object.assign({}, m);
    var levelSign = "__";
    var levelPrefix = "";
    for (var i = 0; i < level; i++) {
      levelPrefix = levelPrefix + levelSign;
    }
    cloned.message = `${levelPrefix}${m.message.replaceAll('"', " ' ")}`;
    var user = users.find((u) => u.uid === m.byUserId);
    var messageUser = {};
    if (user) {
      Object.keys(user).forEach((p) => {
        messageUser["user_" + p] = objectPath.get(user, p, "");
      });
      Object.assign(messageUser, this.getUserTagsColumns(user));
    }
    cloned.createdTime = DateUtil.localizedTime(m.createdTime);
    cloned.isPrivateMessage = 0;
    cloned.anonymous = objectPath.get(m, "anonymous", 0) ? 1 : 0;
    cloned.directReactionsCount = directReactionsCount;
    cloned.likesCount = objectPath.get(
      discussionLikes.find((x) => objectPath.get(x, "id", null) === m.id),
      "likes",
      []
    ).length;
    cloned.dislikesCount = objectPath.get(
      discussionLikes.find((x) => objectPath.get(x, "id", null) === m.id),
      "dislikes",
      []
    ).length;
    cloned.link =
      UrlBuilderUtil.getRequestedHostUrl() +
      UrlBuilderUtil.getDiscussionConversation(
        projectId,
        discussionId,
        cloned.id
      );
    cloned.isStarred = objectPath.get(m, "isStarred", false) ? 1 : 0;
    cloned.replyToLink = objectPath.get(cloned, "replyToMessageId", null)
      ? UrlBuilderUtil.getRequestedHostUrl() +
        UrlBuilderUtil.getDiscussionConversation(
          projectId,
          discussionId,
          cloned.replyToMessageId
        )
      : "";
    const discussionData = {};
    if(this.getSelectedDiscussion() === null){
      const messageDiscussion = discussions.find((x) => x.id === objectPath.get(cloned, "discussionId", null));
      if(messageDiscussion){
        discussionData["discussionName"] = objectPath.get(messageDiscussion, "name", "");
        discussionData["discussionDescription"] = objectPath.get(messageDiscussion, "description", "").replace(/(<([^>]+)>)/gi, "");
      } else {
        discussionData["discussionName"] = "";
        discussionData["discussionDescription"] = "";
      }
    }
    return Object.assign(discussionData, cloned, messageUser);
  };

  mapDirectMessageForExport = (m, users, level) => {
    const { discussions } = this.props;
    var cloned = Object.assign({}, m);
    var levelSign = "_-_";
    var levelPrefix = "";
    for (var i = 0; i < level; i++) {
      levelPrefix = levelPrefix + levelSign;
    }
    cloned.message = `${levelPrefix}${m.message.replaceAll('"', " ' ")}`;
    var user = users.find((u) => u.uid === m.byUserId);
    var messageUser = {};
    if (user) {
      Object.keys(user).forEach((p) => {
        messageUser["user_" + p] = objectPath.get(user, p, "");
      });
      Object.assign(messageUser, this.getUserTagsColumns(user));
    }
    cloned.createdTime = DateUtil.localizedTime(m.createdTime);
    cloned.isPrivateMessage = 1;
    cloned.directReactionsCount = 0;
    cloned.isStarred = 0;
    cloned.likesCount = 0;
    cloned.dislikesCount = 0;
    cloned.link = "";
    cloned.replyToLink = "";
    const discussionData = {};
    if(this.getSelectedDiscussion() === null){
      const messageDiscussion = discussions.find((x) => x.id === objectPath.get(cloned, "discussionId", null));
      if(messageDiscussion){
        discussionData["discussionName"] = objectPath.get(messageDiscussion, "name", "");
        discussionData["discussionDescription"] = objectPath.get(messageDiscussion, "description", "").replace(/(<([^>]+)>)/gi, "");
      } else {
        discussionData["discussionName"] = "";
        discussionData["discussionDescription"] = "";
      }
    }
    return Object.assign(discussionData, cloned, messageUser);
  }

  getRepliesForExport = (directReactions, users, level = 0, messages, directPrivateMessages) => {
    let output = [];
    directReactions.forEach((m) => {
      const _directReactions = messages.filter(
        (x) => objectPath.get(x, "replyToMessageId", null) === m.id
      );
      output.push(
        this.mapMessageForExport(m, users, _directReactions.length, level)
      );
      output = output.concat(
        this.getDirectMessagesForExport(m.id, users, level + 1, directPrivateMessages)
      );
      if (_directReactions.length) {
        output = output.concat(
          this.getRepliesForExport(_directReactions, users, level + 1, messages, directPrivateMessages)
        );
      }
    });
    return output;
  };

  getDirectMessagesForExport = (messageId, users, level = 0, directPrivateMessages) => {
    let output = [];
    let conversations = {};
    const directMessageRelatedToMessage = directPrivateMessages.filter(m => m.messageId === messageId);
    directMessageRelatedToMessage.forEach((m) => {
      if (!objectPath.has(conversations,m.userIds.join("_"))) {
        conversations[m.userIds.join("_")] = [];
      }
      conversations[m.userIds.join("_")].push(m);
    });
    
    Object.keys(conversations).forEach((cKey) => {
      conversations[cKey].forEach((m) => {
        output.push(
          this.mapDirectMessageForExport(m, users, level)
        );
      });
    });
    return output;
  };

  getFilteredMessages = () => {
    const messages = this.getSelectedDiscussionMessages();
    const directPrivateMessages = this.getSelectedDiscussionDirectMessages();
    console.log("directPrivateMessages", directPrivateMessages);
    let users = this.getFilteredUsers();
    let userIds = users.map((x) => x.uid);
    const filteredOrderedMessages = messages.filter((m) => userIds.includes(m.byUserId));

    let output = [];

    filteredOrderedMessages
      .filter((m) => objectPath.get(m, "replyToMessageId", null) === null)
      .forEach((m) => {
        var directReactions = messages.filter(
          (x) => objectPath.get(x, "replyToMessageId", null) === m.id
        );
        output.push(
          this.mapMessageForExport(m, users, directReactions.length, 0)
        );
        output = output.concat(
          this.getDirectMessagesForExport(m.id, users, 1, directPrivateMessages)
        );
        if (directReactions.length) {
          output = output.concat(
            this.getRepliesForExport(directReactions, users, 1, messages, directPrivateMessages)
          );
        }
        
      });
    return output;
  };

  getExportFileName = () => {
    const {
      selectedProject,
      userTags,
      userFilterTags,
      messagesFilterRespondentId,
      profiles,
    } = this.props;
    const discussion = this.getSelectedDiscussion();
    const userFilterTagLabels = userTags
      .filter((x) => userFilterTags.includes(x.value))
      .map((x) => x.label);
    const userNames = profiles
      .filter((x) => x.id === messagesFilterRespondentId)
      .map((x) => x.displayName);
    let sectionNames = [];
    if (selectedProject) {
      sectionNames.push(selectedProject.name);
    }
    if (discussion) {
      sectionNames.push(discussion.name);
    }
    if (ArrayUtil.isNonEmptyArray(userFilterTagLabels)) {
      sectionNames.push(userFilterTagLabels.join("-|-"));
    }
    if (ArrayUtil.isNonEmptyArray(userNames)) {
      sectionNames.push(userNames.join("-|-"));
    }
    sectionNames.push(DateUtil.localizedTimeWithSeconds());
    return FormatterUtil.getSlugVersion(sectionNames.join("__") + ".xlsx");
  };


  exportXlsx = async () => {
    const { selectedProject } = this.props;
    const messageExportProps = this.getExportHeaders("messages").map((x) => x.key);
    const listMessagesData = this.getFilteredMessages().map((x) => {
      let exportMessage = {};
      messageExportProps.forEach((prop) => {
        exportMessage[prop] = objectPath.get(x, prop, "");
      });
      return exportMessage;
    });;
    const wsMessages = utils.json_to_sheet(listMessagesData, {
      origin: "A2",
      skipHeader: true,
    });
    utils.sheet_add_aoa(
      wsMessages,
      [this.getExportHeaders("messages").map((x) => x.label)],
      { origin: "A1" }
    );

    const userExportProps = this.getExportHeaders("users").map((x) => x.key);

    const listUsersData = this.getFilteredUsers()
      .filter((x) =>
        AccessUtil.isUserRespondentForProject(
          selectedProject ? selectedProject.id : null,
          x.email
        )
      )
      .map((x) => {
        let exportUser = {};
        userExportProps.forEach((prop) => {
          exportUser[prop] = objectPath.get(x, prop, "");
        });
        return exportUser;
      });
    const wsUsers = utils.json_to_sheet(listUsersData, {
      origin: "A2",
      skipHeader: true,
    });
    utils.sheet_add_aoa(
      wsUsers,
      [this.getExportHeaders("users").map((x) => x.label)],
      { origin: "A1" }
    );

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, wsMessages, TranslatorUtil.t("All comments"));
    utils.book_append_sheet(
      wb,
      wsUsers,
      TranslatorUtil.t("Users and their properties")
    );
    /* export to XLSX */

    writeFile(wb, this.getExportFileName());
  };

  getExportHeaders = (type) => {
    var userPrepend = "👤 ";
    var fixHeaders =
      type === "users"
        ? [
            { label: TranslatorUtil.t("Display name"), key: "displayName" },
            { label: TranslatorUtil.t("Email"), key: "email" },
            { label: TranslatorUtil.t("Phone"), key: "phone" },
            { label: TranslatorUtil.t("Description"), key: "description" },

            { label: TranslatorUtil.t("Age"), key: "age" },
            { label: TranslatorUtil.t("Gender"), key: "localized_gender" },
            {
              label: TranslatorUtil.t("Total messages count"),
              key: "messagesCount",
            },
            {
              label: TranslatorUtil.t("Responses count"),
              key: "responsesCount",
            },
            { label: TranslatorUtil.t("Likes count"), key: "userLikesCount" },
            { label: TranslatorUtil.t("Dislikes count"), key: "userDislikesCount" },

            // { label: TranslatorUtil.t("Email notifications off"), key: "emailNotificationsOff" },
            // { label: TranslatorUtil.t("id"), key: "uid" },
          ]
        : [
            { label: TranslatorUtil.t("Message"), key: "message" },
            { label: TranslatorUtil.t("Star"), key: "isStarred" },
            { label: TranslatorUtil.t("Private message"), key: "isPrivateMessage" },
            {
              label: TranslatorUtil.t("Direct responses count"),
              key: "directReactionsCount",
            },
            { label: TranslatorUtil.t("Likes count"), key: "likesCount" },
            { label: TranslatorUtil.t("Dislikes count"), key: "dislikesCount" },
            { label: TranslatorUtil.t("anonymous"), key: "anonymous" },
            { label: TranslatorUtil.t("Created"), key: "createdTime" },
            //{ label: TranslatorUtil.t("reply to message id"), key: "replyToMessageId" },
            { label: TranslatorUtil.t("message link"), key: "link" },
            {
              label: TranslatorUtil.t("reply to message link"),
              key: "replyToLink",
            },
            {
              label: userPrepend + TranslatorUtil.t("Display name"),
              key: "user_displayName",
            },
            {
              label: userPrepend + TranslatorUtil.t("Email"),
              key: "user_email",
            },
            {
              label: userPrepend + TranslatorUtil.t("Total messages count"),
              key: "user_responsesCount",
            },
            {
              label: userPrepend + TranslatorUtil.t("Likes count"),
              key: "user_userLikesCount",
            },
            {
              label: userPrepend + TranslatorUtil.t("Dislikes count"),
              key: "user_userDislikesCount",
            },
            {
              label: userPrepend + TranslatorUtil.t("Is respondent"),
              key: "user_isRespondentOnly",
            },

            {
              label: userPrepend + TranslatorUtil.t("Phone"),
              key: "user_phone",
            },
            {
              label: userPrepend + TranslatorUtil.t("Description"),
              key: "user_description",
            },
            { label: userPrepend + TranslatorUtil.t("Age"), key: "user_age" },
            {
              label: userPrepend + TranslatorUtil.t("Gender"),
              key: "user_localized_gender",
            },

            // { label: userPrepend+TranslatorUtil.t("Email notifications off"), key: "user_emailNotificationsOff" },
            // { label: userPrepend+TranslatorUtil.t("Tags"), key: "user_userTags" },
            // { label: userPrepend+TranslatorUtil.t("id"), key: "user_uid" },
          ];

    if (type !== "users" && this.getSelectedDiscussion() === null) {
      fixHeaders = [
        {
          label: TranslatorUtil.t("Discussion"),
          key: "discussionName",
        },
        {
          label: TranslatorUtil.t("Discussion description"),
          key: "discussionDescription",
        },
      ].concat(fixHeaders);
    }

    return fixHeaders.concat(
      this.getProjectTags().map((x) => ({
        label: x,
        key: "tag_" + FormatterUtil.getSlugVersion(x),
      }))
    );
  };

  getProjectTags = () => {
    const { selectedProject } = this.props;
    return CompanyUtil.getCompanyUserTags(
      selectedProject ? selectedProject.id : null,
      true
    );
  };

  render() {
    if (this.state.dataLoading) {
      return <ComponentLoading />;
    }

    const {
      discussionId,
      userFilterTags,
      userTags,
      profiles,
      messagesFilterRespondentId,
    } = this.props;
    // const { discussion } = this.state;
    console.log("userTags", userTags, userFilterTags);

    return (
      <div>
        <React.Fragment>
          <div className="flex chips-wrap mb-2">
            {profiles
              .filter((x) => x.id === messagesFilterRespondentId)
              .map((x) => (
                <Chip key={x.id} label={x.email} />
              ))}
            {userTags
              .filter((x) => userFilterTags.includes(x.value))
              .map((x) => (
                <Chip key={x} label={x.label} />
              ))}
          </div>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloudDownloadIcon />}
            onClick={this.exportXlsx}
          >
            {discussionId
              ? TranslatorUtil.t(
                  "Export data only related to the selected discussion"
                )
              : TranslatorUtil.t("Export whole project")}
          </Button>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(ExportProjectData)
);
