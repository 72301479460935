import * as types from '../constants/actionTypes';


export function getNotifications(state = [], action) {
    if (action.type === types.GET_NOTIFICATIONS) {
        console.log(types.GET_NOTIFICATIONS+' set in store');
        return action.data;
    }
    return state;
}




