import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";

export default ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText className={touched && error ? "hasError":""} error={error && touched}>{touched && error}</FormHelperText>;
  }
};
