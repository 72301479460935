import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, ListItem, ListItemAvatar, Paper, ListItemText, Grid, Button, ButtonGroup, Hidden, Badge } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import UserAvatar from '../user/UserAvatar';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import objectPath from 'object-path';
import ReplyIcon from '@material-ui/icons/Reply';
import UrlBuilderUtil from '../../utils/projectBased/UrlBuilderUtil';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import UsersUtil from '../../utils/projectBased/UsersUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import classnames from 'classnames';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LinkIcon from '@material-ui/icons/Link';
import { withSnackbar } from 'notistack';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

export const canEditMessageAfterMs = 1000 * 60; 


class MessageItem extends React.Component {


  renderListText = () => {
    const { classes, message, projectId, currentUserId, isRead} = this.props;    

    const isByModerator = AccessUtil.isUserAtLeastModeratorForProject(projectId,UsersUtil.getUserEmailFromStoreById(message.byUserId));
    const notCurrent = currentUserId !== message.byUserId;
    return <ListItemText
      secondaryTypographyProps={{ component: "div" }}
      className={(isByModerator ? "messageContentWrap moderatorMessage" : "messageContentWrap") + (notCurrent ? " otherMessage" : " myMessage") + (isRead ? " read" : " unread")}
      secondary={
        <Paper elevation={1} className={"p-2 messagePaper"} component="div" style={{ display: "block" }}>
          <Typography component="div" className={classnames(classes.inline, "messageTextWrap")} color="textPrimary">

            {message.message}
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <small className="messageDate">{DateUtil.displayTimeFromNow(message.createdTime, TranslatorUtil.getUserLangFromStore())}</small>
              <small className="messageUserId hidden">{message.byUserId}</small>
              <small className="messageId hidden">{message.id}</small>
            </Grid>
            <Grid item xs={6} className="likeBtns">
              { this.renderReactions() }  
            </Grid>
          </Grid>

        </Paper>
      }
    />
  }

  renderReactions = () => {
    const { message, currentUserId, onLikeDislike, likes, onMessageReply, disableReactions, onReplyText, onDiscussMessageWithModerator, onToggleMessageRead, isRead, onMessageEdit, showCopyMessageLink, enqueueSnackbar, onToggleMessageStarred } = this.props;
    
    if(likes){
      var messageLikes = {
        id: message.id,
        likes:objectPath.get(likes.find(x => x.id === message.id), 'likes', []),
        dislikes:objectPath.get(likes.find(x => x.id === message.id), 'dislikes', []),
      };


      const likesCount = objectPath.get(messageLikes, 'likes', []).length;
      const hasUserLiked = objectPath.get(messageLikes,'likes', []).includes(currentUserId);
      const dislikesCount = objectPath.get(messageLikes, 'dislikes', []).length;
      const hasUserDisliked = objectPath.get(messageLikes, 'dislikes', []).includes(currentUserId);

      return <ButtonGroup>
        { onMessageEdit && currentUserId === message.byUserId && DateUtil.timestamp() - message.createdTime < canEditMessageAfterMs ? <Button
        variant="text"
        size="small"
        onClick={() => onMessageEdit(message)}
        startIcon={<EditIcon />}
      >
        <Hidden lgDown>{TranslatorUtil.t("Edit")}</Hidden>
      </Button> : null }
    { onDiscussMessageWithModerator? <Button
        variant="text"
        size="small"
        onClick={() => onDiscussMessageWithModerator(message)}
        startIcon={<QuestionAnswerIcon />}
      >
        <Hidden lgDown>{TranslatorUtil.t("Ask moderator")}</Hidden>
      </Button> : null }
    { onMessageReply && !disableReactions ? <Button
        variant="text"
        size="small"
        onClick={() => onMessageReply(message.id)}
        startIcon={<ReplyIcon />}
      >
        <Hidden lgDown>{onReplyText}</Hidden>
      </Button> : null }
      <Button
        variant="text"
        size="small"
        disabled={!onLikeDislike || hasUserDisliked || disableReactions}
        className={"likeBtn "+(hasUserLiked ? "liked" : "")}
        onClick={() => onLikeDislike ? onLikeDislike(messageLikes,true) : null }
        startIcon={<ThumbUpAltRoundedIcon />}
      >
        {likesCount ? likesCount : ''}
      </Button>
      <Button
        variant="text"
        size="small"
        disabled={!onLikeDislike || hasUserLiked || disableReactions}
        onClick={() => onLikeDislike ? onLikeDislike(messageLikes,false) : null }
        className={"dislikeBtn "+(hasUserDisliked ? "liked" : "")}
        startIcon={<ThumbDownRoundedIcon />}
      >
        {dislikesCount ? dislikesCount : ''}
      </Button>
      {
        !disableReactions && onToggleMessageStarred ? <Button className="star-message-btn"
        variant="text"
        size="small"
        onClick={() => onToggleMessageStarred(message)}
        startIcon={objectPath.get(message, 'isStarred', false) ? <StarIcon /> : <StarBorderIcon />}
      >
      </Button> : null
      }
      {
        showCopyMessageLink ? <CopyToClipboard className="copy-message-link-btn" text={UrlBuilderUtil.getAbsoluteProjectDiscussionLink(message.projectId, message.discussionId, message.id)}
        onCopy={() => enqueueSnackbar(TranslatorUtil.t("Message link has been copied"), { variant: "info"})}>
          <Button variant="text" size="small" className="MuiButton-text"
          startIcon={<LinkIcon style={{ fontSize: 16 }} />}
          ></Button>
      </CopyToClipboard> : null
      }
      {!disableReactions && <Button
        variant="text"
        size="small"
        disabled={!onToggleMessageRead}
        onClick={() => onToggleMessageRead ? onToggleMessageRead(message) : null }
        startIcon={isRead ? <Badge anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }} badgeContent={<DoneIcon style={{ fontSize: 14 }} />}>
        <VisibilityIcon style={{ fontSize: 16 }} />
      </Badge> : <VisibilityIcon style={{ fontSize: 16 }} />}
      >
      </Button> }
    </ButtonGroup>
    }else{
      return null;
    }
  }

  renderAvatar = () => {
    const { message, currentUserId, match, disableAvatarActions, avatarBadgeContent, discussionId } = this.props;
    var notCurrent = currentUserId !== message.byUserId;
    var currentProjectId = UrlBuilderUtil.getRequestedParam(match, 'projectId');
    const userAvatar = message.anonymous ? <UserAvatar anonymous={true} userId={message.byUserId} /> : <UserAvatar userId={message.byUserId}
    menuItems={ disableAvatarActions ?  {} : {
      withDialog: AccessUtil.isAtLeastObserverForProject(currentProjectId) ? notCurrent : false,
      withChat: AccessUtil.isAtLeastModeratorForProject(currentProjectId) ? notCurrent : false,
      withSupport: AccessUtil.isAtLeastModeratorForProject(currentProjectId) ? notCurrent : false,
      withChatAboutDiscussionMessage: AccessUtil.isAtLeastModeratorForProject(currentProjectId) ? {
        projectId: currentProjectId,
        discussionId: discussionId ? discussionId : UrlBuilderUtil.getRequestedParam(match, 'discussionId'),
        message
      } : false
    }
  } />;
    return <ListItemAvatar className="avatar">{
      message.anonymous ? userAvatar : 
      avatarBadgeContent ? <Badge classes={{badge: "bg-white"}} badgeContent={avatarBadgeContent}>{userAvatar}</Badge> : 
        
      userAvatar
      
    }

    </ListItemAvatar>
  }

  highlightMessage = () => {
    const { message } = this.props;
    return objectPath.get(window,'location.hash','').indexOf(message.id) !== -1;
  }

  render() {
    const { message } = this.props;

    return message ? <ListItem alignItems="center" disableGutters={true} id={message.id} className={"messageItem "+(this.highlightMessage() ? "highlight" : "")}>
                {this.renderAvatar()}
                {this.renderListText()}
                

              </ListItem> : null
    };
}


export default withSnackbar(withRouter(withStyles(styles, { withTheme: true })(MessageItem)));