import pace from "pace-js";

export default function() {
  pace.start({
    ajax: {
      trackMethods: ["GET", "POST", "PUT", "DELETE", "REMOVE"]
    },
    restartOnRequestAfter: true
  });
}
