export default {
    "type": "service_account",
    "project_id": "opinions-wizard",
    "private_key_id": "b270c7fd67ed9620242351d3d485688015febb8a",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCnEEhnk4SGekub\nat120YlURp/qgeE8JNEskhn7+i4WvXMA5tJKYg/aRB7B9XUPwQTA9R4X/9j/eQhM\nJEc4U/9TFMSKwmjpKM2bxL7WvVymCnIudBMz0LSw7G+qx8Z6bnXCTK3K/IVuZ7Da\n7KLeIJxXbo5rnxqk1b325CRIG2Otv60yKr16H6zouU2KnBlp+Agjo7e30kz+ts2/\n4Jl0ERIFORxn8xnn1efK4ErKDltEmIQdYupbamgzHUEOTHQDkY1s+9iEeEDPEULo\ngvWuRRw4tM3C9IWpEPV+Y4EmeEdO1CSwX0Fook7L8YEhpi6gWslPLfbD9wVNK5wX\n/InRGYcTAgMBAAECggEAKW1wvvqGTQCrmg0sgOfh+ZuP0UvGyi7JDhfmyKTX4/yz\ntYAHmrlpe/MTe35+Oltfbef6v/HKYkOlqpf+bznpITWPrWcbM+JR5jQqLL8FaAlE\nGj/feSRiglMHN97VWYvluLpiCGmJPwEIiqlOk+2bR1UbjRWFumVkd4JIevcLx0xP\npGSuTaz1pnkPk/SS688OEBnyyXqJkOzbSIY7zjjZLsGlvAwLXIQNSLfIwLugHwnk\nK98yr33aKd3rI5HJY8LU1S7FldvUXlleJU5/ovfBzS5dOaAdnRCIiPQNjwGeAKrM\nayeM3m/4AYg1I0RbyL5qKsLji7JnlCcfOJ9nn4iFJQKBgQDXZKJOS33cX7ukGD9N\nj4A8sFKOpdh5Yr1DDtXlbP+KC7TPbu0qZUhd4YiB9gTMIBkOeuUeJJbuT18dQF7x\n7Qr3yQDVGcmZygvMnH9hZDoSZqZf/9Qy4P9pBx9cdh6Rl5ifdm4g3pS1bRQUkwCA\n/hG2TG4/qanLoFhjyZnjjM+uRwKBgQDGjygAwJTsiz99h1+wVfeSgMsDyxfHcO69\nfrJn56XddBCxqrax0pmbsxcxOBcv8L5C0Dj/jFhgaiAGbyug3AqenoNGcvAVkzDa\nJgpim1G7GVNQwRzKgzpwueO5BTSvBj3jyedMGEaxzXGAcew5e2fI8rZKXNUqAikW\nlZeQ1itK1QKBgQC6VUWPxAD6vxq9x3gj6ct/NO4Gl3Rg9klSlKf31MZwLtZZSNYF\nAh+Lj8se2qylxfLHMwu4euDTAnj+/ivk0DLXztxYffmIgGCavpv3rdQLDSBLFi8F\nYN2zh0TbIRgqzWFsOfDloIg3N0jSz6T08lZyRUZu7qCEFo/Aq6vkTXFwiwKBgQCU\n8oqlldGhaAumlMh5NOPUqHyiSdV6rQLKb6JRQ3ovF4P2YwNNfZfxLnU1XMg4FDYF\nVMGNIbCiEroXWYm2z5m/x1TeohXreVDqNCMSOUUkPyk6qMJ6P9q0+R0UjA0rRlli\n6PBzKrrNYjnEirETpbkDCt8oBV96dchwwqLB6YbsSQKBgEMdcmYIycVPHrfIE5Pl\nnc6CWmSLG9JeRrQJlcg8dJkGw64vuLSguJDKvhRST7IFoPuZPwG/vmNcIjCB/BJc\n1S3EtSjfTLimjv32pjcpIEAf92ztqQBt3bVDU4yNbB3J+wY1XnQqXfKWwYgSeQgk\nch3hhbPZ3qexQR1OlmFm7u6L\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-v3i1b@opinions-wizard.iam.gserviceaccount.com",
    "client_id": "107600900922639270260",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-v3i1b%40opinions-wizard.iam.gserviceaccount.com",
    "api_key": "AIzaSyA3GQ0WFWExpJUEnoI42naFknMrv-j_Me8",
    "project_number": "529364264178"
  }
  