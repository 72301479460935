
import React from 'react';
import { Button, DialogActions } from "@material-ui/core";
import AppDialog from '../AppDialog';
import TranslatorUtil from '../../utils/TranslatorUtil';

function ConfirmDialog(props) {
    const { onClose, message, onConfirm } = props;
  
  
  
    return (
      <AppDialog 
        warningStyle={true}
      title={TranslatorUtil.t("Are you sure?")}
      message={message}
      onClose={() => onClose(null)}
      actions={
        <DialogActions>
          <Button variant="text" onClick={() => onClose()}>{TranslatorUtil.t("Cancel")}</Button>
          <Button variant="contained" color="default" className="errorBtn" onClick={() => onConfirm()}>{TranslatorUtil.t("Confirm")}</Button>
        </DialogActions>
      } />
    );
  }
  
  
  
  export default ConfirmDialog;