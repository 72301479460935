import { createMuiTheme } from "@material-ui/core/styles";
import {
  primaryPalette,
} from "./constants/colors";
import {
  defaultFont, headingFont
} from "./constants/fonts";

//see https://material-ui.com/customization/default-theme/ for list of all variables

const CustomTheme = createMuiTheme({
  palette: {
    primary: { main: primaryPalette.primary }, // Purple and green play nicely together.
    secondary: { main: primaryPalette.secondary, contrastText: primaryPalette.white }, // This is just green.A700 as hex.
    background: {
      paper: primaryPalette.white,
      default: primaryPalette.white
    },
  },
  typography: { 
    fontFamily: defaultFont, // '"Open Sans", sans-serif'
    useNextVariants: true,
    h1: {
      fontFamily: headingFont,
      fontSize: 24,
      fontWeight: 900,
      lineHeight: 'normal'
    },
    h2: {
      fontSize: 24,
      fontWeight: 900,
      fontFamily: headingFont,
    },
    h3: {
      fontSize: 18,
      fontFamily: headingFont,
    },
    h4: {
      fontFamily: headingFont,
      fontWeight: 600,
      fontSize: '1.2rem',
      textTransform: 'uppercase',
    },
    h5: {
      fontSize: '1.1rem',
    },
    button: {
      fontWeight: 900,
      textTransform: 'none'
    },
    caption: {
      fontSize: '0.85rem',
    },
    body2: {
      fontSize: '0.95rem',
    }
  }
});

export default CustomTheme;
