import React, { Component } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import AddImageIcon from "@material-ui/icons/PhotoOutlined";

import FormatterUtil from "../../utils/FormatterUtil";
import FileList from "../FileList";
import ArrayUtil from "../../utils/ArrayUtil";
import objectPath from "object-path";
import TranslatorUtil from "../../utils/TranslatorUtil";
import LabelTooltip from "./LabelTooltip";
import DateUtil from "../../utils/DateUtil";

export default class ImageInput extends Component {

  filesChanged = (e) => {
    const {
      input,
      maxFileSize,
      multiple
    } = this.props;

    var singleMaxFileSize = maxFileSize || 8388608; //8MB default

    if(multiple){
      if(e.target && e.target.files){
        var inputFiles = this.getCurrentValue();
        e.target.files.forEach(fileObject => {
          FormatterUtil.getBase64(fileObject).then(data => {
            //does exist already
            if(singleMaxFileSize > fileObject.size && inputFiles.findIndex(x => x.name === fileObject.name && x.size === fileObject.size) === -1){
              inputFiles.push({
                name: fileObject.name,
                folder: DateUtil.formatForSorting(null),
                remoteName: FormatterUtil.getRemoteFileName(fileObject.name),
                type: fileObject.type,
                base64String: data,
                size: fileObject.size,
              });
            }
          }).then(() => {
            input.onChange(Array.from(inputFiles));
          });  
        });
        
      }
    } else {
      if(e.target && e.target.files && e.target.files[0]){
        var fileObject = e.target.files[0];
        FormatterUtil.getBase64(fileObject).then(data => {
          input.onChange({
            name: fileObject.name,
            folder: DateUtil.formatForSorting(null),
            remoteName: FormatterUtil.getRemoteFileName(fileObject.name),
            type: fileObject.type,
            base64String: data,
            size: fileObject.size,
          });
        });
      }
      
      
    }
    
  }

  getCurrentValue = () => {
    const {
      input,
      multiple
    } = this.props;
    var value = objectPath.get(input,'value');
    if(multiple){
      value = ArrayUtil.isNonEmptyArray(value) ? Array.from(value) : [];
    }
    return value;
  }

  removeFileFromList = (index) => {
    const {
      input,
      multiple
    } = this.props;
    
    var newValue = null;

    if(multiple){
      newValue = this.getCurrentValue().filter((x,i) => i !== index);
    }
    input.onChange(newValue);
    
  }

  selectedFiles = (value) => {
    const {
      multiple,
      disabled
    } = this.props;
    var filesArray = multiple ? Array.from(value) : value ? [value] : null;
    return  <FileList files={filesArray} disabled={disabled} removeFileFromList={this.removeFileFromList} />
  }

  render() {
    //const { input: { value } } = this.props;
    const {
      input,
      label,
      name,
      meta: { touched, error, form },
      multiple,
      helpText,
      fileAccess, // "image/*" OR ".pdf, image/*, .xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      disabled
    } = this.props;

    var showError = touched && error ? true : false;

    

    return (
      <FormControl error={showError} className={showError ? "error imageInputWrap" : "imageInputWrap"}>
        <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
        {this.selectedFiles(this.getCurrentValue())}
        {!disabled ? <Button variant="contained"><FormControlLabel
          control={
            <div>
            <input
              name={name}
              type="file"
              accept={fileAccess}
              onChange={this.filesChanged}
              multiple={multiple}
            />
            <AddImageIcon fontSize="small" /> {TranslatorUtil.t('Add')}
            </div>
          }
        /></Button> : null }

        {InputHelperText({ touched, error })}
      </FormControl>
    );
  }
}
