import * as React from "react"
const Error500Svg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      d="m205.12 353.37-58.75-33.93v-2.56l54-31.18 58.75 33.92v2.57l-54 31.18z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m146.37 316.88 58.75 33.92 54-31.18-58.75-33.92-54 31.18z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m146.37 316.88 58.75 33.92 54-31.18-58.75-33.92-54 31.18z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M219.68 331a4.34 4.34 0 0 0 3.94 0 1.2 1.2 0 0 0 0-2.27 4.34 4.34 0 0 0-3.94 0 1.2 1.2 0 0 0 0 2.27Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M205.12 350.8v2.57l-58.75-33.93v-2.56l58.75 33.92z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="m128.34 115.9 65.46 37.79a4.85 4.85 0 0 1 2.19 3.79v198.81a4.85 4.85 0 0 1-2.19 3.8L123 401a4.83 4.83 0 0 1-4.38 0l-65.46-37.83a4.83 4.83 0 0 1-2.16-3.79V160.57a4.85 4.85 0 0 1 2.19-3.8L124 115.9a4.83 4.83 0 0 1 4.34 0Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M51 160.56v198.82a4.87 4.87 0 0 0 2.19 3.79L118.62 401a4.36 4.36 0 0 0 2.19.53V197.62a4.49 4.49 0 0 1-2.19-.53L53.16 159.3l-.6-.5-.78-.64a4.57 4.57 0 0 0-.78 2.4Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M51 160.56v198.82a4.87 4.87 0 0 0 2.19 3.79L118.62 401a4.36 4.36 0 0 0 2.19.53V197.62a4.49 4.49 0 0 1-2.19-.53L53.16 159.3l-.6-.5-.78-.64a4.57 4.57 0 0 0-.78 2.4Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M53.16 156.77 124 115.9a4.81 4.81 0 0 1 4.37 0l65.46 37.79a1.34 1.34 0 0 1 0 2.53L123 197.1a4.89 4.89 0 0 1-4.38 0l-65.46-37.8a1.34 1.34 0 0 1 0-2.53Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M53.16 156.77 124 115.9a4.81 4.81 0 0 1 4.37 0l65.46 37.79a1.34 1.34 0 0 1 0 2.53L123 197.1a4.89 4.89 0 0 1-4.38 0l-65.46-37.8a1.34 1.34 0 0 1 0-2.53Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m188.13 291.62-58.75 33.92v62.35l58.75-33.92v-62.35z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M159.26 336.16a.62.62 0 0 0-.61.06L134.75 350a1.79 1.79 0 0 0-.61.64l-1.6-.93a1.77 1.77 0 0 1 .6-.64l23.4-13.45a1.85 1.85 0 0 1 1.71 0Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M133.89 351.46v26.77c0 .55.39.77.86.5l23.9-13.73a1.9 1.9 0 0 0 .86-1.48v-26.8c0-.55-.39-.77-.86-.5L134.75 350a1.89 1.89 0 0 0-.86 1.46Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m134.12 378.78-1-.57a1.89 1.89 0 0 1-.85-1.48v-26.2a1.7 1.7 0 0 1 .25-.85l1.6.93a1.81 1.81 0 0 0-.25.85v26.77a.65.65 0 0 0 .25.55Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M135.8 376.32a.46.46 0 0 1-.2-.05.51.51 0 0 1-.22-.46V352a1.34 1.34 0 0 1 .61-1l21.31-12.25a.5.5 0 0 1 .51 0 .51.51 0 0 1 .22.46V363a1.32 1.32 0 0 1-.6 1.05l-21.31 12.25a.64.64 0 0 1-.32.02Zm21.8-37.44a.41.41 0 0 0-.18.05l-21.32 12.26a1.08 1.08 0 0 0-.48.83v23.79c0 .12 0 .21.09.24a.26.26 0 0 0 .26 0l21.31-12.25a1.08 1.08 0 0 0 .48-.83v-23.83c0-.12 0-.21-.09-.24a.12.12 0 0 0-.07-.02Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M137.48 373.19a.21.21 0 0 1-.12 0 .26.26 0 0 1-.13-.25v-20.22a.72.72 0 0 1 .29-.5l18.21-10.49a.28.28 0 0 1 .29 0 .29.29 0 0 1 .13.26v20.22a.64.64 0 0 1-.29.51l-18.19 10.45a.32.32 0 0 1-.19.02Zm0-.24Zm18.4-31-18.22 10.5a.5.5 0 0 0-.16.28V373l18.18-10.45a.39.39 0 0 0 .17-.29v-20.22a.06.06 0 0 0 .04-.11ZM138.71 346.89a.25.25 0 0 1-.24-.25v-5.7a.24.24 0 1 1 .48 0v5.7a.25.25 0 0 1-.24.25ZM142.7 344.6a.24.24 0 0 1-.24-.24v-5.71a.24.24 0 0 1 .24-.24.24.24 0 0 1 .24.24v5.71a.24.24 0 0 1-.24.24ZM146.69 342.31a.24.24 0 0 1-.25-.24v-5.7a.25.25 0 0 1 .49 0v5.7a.24.24 0 0 1-.24.24ZM150.67 340a.24.24 0 0 1-.24-.24v-5.71a.25.25 0 0 1 .49 0v5.71a.25.25 0 0 1-.25.24ZM154.66 337.74a.24.24 0 0 1-.24-.24v-5.7a.24.24 0 0 1 .24-.24.23.23 0 0 1 .24.24v5.7a.23.23 0 0 1-.24.24Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M159.26 315.1a.6.6 0 0 0-.61.06l-23.9 13.75a1.84 1.84 0 0 0-.61.63l-1.6-.92a1.69 1.69 0 0 1 .6-.64l23.4-13.46a1.91 1.91 0 0 1 1.71 0Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M133.89 330.39v13.5c0 .55.39.77.86.5l23.9-13.75a1.9 1.9 0 0 0 .86-1.48v-13.51c0-.54-.39-.76-.86-.49l-23.9 13.75a1.88 1.88 0 0 0-.86 1.48Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m134.12 344.44-1-.57a1.89 1.89 0 0 1-.85-1.48v-12.93a1.7 1.7 0 0 1 .25-.84l1.6.92a1.84 1.84 0 0 0-.25.85v13.51a.64.64 0 0 0 .25.54Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M135.8 342a.46.46 0 0 1-.2-.05.51.51 0 0 1-.22-.46V331a1.34 1.34 0 0 1 .61-1l21.31-12.26a.46.46 0 0 1 .73.43v10.51a1.32 1.32 0 0 1-.6 1.05l-21.31 12.25a.64.64 0 0 1-.32.02Zm21.8-24.17a.32.32 0 0 0-.18.06l-21.32 12.24a1.08 1.08 0 0 0-.48.83v10.51c0 .12 0 .21.09.24a.26.26 0 0 0 .26 0l21.31-12.25a1.08 1.08 0 0 0 .48-.83v-10.55a.29.29 0 0 0-.09-.25Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M137.48 338.86a.21.21 0 0 1-.12 0 .26.26 0 0 1-.13-.25v-6.91a.76.76 0 0 1 .29-.51l18.21-10.49a.3.3 0 0 1 .29 0 .27.27 0 0 1 .13.25v7a.64.64 0 0 1-.29.51l-18.19 10.45a.33.33 0 0 1-.19-.05Zm0-.25Zm18.4-17.75-18.22 10.49a.56.56 0 0 0-.16.29v6.95l18.18-10.45a.39.39 0 0 0 .17-.29v-7s.04.02.04.01ZM164.09 312.35a3.14 3.14 0 0 0-1.42 2.45c0 .9.64 1.26 1.42.81a3.14 3.14 0 0 0 1.41-2.44c0-.9-.64-1.27-1.41-.82ZM164.09 336.35a3.14 3.14 0 0 0-1.42 2.45c0 .9.64 1.26 1.42.81a3.14 3.14 0 0 0 1.41-2.44c0-.9-.64-1.27-1.41-.82ZM164.09 342.81a3.12 3.12 0 0 0-1.42 2.44c0 .9.64 1.26 1.42.81a3.12 3.12 0 0 0 1.41-2.44c0-.9-.64-1.26-1.41-.81ZM164.09 349.26a3.14 3.14 0 0 0-1.42 2.45c0 .89.64 1.26 1.42.81a3.14 3.14 0 0 0 1.41-2.44c0-.9-.64-1.27-1.41-.82ZM164.09 355.72a3.12 3.12 0 0 0-1.42 2.44c0 .9.64 1.26 1.42.81a3.14 3.14 0 0 0 1.41-2.44c0-.9-.64-1.26-1.41-.81ZM164.09 318.18a3.14 3.14 0 0 0-1.42 2.45c0 .9.64 1.26 1.42.81a3.14 3.14 0 0 0 1.41-2.44c0-.9-.64-1.27-1.41-.82ZM164.09 324a3.14 3.14 0 0 0-1.42 2.45c0 .9.64 1.26 1.42.81a3.14 3.14 0 0 0 1.41-2.44c0-.89-.64-1.26-1.41-.82Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m179.52 315.4-.14-1.31a.14.14 0 0 0-.18-.13 2.94 2.94 0 0 1-.65.07.3.3 0 0 0-.26.13l-.29.43 1.54.88a.14.14 0 0 1-.02-.07Zm1.11-9.18a3 3 0 0 1 0 .31 5 5 0 0 1-2.28 4l-.28.15c.14 1.22 1.1 1.67 2.26 1a5 5 0 0 0 2.28-3.95c.02-1.4-.85-2-1.98-1.51Zm4.94-1.16-1.55-.9-.23.47a.33.33 0 0 0 0 .29 3 3 0 0 1 .27.6.14.14 0 0 0 .2.09l1.2-.53a.12.12 0 0 1 .1 0Zm-3-3.42-.87-.51h-.13l-.71 1.07-.9-.52-.65-.38h-.07l-.93.54-.42.24h-.05v.06a.41.41 0 0 0 0 .08l-.13 1.51a.17.17 0 0 1 0 .05.11.11 0 0 1 0 .05.61.61 0 0 1-.11.18 8.13 8.13 0 0 0-.76.79.14.14 0 0 1-.11.07.26.26 0 0 1-.15 0l-.87-.3h-.2l-.41.71-.43.75-.15.26a.21.21 0 0 0 0 .13.23.23 0 0 0 .07.13l.7.6.05.07a.23.23 0 0 1 0 .11.19.19 0 0 1 0 .08 8.41 8.41 0 0 0-.32 1.05.51.51 0 0 1-.08.17l-.09.1-1.23.86s-.06.06-.09.1a.34.34 0 0 0 0 .17v1.56a.12.12 0 0 0 0 .1l1.57.9-.57 1.15a.18.18 0 0 0 .07.23l1.9 1.1h.08a.46.46 0 0 1-.07-.07.2.2 0 0 1 0-.17l.8-1.61a.14.14 0 0 0 0-.07.29.29 0 0 0 0-.22 3.29 3.29 0 0 1-.26-.6.14.14 0 0 0-.2-.09l-.61.27-.6.27a.09.09 0 0 1-.1 0 .1.1 0 0 1-.05-.1v-1.56a.33.33 0 0 1 .05-.16s0-.08.09-.1l1.23-.87a.32.32 0 0 0 .1-.1.37.37 0 0 0 .07-.16 8.41 8.41 0 0 1 .32-1.05.25.25 0 0 0 0-.09.27.27 0 0 0-.08-.18l-.7-.6a.16.16 0 0 1-.06-.12.2.2 0 0 1 0-.13l.29-.49.34-.61.36-.62a.25.25 0 0 1 .16-.1h.09l.86.3a.22.22 0 0 0 .16 0 .26.26 0 0 0 .11-.07 8.22 8.22 0 0 1 .75-.8.42.42 0 0 0 .11-.17.23.23 0 0 0 0-.11l.14-1.5a.24.24 0 0 1 0-.08.34.34 0 0 1 .14-.17l.25-.15.54-.31.56-.32a.1.1 0 0 1 .09 0s.05.05.06.09l.07.69.06.62a.14.14 0 0 0 .18.13 3 3 0 0 1 .65-.07.24.24 0 0 0 .19-.06.16.16 0 0 0 .07-.07l1-1.5a.17.17 0 0 1 .23-.05Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M179.54 303.46a.24.24 0 0 0 0 .08l-.14 1.5a.23.23 0 0 1 0 .11l-2-1.15v-.05a.17.17 0 0 0 0-.05l.13-1.51a.41.41 0 0 1 0-.08Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M178.38 306.19a.22.22 0 0 1-.16 0l-.86-.3h-.09l-2-1.13h.06l.87.3a.26.26 0 0 0 .15 0ZM176.94 308.73l-2-1.14v-.1l-.05-.07-.7-.6a.23.23 0 0 1-.07-.13l2 1.15a.16.16 0 0 0 .06.12l.7.6a.27.27 0 0 1 .06.17ZM176.93 313.29a.14.14 0 0 1 0 .07l-.8 1.61a.2.2 0 0 0 0 .17l-1.89-1.1a.18.18 0 0 1-.07-.23l.57-1.15.42.24a.09.09 0 0 0 .1 0l.6-.27ZM179.52 315.4a.14.14 0 0 0 0 .07l-1.54-.88.29-.43a.3.3 0 0 1 .26-.13 2.94 2.94 0 0 0 .65-.07.14.14 0 0 1 .18.13Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M183.64 302.28a.17.17 0 0 0-.23.05l-1 1.5a.16.16 0 0 1-.07.07l-.56-.32-.52-.3-.07-.69s0-.08-.06-.09l-.4-.24.71-1.07a.22.22 0 0 1 .11 0l.87.51Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M176.54 310a.32.32 0 0 1-.1.1l-1.23.87s-.06.06-.09.1l-2-1.15s.05-.08.09-.1l1.23-.86.09-.1Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m185.48 305.07-1.2.54a.14.14 0 0 1-.2-.09 3 3 0 0 0-.27-.6.33.33 0 0 1 0-.29l.8-1.61a.19.19 0 0 0-.07-.24l-.87-.5a.18.18 0 0 0-.24.05l-1 1.5a.3.3 0 0 1-.26.13 3 3 0 0 0-.65.07.14.14 0 0 1-.18-.13l-.13-1.31a.1.1 0 0 0-.16-.08l-1.35.78a.33.33 0 0 0-.16.25l-.14 1.5a.47.47 0 0 1-.14.28 8.22 8.22 0 0 0-.75.8.27.27 0 0 1-.27.07l-.86-.3a.22.22 0 0 0-.25.09l-1 1.72a.21.21 0 0 0 0 .25l.7.6a.27.27 0 0 1 .07.27 8.41 8.41 0 0 0-.32 1.05.41.41 0 0 1-.17.26l-1.23.87a.34.34 0 0 0-.14.26v1.56a.1.1 0 0 0 .15.1l1.21-.54a.14.14 0 0 1 .2.09 3.29 3.29 0 0 0 .26.6.3.3 0 0 1 0 .29l-.8 1.61a.19.19 0 0 0 .07.24l.87.5a.18.18 0 0 0 .24 0l1-1.5a.3.3 0 0 1 .26-.13 2.94 2.94 0 0 0 .65-.07.14.14 0 0 1 .18.13l.14 1.31c0 .09.07.13.16.08l1.35-.78a.38.38 0 0 0 .16-.25l.13-1.5a.47.47 0 0 1 .14-.28 7.16 7.16 0 0 0 .75-.8.27.27 0 0 1 .27-.07l.87.3a.21.21 0 0 0 .24-.09l1-1.72a.2.2 0 0 0 0-.25l-.7-.6a.27.27 0 0 1-.07-.27 8.41 8.41 0 0 0 .32-1.05.46.46 0 0 1 .17-.26l1.23-.87a.34.34 0 0 0 .14-.26v-1.56a.1.1 0 0 0-.15-.15Zm-5.13 6.56c-1.26.73-2.28.14-2.28-1.32a5 5 0 0 1 2.28-3.95c1.26-.73 2.28-.14 2.28 1.32a5 5 0 0 1-2.28 3.95Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M176.19 318.62h-.06l-.32-.18-.91-.53h-.2l-.85.08h-.08l-.46 1.23a.23.23 0 0 1-.07.1l-.11.08-.28.14-.88-.51a.16.16 0 0 0-.09 0l-.66.7-.27.28a.13.13 0 0 0 0 .06v.06a.11.11 0 0 0 0 .05l.26.88v.11a.42.42 0 0 1 0 .1 6.17 6.17 0 0 0-.43.74.24.24 0 0 1-.1.1.24.24 0 0 1-.1.05l-.8.2h-.06a.1.1 0 0 0-.05 0 .07.07 0 0 0 0 .06l-.3 1-.1.36v.1l.53.31h.07l.29.16v.36a.28.28 0 0 1-.07.21l-.81 1a.34.34 0 0 0 0 .11.12.12 0 0 0 0 .1l.38.84 1.61.94h.08v-.08l-.19-.41-.16-.36a.2.2 0 0 1 0-.11.14.14 0 0 1 .05-.09l.81-1a.31.31 0 0 0 0-.1.3.3 0 0 0 0-.12 4.09 4.09 0 0 1 0-.7.12.12 0 0 0-.12-.12h-.79a.09.09 0 0 1 0-.09l.18-.61.2-.7v-.06a.26.26 0 0 1 .15-.09l.8-.2a.16.16 0 0 0 .1-.05.19.19 0 0 0 .1-.1 7.89 7.89 0 0 1 .43-.74.3.3 0 0 0 0-.1.22.22 0 0 0 0-.11l-.26-.88a.17.17 0 0 1 0-.1.17.17 0 0 1 .06-.1l.63-.67.3-.32s.1-.06.13 0l.43.66h.18a4.26 4.26 0 0 1 .53-.31.27.27 0 0 0 .1-.06.26.26 0 0 0 .08-.11l.46-1.24v-.06l.31-.08.68-.07h.07Zm-3.6 9a.13.13 0 0 0-.08-.15 1.51 1.51 0 0 1-.37-.22.18.18 0 0 0-.2 0l-.86.79a.21.21 0 0 1-.1 0l.85.49.53.31a.09.09 0 0 1 0-.08Zm3.8-8.92a.1.1 0 0 1 0 .09l-.27 1.21a.14.14 0 0 0 .09.15 2 2 0 0 1 .37.22s.12 0 .19 0l.86-.8c.05 0 .1-.05.13 0Zm-1.13 9-.43-.66c0-.05-.11-.05-.18 0l-.26.16.89.52Zm.69-5.51a.85.85 0 0 0-.67-.53h-.1a1.39 1.39 0 0 0-.6.13 1.69 1.69 0 0 1 0 .23 3.74 3.74 0 0 1-1.71 2.95l-.19.11a1.24 1.24 0 0 0 .11.44.84.84 0 0 0 .8.54h.33a2.42 2.42 0 0 0 1.1-.72 3.23 3.23 0 0 0 .98-3.15Zm1.34 0v.34a.12.12 0 0 0 .13.12h.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m175.26 327.7-.89-.52.26-.16c.07-.05.15-.05.18 0Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M172.32 328.88a.09.09 0 0 0 0 .08l-.53-.31-.85-.49a.21.21 0 0 0 .1 0l.86-.79a.18.18 0 0 1 .2 0 1.51 1.51 0 0 0 .37.22.13.13 0 0 1 .08.15ZM171.46 326a.28.28 0 0 1-.06.09l-.82 1a.18.18 0 0 0 0 .1l-1.71-1a.51.51 0 0 1 0-.12l.81-1s0-.06.05-.08Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M171.47 325.17v.7a.26.26 0 0 1 0 .12l-1.72-1a.21.21 0 0 0 0-.13v-.36l.8.47h.79a.12.12 0 0 1 .13.2Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="M172 323.15a.19.19 0 0 1-.11 0l-.8.2a.23.23 0 0 0-.15.1l-1.71-1h.11l.8-.19a.27.27 0 0 0 .1-.06ZM172.59 322.09a.22.22 0 0 1 0 .11l-1.71-1a.24.24 0 0 0 0-.12l-.26-.88v-.05l1.72 1a.38.38 0 0 0 0 .1ZM175.32 318.88v.06l-.45 1.23a.31.31 0 0 1-.09.12l-1.72-1 .07-.09.47-1.24Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M175.32 318.88a.27.27 0 0 1 .14-.07h.17"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M178.18 322.71h-.78a.12.12 0 0 1-.13-.12v-.7a.34.34 0 0 1 .08-.21l.82-1a.22.22 0 0 0 0-.2l-.38-.85h-.13l-.86.8c-.07.05-.15.07-.19 0a2 2 0 0 0-.37-.22.14.14 0 0 1-.09-.15l.27-1.21a.1.1 0 0 0 0-.09.11.11 0 0 0-.08 0l-.68.07h-.17a.27.27 0 0 0-.14.07v.06l-.46 1.24a.26.26 0 0 1-.08.11.27.27 0 0 1-.1.06 4.26 4.26 0 0 0-.53.31H174l-.43-.66h-.13l-.3.32-.63.67a.26.26 0 0 0-.07.1.17.17 0 0 0 0 .1l.26.88a.22.22 0 0 1 0 .11.3.3 0 0 1 0 .1 7.89 7.89 0 0 0-.43.74.19.19 0 0 1-.1.1.16.16 0 0 1-.1.05l-.8.2a.26.26 0 0 0-.15.09v.06l-.2.7-.18.61a.09.09 0 0 0 0 .09h.79a.12.12 0 0 1 .12.12 4.09 4.09 0 0 0 0 .7.3.3 0 0 1 0 .12.31.31 0 0 1 0 .1l-.81 1a.23.23 0 0 0-.05.2l.16.36v.05l.05.11.07.14v.19a.21.21 0 0 0 .1 0l.86-.79a.18.18 0 0 1 .2 0 1.51 1.51 0 0 0 .37.22.13.13 0 0 1 .08.15l-.27 1.22a.09.09 0 0 0 0 .08.11.11 0 0 0 .08 0l.85-.08a.27.27 0 0 0 .19-.13l.46-1.23a.32.32 0 0 1 .18-.18l.27-.15.26-.16c.07-.05.15-.05.18 0l.43.66a.12.12 0 0 0 .14 0l.93-1a.21.21 0 0 0 .07-.2l-.26-.88a.34.34 0 0 1 .05-.21 7.89 7.89 0 0 0 .43-.74.28.28 0 0 1 .2-.15l.8-.19a.31.31 0 0 0 .18-.15l.4-1.36a.08.08 0 0 0-.07-.05Zm-4.25 3.3h-.33a.84.84 0 0 1-.8-.54 1.24 1.24 0 0 1-.11-.44 2.11 2.11 0 0 1 0-.25 3.83 3.83 0 0 1 1.05-2.41 2.75 2.75 0 0 1 .65-.52l.21-.11a1.39 1.39 0 0 1 .6-.13h.1a.85.85 0 0 1 .67.53 3.23 3.23 0 0 1-.92 3.1 2.42 2.42 0 0 1-1.12.76Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M175.34 341.7a6.41 6.41 0 0 0-3.62 3 5.94 5.94 0 0 0 .19 5.64 6.5 6.5 0 0 0-1 3.49 7.74 7.74 0 0 0 5.1 6.89l.29.1.87-.5a8.29 8.29 0 0 1-4.68-4.3 5.85 5.85 0 0 1 .15-4.59 7.35 7.35 0 0 0 1.66 1.57c1.74 1.14 5.13 2 6.72.17a2.84 2.84 0 0 0-.13-3.71 5.56 5.56 0 0 0-4-1.87 5.62 5.62 0 0 0-4.16 1.75 5.46 5.46 0 0 1-.41-2.58 4.92 4.92 0 0 1 3.52-4.13c1.44 2.24 4.62 4.28 7.07 2.37s-1.46-3.64-3.1-3.79a10.71 10.71 0 0 0-3.39.19 3.68 3.68 0 0 1-.22-2 4.39 4.39 0 0 1 1.67-2.6 5.6 5.6 0 0 1 6.6-.4.36.36 0 1 0 .46-.56c-3.93-3.6-11.65.81-9.59 5.86Zm-.62 7.42a4.17 4.17 0 0 1 3.69-.17c1 .45 2.2 1.4 2 2.66-.28 2.19-3.23 1.58-4.61 1a5.79 5.79 0 0 1-2.59-2.24 4.77 4.77 0 0 1 1.51-1.25Zm3-6.91a7.28 7.28 0 0 1 3.08.22c.47.15 1.81.6 1.74 1.28 0 .42-.65.74-1 .88a2.54 2.54 0 0 1-1.61.08 5.49 5.49 0 0 1-2.78-2l-.23-.33a6.84 6.84 0 0 1 .77-.13Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M175.34 341.7a6.41 6.41 0 0 0-3.62 3 5.94 5.94 0 0 0 .19 5.64 6.5 6.5 0 0 0-1 3.49 7.74 7.74 0 0 0 5.1 6.89l.29.1.87-.5a8.29 8.29 0 0 1-4.68-4.3 5.85 5.85 0 0 1 .15-4.59 7.35 7.35 0 0 0 1.66 1.57c1.74 1.14 5.13 2 6.72.17a2.84 2.84 0 0 0-.13-3.71 5.56 5.56 0 0 0-4-1.87 5.62 5.62 0 0 0-4.16 1.75 5.46 5.46 0 0 1-.41-2.58 4.92 4.92 0 0 1 3.52-4.13c1.44 2.24 4.62 4.28 7.07 2.37s-1.46-3.64-3.1-3.79a10.71 10.71 0 0 0-3.39.19 3.68 3.68 0 0 1-.22-2 4.39 4.39 0 0 1 1.67-2.6 5.6 5.6 0 0 1 6.6-.4.36.36 0 1 0 .46-.56c-3.93-3.6-11.65.81-9.59 5.86Zm-.62 7.42a4.17 4.17 0 0 1 3.69-.17c1 .45 2.2 1.4 2 2.66-.28 2.19-3.23 1.58-4.61 1a5.79 5.79 0 0 1-2.59-2.24 4.77 4.77 0 0 1 1.51-1.25Zm3-6.91a7.28 7.28 0 0 1 3.08.22c.47.15 1.81.6 1.74 1.28 0 .42-.65.74-1 .88a2.54 2.54 0 0 1-1.61.08 5.49 5.49 0 0 1-2.78-2l-.23-.33a6.84 6.84 0 0 1 .77-.13Z"
      style={{
        fill: "#fff",
        opacity: 0.7000000000000001,
      }}
    />
    <path
      d="M172.27 352.4a5.43 5.43 0 0 1 .35-1 3.86 3.86 0 0 1-.71-1.09 7 7 0 0 0-.54 1.06 8.6 8.6 0 0 0 .9 1.03ZM174.6 343.21a7.24 7.24 0 0 1 1.22-.59 5.7 5.7 0 0 1-.48-.92 7.31 7.31 0 0 0-1.36.63 6.94 6.94 0 0 0 .62.88ZM176.28 360.81l.87-.5a10.72 10.72 0 0 1-1.08-.52l-.93.53a7.2 7.2 0 0 0 .85.39ZM199.46 344.67a6.45 6.45 0 0 0-4.52-1.29 6 6 0 0 0-4.54 3.36 6.44 6.44 0 0 0-2.28.5 7.83 7.83 0 0 0-1.17.64 7.58 7.58 0 0 0-2.93 7.2c0 .31.09.61.15.91l.06.22.77-.44v-.24a7.81 7.81 0 0 1 1.08-5.11 5.7 5.7 0 0 1 2.07-1.86 6 6 0 0 1 1.79-.62 6.89 6.89 0 0 0-.33 2.28c0 2.08 1.26 5.36 3.67 5.63a2.84 2.84 0 0 0 3-2.22 5.47 5.47 0 0 0-.74-4.36 5.63 5.63 0 0 0-3.79-2.44 5.4 5.4 0 0 1 1.88-1.8 4.92 4.92 0 0 1 5.4.56c-1 2.46-.9 6.22 2.06 7.15s2.16-3.26 1.37-4.69a11.14 11.14 0 0 0-2.08-2.69 3.64 3.64 0 0 1 1.54-1.33 4.36 4.36 0 0 1 3.08-.1 5.62 5.62 0 0 1 4.08 5.21.36.36 0 1 0 .72.06c.67-5.2-7.35-9.09-10.34-4.53Zm-6.46 3.7a4.16 4.16 0 0 1 2.23 2.94c.21 1.1.1 2.61-1 3.19-2 1-3.14-1.77-3.45-3.24a5.87 5.87 0 0 1 .38-3.4 4.84 4.84 0 0 1 1.84.51Zm7.37-1.48a7.41 7.41 0 0 1 1.57 2.66c.14.47.54 1.84-.08 2.16-.36.2-1-.11-1.28-.31a2.47 2.47 0 0 1-1-1.28 5.44 5.44 0 0 1 .08-3.43 3 3 0 0 1 .13-.39c.21.2.41.39.58.59Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M199.46 344.67a6.45 6.45 0 0 0-4.52-1.29 6 6 0 0 0-4.54 3.36 6.44 6.44 0 0 0-2.28.5 7.83 7.83 0 0 0-1.17.64 7.58 7.58 0 0 0-2.93 7.2c0 .31.09.61.15.91l.06.22.77-.44v-.24a7.81 7.81 0 0 1 1.08-5.11 5.7 5.7 0 0 1 2.07-1.86 6 6 0 0 1 1.79-.62 6.89 6.89 0 0 0-.33 2.28c0 2.08 1.26 5.36 3.67 5.63a2.84 2.84 0 0 0 3-2.22 5.47 5.47 0 0 0-.74-4.36 5.63 5.63 0 0 0-3.79-2.44 5.4 5.4 0 0 1 1.88-1.8 4.92 4.92 0 0 1 5.4.56c-1 2.46-.9 6.22 2.06 7.15s2.16-3.26 1.37-4.69a11.14 11.14 0 0 0-2.08-2.69 3.64 3.64 0 0 1 1.54-1.33 4.36 4.36 0 0 1 3.08-.1 5.62 5.62 0 0 1 4.08 5.21.36.36 0 1 0 .72.06c.67-5.2-7.35-9.09-10.34-4.53Zm-6.46 3.7a4.16 4.16 0 0 1 2.23 2.94c.21 1.1.1 2.61-1 3.19-2 1-3.14-1.77-3.45-3.24a5.87 5.87 0 0 1 .38-3.4 4.84 4.84 0 0 1 1.84.51Zm7.37-1.48a7.41 7.41 0 0 1 1.57 2.66c.14.47.54 1.84-.08 2.16-.36.2-1-.11-1.28-.31a2.47 2.47 0 0 1-1-1.28 5.44 5.44 0 0 1 .08-3.43 3 3 0 0 1 .13-.39c.21.2.41.39.58.59Z"
      style={{
        fill: "#fff",
        opacity: 0.7000000000000001,
      }}
    />
    <path
      d="M188.91 348.21a5.68 5.68 0 0 1 1-.27 4.22 4.22 0 0 1 .49-1.2 6.85 6.85 0 0 0-1.18.17 8.27 8.27 0 0 0-.31 1.3ZM197.79 344.92a6 6 0 0 1 1.18.67 6.92 6.92 0 0 1 .49-.93 7.66 7.66 0 0 0-1.31-.73 5.86 5.86 0 0 0-.36.99ZM184.23 356.21l-.06-.22c-.06-.3-.11-.6-.15-.91l.95.45v.24Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m185.94 245-54.38 31.4a4.84 4.84 0 0 0-2.18 3.79v18.66c0 1.4 1 2 2.19 1.27l54.37-31.39a4.87 4.87 0 0 0 2.19-3.79v-18.67c0-1.39-.98-1.96-2.19-1.27Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M184 253.23a4.7 4.7 0 0 1-2.19 3.64l-46.16 26.65c-1.21.69-2.19.2-2.19-1.11a4.7 4.7 0 0 1 2.19-3.64l46.16-26.65c1.19-.69 2.19-.2 2.19 1.11Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M184 262.72a4.7 4.7 0 0 1-2.19 3.64L135.67 293c-1.21.7-2.19.21-2.19-1.1a4.7 4.7 0 0 1 2.19-3.64l46.16-26.65c1.17-.7 2.17-.2 2.17 1.11Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m181.83 261.61-19.59 11.31v4.74l19.59-11.3a4.7 4.7 0 0 0 2.19-3.64c-.02-1.31-1.02-1.81-2.19-1.11ZM110.54 201.07c-2.27-1.31-4.11-.12-4.11 2.68v100.38a9.4 9.4 0 0 0 4.11 7.44c2.27 1.32 4.11.12 4.11-2.67V208.51a9.43 9.43 0 0 0-4.11-7.44Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M110.54 201.07c-2.27-1.31-4.11-.12-4.11 2.68v100.38a9.4 9.4 0 0 0 4.11 7.44c2.27 1.32 4.11.12 4.11-2.67V208.51a9.43 9.43 0 0 0-4.11-7.44Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M110.54 309.17a4.7 4.7 0 0 1-2.05-3.71V204.77c0-1.4.92-2 2.05-1.35a4.69 4.69 0 0 1 2 3.72v100.69c.05 1.4-.87 2-2 1.34Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M110.54 309.17a4.7 4.7 0 0 1-2.05-3.71v-49.2l4.1 2.37v49.2c0 1.4-.92 2-2.05 1.34ZM98.22 194c-2.27-1.32-4.11-.12-4.11 2.67V297a9.43 9.43 0 0 0 4.11 7.44c2.26 1.31 4.1.11 4.1-2.68V201.39a9.41 9.41 0 0 0-4.1-7.39Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M98.22 194c-2.27-1.32-4.11-.12-4.11 2.67V297a9.43 9.43 0 0 0 4.11 7.44c2.26 1.31 4.1.11 4.1-2.68V201.39a9.41 9.41 0 0 0-4.1-7.39Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M98.22 302.06a4.7 4.7 0 0 1-2.06-3.72V197.65c0-1.4.92-2 2.06-1.34a4.7 4.7 0 0 1 2 3.71v100.69c.05 1.4-.87 2-2 1.35ZM85.89 186.84c-2.27-1.31-4.11-.12-4.11 2.68V289.9a9.4 9.4 0 0 0 4.11 7.44c2.27 1.32 4.11.12 4.11-2.67V194.28a9.43 9.43 0 0 0-4.11-7.44Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M85.89 186.84c-2.27-1.31-4.11-.12-4.11 2.68V289.9a9.4 9.4 0 0 0 4.11 7.44c2.27 1.32 4.11.12 4.11-2.67V194.28a9.43 9.43 0 0 0-4.11-7.44Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M85.89 294.94a4.7 4.7 0 0 1-2-3.71V190.54c0-1.4.92-2 2-1.35a4.7 4.7 0 0 1 2.11 3.72V293.6c-.06 1.4-1 2-2.11 1.34Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M85.89 294.94a4.7 4.7 0 0 1-2-3.71v-73.12l4.11 2.38v73.11c-.06 1.4-1 2-2.11 1.34ZM73.57 179.73c-2.27-1.32-4.11-.12-4.11 2.67v100.39a9.43 9.43 0 0 0 4.11 7.44c2.26 1.31 4.1.11 4.1-2.68V187.16a9.39 9.39 0 0 0-4.1-7.43Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M73.57 179.73c-2.27-1.32-4.11-.12-4.11 2.67v100.39a9.43 9.43 0 0 0 4.11 7.44c2.26 1.31 4.1.11 4.1-2.68V187.16a9.39 9.39 0 0 0-4.1-7.43Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M73.57 287.83a4.7 4.7 0 0 1-2.06-3.72V183.42c0-1.4.92-2 2.06-1.34a4.7 4.7 0 0 1 2.05 3.71v100.69c0 1.4-.92 2-2.05 1.35Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M73.57 287.83a4.7 4.7 0 0 1-2.06-3.72v-18.36l4.11 2.37v18.36c0 1.4-.92 2-2.05 1.35ZM61.24 172.61c-2.27-1.32-4.11-.12-4.11 2.68v100.38a9.4 9.4 0 0 0 4.11 7.44c2.27 1.32 4.11.12 4.11-2.67V180.05a9.43 9.43 0 0 0-4.11-7.44Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M61.24 172.61c-2.27-1.32-4.11-.12-4.11 2.68v100.38a9.4 9.4 0 0 0 4.11 7.44c2.27 1.32 4.11.12 4.11-2.67V180.05a9.43 9.43 0 0 0-4.11-7.44Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M61.24 280.71a4.7 4.7 0 0 1-2.05-3.71V176.3c0-1.39.92-2 2.05-1.34a4.7 4.7 0 0 1 2.06 3.72v100.69c0 1.39-.92 2-2.06 1.34Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M61.24 280.71a4.7 4.7 0 0 1-2.05-3.71v-53.95l4.11 2.38v53.94c0 1.39-.92 2-2.06 1.34Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m185.94 169.32-54.38 31.39a4.82 4.82 0 0 0-2.18 3.79V267c0 1.39 1 2 2.19 1.26l54.37-31.39a4.87 4.87 0 0 0 2.19-3.79v-62.5c0-1.4-.98-1.96-2.19-1.26Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M130 202.43a4.29 4.29 0 0 0-.59 2.07V267c0 1.39 1 2 2.19 1.26l54.37-31.39a4.58 4.58 0 0 0 1.59-1.72Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M131.56 200.71a4.82 4.82 0 0 0-2.18 3.79V267l54.37-31.39a4.87 4.87 0 0 0 2.18-3.8v-62.49Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M149.47 217.14a1.1 1.1 0 0 1-.78-.31l-7.23-7a1.12 1.12 0 1 1 1.56-1.62l7.23 6.95a1.13 1.13 0 0 1-.78 1.94Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M142.24 221.31a1.05 1.05 0 0 1-.48-.11 1.13 1.13 0 0 1-.54-1.5l7.23-15.3a1.12 1.12 0 0 1 1.5-.53 1.13 1.13 0 0 1 .54 1.5l-7.23 15.3a1.13 1.13 0 0 1-1.02.64ZM173.07 203.51a1.12 1.12 0 0 1-.78-.31l-7.23-6.95a1.13 1.13 0 0 1 1.57-1.63l7.22 6.95a1.14 1.14 0 0 1 0 1.6 1.1 1.1 0 0 1-.78.34Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M165.84 207.69a1.2 1.2 0 0 1-.48-.11 1.14 1.14 0 0 1-.54-1.5l7.23-15.3a1.13 1.13 0 0 1 2 1L166.86 207a1.13 1.13 0 0 1-1.02.69ZM141.92 238.65a1.14 1.14 0 0 1-1-.57 1.13 1.13 0 0 1 .42-1.54l31.47-18.17a1.13 1.13 0 0 1 1.54.41 1.14 1.14 0 0 1-.42 1.55l-31.47 18.16a1 1 0 0 1-.54.16Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M163.85 239.16a3.11 3.11 0 0 1-1.58-.42 4.17 4.17 0 0 1-1.81-3.79v-8.79a1.14 1.14 0 0 1 1.13-1.13 1.14 1.14 0 0 1 1.13 1.13V235a2.07 2.07 0 0 0 .68 1.83 1.65 1.65 0 0 0 1.56-.24 7.85 7.85 0 0 0 3.37-6.14v-8.78a1.13 1.13 0 1 1 2.25 0v8.78a10.09 10.09 0 0 1-4.49 8.09 4.46 4.46 0 0 1-2.24.62Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M165.52 233.5a1.13 1.13 0 0 1-1.13-1.13v-8.48a1.13 1.13 0 0 1 2.26 0v8.48a1.12 1.12 0 0 1-1.13 1.13Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M66.16 363.4a2.11 2.11 0 0 1 1 1.67c0 .62-.43.87-1 .56a2.16 2.16 0 0 1-1-1.67c.03-.62.47-.87 1-.56ZM74.24 368.07a2.11 2.11 0 0 1 1 1.67c0 .61-.43.86-1 .56a2.13 2.13 0 0 1-1-1.68c.03-.62.46-.86 1-.55ZM82.17 372.65a2.1 2.1 0 0 1 1 1.66c0 .62-.43.87-1 .56a2.13 2.13 0 0 1-1-1.67c.04-.61.47-.86 1-.55ZM90.18 377.27a2.13 2.13 0 0 1 1 1.66c0 .61-.43.86-1 .56a2.13 2.13 0 0 1-1-1.66c.04-.62.47-.83 1-.56ZM56.74 315.92l8.26 4.71a5.17 5.17 0 0 1 2.32 4v4.71c0 1.48-1 2.09-2.32 1.34L56.74 326a5.16 5.16 0 0 1-2.33-4v-4.71c0-1.51 1.05-2.11 2.33-1.37Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m59 321.23 1.07-.62a1.41 1.41 0 0 1 1.42.14 4.48 4.48 0 0 1 2 3.51 1.4 1.4 0 0 1-.59 1.3l-1 .57-.18-.3a1.81 1.81 0 0 1-.58-.22 4.46 4.46 0 0 1-2-3.5 2.06 2.06 0 0 1 .06-.52Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m59 321.23 1.07-.62a1.41 1.41 0 0 1 1.42.14 4.48 4.48 0 0 1 2 3.51 1.4 1.4 0 0 1-.59 1.3l-1 .57-.18-.3a1.81 1.81 0 0 1-.58-.22 4.46 4.46 0 0 1-2-3.5 2.06 2.06 0 0 1 .06-.52Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M60.42 321.34a4.49 4.49 0 0 1 2 3.51c0 1.28-.91 1.81-2 1.16a4.47 4.47 0 0 1-2-3.5c-.02-1.29.89-1.81 2-1.17Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M56.74 293.25 65 298a5.14 5.14 0 0 1 2.32 4v4.7c0 1.49-1 2.09-2.32 1.35l-8.21-4.72a5.15 5.15 0 0 1-2.33-4v-4.71c-.05-1.51 1-2.11 2.28-1.37Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="m59 298.56 1.07-.62a1.42 1.42 0 0 1 1.42.15 4.45 4.45 0 0 1 2 3.5 1.43 1.43 0 0 1-.59 1.31l-1 .57-.18-.3a2.12 2.12 0 0 1-.58-.23 4.45 4.45 0 0 1-2-3.5 2.12 2.12 0 0 1 .06-.52Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m59 298.56 1.07-.62a1.42 1.42 0 0 1 1.42.15 4.45 4.45 0 0 1 2 3.5 1.43 1.43 0 0 1-.59 1.31l-1 .57-.18-.3a2.12 2.12 0 0 1-.58-.23 4.45 4.45 0 0 1-2-3.5 2.12 2.12 0 0 1 .06-.52Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M60.42 298.68a4.46 4.46 0 0 1 2 3.5c0 1.29-.91 1.81-2 1.17a4.51 4.51 0 0 1-2-3.51c-.02-1.29.89-1.84 2-1.16Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m185.57 276.07-38 21.95a5.66 5.66 0 0 0-2.55 4.43v5.17c0 1.62 1.14 2.28 2.55 1.47l38-21.95a5.67 5.67 0 0 0 2.56-4.43v-5.16c0-1.63-1.13-2.29-2.56-1.48Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M185.43 280.45V283a1.13 1.13 0 0 1-.51.89l-.57.33c-.29.16-.52 0-.52-.29v-2.51a1.13 1.13 0 0 1 .52-.89l.57-.33c.28-.2.51-.08.51.25ZM182.85 281.94v2.5a1.13 1.13 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.29v-2.51a1.15 1.15 0 0 1 .51-.89l.58-.33c.28-.16.51-.03.51.3ZM180.27 283.42v2.51a1.15 1.15 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.3v-2.5a1.13 1.13 0 0 1 .51-.89l.58-.33c.24-.13.51-.03.51.29ZM177.69 284.91v2.51a1.11 1.11 0 0 1-.51.88l-.58.34c-.28.16-.51 0-.51-.3v-2.51a1.13 1.13 0 0 1 .51-.88l.58-.33c.28-.17.51-.03.51.29Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M175.1 286.4v2.51a1.1 1.1 0 0 1-.51.88l-.57.33c-.28.17-.51 0-.51-.29v-2.51a1.13 1.13 0 0 1 .51-.88l.57-.33c.29-.17.51-.03.51.29ZM172.52 287.89v2.51a1.13 1.13 0 0 1-.51.88l-.57.33c-.29.17-.51 0-.51-.29v-2.51a1.1 1.1 0 0 1 .51-.88l.57-.34c.28-.16.51-.03.51.3Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M169.94 289.38v2.5a1.13 1.13 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.29v-2.51a1.15 1.15 0 0 1 .51-.89l.58-.33c.28-.16.51-.03.51.3ZM167.36 290.87v2.5a1.13 1.13 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.3v-2.5a1.15 1.15 0 0 1 .51-.89l.58-.33c.28-.16.51-.03.51.3ZM164.78 292.35v2.51a1.15 1.15 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.3v-2.5a1.13 1.13 0 0 1 .51-.89l.58-.33c.28-.16.51-.06.51.29Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M162.2 293.84v2.51a1.1 1.1 0 0 1-.52.88l-.57.34c-.28.16-.51 0-.51-.3v-2.51a1.13 1.13 0 0 1 .51-.88l.57-.33c.32-.17.52-.03.52.29Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M159.61 295.33v2.51a1.13 1.13 0 0 1-.51.88l-.57.33c-.28.17-.51 0-.51-.29v-2.51a1.13 1.13 0 0 1 .51-.88l.57-.33c.28-.17.51-.04.51.29ZM157 296.82v2.51a1.13 1.13 0 0 1-.51.88l-.57.33c-.29.17-.51 0-.51-.29v-2.51a1.1 1.1 0 0 1 .51-.88l.57-.34c.31-.16.51-.03.51.3Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M154.45 298.31v2.5a1.13 1.13 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.29v-2.51a1.15 1.15 0 0 1 .51-.89l.58-.33c.28-.16.51-.01.51.3Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M151.87 299.8v2.5a1.13 1.13 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.3v-2.5a1.15 1.15 0 0 1 .51-.89l.58-.33c.28-.16.51-.03.51.3Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M149.29 301.28v2.51a1.15 1.15 0 0 1-.51.89l-.58.33c-.28.16-.51 0-.51-.3v-2.5a1.13 1.13 0 0 1 .51-.89l.58-.33c.28-.16.51.01.51.29Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m141.15 306.31-1.06-.62a1.41 1.41 0 0 0-1.42.14 4.49 4.49 0 0 0-2 3.51 1.42 1.42 0 0 0 .59 1.3l1 .56.18-.3a2 2 0 0 0 .58-.22 4.49 4.49 0 0 0 2-3.5 2.66 2.66 0 0 0-.06-.52Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M139.68 306.42a4.51 4.51 0 0 0-2 3.51c0 1.29.91 1.81 2 1.16a4.45 4.45 0 0 0 2-3.5c.02-1.29-.88-1.81-2-1.17Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M139.68 306.42a4.51 4.51 0 0 0-2 3.51c0 1.29.91 1.81 2 1.16a4.45 4.45 0 0 0 2-3.5c.02-1.29-.88-1.81-2-1.17Z"
      style={{
        fill: "#fff",
        opacity: 0.8,
      }}
    />
    <path
      d="m132.38 311.37-1.07-.61a1.44 1.44 0 0 0-1.42.15 4.45 4.45 0 0 0-2 3.5 1.41 1.41 0 0 0 .59 1.31l1 .57.18-.3a1.94 1.94 0 0 0 .58-.23 4.45 4.45 0 0 0 2-3.5 2 2 0 0 0-.06-.51Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M130.9 311.49a4.47 4.47 0 0 0-2 3.5c0 1.29.91 1.81 2 1.17a4.47 4.47 0 0 0 2-3.5c.03-1.29-.9-1.82-2-1.17Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M130.9 311.49a4.47 4.47 0 0 0-2 3.5c0 1.29.91 1.81 2 1.17a4.47 4.47 0 0 0 2-3.5c.03-1.29-.9-1.82-2-1.17Z"
      style={{
        fill: "#fff",
        opacity: 0.8,
      }}
    />
    <path
      d="m73.87 301.3 39.79 23a2.85 2.85 0 0 1 1.29 2.23v11c0 .82-.58 1.15-1.29.74l-39.79-23a2.83 2.83 0 0 1-1.29-2.27v-11c0-.78.57-1.11 1.29-.7Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M75 306.14a2.74 2.74 0 0 0 1.29 2.14l34.95 20.17c.71.41 1.29.11 1.29-.66a2.77 2.77 0 0 0-1.29-2.14l-34.95-20.16c-.72-.41-1.29-.12-1.29.65Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M75 311.73a2.74 2.74 0 0 0 1.29 2.14L111.24 334c.71.41 1.29.12 1.29-.65a2.77 2.77 0 0 0-1.29-2.14l-34.95-20.13c-.72-.41-1.29-.08-1.29.65Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m76.29 311.08 19.31 11.13V325l-19.31-11.13a2.74 2.74 0 0 1-1.29-2.14c0-.73.57-1.06 1.29-.65Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M56.74 293.25 65 298a5.14 5.14 0 0 1 2.32 4v4.7c0 1.49-1 2.09-2.32 1.35l-8.21-4.72a5.15 5.15 0 0 1-2.33-4v-4.71c-.05-1.51 1-2.11 2.28-1.37Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m59 298.56 1.07-.62a1.42 1.42 0 0 1 1.42.15 4.45 4.45 0 0 1 2 3.5 1.43 1.43 0 0 1-.59 1.31l-1 .57-.18-.3a2.12 2.12 0 0 1-.58-.23 4.45 4.45 0 0 1-2-3.5 2.12 2.12 0 0 1 .06-.52Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m59 298.56 1.07-.62a1.42 1.42 0 0 1 1.42.15 4.45 4.45 0 0 1 2 3.5 1.43 1.43 0 0 1-.59 1.31l-1 .57-.18-.3a2.12 2.12 0 0 1-.58-.23 4.45 4.45 0 0 1-2-3.5 2.12 2.12 0 0 1 .06-.52Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M60.42 298.68a4.46 4.46 0 0 1 2 3.5c0 1.29-.91 1.81-2 1.17a4.51 4.51 0 0 1-2-3.51c-.02-1.29.89-1.84 2-1.16Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m73.87 301.3 39.79 23a2.85 2.85 0 0 1 1.29 2.23v11c0 .82-.58 1.15-1.29.74l-39.79-23a2.83 2.83 0 0 1-1.29-2.27v-11c0-.78.57-1.11 1.29-.7Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M75 306.14a2.74 2.74 0 0 0 1.29 2.14l34.95 20.17c.71.41 1.29.11 1.29-.66a2.77 2.77 0 0 0-1.29-2.14l-34.95-20.16c-.72-.41-1.29-.12-1.29.65Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M75 311.73a2.74 2.74 0 0 0 1.29 2.14L111.24 334c.71.41 1.29.12 1.29-.65a2.77 2.77 0 0 0-1.29-2.14l-34.95-20.13c-.72-.41-1.29-.08-1.29.65Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m76.29 311.08 19.31 11.13V325l-19.31-11.13a2.74 2.74 0 0 1-1.29-2.14c0-.73.57-1.06 1.29-.65Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m73.87 324 39.79 23a2.89 2.89 0 0 1 1.29 2.24v11c0 .82-.58 1.15-1.29.74L73.87 338a2.84 2.84 0 0 1-1.29-2.24v-11c0-.76.57-1.14 1.29-.76Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M75 328.87a2.74 2.74 0 0 0 1.29 2.13l34.95 20.16c.71.42 1.29.12 1.29-.65a2.77 2.77 0 0 0-1.29-2.14l-34.95-20.15c-.72-.41-1.29-.12-1.29.65Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M75 334.46a2.74 2.74 0 0 0 1.29 2.14l34.95 20.16c.71.41 1.29.12 1.29-.65a2.77 2.77 0 0 0-1.29-2.14l-34.95-20.16c-.72-.41-1.29-.12-1.29.65Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m76.29 333.81 24.15 13.92v2.79L76.29 336.6a2.74 2.74 0 0 1-1.29-2.14c0-.77.57-1.06 1.29-.65Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m56.74 338.72 8.26 4.71a5.15 5.15 0 0 1 2.32 4v4.71c0 1.48-1 2.08-2.32 1.34l-8.21-4.71a5.18 5.18 0 0 1-2.33-4v-4.71c-.05-1.48 1-2.06 2.28-1.34Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="m59 344 1.07-.62a1.41 1.41 0 0 1 1.42.14 4.46 4.46 0 0 1 2 3.5 1.41 1.41 0 0 1-.59 1.31l-1 .57-.18-.3a2.08 2.08 0 0 1-.58-.22 4.48 4.48 0 0 1-2-3.51 2 2 0 0 1 .06-.51Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m59 344 1.07-.62a1.41 1.41 0 0 1 1.42.14 4.46 4.46 0 0 1 2 3.5 1.41 1.41 0 0 1-.59 1.31l-1 .57-.18-.3a2.08 2.08 0 0 1-.58-.22 4.48 4.48 0 0 1-2-3.51 2 2 0 0 1 .06-.51Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M60.42 344.14a4.46 4.46 0 0 1 2 3.5c0 1.29-.91 1.81-2 1.17a4.47 4.47 0 0 1-2-3.5c-.02-1.31.89-1.81 2-1.17Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m56.74 338.72 8.26 4.71a5.15 5.15 0 0 1 2.32 4v4.71c0 1.48-1 2.08-2.32 1.34l-8.21-4.71a5.18 5.18 0 0 1-2.33-4v-4.71c-.05-1.48 1-2.06 2.28-1.34Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m59 344 1.07-.62a1.41 1.41 0 0 1 1.42.14 4.46 4.46 0 0 1 2 3.5 1.41 1.41 0 0 1-.59 1.31l-1 .57-.18-.3a2.08 2.08 0 0 1-.58-.22 4.48 4.48 0 0 1-2-3.51 2 2 0 0 1 .06-.51Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m59 344 1.07-.62a1.41 1.41 0 0 1 1.42.14 4.46 4.46 0 0 1 2 3.5 1.41 1.41 0 0 1-.59 1.31l-1 .57-.18-.3a2.08 2.08 0 0 1-.58-.22 4.48 4.48 0 0 1-2-3.51 2 2 0 0 1 .06-.51Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M60.42 344.14a4.46 4.46 0 0 1 2 3.5c0 1.29-.91 1.81-2 1.17a4.47 4.47 0 0 1-2-3.5c-.02-1.31.89-1.81 2-1.17Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m73.87 346.77 39.79 23A2.85 2.85 0 0 1 115 372v11c0 .83-.58 1.16-1.29.75l-39.79-23a2.83 2.83 0 0 1-1.29-2.23v-11c-.05-.83.52-1.17 1.24-.75Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M75 351.61a2.74 2.74 0 0 0 1.29 2.14l34.95 20.16c.71.41 1.29.12 1.29-.65a2.77 2.77 0 0 0-1.29-2.14L76.29 351c-.72-.46-1.29-.16-1.29.61Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M75 357.19a2.76 2.76 0 0 0 1.29 2.15l34.95 20.16c.71.41 1.29.12 1.29-.65a2.79 2.79 0 0 0-1.29-2.15l-34.95-20.16c-.72-.41-1.29-.12-1.29.65Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m76.29 356.54 19.31 11.13v2.8l-19.31-11.13a2.76 2.76 0 0 1-1.29-2.15c0-.77.57-1.06 1.29-.65Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m177.29 158.41-53.07 30.64a3.07 3.07 0 0 1-2.74 0l-53.07-30.64a.84.84 0 0 1 0-1.59l53.07-29.58a3.07 3.07 0 0 1 2.74 0l53.07 29.58a.84.84 0 0 1 0 1.59Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m124.16 187.37 50.78-29.32a2.9 2.9 0 0 0 1.32-2.27v-1.71a2.91 2.91 0 0 0-1.32-2.28l-50.78-29.32a2.88 2.88 0 0 0-2.62 0l-50.78 29.32a2.91 2.91 0 0 0-1.32 2.28v1.71a2.9 2.9 0 0 0 1.32 2.27l50.78 29.32a2.88 2.88 0 0 0 2.62 0Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m124.16 187.37 50.78-29.32a2.9 2.9 0 0 0 1.32-2.27v-1.71a2.91 2.91 0 0 0-1.32-2.28l-50.78-29.32a2.88 2.88 0 0 0-2.62 0l-50.78 29.32a2.91 2.91 0 0 0-1.32 2.28v1.71a2.9 2.9 0 0 0 1.32 2.27l50.78 29.32a2.88 2.88 0 0 0 2.62 0Z"
      style={{
        fill: "#fff",
        opacity: 0.5,
      }}
    />
    <path
      d="m124.16 187.37 50.78-29.32a2.9 2.9 0 0 0 1.32-2.27v-1.71a2.91 2.91 0 0 0-1.32-2.28l-50.78-29.32a2.88 2.88 0 0 0-2.62 0l-50.78 29.32a2.91 2.91 0 0 0-1.32 2.28v1.71a2.9 2.9 0 0 0 1.32 2.27l50.78 29.32a2.88 2.88 0 0 0 2.62 0Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m174.94 153.31-50.78 29.31a2.88 2.88 0 0 1-2.62 0l-50.78-29.31a.8.8 0 0 1 0-1.52l50.78-28.3a2.88 2.88 0 0 1 2.62 0l50.78 28.3a.8.8 0 0 1 0 1.52Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m174.94 153.31-50.78 29.31a2.88 2.88 0 0 1-2.62 0l-50.78-29.31a.8.8 0 0 1 0-1.52l50.78-28.3a2.88 2.88 0 0 1 2.62 0l50.78 28.3a.8.8 0 0 1 0 1.52Z"
      style={{
        fill: "#fff",
        opacity: 0.7000000000000001,
      }}
    />
    <path
      d="M122.85 182.94v4.74a2.67 2.67 0 0 0 1.31-.31l50.78-29.32a2.91 2.91 0 0 0 1.32-2.28v-1.71a2.92 2.92 0 0 0-1.07-2.1c.47.41.39 1-.25 1.34l-50.78 29.32a2.57 2.57 0 0 1-1.31.32Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M122.85 182.94v4.74a2.67 2.67 0 0 0 1.31-.31l50.78-29.32a2.91 2.91 0 0 0 1.32-2.28v-1.71a2.92 2.92 0 0 0-1.07-2.1c.47.41.39 1-.25 1.34l-50.78 29.32a2.57 2.57 0 0 1-1.31.32Z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="m124.16 173.14 34.35-19.83a2.89 2.89 0 0 0 1.31-2.28v-1.71a2.88 2.88 0 0 0-1.31-2.27l-22-12.72a8.71 8.71 0 0 1-2.22-2l-10.52-14.17a1.74 1.74 0 0 0-2.21-.46l-34.37 19.86a2.89 2.89 0 0 0-1.31 2.27V151a2.92 2.92 0 0 0 1.31 2.28l34.35 19.83a2.88 2.88 0 0 0 2.62.03Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m121.54 168.39-22-12.71a7.19 7.19 0 0 1-1.15-.85 7.87 7.87 0 0 1-1.07-1.13l-10.54-14.17a1.31 1.31 0 0 1 .41-2l34.35-19.83a1.74 1.74 0 0 1 2.21.46l10.52 14.17a8.28 8.28 0 0 0 2 2l22.2 12.64a.79.79 0 0 1 0 1.51l-34.35 19.83a2.88 2.88 0 0 1-2.58.08Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="m87.19 137.56 34.35-19.83a1.74 1.74 0 0 1 2.21.46l10.52 14.17a8 8 0 0 0 1.08 1.12s-37 21.35-37 21.34a7.84 7.84 0 0 1-1.07-1.12l-10.5-14.17a1.31 1.31 0 0 1 .41-1.97Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M150.18 147.39a3.41 3.41 0 0 0-3.08 0c-.86.5-.86 1.29 0 1.78a3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78ZM144 151a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M137.85 154.51a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78ZM131.69 158.07a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M145.56 144.73a3.37 3.37 0 0 0-3.09 0 .93.93 0 0 0 0 1.77 3.37 3.37 0 0 0 3.09 0 .93.93 0 0 0 0-1.77Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M139.39 148.28a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <ellipse
      cx={131.69}
      cy={152.73}
      rx={2.18}
      ry={1.26}
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M127.07 155.4a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78ZM140.93 142.06a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M134.77 145.62a3.35 3.35 0 0 0-3.08 0 .93.93 0 0 0 0 1.77 3.35 3.35 0 0 0 3.08 0 .93.93 0 0 0 0-1.77Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M128.61 149.17a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M125.49 161.55a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <ellipse
      cx={119.33}
      cy={159.77}
      rx={2.18}
      ry={1.26}
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M122.45 152.73a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M136.31 139.39a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M130.15 143a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M124 146.51a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M117.83 150.06a3.41 3.41 0 0 0-3.08 0c-.86.5-.86 1.29 0 1.78a3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M116.25 156.22a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M111.63 153.55a3.41 3.41 0 0 0-3.08 0 .94.94 0 0 0 0 1.78 3.41 3.41 0 0 0 3.08 0 .94.94 0 0 0 0-1.78Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M122.78 168.71v4.73a2.72 2.72 0 0 0 1.38-.3l34.35-19.84a2.86 2.86 0 0 0 1.31-2.27v-1.71a2.89 2.89 0 0 0-1.07-2.1c.48.41.4 1-.24 1.34l-34.35 19.83a2.63 2.63 0 0 1-1.38.32Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M129.73 131.76a1.17 1.17 0 0 0-1.48-.31l-29.92 17.22a.89.89 0 0 0-.27 1.32 1.18 1.18 0 0 0 1.48.31l29.88-17.26a.88.88 0 0 0 .31-1.28Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M129.73 131.76a1.17 1.17 0 0 0-1.48-.31L106 144.24l1.24 1.67 22.21-12.83a.88.88 0 0 0 .28-1.32Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M127.26 128.45a1.16 1.16 0 0 0-1.48-.31l-29.92 17.22a.87.87 0 0 0-.27 1.31 1.16 1.16 0 0 0 1.48.31L127 129.77a.88.88 0 0 0 .26-1.32Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M127.26 128.45a1.16 1.16 0 0 0-1.48-.31l-7.43 4.29 1.24 1.66 7.42-4.28a.88.88 0 0 0 .25-1.36Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M124.79 125.13a1.16 1.16 0 0 0-1.48-.31L93.39 142a.89.89 0 0 0-.27 1.32 1.16 1.16 0 0 0 1.48.3l29.88-17.25a.88.88 0 0 0 .31-1.24Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M124.79 125.13a1.16 1.16 0 0 0-1.48-.31l-15.57 9 1.26 1.58 15.56-9a.88.88 0 0 0 .23-1.27Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M122.32 121.81a1.16 1.16 0 0 0-1.48-.3l-29.92 17.21a.88.88 0 0 0-.27 1.32 1.16 1.16 0 0 0 1.48.31L122 123.13a.88.88 0 0 0 .32-1.32Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M122.32 121.81a1.16 1.16 0 0 0-1.48-.3l-27.16 15.62 1.24 1.66L122 123.13a.88.88 0 0 0 .32-1.32ZM132.4 412.71v7.54a.56.56 0 0 0 .51.55l3.93.24v5.54a.33.33 0 0 0 .19.28l4.15 2.39a1 1 0 0 0 .52.11.83.83 0 0 0 .39-.08l4.2-1.94a1.58 1.58 0 0 1 1-.06 24.6 24.6 0 0 0 3.26.78.78.78 0 0 1 .61.59l.52 2.76a.69.69 0 0 0 .23.37.62.62 0 0 0 .39.14h6.09a.55.55 0 0 0 .34-.13.19.19 0 0 0 .08-.07.73.73 0 0 0 .09-.08v-.06c0-.06.09-.1.1-.17l.53-2.76a.77.77 0 0 1 .6-.59 24.23 24.23 0 0 0 3.26-.78 1.58 1.58 0 0 1 1 .06l4.2 1.94a.71.71 0 0 0 .26 0h.15a1 1 0 0 0 .4-.1l4.14-2.39a.41.41 0 0 0 .15-.14.35.35 0 0 0 0-.11V421l3.86-.25h.07a.93.93 0 0 0 .1-.05.53.53 0 0 0 .26-.13.41.41 0 0 0 .08-.19c0-.06.08-.11.08-.18v-10.11a.56.56 0 0 0-.52-.55l-3.93-.25v-5.53a.34.34 0 0 0-.19-.3l-4.14-2.39a1.14 1.14 0 0 0-.92 0l-4.2 1.94a1.5 1.5 0 0 1-1 .06 24.21 24.21 0 0 0-3.26-.77.79.79 0 0 1-.6-.59L159 399a.65.65 0 0 0-.61-.51h-6.09a.66.66 0 0 0-.62.51l-.52 2.77a.8.8 0 0 1-.61.59 24.58 24.58 0 0 0-3.26.77 1.51 1.51 0 0 1-1-.06l-4.2-1.94a1.11 1.11 0 0 0-.91 0l-4.14 2.39a.35.35 0 0 0-.2.3v5.52l-3.93.25a.56.56 0 0 0-.51.55v2.46a.41.41 0 0 0 0 .11Zm15.91 2a15.48 15.48 0 0 1 14 0q.44.25.81.51a8.14 8.14 0 0 1-.81.54 15.46 15.46 0 0 1-14 0 7.58 7.58 0 0 1-.81-.53 8.52 8.52 0 0 1 .81-.56Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M132.39 412.71v7.54a.55.55 0 0 0 .51.54l3.93.25v5.54a.33.33 0 0 0 .19.28l4.15 2.39a1 1 0 0 0 .52.11.83.83 0 0 0 .39-.08l4.2-1.94a1.46 1.46 0 0 1 .4-.1 1.47 1.47 0 0 1 .56 0 24.6 24.6 0 0 0 3.26.78.64.64 0 0 1 .26.1.79.79 0 0 1 .35.46l.52 2.76a.63.63 0 0 0 .23.36.62.62 0 0 0 .39.14h6.2a.69.69 0 0 0 .4-.27v-.06c0-.06.09-.1.1-.17l.53-2.76a.7.7 0 0 1 .24-.4.77.77 0 0 1 .36-.18 24.23 24.23 0 0 0 3.26-.78 1.69 1.69 0 0 1 .64 0 1.27 1.27 0 0 1 .32.07l4.2 1.94a.71.71 0 0 0 .26 0 1.07 1.07 0 0 0 .65-.1l4.14-2.39.09-.06.07-.09a.27.27 0 0 0 0-.12v-5.54l.72-.05 3.14-.2h.07a.55.55 0 0 0 .52-.54v-7a.55.55 0 0 1-.52.54l-5.52.35a1 1 0 0 0-.74.5 8.28 8.28 0 0 1-1.18 1.63.43.43 0 0 0 .07.67l3.28 2.36a.31.31 0 0 1 0 .56l-4.14 2.39a1.1 1.1 0 0 1-.92 0l-4.2-1.94a1.57 1.57 0 0 0-1-.06 23.87 23.87 0 0 1-3.26.78.79.79 0 0 0-.6.59l-.53 2.76a.65.65 0 0 1-.61.51h-6.09a.66.66 0 0 1-.62-.51l-.52-2.76a.81.81 0 0 0-.61-.59 24.6 24.6 0 0 1-3.26-.78 1.58 1.58 0 0 0-1 .06l-4.2 1.94a1.07 1.07 0 0 1-.91 0l-4.08-2.31a.31.31 0 0 1 0-.56l3.28-2.36a.45.45 0 0 0 .06-.68 7.89 7.89 0 0 1-1.17-1.62 1 1 0 0 0-.75-.5l-5.52-.34a.56.56 0 0 1-.51-.55v-.62a.43.43 0 0 0 0 .11ZM136.85 403.82v5.52l1.59-.1a1 1 0 0 0 .75-.5 8.17 8.17 0 0 1 1.17-1.62.45.45 0 0 0-.06-.68l-3.3-2.36a.37.37 0 0 1-.17-.26Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="m147.51 415.19.35-.25.41-.25a15.48 15.48 0 0 1 14 0l.37.22c.15.1.3.19.44.3 3-2.25 2.76-5.49-.81-7.55a15.4 15.4 0 0 0-14 0c-3.53 2.04-3.8 5.34-.76 7.53Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M170.26 407.12a8.23 8.23 0 0 1 1.18 1.62 1 1 0 0 0 .74.5l1.59.1v-5.53a.39.39 0 0 1-.17.27l-3.28 2.36a.44.44 0 0 0-.06.68Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="m168.52 401.09-4.2 1.94a1.5 1.5 0 0 1-1 .06 24.21 24.21 0 0 0-3.26-.77.79.79 0 0 1-.6-.59L159 399a.65.65 0 0 0-.61-.51h-6.09a.66.66 0 0 0-.62.51l-.52 2.77a.8.8 0 0 1-.61.59 24.58 24.58 0 0 0-3.26.77 1.51 1.51 0 0 1-1-.06l-4.2-1.94a1.11 1.11 0 0 0-.91 0l-4.14 2.39a.31.31 0 0 0 0 .56l3.28 2.36a.45.45 0 0 1 .06.68 8.17 8.17 0 0 0-1.17 1.62 1 1 0 0 1-.75.5l-5.52.35a.56.56 0 0 0-.51.55v3.08a.56.56 0 0 0 .51.55l5.52.34a1 1 0 0 1 .75.5 8.22 8.22 0 0 0 1.17 1.63.44.44 0 0 1-.06.67l-3.32 2.36a.31.31 0 0 0 0 .56l4.14 2.39a1.07 1.07 0 0 0 .91 0l4.2-1.94a1.58 1.58 0 0 1 1-.06 24.6 24.6 0 0 0 3.26.78.78.78 0 0 1 .61.59l.52 2.76a.66.66 0 0 0 .62.51h6.09a.65.65 0 0 0 .61-.51l.53-2.76a.77.77 0 0 1 .6-.59 24.23 24.23 0 0 0 3.26-.78 1.57 1.57 0 0 1 1 .06l4.2 1.94a1.1 1.1 0 0 0 .92 0l4.14-2.39a.31.31 0 0 0 0-.56l-3.28-2.36a.42.42 0 0 1-.06-.67 8.22 8.22 0 0 0 1.17-1.63 1 1 0 0 1 .74-.5l5.52-.34a.56.56 0 0 0 .52-.55v-3.08a.56.56 0 0 0-.52-.55l-5.52-.35a1 1 0 0 1-.74-.5 8.17 8.17 0 0 0-1.17-1.62.43.43 0 0 1 .06-.68l3.28-2.36a.31.31 0 0 0 0-.56l-4.14-2.39a1.14 1.14 0 0 0-.95-.04Zm-6.21 6.55c3.87 2.23 3.87 5.85 0 8.08a15.46 15.46 0 0 1-14 0c-3.87-2.23-3.87-5.85 0-8.08a15.46 15.46 0 0 1 14 0Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M173.77 419.55a.35.35 0 0 0-.16-.28l-1.61-1.19v-3.91a.9.9 0 0 1 .22-.06l5.52-.35a.55.55 0 0 0 .52-.54v7a.55.55 0 0 1-.52.54h-.07l-3.14.2-.72.05s-.03-1.36-.04-1.46Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M173.77 419.55a.35.35 0 0 0-.16-.28l-1.61-1.19v-3.91a.9.9 0 0 1 .22-.06l5.52-.35a.55.55 0 0 0 .52-.54v7a.55.55 0 0 1-.52.54h-.07l-3.14.2-.72.05s-.03-1.36-.04-1.46Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M164 420.23a1.26 1.26 0 0 1 .31.09l4.2 1.94a1.06 1.06 0 0 0 .54.07v7a.88.88 0 0 1-.28 0 .71.71 0 0 1-.26 0l-4.2-1.94a1.29 1.29 0 0 0-.31-.07Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M164 420.23a1.26 1.26 0 0 1 .31.09l4.2 1.94a1.06 1.06 0 0 0 .54.07v7a.88.88 0 0 1-.28 0 .71.71 0 0 1-.26 0l-4.2-1.94a1.29 1.29 0 0 0-.31-.07Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M158.89 424.59a.5.5 0 0 0 .08-.2l.53-2.76a.49.49 0 0 1 .08-.21v7a.54.54 0 0 0-.08.21l-.53 2.76c0 .06-.05.09-.08.14Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M158.89 424.59a.5.5 0 0 0 .08-.2l.53-2.76a.49.49 0 0 1 .08-.21v7a.54.54 0 0 0-.08.21l-.53 2.76c0 .06-.05.09-.08.14Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M151.05 421.42a.66.66 0 0 1 .08.21l.52 2.76a1.19 1.19 0 0 0 .08.2v7a1.19 1.19 0 0 1-.08-.2l-.52-2.76a.64.64 0 0 0-.08-.19ZM141.56 422.33a1.06 1.06 0 0 0 .54-.07l4.2-1.94a1.35 1.35 0 0 1 .32-.09v7a1 1 0 0 0-.32.08l-4.2 1.94a.83.83 0 0 1-.39.08h-.15ZM132.92 413.77l5.52.34a1 1 0 0 1 .23.06v3.91l-1.67 1.19a.36.36 0 0 0-.17.28V421l-3.93-.25a.55.55 0 0 1-.51-.54v-7.54a.43.43 0 0 1 0-.11v.62a.56.56 0 0 0 .53.59Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M213.17 391.05v-5.1a.4.4 0 0 0-.09-.24l-1.55-2.16a.71.71 0 0 0-.63-.3l-4.06.68a1.3 1.3 0 0 1-.77-.22 9.1 9.1 0 0 0-.8-.51V380c0-.13-.12-.25-.32-.3l-4.12-1a.79.79 0 0 0-.66.12l-2 2.07a.88.88 0 0 1-.64.2 21.77 21.77 0 0 0-2.68 0 1.1 1.1 0 0 1-.73-.32l-1.76-1.77a.79.79 0 0 0-.68-.25l-4.28 1a.27.27 0 0 0-.22.28v3.21a9.23 9.23 0 0 0-1 .61 1.12 1.12 0 0 1-.7.12l-3.93-.66a.69.69 0 0 0-.62.18l-1.7 2.37a.15.15 0 0 0 0 .11v6.16a.29.29 0 0 0 0 .1v5a.41.41 0 0 0 .09.26l1.55 2.16a.74.74 0 0 0 .63.3l4-.69a1.26 1.26 0 0 1 .78.23c.25.17.52.34.8.5v3.21c0 .14.12.26.32.31l4.11 1a.77.77 0 0 0 .67-.13l2-2.07a.88.88 0 0 1 .64-.2 24 24 0 0 0 2.68 0 1 1 0 0 1 .72.31l1.77 1.85a.82.82 0 0 0 .68.26l4.28-1a.29.29 0 0 0 .22-.23v-3.26a11.2 11.2 0 0 0 1-.6 1 1 0 0 1 .7-.13l3.93.67a.67.67 0 0 0 .62-.19l1.7-2.37a.17.17 0 0 0 0-.11v-6.17Zm-22.89.13c.19-.13.39-.26.61-.39a11.8 11.8 0 0 1 10.64 0c.21.13.41.25.59.38a5.89 5.89 0 0 1-.64.42 11.82 11.82 0 0 1-8.16.92 9.18 9.18 0 0 1-2.41-.92c-.22-.13-.44-.27-.63-.41Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M213.17 391.05v-5.1a.4.4 0 0 0-.09-.24l-1.55-2.16a.71.71 0 0 0-.63-.3l-4.06.68a1.3 1.3 0 0 1-.77-.22 9.1 9.1 0 0 0-.8-.51V380c0-.13-.12-.25-.32-.3l-4.12-1a.79.79 0 0 0-.66.12l-2 2.07a.88.88 0 0 1-.64.2 21.77 21.77 0 0 0-2.68 0 1.1 1.1 0 0 1-.73-.32l-1.76-1.77a.79.79 0 0 0-.68-.25l-4.28 1a.27.27 0 0 0-.22.28v3.21a9.23 9.23 0 0 0-1 .61 1.12 1.12 0 0 1-.7.12l-3.93-.66a.69.69 0 0 0-.62.18l-1.7 2.37a.15.15 0 0 0 0 .11v6.16a.29.29 0 0 0 0 .1v5a.41.41 0 0 0 .09.26l1.55 2.16a.74.74 0 0 0 .63.3l4-.69a1.26 1.26 0 0 1 .78.23c.25.17.52.34.8.5v3.21c0 .14.12.26.32.31l4.11 1a.77.77 0 0 0 .67-.13l2-2.07a.88.88 0 0 1 .64-.2 24 24 0 0 0 2.68 0 1 1 0 0 1 .72.31l1.77 1.85a.82.82 0 0 0 .68.26l4.28-1a.29.29 0 0 0 .22-.23v-3.26a11.2 11.2 0 0 0 1-.6 1 1 0 0 1 .7-.13l3.93.67a.67.67 0 0 0 .62-.19l1.7-2.37a.17.17 0 0 0 0-.11v-6.17Zm-22.89.13c.19-.13.39-.26.61-.39a11.8 11.8 0 0 1 10.64 0c.21.13.41.25.59.38a5.89 5.89 0 0 1-.64.42 11.82 11.82 0 0 1-8.16.92 9.18 9.18 0 0 1-2.41-.92c-.22-.13-.44-.27-.63-.41Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M179.23 386Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M188.12 382.74c-.33.15-.64.31-.94.48V380a.28.28 0 0 0 0 .17l1 2a.38.38 0 0 1-.06.57Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M183.41 387.81a4 4 0 0 0 0 1.34.42.42 0 0 1-.27.47l-3.7 1.17a.26.26 0 0 0-.18.24v1.2V386c0 .12.12.23.3.28l3.49 1.11a.49.49 0 0 1 .36.42ZM185.72 393v6.18h-.15l-4 .68a.47.47 0 0 1-.2 0v-6.18a.5.5 0 0 0 .21 0l4.05-.68Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M187.15 400c-.28-.16-.55-.33-.8-.5a1.51 1.51 0 0 0-.63-.23V393a1.42 1.42 0 0 1 .64.23 12.15 12.15 0 0 0 1.64.94c.24.12.37.33.29.49l-1.11 2.2a.3.3 0 0 0 0 .1c-.02.16-.03 3.04-.03 3.04Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M194.47 395.94v6.18a.55.55 0 0 0-.25.14l-2 2.07a.54.54 0 0 1-.36.14v-6.18a.55.55 0 0 0 .37-.14l2-2.07a.76.76 0 0 1 .24-.14Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M197.8 395.92v6.18a.61.61 0 0 0-.26 0 24 24 0 0 1-2.68 0 1 1 0 0 0-.39.06v-6.18a1.08 1.08 0 0 1 .4-.06 21.78 21.78 0 0 0 2.68 0 .59.59 0 0 1 .25 0Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M200.42 398.27v6.18a.86.86 0 0 1-.39-.23l-1.76-1.85a1.06 1.06 0 0 0-.47-.27v-6.18a1.16 1.16 0 0 1 .47.27L200 398a.83.83 0 0 0 .42.27Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M205.21 397.05v6.21a.29.29 0 0 1-.22.23l-4.28 1a.49.49 0 0 1-.29 0v-6.18a.54.54 0 0 0 .3 0l4.28-1a.25.25 0 0 0 .21-.26Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M205.21 397.05v6.21a.29.29 0 0 1-.22.23l-4.28 1a.49.49 0 0 1-.29 0v-6.18a.54.54 0 0 0 .3 0l4.28-1a.25.25 0 0 0 .21-.26Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M206.59 393.05v6.18a.91.91 0 0 0-.42.13 11.2 11.2 0 0 1-1 .6s0-2.83 0-3a.41.41 0 0 0-.05-.17l-1-2a.36.36 0 0 1 .12-.51 11.52 11.52 0 0 0 1.9-1.09.87.87 0 0 1 .45-.14Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M213.17 391.06v6.17a.17.17 0 0 1 0 .11l-1.7 2.37a.52.52 0 0 1-.29.17v-6.17a.66.66 0 0 0 .3-.17l1.7-2.38a.21.21 0 0 0-.01-.1Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M213.17 391.06v6.17a.17.17 0 0 1 0 .11l-1.7 2.37a.52.52 0 0 1-.29.17v-6.17a.66.66 0 0 0 .3-.17l1.7-2.38a.21.21 0 0 0-.01-.1Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M213.17 391.06v6.17a.17.17 0 0 1 0 .11l-1.7 2.37a.52.52 0 0 1-.29.17v-6.17a.66.66 0 0 0 .3-.17l1.7-2.38a.21.21 0 0 0-.01-.1Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M187.18 380a.37.37 0 0 0 0 .16l1 2a.37.37 0 0 1-.13.52c-.33.15-.64.31-.94.48a9.23 9.23 0 0 0-1 .61 1.12 1.12 0 0 1-.7.12l-3.93-.66a.69.69 0 0 0-.62.18l-1.7 2.37a.15.15 0 0 0 0 .11c0 .11.12.21.3.27l3.49 1.11a.49.49 0 0 1 .39.46 4 4 0 0 0 0 1.34.42.42 0 0 1-.27.47l-3.7 1.17a.25.25 0 0 0-.17.24.49.49 0 0 0 .08.24l1.55 2.16a.76.76 0 0 0 .64.3l4.05-.68a1.2 1.2 0 0 1 .78.22 12.15 12.15 0 0 0 1.64.94c.24.12.37.33.29.49l-1.11 2.2c-.08.16.06.33.3.4l4.11 1a.77.77 0 0 0 .67-.13l2-2.07a1 1 0 0 1 .64-.2 24 24 0 0 0 2.68 0 1 1 0 0 1 .72.31L200 398a.82.82 0 0 0 .68.26l4.28-1a.29.29 0 0 0 .17-.45l-1-2a.35.35 0 0 1 .13-.51 11.84 11.84 0 0 0 1.89-1.09 1 1 0 0 1 .71-.12l3.92.66a.69.69 0 0 0 .63-.19l1.7-2.37a.14.14 0 0 0 0-.1v-.1a.47.47 0 0 0-.27-.19l-3.5-1.1c-.24-.08-.41-.29-.38-.47a4.56 4.56 0 0 0 0-1.33.43.43 0 0 1 .27-.48l3.7-1.17a.23.23 0 0 0 .17-.22.4.4 0 0 0-.09-.24l-1.55-2.16a.71.71 0 0 0-.63-.3l-4.06.68a1.3 1.3 0 0 1-.77-.22 9.1 9.1 0 0 0-.8-.51c-.27-.15-.55-.3-.84-.44s-.38-.33-.3-.48l1.12-2.21a.29.29 0 0 0 0-.09c0-.13-.12-.25-.32-.3l-4.12-1a.79.79 0 0 0-.66.12l-2 2.07a.88.88 0 0 1-.64.2 21.77 21.77 0 0 0-2.68 0 1.1 1.1 0 0 1-.73-.32l-1.67-1.83a.79.79 0 0 0-.68-.25l-4.28 1a.27.27 0 0 0-.22.25Zm15.55 6.35a1.14 1.14 0 0 1 .11.11l.07.07.15.19c.06.07.11.15.16.22l.09.15v.06a2.65 2.65 0 0 1-.21 3 4.45 4.45 0 0 1-1 1 5.89 5.89 0 0 1-.64.42 11.82 11.82 0 0 1-8.16.92 9.18 9.18 0 0 1-2.41-.92c-.22-.13-.44-.27-.63-.41a4.26 4.26 0 0 1-1.28-1.43.2.2 0 0 1 0-.08.36.36 0 0 1 0-.09.36.36 0 0 1 0-.09 2.66 2.66 0 0 1 .38-2.63c1.59-2.21 6-3.27 9.82-2.35a7.61 7.61 0 0 1 3.55 1.87Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M236.85 372.18c-.85.49-2.44.47-2.94 1.05v2.08l9.68-1.41 3.66 2.14-1.39 2.96-9.78-.65v2.08a6.19 6.19 0 0 0 .66.42 16.48 16.48 0 0 0 14.92.07c2.09-1.19 3.14-2.76 3.14-4.33v-2.08l-.58.09-.06-.11 36.63-21.15a16.63 16.63 0 0 0 11-1.38 6.13 6.13 0 0 0 2.94-3.13v-2.13l-9.68 3.49-3.66-2.14 1.39-2.91 9.78-1.43v-2.09s-11.43-.76-15.58 1.6a6.89 6.89 0 0 0-2.59 2.39l-.55-.2v2.07a4 4 0 0 0 .64 2.17l-36.61 21.15a16.6 16.6 0 0 0-11.02 1.38Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m290.79 353.29.01-2.09-36.64 21.15v2.09l36.63-21.15z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m284.51 347.57-36.63 21.15a16.57 16.57 0 0 0-11 1.38 6.1 6.1 0 0 0-2.94 3.13l9.68-1.42 3.66 2.15-1.39 2.91-9.78 1.43c.21.14.42.28.66.42a16.48 16.48 0 0 0 14.92.06c3-1.73 3.87-4.26 2.5-6.43l36.63-21.15a16.57 16.57 0 0 0 11-1.38 6.12 6.12 0 0 0 2.94-3.12l-9.68 1.41-3.66-2.11 1.39-2.9 9.78-1.44-.66-.42a16.51 16.51 0 0 0-14.92-.06c-3.01 1.69-3.87 4.22-2.5 6.39Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="m292.2 346.42-.78-.46 1.39-2.9 9.78-1.44v2.09l-9.78 1.43-.61 1.28zM246.47 375.59l.78-1.63-3.66-2.15-9.68 1.42v2.08l9.68-1.41 2.88 1.69z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m259.26 403 7.66 13.26 25.83-14.91c1.06-.53 1.73-1.77 1.73-3.58a12 12 0 0 0-5.42-9.38 3.82 3.82 0 0 0-3.84-.38Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M257.72 405.71c-.11 2-3 2.45-3 2.45l5.41 9.38s1.81-2.22 3.62-1.34c2.67 1.16 4.74-.28 4.74-3.46a12 12 0 0 0-5.42-9.39c-2.74-1.6-5.01-.53-5.35 2.36Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M257.72 405.71c-.11 2-3 2.45-3 2.45l5.41 9.38s1.81-2.22 3.62-1.34c2.67 1.16 4.74-.28 4.74-3.46a12 12 0 0 0-5.42-9.39c-2.74-1.6-5.01-.53-5.35 2.36Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="M256 407.76a5.79 5.79 0 0 1-1.29.4l5.41 9.38a6 6 0 0 1 1-.91 12.1 12.1 0 0 0-5.12-8.87Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m249.11 408.82.45.79a5.69 5.69 0 0 0-.23 1.68 12 12 0 0 0 5.42 9.38 5.36 5.36 0 0 0 1.57.64l.45.78 1.81-1a3.81 3.81 0 0 0 1.58-3.51 12 12 0 0 0-5.41-9.38 3.81 3.81 0 0 0-3.83-.38Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M258.36 418.59a12 12 0 0 0-5.42-9.39c-3-1.73-5.42-.32-5.42 3.13a12 12 0 0 0 5.42 9.38c2.99 1.73 5.42.29 5.42-3.12Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M258.36 418.59a12 12 0 0 0-5.42-9.39c-3-1.73-5.42-.32-5.42 3.13a12 12 0 0 0 5.42 9.38c2.99 1.73 5.42.29 5.42-3.12Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M250.83 420a10.68 10.68 0 0 0 1.34 1.2l1.27-3.61-1.53-1.13Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M290.07 394a1.49 1.49 0 0 1-.55 2l-18.45 10.65a1.49 1.49 0 0 1-2-.55 1.49 1.49 0 0 1 .55-2L288 393.47a1.49 1.49 0 0 1 2.07.53ZM286.74 390.05a.87.87 0 0 1-.07 1.53l-18.79 10.85a2.06 2.06 0 0 1-2.18-.23.88.88 0 0 1 .07-1.54l18.8-10.85a2 2 0 0 1 2.17.24ZM291.84 398.89a.87.87 0 0 0-1.36-.71L271.68 409a2.06 2.06 0 0 0-.88 2 .87.87 0 0 0 1.36.71L291 400.89a2.05 2.05 0 0 0 .84-2Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M205.43 440.47v3.61l9-2.93s1.81-3.32 3.61-4.37L252.39 417l1.05-3-1.05-.61-34.32 19.81a3.61 3.61 0 0 1-3.61-.19Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M206.49 441.08v3.61l9-2.93s1.81-3.32 3.61-4.36l34.31-19.81V414l-34.31 19.81a3.59 3.59 0 0 1-3.61-.19Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m206.49 444.69-1.06-.61v-3.61l1.06.61v3.61z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m205.43 440.47 1.06.61 9.03-7.49-1.06-.61-9.03 7.49z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M357.56 210c5.84-6 16.41-19.63 16.41-19.63l6-20.71c.15.06-3.05.07-4 0-6.46.43-8.36 1-12.38 5.38-3.19 3.49-15.36 20.12-19.6 25.71l-11.23-14.31c-2.69-4.07-2.25-8.62-3.65-11.94s-3.65-4.79-5.46-7.8-2.24-5-3.72-3.43.23 5.68.45 6.62 2.48 3.75-1.3 2.29-9.62-8.37-11.13-9.79-2.3.78-2 2.51-1.28 5.63-.69 8.49c.62 3 1.07 4.43 4 7.38 2.72 2.75 9.4 6.11 12 9.72s8.18 14.81 11.59 21.56c4.32 8.58 8 11.41 12.88 8.52s9.66-8.34 11.83-10.57Z"
      style={{
        fill: "#ffa8a7",
      }}
    />
    <path
      d="M378.05 169.46a21.46 21.46 0 0 0-9.74 1.33c-3.51 1.58-5.89 5-9.31 9.56S347 196 347 196a25.79 25.79 0 0 1 10.42 14.65l20.9-23.1Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M344 200.76a13.6 13.6 0 0 1 4 7.54s1.26-4.15-3.11-8.82Z"
      style={{
        fill: "#f28f8f",
      }}
    />
    <path
      d="M398.16 169.39c2.27.55 8.37 1.33 12.39 2.48 3 .85 6 4.53 6.58 7.25 2 8.71.22 14.19-.52 21.82s-.79 45.7-.79 45.7c-4.23 8.26-36.28 9.94-51.79-1.39 0 0 1.9-50.68 1.9-58.57s4.38-17.36 16.48-18.07Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M384.46 144.43a1.68 1.68 0 1 0 1.67-1.74 1.71 1.71 0 0 0-1.67 1.74ZM386.13 138.77l3.46 1.91a2.07 2.07 0 0 0-.8-2.75 1.93 1.93 0 0 0-2.66.84Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M384.88 154.65 381 156.1a2 2 0 0 0 2.63 1.29 2.17 2.17 0 0 0 1.25-2.74Z"
      style={{
        fill: "#f28f8f",
      }}
    />
    <path
      d="m371.1 140.16 3.15-2.43a1.91 1.91 0 0 0-2.74-.42 2.09 2.09 0 0 0-.41 2.85ZM371.81 144.43a1.62 1.62 0 1 0 1.62-1.68 1.65 1.65 0 0 0-1.62 1.68Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m380.58 141.64-.55 10.18-5.32-1.81 5.87-8.37z"
      style={{
        fill: "#f28f8f",
      }}
    />
    <path
      d="m384.48 142.09 23.81-1.76c4.61 3.49 13.17 10.83 13.16 12.86v1.41a.48.48 0 0 1-.05.18c-.84 1.6-3.88 2.59-8.61 2.67-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63l1.2-.09Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="m384.48 142.09 23.81-1.76c4.61 3.49 13.17 10.83 13.16 12.86v1.41a.48.48 0 0 1-.05.18c-.84 1.6-3.88 2.59-8.61 2.67-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63l1.2-.09Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M408.29 141.67c4.81 3.65 14 11.5 13.11 13.11s-3.88 2.59-8.61 2.67c-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M408.29 141.67c4.81 3.65 14 11.5 13.11 13.11s-3.88 2.59-8.61 2.67c-5.61.09-14.32-.42-16.61-2.39-3.44-3-11.7-11.63-11.7-11.63Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M408.29 141.67c.08.69-2.35 2.13-2.35 2.13l.68-4.18Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M408.29 141.67c.08.69-2.35 2.13-2.35 2.13l.68-4.18Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M402 127.76s3.72.52 5.09 3.32c1.18 2.44.76 8.33-.83 14.75a59.07 59.07 0 0 1-4 12 4.9 4.9 0 0 1-2.84 2.28l.19-7.62.28-5.3s-3.41-4.65-3.78-8.46c-.49-4.91.55-6.91.55-6.91Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M396 135.13a14.22 14.22 0 0 1-14.26 14.17c-7.85 0-13.74-6.47-13.71-14.32s5.95-14.14 13.8-14.11A14.22 14.22 0 0 1 396 135.13Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M399.71 146.39c1.28 1.11 2.32-1.76 3.4-2.88s4.59-2.64 6.32 1.05-1.53 9-4.23 10.07a3.84 3.84 0 0 1-4.58-1.4v16.87c-4 7.29-11.18 7.07-15 6.65s-4.65-4.37-1.86-7.57v-5.22a30.05 30.05 0 0 1-6.41.31c-3.49-.55-5.32-3.19-6.36-7-1.69-6.08-2.36-16.3 0-28.11 3.91-3 17.48-2.53 25.69 2.68-.59 10.5 1.76 13.44 3.03 14.55Z"
      style={{
        fill: "#ffa8a7",
      }}
    />
    <path
      d="M372.46 124a16.75 16.75 0 0 1 5.77-4 14.62 14.62 0 0 1 5.55-1.18c2.48 0 5-.37 7.43-.29 4.3.14 9.5 1.89 11.44 6.07.89 1.92 1 3.94-.72 5.26a12.14 12.14 0 0 1-5.24 2c-3.19.55-6.35 1.25-9.56 1.68a28.57 28.57 0 0 1-10.35-.15 13.61 13.61 0 0 1-4.5-1.92 12 12 0 0 1-1.94-1.62c-.23-.23-.84-.71-.91-1s.34-1 .51-1.26a15.87 15.87 0 0 1 2.52-3.59Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M383.79 164s7.81-1.54 10.54-3a9 9 0 0 0 3.78-3.72 12.37 12.37 0 0 1-2.15 4.39c-2 2.54-12.18 4.39-12.18 4.39Z"
      style={{
        fill: "#f28f8f",
      }}
    />
    <path
      d="M385.9 143.76a1.65 1.65 0 1 0 1.65-1.71 1.68 1.68 0 0 0-1.65 1.71Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M382.14 156.42a1.65 1.65 0 1 0 1.65-1.71 1.67 1.67 0 0 0-1.65 1.71Z"
      style={{
        fill: "#f28f8f",
      }}
    />
    <path
      d="m388.15 137.39 3.17 2.48A2.12 2.12 0 0 0 391 137a2 2 0 0 0-2.85.39ZM371.31 139.92l3.06-2.66a2 2 0 0 0-2.82-.27 2.14 2.14 0 0 0-.24 2.93ZM372.35 143.27a1.65 1.65 0 1 0 1.65-1.71 1.68 1.68 0 0 0-1.65 1.71Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="m381.39 140.45-.62 11.68-5.54-1.46 6.16-10.22z"
      style={{
        fill: "#f28f8f",
      }}
    />
    <path
      d="M409.31 135.88a20.63 20.63 0 0 1-1 5.91 7.23 7.23 0 0 0-1.46-1.65 35 35 0 0 0-10.72-6.14c-9-3.48-19.5-4-27.89-1.06a18.87 18.87 0 0 1 1.12-5c3.06-8 9.25-12.15 19.13-11.12 11.57 1.18 21.13 7.6 20.82 19.06Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M388.1 128.83c-2.69-.91-9.55-1.44-11.55-.81a12.35 12.35 0 0 1-2.41-2.66c-.94-1.49-.62-3.36.26-3.75s.94.78 4.35 2.47a5.68 5.68 0 0 1-.28-3.45c.56-1.38 1.73 1.1 4.27 2.71s5.04 2.41 5.36 5.49Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M423.2 162.72c2.24-1.84 15.82 14.25 10.14 18.88s-12.62-7-11.68-12.74 1.54-6.14 1.54-6.14Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M434.12 153.68c-3.4-4.17-12.43-14.19-14.43-16s-3.2-1.57-4.44-2a10.28 10.28 0 0 1-3.71-2.32 22.53 22.53 0 0 1-1.79-2.1A32.4 32.4 0 0 0 403 125c-2.68-1.85-5.62-3.22-8.36-5-1.64-1.05-3.42-2.82-1.56-4.63a4.48 4.48 0 0 1 2-1.07c4.65-1.22 8.81.22 13.45 1.2a39.46 39.46 0 0 1 5.93 1.73 10.13 10.13 0 0 1 2.65 1.38 36.9 36.9 0 0 1 4.35 4.4c3.19 3.91 5.89 5.12 9 7.83 3.67 3.17 20.12 14.8 23.59 18.26 2.88 2.87 2 5.24 1.62 6.06-2.05 4.71-4.49 6.46-7.37 9.44-.95 1-1.91 2-2.87 3-3.74 3.79-7.5 7.36-11.4 11-4.16 3.88-5.86 5.74-9.57 9.48a29.41 29.41 0 0 0-5.6 8.27c-6.71 1.53-11.29-12.67-8.89-20.9a10.64 10.64 0 0 1 3.9-5.13c6.84-5.46 20.25-16.64 20.25-16.64Z"
      style={{
        fill: "#ffa8a7",
      }}
    />
    <path
      d="M434.12 153.68a17.86 17.86 0 0 0 8.51 5.34s-4.27 1.28-10.4-3.77Z"
      style={{
        fill: "#f28f8f",
      }}
    />
    <path
      d="M410.55 171.87c1.53-1.2 5.25-3.76 12.65-9.15 0 0-1 2.56 1.21 8.7s6.42 9.88 8.93 10.18c0 0-4.11 3.66-7.85 7.74s-6.85 7-8.88 11.6c0 0-5.77-1.12-7.62-10.66-1.31-6.7-1.24-14.65 1.56-18.41Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M397.62 415.78c-.36.38-.13 3.14.32 3.77s3 2.73 7.64 2.81c4.45.08 8.38-.73 10.9-2.53s3.67-3.68 3.78-6.22-.3-5 .8-7c1-1.85 2.4-3.68 2.74-4.68a12.73 12.73 0 0 0 0-5.43Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M408.42 394.36a11.7 11.7 0 0 1-.39 2.84 25.51 25.51 0 0 1-2.42 5.7 23.18 23.18 0 0 1-1.7 2.71c-1.26 1.67-2.86 3.07-4.19 4.67a9.08 9.08 0 0 0-2.45 5.59c0 3.49 3.84 4.47 6.77 4.83a21.34 21.34 0 0 0 9.42-1 8.69 8.69 0 0 0 6-7.27c.11-1 0-2 .06-2.94a13.73 13.73 0 0 1 2.37-6.23 18.23 18.23 0 0 0 1.9-3.42c.79-2.2-.06-4.59-.76-6.7-.6-1.83-1.08-4-1.72-3.82v1.19c-.2.42-.66.72-.77 1.19a8.22 8.22 0 0 1-.45 1.46 3.77 3.77 0 0 1-1.45 1.79c-.12-1.31-.23-2.62-.35-3.93a1.79 1.79 0 0 0-.36-1.14 1.81 1.81 0 0 0-1.14-.42 24.67 24.67 0 0 0-7.47-.22 1.35 1.35 0 0 0-1 .49 1.44 1.44 0 0 0-.11.9 36 36 0 0 1 .21 3.73Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M408.42 394.36a11.7 11.7 0 0 1-.39 2.84 25.51 25.51 0 0 1-2.42 5.7 23.18 23.18 0 0 1-1.7 2.71c-1.26 1.67-2.86 3.07-4.19 4.67a9.08 9.08 0 0 0-2.45 5.59c0 3.49 3.84 4.47 6.77 4.83a21.34 21.34 0 0 0 9.42-1 8.69 8.69 0 0 0 6-7.27c.11-1 0-2 .06-2.94a13.73 13.73 0 0 1 2.37-6.23 18.23 18.23 0 0 0 1.9-3.42c.79-2.2-.06-4.59-.76-6.7-.6-1.83-1.08-4-1.72-3.82v1.19c-.2.42-.66.72-.77 1.19a8.22 8.22 0 0 1-.45 1.46 3.77 3.77 0 0 1-1.45 1.79c-.12-1.31-.23-2.62-.35-3.93a1.79 1.79 0 0 0-.36-1.14 1.81 1.81 0 0 0-1.14-.42 24.67 24.67 0 0 0-7.47-.22 1.35 1.35 0 0 0-1 .49 1.44 1.44 0 0 0-.11.9 36 36 0 0 1 .21 3.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M405.18 403.66c1.25-1 4.15-1.21 5.74-1.13a9.74 9.74 0 0 1 4.6 1.34 1 1 0 0 0 1.32-.18 1 1 0 0 0-.18-1.47 9.77 9.77 0 0 0-5.17-1.61c-3.92-.06-5 .67-5 .67s-1.75 1.24-1.31 2.38ZM402.4 407.38c1.57-1.05 4.77-1.08 6.37-1a9.15 9.15 0 0 1 4.48 1.43 1 1 0 0 0 1.32-.17 1 1 0 0 0-.18-1.48 10.2 10.2 0 0 0-5.29-1.75c-3.92-.06-4.9.79-4.9.79a3.24 3.24 0 0 0-1.8 2.18ZM412.84 396.79a9.65 9.65 0 0 0-4.88.68c-.67.42-1.07 1.53-.61 1.79a9 9 0 0 1 4.32-.84 13 13 0 0 1 4.28.94 11.77 11.77 0 0 1 1.11.49.93.93 0 0 0 1.26-.47.87.87 0 0 0-.4-1.1 12.54 12.54 0 0 0-5.08-1.49Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M343.7 401.82a4.22 4.22 0 0 0 .34 3.08c.48.88 5.36 3.3 11.71 2.52a26.56 26.56 0 0 0 12.95-5.32c2.35-1.74 4.86-2.18 8-2.53s5.52-1.45 6.13-3-.32-4-.32-4Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M368.47 381.6a13.6 13.6 0 0 1-1.69-.48 1.42 1.42 0 0 0-.95-.06 1.4 1.4 0 0 0-.62 1.1 16.25 16.25 0 0 1-.55 2.81 6.92 6.92 0 0 1-1.69 2 28.79 28.79 0 0 1-4.93 3.57c-2 1.23-4 2.21-6.12 3.3s-5 1.86-6.68 3.11c-2.41 1.82-2.51 6.08.43 7.6 2.54 1.32 9.1 2.53 15.79.24 3.65-1.24 7.54-5.42 12.38-6.06 3.07-.41 7.17-.89 9-3.34.67-1.08.08-4.09-.47-7.66-.49-3.2-.94-8.2-2-7.87 0 0-.38 1.18-.45 1.31a9.65 9.65 0 0 1-.85 1.14 11.13 11.13 0 0 1-.87.82 12.79 12.79 0 0 1-1.09 1.12 3.11 3.11 0 0 1-1.78.74 1.71 1.71 0 0 1-.89-.19c-.53-.3-.6-1-1-1.42a5.07 5.07 0 0 0-1.72-1.12 9.48 9.48 0 0 0-2.79-.56Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M368.47 381.6a13.6 13.6 0 0 1-1.69-.48 1.42 1.42 0 0 0-.95-.06 1.4 1.4 0 0 0-.62 1.1 16.25 16.25 0 0 1-.55 2.81 6.92 6.92 0 0 1-1.69 2 28.79 28.79 0 0 1-4.93 3.57c-2 1.23-4 2.21-6.12 3.3s-5 1.86-6.68 3.11c-2.41 1.82-2.51 6.08.43 7.6 2.54 1.32 9.1 2.53 15.79.24 3.65-1.24 7.54-5.42 12.38-6.06 3.07-.41 7.17-.89 9-3.34.67-1.08.08-4.09-.47-7.66-.49-3.2-.94-8.2-2-7.87 0 0-.38 1.18-.45 1.31a9.65 9.65 0 0 1-.85 1.14 11.13 11.13 0 0 1-.87.82 12.79 12.79 0 0 1-1.09 1.12 3.11 3.11 0 0 1-1.78.74 1.71 1.71 0 0 1-.89-.19c-.53-.3-.6-1-1-1.42a5.07 5.07 0 0 0-1.72-1.12 9.48 9.48 0 0 0-2.79-.56Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M360.83 388.68A2.87 2.87 0 0 1 363 387c1.39-.34 3.93.56 5.54 1.75a1.15 1.15 0 0 1-.06 1.91 1.14 1.14 0 0 1-1.32-.07 8.79 8.79 0 0 0-6.33-1.91ZM356.61 391.35a3.3 3.3 0 0 1 2.63-1.54s3.67-.09 6.06 1.9a1.13 1.13 0 0 1-.06 1.85 1.08 1.08 0 0 1-1.27-.08 8.9 8.9 0 0 0-7.36-2.13ZM351.92 393.81a3.69 3.69 0 0 1 2.74-1.41 9.86 9.86 0 0 1 5.81 1.94 1.13 1.13 0 0 1-.06 1.85 1.12 1.12 0 0 1-1.27-.08 8.42 8.42 0 0 0-7.22-2.3ZM362.17 243.55c-.17 14.61.77 63.89 1.09 71.24.28 6.44 1.4 69.82 1.4 69.82 7.38 2.29 17.48-.08 17.48-.08s5.14-39.53 5.47-46.53a119.81 119.81 0 0 0-1-19.28l3.78-40.33s4.13 30.2 6.12 43.42c2.28 15.15 10.81 73.17 10.81 73.17 6.79 2.64 16.3-1.11 16.3-1.11s-.49-45.14-1.17-60.48c-.48-10.59-3.43-15.74-3.75-18.68 0 0-1.47-55-1.63-68.94-.1-9.34-1.11-19.6-1.11-19.6s-10.2.82-11.1-2.15 0-31.52.38-37.07c0 0-21.44-4.77-40-1.25.01-.04-2.89 43.24-3.07 57.85Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M405.31 232c-.11 9.71 1.6 15.74 4.18 17.84s7.66 1.94 7.66 1.94v-1.45s-4.8.71-6.95-1.54-4.11-5.49-4.89-16.79ZM372.31 196s11.13 0 21.66.72c0 0 .07 8.13-.84 11.09s-7.92 7.39-10.83 7.38c-2.52 0-9.53-5-10.08-7.62s.09-11.57.09-11.57ZM390.37 278.35l-1-6.24c-3-.45-10.73-5.25-15.29-9.45a36.22 36.22 0 0 0 12.79 11.78l.32 39.8ZM410.55 171.87c-2-.51-3.13-.09-4.14 2.44s-2.17 10.75-2.56 18.44c0 0-4.63.84-7.84-.52 0 0 .39-10.1 1.47-15.31a18.61 18.61 0 0 1 1.5-4.44 4.64 4.64 0 0 1 5-2.53l7.32 1.34Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M401.79 189.35a1.82 1.82 0 1 1-1.82-1.82 1.82 1.82 0 0 1 1.82 1.82Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
    <path
      d="M383.76 169.18a8.43 8.43 0 0 0-7.44 5.13c-1.51 3.84-1.52 12.36-1.61 16.59a10.1 10.1 0 0 1-7.19 0s0-13.41 2.29-17.85 6.7-4.71 9-4.88a41.71 41.71 0 0 1 4.93 0Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M373.13 187.93a1.82 1.82 0 1 1-1.82-1.82 1.82 1.82 0 0 1 1.82 1.82Z"
      style={{
        fill: "#bd8dbd",
      }}
    />
  </svg>
)
export default Error500Svg

