import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import UserLoginForm from "../../forms/user/login/form";
import * as firebaseUserActions from "../../actions/firebaseUser";
import * as formNames from "../../constants/forms";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import SnackBarUtil from "../../utils/SnackBarUtil";
import { change } from "redux-form";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";
import { NavLink } from "react-router-dom";
import TranslatorUtil from "../../utils/TranslatorUtil";
const entityFormName = formNames.LOGIN_FORM_NAME;

class UserLoginWrap extends React.Component {
  handleResponse = (response) => {
    const { enqueueSnackbar, history, redirectAfterLoginUrl } = this.props;
    if (
      !SnackBarUtil.isResponseError(
        response,
        enqueueSnackbar,
        TranslatorUtil.t("Login failed")
      )
    ) {
      var successMessage = TranslatorUtil.t("You are logged in");
      enqueueSnackbar(successMessage, {
        variant: "success",
      });
      history.push(
        redirectAfterLoginUrl
          ? redirectAfterLoginUrl
          : UrlBuilderUtil.getDashboard()
      );
    }
  };

  handleSubmit = (values) => {
    const { actions } = this.props;
    //has to return promise so submitting flag works!
    return actions
      .loginWithEmail(values)
      .then((response) => this.handleResponse(response));
  };

  render() {
    return (
      <div>
        <UserLoginForm proceedSubmit={this.handleSubmit} />
        <div className="formLinksWrap">
          <Box px={1} pb={2} className="narrowMaxMediumWidthPart">
            <Alert severity="info">
              {TranslatorUtil.t(
                "Please continue below if you forgot your password or do not have account"
              )}
            </Alert>
          </Box>
          <NavLink className="linkBtn" to={UrlBuilderUtil.getRegistration()}>
            {TranslatorUtil.t("Sign in")}
          </NavLink>
          <NavLink className="linkBtn" to={UrlBuilderUtil.getResetPassword()}>
            {TranslatorUtil.t("Reset password")}
          </NavLink>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value));
  },
  actions: bindActionCreators(
    {
      ...firebaseUserActions,
    },
    dispatch
  ),
});

export default withRouter(
  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UserLoginWrap))
);
