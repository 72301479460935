
export default (theme, themeConfig, themeColors) => {
    return {
        successIndicator: {
            backgroundColor: themeColors.primaryPalette.success,
            color: themeColors.primaryPalette.success,
          },
          errorIndicator: {
            backgroundColor: themeColors.primaryPalette.error,
            color: themeColors.primaryPalette.white,
          },
          warningIndicator: {
            backgroundColor: themeColors.primaryPalette.warning,
            color: themeColors.primaryPalette.warning,
          },
        
          successIndicatorText: {
            color: themeColors.primaryPalette.success,
          },
          errorIndicatorText: {
            color: themeColors.primaryPalette.error,
          },
          warningIndicatorText: {
            color: themeColors.primaryPalette.warning,
          },
        
          
        
          statusIndicator: {
            display: 'block',
            width: 12,
            position: 'absolute',
            top: 1,
            right: 0,
            bottom: 0,
          },
    }
}