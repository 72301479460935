import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, Divider, Box, Badge } from '@material-ui/core';
import TranslatorUtil from '../../utils/TranslatorUtil';
import { withRouter } from "react-router-dom";
import RichTextRenderer from '../RichTextRenderer';
import ValidationUtil from '../../utils/ValidationUtil';
import ImagesList from '../ImagesList';
import ImageIcon from '@material-ui/icons/Image';


class ProjectPreviewCard extends React.Component {

  

  render () {
  const { project,
    hideTerms
    //, company
  //  ,discussion 
  } = this.props;

  return project && <div className="projectPreviewContentWrapper">
      
      {
          project.youtubeEmbedLink ? <Box mt={2} className={"youtube-responsive-container"}>
            <iframe title="ytplayer" type="text/html" width="720" height="405"
src={"https://www.youtube.com/embed/"+ValidationUtil.getYoutubeLinkIdFromLink(project.youtubeEmbedLink)+"?autoplay=1&controls=0&loop=1&color=white"}
frameBorder="0" allowFullScreen></iframe>
          </Box> : project.images ? <div className="imagesWrapper">{project.images.length > 1 ? <Badge className="badge" color="primary" anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }} badgeContent={<React.Fragment>{project.images.length} <ImageIcon /></React.Fragment>}><ImagesList item={project} images={project.images} /></Badge> : <ImagesList item={project} images={project.images} />}</div> : null 
      }
      <div className="headingWrapper"><br/><Typography variant="h1">{project.name}</Typography></div>
      
      <RichTextRenderer text={project.welcomeContent} />
      { !hideTerms ? <React.Fragment>
      <br />
      <Divider />
      <br />
      <Typography variant="h2">{TranslatorUtil.t("Terms and conditions")}</Typography>
      <RichTextRenderer text={project.termsConditions} />
      </React.Fragment> : null }
    </div>;
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(ProjectPreviewCard));