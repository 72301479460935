import React, { Fragment } from 'react';
import { useSnackbar } from "notistack";
import { IconButton } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';

function SnackBarDismissAction(props){
    const { closeSnackbar } = useSnackbar()
    const { classes,id } = props;
    return (
        <Fragment>
            {/* <Button onClick={() => alert(`Show more data for key: ${id}`)}>More</Button> */}
            <IconButton onClick={() => closeSnackbar(id)} size="small" className={classes.snackbarBtn}>
              <CloseIcon />
              </IconButton>
        </Fragment>
    )
}

export default withStyles(styles, { withTheme: true })(SnackBarDismissAction);


            
