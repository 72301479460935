import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Avatar, Typography, Grid, Box, Button, Paper } from '@material-ui/core';
import FormatterUtil from '../../utils/FormatterUtil';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import classnames from 'classnames';
import ValidationUtil from '../../utils/ValidationUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import ImagesList from '../ImagesList';
import RichTextRenderer from '../RichTextRenderer';
import LockIcon from '@material-ui/icons/Lock';

class DiscussionIntro extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.isExpanded,
    }
  }


  render () {
  const { expanded } = this.state;
  const { classes, item, isLocked } = this.props;

  return item ? (
    <Box p={2}><Paper elevation={0}>
    <div className={classnames("gridItem","sectionBottomMarginItem")}>
    <Grid container
    direction="row"
    justify="flex-start"
    alignItems="flex-start"
    spacing={1}
    className={"gridItem"}
    >
      <Grid item>
      
      {isLocked ? <Avatar aria-label={item.name} className={classes.avatar} style={{backgroundColor:item.color}}>
      <LockIcon />
          </Avatar> : 
      <Avatar aria-label={item.name} className={classes.avatar} style={{backgroundColor:item.color}}>
            {FormatterUtil.getIconText(item.name)}
          </Avatar>}
          

                </Grid>
                <Grid item  xs={12} sm={10} className={"narrowMaxMediumWidthPart"}>
                  <Box pl={2}>
                  <Typography variant="body2" color="textSecondary" component="p">
          <small>{DateUtil.displayTimeFromNow(item.updatedTime, TranslatorUtil.getUserLangFromStore())}</small>
        </Typography>
        <RichTextRenderer text={item.description} />
      
                  </Box>
                
                </Grid>

                <Grid item  xs={12} className={"narrowMaxMediumWidthPart"}>
                {expanded ? <div>
        <ImagesList item={item} images={item.images} />
        {
          item.youtubeEmbedLink ? <Box mt={2} className={"youtube-responsive-container"}>
            <iframe title="ytplayer" type="text/html" width="720" height="405"
src={"https://www.youtube.com/embed/"+ValidationUtil.getYoutubeLinkIdFromLink(item.youtubeEmbedLink)+"?autoplay=1&controls=0&loop=1&color=white"}
frameBorder="0" allowFullScreen></iframe>
          </Box> : null
        }
      </div> : ((item.images && item.images.length) || item.youtubeEmbedLink ? <Button variant="contained" onClick={() => this.setState({expanded:true})}>{TranslatorUtil.t("See more")}</Button> : null)}
                </Grid>
      
      
                
    </Grid>
    </div>
    </Paper>
    </Box>
  ) : null;
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(DiscussionIntro));