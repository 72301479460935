import React, { Component } from "react";
import { auth } from '../firebase';
import * as profileActions from "../actions/profile.js";
import UsersUtil from "../utils/projectBased/UsersUtil";
import CompanyUtil from "../utils/projectBased/CompanyUtil";

const withFirebaseAuth = WrappedComponent => {


  class AuthedComponent extends Component {
    state = {
      user: false,
      userLoading: true
    };

    componentDidMount() {
      this.authenticate();
    }

    authenticate() {
      auth.onAuthStateChanged((user) => {
        if(user){
          this.mountUserProfile(user);
        } else {
          this.finishLoading(user);
        }
      });
    }

    mountUserProfile = (user) => {
      return profileActions.getFirestoreProfileByUserId(user.uid).then((profile) => {
        if(profile === null){
          profile = UsersUtil.createDefaultProfileFromUser(user);
          profileActions.postFirestoreDefaultProfile(profile);
        }
        //set company based on access / invitation for all users except respondets
        user.company = CompanyUtil.getMockedCompany();
        user.profile = profile;
        this.finishLoading(user);
      });
    }

    finishLoading = (user) => {
      this.setState({
        user,
        userLoading: false
      });
    }


    render() {
      let { user, userLoading } = this.state;

        
        return <WrappedComponent user={user} userLoading={userLoading} />;

      
    }
  }


  return AuthedComponent;
};

export default withFirebaseAuth;
