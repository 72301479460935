import {
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import * as constants from "../../constants/constants";
import styles from "../../theme/styles";
import DateUtil from "../../utils/DateUtil";
import TranslatorUtil from "../../utils/TranslatorUtil";

class SupportCompactMessageItem extends React.Component {
  renderListText = (message) => {
    const { classes, currentUserId } = this.props;
    console.log("message", message, currentUserId);
    return (
      
          <Tooltip
            enterDelay={constants.TOOLTIP_CARDS_DELAY}
            title={DateUtil.displayTimeFromNow(
              message.createdTime,
              TranslatorUtil.getUserLangFromStore()
            )}
            PopperProps={{style:{zIndex:9999}}}
          >
            <Paper
              elevation={1}
              className={"messageItemWrap "+ (currentUserId === message.byUserId ? "myMessage" : "otherMessage")}
              component="span"
              style={{ display: "block" }}
            >
              <Typography
                component="span"
                className={classes.inline}
                color="textPrimary"
              >
                <small>{message.message}</small>
              </Typography>
            </Paper>
          </Tooltip>
    );
  };

  render() {
    const { message, currentUserId } = this.props;

    return message && currentUserId ? (
      <div className="messageItemOuterWrap">
        {/* {this.renderAvatar(message)} */}
        {this.renderListText(message)}
      </div>
    ) : null;
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(SupportCompactMessageItem)
);
