// AJAX
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";

// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

// UI
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const SET_CONTENT_SUBMENU_LEVEL = "SET_CONTENT_SUBMENU_LEVEL";


// USERS
export const GET_USERS = "GET_USERS";
export const SET_USER_INFO_ERROR = "SET_USER_INFO_ERROR";
export const SET_USER_INFO_SUCCESS = "SET_USER_INFO_SUCCESS";

// INFO TEXT
export const GET_INFO_TEXT = "GET_INFO_TEXT";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";

// ACCESS
export const GET_ACCESS = "GET_ACCESS";
export const POST_ACCESS = "POST_ACCESS";
export const PUT_ACCESS = "PUT_ACCESS";
export const GET_ACCESS_ITEM = "GET_ACCESS_ITEM";
export const GET_COMPANY_PROJECT_ACCESS_ITEMS = "GET_COMPANY_PROJECT_ACCESS_ITEMS";
export const GET_COMPANY_ACCESS_ITEMS = "GET_COMPANY_ACCESS_ITEMS";

// PROJECTS
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_PREVIEW = "GET_PROJECT_PREVIEW";
export const SELECT_PROJECT = "SELECT_PROJECT";
export const POST_PROJECT = "POST_PROJECT";
export const PUT_PROJECT = "PUT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const UNLOCK_PROJECT = "UNLOCK_PROJECT";
export const LOCK_PROJECT = "LOCK_PROJECT";
export const GET_ARCHIVED_PROJECTS = "GET_ARCHIVED_PROJECTS";

// PROFILES
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILE = "GET_PROFILE";
export const POST_PROFILE = "POST_PROFILE";
export const PUT_PROFILE = "PUT_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";

// USER_COMMENTS
export const GET_USER_COMMENTS = "GET_USER_COMMENTS";
export const POST_USER_COMMENT = "POST_USER_COMMENT";
export const DELETE_USER_COMMENT = "DELETE_USER_COMMENT";

// CHAT
export const GET_CHAT_CONVERSATIONS = "GET_CHAT_CONVERSATIONS";
export const GET_CHAT_CONVERSATION_MESSAGES = "GET_CHAT_CONVERSATION_MESSAGES";
export const GET_CHAT_CONVERSATION = "GET_CHAT_CONVERSATION";
export const POST_CHAT_CONVERSATION_MESSAGE = "POST_CHAT_CONVERSATION_MESSAGE";
export const POST_CHAT_CONVERSATION = "POST_CHAT_CONVERSATION";
export const PATCH_CHAT_CONVERSATION = "PATCH_CHAT_CONVERSATION";

// SUPPORT
export const GET_SUPPORT_CONVERSATIONS = "GET_SUPPORT_CONVERSATIONS";
export const GET_SUPPORT_CONVERSATION_MESSAGES = "GET_SUPPORT_CONVERSATION_MESSAGES";
export const GET_SUPPORT_CONVERSATION = "GET_SUPPORT_CONVERSATION";
export const POST_SUPPORT_CONVERSATION_MESSAGE = "POST_SUPPORT_CONVERSATION_MESSAGE";
export const POST_SUPPORT_CONVERSATION = "POST_SUPPORT_CONVERSATION";
export const PATCH_SUPPORT_CONVERSATION = "PATCH_SUPPORT_CONVERSATION";

//DISCUSSION PROJECT subcollection
export const GET_DISCUSSIONS = "GET_DISCUSSIONS";
export const GET_DISCUSSION = "GET_DISCUSSION";
export const GET_DISCUSSION_PREVIEW = "GET_DISCUSSION_PREVIEW";
export const POST_DISCUSSION = "POST_DISCUSSION";
export const PUT_DISCUSSION = "PUT_DISCUSSION";
export const DELETE_DISCUSSION = "DELETE_DISCUSSION";
export const GET_DISCUSSION_MESSAGES = "GET_DISCUSSION_MESSAGES";
export const POST_DISCUSSION_MESSAGE = "POST_DISCUSSION_MESSAGE";
export const PUT_DISCUSSION_MESSAGE = "PUT_DISCUSSION_MESSAGE";

export const UNLOCK_DISCUSSION = "UNLOCK_DISCUSSION";
export const LOCK_DISCUSSION = "LOCK_DISCUSSION";

export const PUT_DISCUSSION_MESSAGE_LIKES = "PUT_DISCUSSION_MESSAGE_LIKES";
export const GET_DISCUSSION_LIKES = "GET_DISCUSSION_LIKES";
export const GET_DISCUSSION_MESSAGE = "GET_DISCUSSION_MESSAGE";



export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN = "USER_LOGIN";
export const USER_SIGNIN = "USER_SIGNIN";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";


//Files
export const POST_FILE_TO_STORAGE = "POST_FILE_TO_STORAGE";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_PREVIEW = "GET_COMPANY_PREVIEW";
export const GET_INVITATION_DISCUSSION = "GET_INVITATION_DISCUSSION";
export const GET_INVITATION_PROJECT = "GET_INVITATION_PROJECT";
export const GET_INVITATION_COMPANY = "GET_INVITATION_COMPANY";

//tags
export const GET_COMPANY_USER_TAGS = "GET_COMPANY_USER_TAGS";
export const GET_USER_TAGS = "GET_USER_TAGS";
export const PUT_USER_TAG = "PUT_USER_TAG";

export const GET_MAILS = "GET_MAILS";
export const POST_MAIL = "POST_MAIL";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const POST_NOTIFICATION = "POST_NOTIFICATION";

// read messages 
export const GET_USER_DISCUSSION_READ_MESSAGE_IDS = "GET_USER_DISCUSSION_READ_MESSAGE_IDS";
