import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import * as constants from '../../constants/constants';
import { MenuItem, Chip, Tooltip } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import classnames from 'classnames';
import UrlBuilderUtil from '../../utils/projectBased/UrlBuilderUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';

function AppMenuItem(props) {

  const { classes, menuItem } = props;

  var isPathAllowed = function(){
    let isAllowed = true;
    return isAllowed;
  }

  var getMenuPath = function(){
    if(menuItem.path && menuItem.path.startsWith('/dashboard')){
      return UrlBuilderUtil.getDashboard();
    }
    return menuItem.path;
  }

  var hasPartialMatch = function(){
    //react router active needs full match
    return (window.location.href.toString().replace(constants.APP_DOMAIN,'').indexOf(menuItem.path) !== -1);
  }

  const menuTitle = TranslatorUtil.t("View ")+TranslatorUtil.t(menuItem.label);

  return isPathAllowed() ? (
    <MenuItem className={classnames(classes.menuItem, menuItem.showChips && classes.menuItemHasChips, hasPartialMatch() ? 'partialActive' : '')}>
        <Tooltip title={menuTitle} enterDelay={constants.TOOLTIP_MENU_DELAY}><NavLink activeClassName={classnames(classes.activeMenuItem,'active')} className={menuItem.active ? 'active' : ''}  to={getMenuPath()}>
          { menuItem.showIcon ? <div className={classes.menuItemIcon}>
            { menuItem.icon ? menuItem.icon : <FolderIcon /> }
          </div> : null }
          <div className={classes.menuItemText} >
            {TranslatorUtil.t(menuItem.label)}
          </div>
          { menuItem.showChips ? <div className={classes.menuItemChipsWrap}>
              { 
                //maybe wont be implemented
                menuItem.showNotification ? <span className="dot"></span> : null 
              }
              { menuItem.partialMetCount > 0 ?
              <Chip label={menuItem.partialMetCount} variant="outlined" clickable={false} className="countChip warning" /> : null }
              { menuItem.notMetCount > 0 ?
              <Chip label={menuItem.notMetCount} variant="outlined" clickable={false} className="countChip error" /> : null }
          </div> : null }
          { menuItem.indicator && menuItem.showStatusDot ? <div className={classnames(classes.statusIndicator,'statusIndicator',classes[menuItem.indicator])} /> : null }
        </NavLink></Tooltip>
      </MenuItem>
  ) : null;
}

AppMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AppMenuItem);