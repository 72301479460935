import objectPath from "object-path";

export default class DataUtil {

    static removeImagesBase64(values,attrName){
        var imagesWithoutBase64String = [];
        objectPath.get(values,attrName,[]).forEach(x => {
          delete x.base64String;
          imagesWithoutBase64String.push(x);
        })
        values.images = imagesWithoutBase64String;
        return values;
    }
}