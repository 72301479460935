import React from "react";
import { Route, Switch } from "react-router";

//containers
import Login from "../containers/pages/user/Login";
import Welcome from "../containers/pages/Welcome";
import NoMatch from "../containers/errors/NoMatch";
import UnauthorisedAccessPage from "../containers/errors/UnauthorisedAccessPage";
import ConnectionError from "../containers/errors/ConnectionError";
import Registration from "../containers/pages/user/Registration";
import ServerError from "../containers/errors/ServerError";
import ResetPassword from "../containers/pages/user/ResetPassword";
import Invitation from "../containers/pages/invitation/Invitation";


const PublicAppRoutes = (props) => {


  return (
    <Switch>

      <Route
        path="/"
        exact
        component={Welcome} />
      <Route
        path="/dashboard"
        exact
        component={Welcome} />
      
     


      <Route exact path="/login" component={Login} />
      <Route exact path="/sign-in" component={Registration} />
      <Route exact path="/reset-password" component={ResetPassword} />

      <Route exact path="/invitation/:invitationHash" render={() => <Invitation />} />

      <Route exact path="/connection-error" component={ConnectionError} />

      <Route exact path="/404" component={NoMatch} />
      <Route exact path="/500" component={ServerError} />
      <Route exact path="/401" component={UnauthorisedAccessPage} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default PublicAppRoutes;
