import * as React from "react"
const Error401Svg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={374.176}
    height={337.332}
    {...props}
  >
    <g data-name="403 Error Forbidden-amico">
      <g fill="#e0e0e0">
        <path
          d="M43.82 132.425v3.46c0 1.09.77 1.53 1.71 1l3.34-1.92v-7.45l-3.34 2a3.78 3.78 0 0 0-1.71 2.91Z"
          data-name="Path 1"
        />
        <path
          d="M33.23 129.515v3.45c0 1.09.77 1.53 1.71 1l13.93-8v-7.45l-13.92 8a3.78 3.78 0 0 0-1.72 3Z"
          data-name="Path 2"
        />
        <path
          d="m40.77 168.565-15.82 9.13a3.8 3.8 0 0 0-1.71 3v3.46c0 1.09.77 1.53 1.71 1l15.82-9.13a3.78 3.78 0 0 0 1.71-3v-3.51c0-1.06-.77-1.5-1.71-.95Z"
          data-name="Path 3"
        />
        <path
          d="m48.87 209.395-13.93 8a3.78 3.78 0 0 0-1.71 3v3.46c0 1.09.77 1.53 1.71 1l13.93-8Z"
          data-name="Path 4"
        />
        <path
          d="M33.23 93.105v3.41c0 1.09.77 1.53 1.71 1l13.93-8v-7.4l-13.93 8a3.78 3.78 0 0 0-1.71 2.99Z"
          data-name="Path 5"
        />
        <path
          d="M17.39 202.425v3.46c0 1.09.76 1.53 1.71 1l15.85-9.14a3.77 3.77 0 0 0 1.71-3v-3.46c0-1.09-.76-1.53-1.71-1l-15.85 9.23a3.77 3.77 0 0 0-1.71 2.91Z"
          data-name="Path 6"
        />
        <path
          d="M33.23 165.835v3.46c0 1.09.77 1.53 1.71 1l13.93-8v-7.41l-13.92 8a3.78 3.78 0 0 0-1.72 2.95Z"
          data-name="Path 7"
        />
        <path
          d="M31.89 224.605v-3.45c0-1.09-.76-1.54-1.71-1l-15.82 9.13a3.8 3.8 0 0 0-1.71 3v3.46c0 1.09.77 1.53 1.71 1l15.82-9.14a3.75 3.75 0 0 0 1.71-3Z"
          data-name="Path 8"
        />
        <path
          d="m31.95 82.845-15.8 9.13a3.8 3.8 0 0 0-1.71 3v3.46c0 1.09.77 1.53 1.71 1l15.8-9.18a3.8 3.8 0 0 0 1.71-3v-3.46c.02-1.05-.71-1.49-1.71-.95Z"
          data-name="Path 9"
        />
        <path
          d="m40.77 95.825-15.82 9.13a3.8 3.8 0 0 0-1.71 3v3.46c0 1.09.77 1.53 1.71 1l15.82-9.14a3.78 3.78 0 0 0 1.71-3v-3.46c0-1.1-.77-1.54-1.71-.99Z"
          data-name="Path 10"
        />
        <path
          d="m40.77 132.195-15.82 9.13a3.8 3.8 0 0 0-1.71 3v3.46c0 1.09.77 1.53 1.71 1l15.82-9.13a3.78 3.78 0 0 0 1.71-3v-3.46c0-1.11-.77-1.55-1.71-1Z"
          data-name="Path 11"
        />
        <path
          d="m24.95 239.655 15.82-9.14a3.8 3.8 0 0 0 1.71-3v-3.46c0-1.09-.77-1.53-1.71-1l-15.82 9.19a3.8 3.8 0 0 0-1.71 3v3.46c0 1.05.77 1.49 1.71.95Z"
          data-name="Path 12"
        />
        <path
          d="M175.6 68.435v3.46a3.77 3.77 0 0 1-1.7 3l-3.34 1.93v-7.41l3.34-1.92c.94-.59 1.7-.15 1.7.94Z"
          data-name="Path 13"
        />
        <path
          d="m170.56 187.605 13.93-8c.94-.55 1.7-.1 1.7 1v3.45a3.77 3.77 0 0 1-1.7 3l-13.93 8Z"
          data-name="Path 14"
        />
        <path
          d="M186.19 53.255v3.46a3.79 3.79 0 0 1-1.7 3l-13.93 8v-7.4l13.93-8c.94-.59 1.7-.15 1.7.94Z"
          data-name="Path 15"
        />
        <path
          d="m178.66 101.055 15.82-9.14c.94-.54 1.71-.1 1.71 1v3.46a3.8 3.8 0 0 1-1.71 3l-15.82 9.13c-1 .55-1.71.1-1.71-1v-3.49a3.75 3.75 0 0 1 1.71-2.96Z"
          data-name="Path 16"
        />
        <path
          d="m170.56 151.235 13.93-8c.94-.54 1.7-.1 1.7 1v3.45a3.77 3.77 0 0 1-1.7 3l-13.93 8Z"
          data-name="Path 17"
        />
        <path
          d="M186.19 16.885v3.46a3.77 3.77 0 0 1-1.7 3l-13.93 8v-7.4l13.93-8c.94-.59 1.7-.15 1.7.94Z"
          data-name="Path 18"
        />
        <path
          d="M202.04 107.915v3.46a3.77 3.77 0 0 1-1.71 3l-15.82 9.14c-.94.55-1.7.11-1.7-1v-3.46a3.77 3.77 0 0 1 1.7-3l15.82-9.13c.95-.54 1.71-.1 1.71.99Z"
          data-name="Path 19"
        />
        <path
          d="M186.19 89.625v3.46a3.79 3.79 0 0 1-1.7 3l-13.93 8v-7.4l13.93-8c.94-.59 1.7-.17 1.7.94Z"
          data-name="Path 20"
        />
        <path
          d="M187.54 146.845v-3.46a3.77 3.77 0 0 1 1.71-3l15.82-9.13c.94-.55 1.71-.11 1.71 1v3.46a3.78 3.78 0 0 1-1.71 3l-15.82 9.14c-.95.52-1.71.08-1.71-1.01Z"
          data-name="Path 21"
        />
        <path
          d="m178.66 28.315 15.82-9.14c.94-.54 1.71-.1 1.71 1v3.46a3.8 3.8 0 0 1-1.71 3l-15.82 9.13c-1 .55-1.71.1-1.71-1v-3.46a3.75 3.75 0 0 1 1.71-2.99Z"
          data-name="Path 22"
        />
        <path
          d="m178.66 64.685 15.82-9.17c.94-.54 1.71-.1 1.71 1v3.46a3.8 3.8 0 0 1-1.71 3l-15.82 9.13c-1 .55-1.71.1-1.71-1v-3.45a3.75 3.75 0 0 1 1.71-2.97Z"
          data-name="Path 23"
        />
        <path
          d="m194.48 153.875-15.82 9.14c-1 .54-1.71.1-1.71-1v-3.46a3.77 3.77 0 0 1 1.71-3l15.82-9.04c.94-.55 1.71-.1 1.71 1v3.46a3.78 3.78 0 0 1-1.71 2.9Z"
          data-name="Path 24"
        />
        <path
          d="m170.56 205.785 13.93-8c.94-.54 1.7-.1 1.7 1v3.46a3.79 3.79 0 0 1-1.7 3l-13.93 8Z"
          data-name="Path 25"
        />
      </g>
      <g data-name="freepik--door--inject-18">
        <g data-name="freepik--door--inject-18">
          <path
            fill="#37474f"
            d="M48.87 319.515V71.955a12 12 0 0 1 5.42-9.38L161.41.725a3.83 3.83 0 0 1 3.83-.38l3.71 2.17a3.79 3.79 0 0 1 1.6 3.51v247.55l-7.81 4.51-6.34-3.67-96 55.42v7.33l-7.81 4.51Z"
            data-name="Path 26"
          />
          <path
            fill="#263238"
            d="m74.23 301.835-13.85 8v7.33l-7.81 4.51-3.7-2.14V71.955a11 11 0 0 1 1.6-5.35l23.58 13.62Z"
            data-name="Path 27"
          />
          <path
            fill="#455a64"
            d="M52.57 74.105v247.55l7.81-4.5v-7.32l96-55.42 6.34 3.66 7.81-4.5V5.995c0-3.47-2.43-4.85-5.4-3.13L57.95 64.725a12 12 0 0 0-5.38 9.38Z"
            data-name="Path 28"
          />
          <path
            fill="#bd8dbd"
            d="M60.38 75.415v237.48l98.67-56.96V18.465Z"
            data-name="Path 29"
          />
          <path
            fill="#fff"
            d="M60.38 75.415v237.48l98.67-56.96V18.465Z"
            data-name="Path 30"
            opacity={0.7}
          />
          <g data-name="Group 1" opacity={0.43}>
            <path
              fill="#bd8dbd"
              d="M77.2 283.775V85.125l65-37.54v198.64Zm2.75-197.07v192.34l59.59-34.4V52.315Z"
              data-name="Path 31"
            />
            <path
              d="M77.2 85.125v198.65l65-37.55V47.585Zm62.31 159.52-59.56 34.4V86.705l59.59-34.39Z"
              data-name="Path 32"
              opacity={0.1}
            />
          </g>
          <path
            fill="#263238"
            d="M159.05 255.935V18.465l3.65-2.12.01 241.74Z"
            data-name="Path 33"
          />
          <path
            fill="#e0e0e0"
            d="m60.38 317.155 102.33-59.07-3.66-2.15-98.67 56.96Z"
            data-name="Path 34"
          />
        </g>
        <path
          fill="#e0e0e0"
          d="m62.29 164.735 1.97 1.14.01 32.73-1.98-1.14Z"
          data-name="Path 35"
        />
        <path
          fill="#fafafa"
          d="m64.26 165.875 10.63-6.14.01 32.73-10.63 6.14Z"
          data-name="Path 36"
        />
        <path
          d="m74.95 168.745-4.66 2.68v5.32a.64.64 0 0 0 1 .55l3.7-2.13Z"
          data-name="Path 37"
          opacity={0.05}
        />
        <path
          fill="#ebebeb"
          d="m74.89 159.735-1.97-1.14-10.63 6.14 1.97 1.14Z"
          data-name="Path 38"
        />
        <path
          fill="#e0e0e0"
          d="m68.34 170.335 4.88 2.81 1.95-3.38-4.88-2.81a.94.94 0 0 0-1 .09 3 3 0 0 0-1.38 2.39 1 1 0 0 0 .43.9Z"
          data-name="Path 39"
        />
        <path
          d="m70.24 171.425 1.77 1.03v-2.33l1.89-1.09-1.77-1.02-1.89 1.08Z"
          data-name="Path 40"
          opacity={0.1}
        />
        <path
          fill="#fafafa"
          d="M72.6 170.495v5.11l19.5-11.25v-5.11Z"
          data-name="Path 41"
        />
        <path
          fill="#e0e0e0"
          d="m72.6 170.495-1.77-1.03v5.12l1.77 1.02Z"
          data-name="Path 42"
        />
        <path
          fill="#ebebeb"
          d="m92.1 159.245-1.77-1.03-19.5 11.25 1.77 1.03Z"
          data-name="Path 43"
        />
        <path
          fill="#455a64"
          d="M67.79 184.745a4 4 0 0 1 1.79-3.1c1-.57 1.79-.11 1.79 1a3.92 3.92 0 0 1-1.08 2.52v3.35a1.35 1.35 0 0 1-.61 1.05l-.2.12a.378.378 0 0 1-.61-.35v-3.34c-.64.08-1.08-.4-1.08-1.25Z"
          data-name="Path 44"
        />
        <path
          d="m95.45 106.675 30.07-17.36c1.79-1 3.25-.2 3.25 1.87v20.65a7.21 7.21 0 0 1-3.25 5.63l-30.07 17.36c-1.79 1-3.25.19-3.25-1.88v-20.65a7.17 7.17 0 0 1 3.25-5.62Z"
          data-name="Path 45"
          opacity={0.15}
        />
        <path
          fill="#455a64"
          d="m96.6 107.515 30.07-17.36c1.79-1 3.25-.2 3.25 1.87v20.65a7.21 7.21 0 0 1-3.25 5.63l-30.07 17.36c-1.8 1-3.25.19-3.25-1.88v-20.61a7.15 7.15 0 0 1 3.25-5.66Z"
          data-name="Path 46"
        />
        <path
          fill="#263238"
          d="M105.54 104.515h-.08a.37.37 0 0 1-.29-.45l6.1-26.68a.36.36 0 0 1 .35-.29.37.37 0 0 1 .36.26l6.1 19.65a.38.38 0 1 1-.72.22l-5.68-18.31-5.73 25.34a.38.38 0 0 1-.41.26Z"
          data-name="Path 47"
        />
        <path
          fill="#fafafa"
          d="M97.61 127.395a.72.72 0 0 1-.09-.44v-10.81a1.37 1.37 0 0 1 .09-.55.58.58 0 0 1 .29-.31l3.27-1.89a.175.175 0 0 1 .23.05 1.509 1.509 0 0 1 .06.52v.45a2.41 2.41 0 0 1-.06.6.5.5 0 0 1-.23.31l-2.16 1.25v3l1.94-1.06a.162.162 0 0 1 .22.05 1.3 1.3 0 0 1 .07.53v.42a1.89 1.89 0 0 1-.07.6.55.55 0 0 1-.22.32l-1.94 1.08v3.28l2.17-1.26a.15.15 0 0 1 .22 0 1.54 1.54 0 0 1 .06.53v.45a2.341 2.341 0 0 1-.06.61.54.54 0 0 1-.22.3l-3.28 1.9c-.14.12-.24.13-.29.07Z"
          data-name="Path 48"
        />
        <path
          fill="#fafafa"
          d="M102.73 124.115v-11a1.19 1.19 0 0 1 .1-.53.72.72 0 0 1 .29-.33l1.81-1q2.25-1.31 2.25 2a5.9 5.9 0 0 1-1 3.53v.07c.3.08.55.56.76 1.44l.68 2.95a.44.44 0 0 0 0 .1.34.34 0 0 1 0 .1c0 .27-.2.52-.61.75l-.32.19c-.35.2-.55.18-.58-.06l-.62-3a1.21 1.21 0 0 0-.31-.64c-.12-.08-.29-.05-.53.09l-.42.24v4.26a1 1 0 0 1-.59.85l-.31.17c-.38.22-.6.15-.6-.18Zm2.28-7.69a1.4 1.4 0 0 0 .51-.76 3.86 3.86 0 0 0 .22-1.37 2 2 0 0 0-.21-1.1q-.21-.27-.57-.06l-.76.44v3.32Z"
          data-name="Path 49"
        />
        <path
          fill="#fafafa"
          d="M108.6 120.725v-11a1.38 1.38 0 0 1 .09-.54.8.8 0 0 1 .3-.33l1.81-1q2.25-1.31 2.25 2a5.94 5.94 0 0 1-1 3.53v.06c.3.09.55.57.76 1.45l.67 2.94v.21c0 .26-.2.51-.61.74l-.32.19c-.36.2-.55.18-.59-.06l-.61-3a1.19 1.19 0 0 0-.31-.63c-.12-.08-.3-.06-.54.08l-.41.24v4.27a1 1 0 0 1-.6.84l-.3.18c-.4.22-.59.17-.59-.17Zm2.28-7.69a1.36 1.36 0 0 0 .51-.76 4.07 4.07 0 0 0 .21-1.37 1.92 1.92 0 0 0-.2-1.09.39.39 0 0 0-.57-.07l-.76.44v3.33Z"
          data-name="Path 50"
        />
        <path
          fill="#fafafa"
          d="M114.24 111.875q0-6.15 2.89-7.82t2.89 4.48q0 6.18-2.89 7.85t-2.89-4.51Zm3.93 1a13.591 13.591 0 0 0 .33-3.46 9.2 9.2 0 0 0-.33-3.07q-.33-.77-1-.36a2.49 2.49 0 0 0-1 1.56 13.449 13.449 0 0 0-.33 3.45 9.36 9.36 0 0 0 .33 3.08c.23.52.57.64 1 .37a2.55 2.55 0 0 0 1-1.57Z"
          data-name="Path 51"
        />
        <path
          fill="#fafafa"
          d="M121.3 113.395v-11a1.38 1.38 0 0 1 .09-.54.68.68 0 0 1 .3-.33l1.8-1q2.27-1.31 2.26 2a5.94 5.94 0 0 1-1.05 3.53v.06c.29.09.55.57.76 1.45l.67 2.94a.3.3 0 0 1 0 .1.41.41 0 0 1 0 .11c0 .26-.2.51-.6.74l-.33.19c-.35.2-.54.18-.58-.06l-.62-3a1.13 1.13 0 0 0-.31-.63c-.11-.09-.29-.06-.53.08l-.41.24v4.24a1 1 0 0 1-.6.84l-.3.17c-.36.26-.55.2-.55-.13Zm2.27-7.69a1.32 1.32 0 0 0 .52-.76 4.111 4.111 0 0 0 .21-1.37 2 2 0 0 0-.21-1.1.39.39 0 0 0-.56-.06l-.76.44v3.32Z"
          data-name="Path 52"
        />
      </g>
      <path
        d="m52.48 276.375-3.7-2.11.01 12.45 3.69 2.11Z"
        data-name="Path 53"
        opacity={0.2}
      />
      <path
        fill="#bd8dbd"
        d="m52.48 273.055-4.18-2.44.01 12.45 4.17 2.44Z"
        data-name="Path 54"
      />
      <path
        d="m52.48 273.055-4.18-2.44.01 12.45 4.17 2.44Z"
        data-name="Path 55"
        opacity={0.2}
      />
      <path
        d="m170.48 121.525-118 154.85.01 12.45 117.99-154.84Z"
        data-name="Path 56"
        opacity={0.25}
      />
      <path
        fill="#bd8dbd"
        d="m170.48 118.215-118 154.84.01 12.45 117.99-154.84Z"
        data-name="Path 57"
      />
      <path
        fill="#455a64"
        d="M170.48 130.645v-5.65l-5.85.9-4.87 6.39Z"
        data-name="Path 58"
      />
      <path
        fill="#455a64"
        d="m155.89 149.835-10.76 1.65-4.88 6.4 10.77-1.66Z"
        data-name="Path 59"
      />
      <path
        fill="#455a64"
        d="m165.64 137.035-10.76 1.65-4.88 6.4 10.77-1.65Z"
        data-name="Path 60"
      />
      <path
        fill="#455a64"
        d="m146.14 162.625-10.76 1.66-4.88 6.39 10.77-1.65Z"
        data-name="Path 61"
      />
      <path
        fill="#455a64"
        d="m136.39 175.425-10.77 1.65-4.87 6.4 10.76-1.65Z"
        data-name="Path 62"
      />
      <path
        fill="#455a64"
        d="m126.64 188.225-10.77 1.65-4.87 6.4 10.76-1.65Z"
        data-name="Path 63"
      />
      <path
        fill="#455a64"
        d="m116.89 201.015-10.77 1.66-4.88 6.39 10.77-1.65Z"
        data-name="Path 64"
      />
      <path
        fill="#455a64"
        d="m107.13 213.815-10.76 1.65-4.88 6.4 10.77-1.65Z"
        data-name="Path 65"
      />
      <path
        fill="#455a64"
        d="m97.38 226.615-10.76 1.65-4.88 6.4 10.77-1.65Z"
        data-name="Path 66"
      />
      <path
        fill="#455a64"
        d="m87.63 239.405-10.77 1.66-4.87 6.39 10.77-1.65Z"
        data-name="Path 67"
      />
      <path
        fill="#455a64"
        d="m77.88 252.205-10.77 1.65-4.87 6.4 10.76-1.65Z"
        data-name="Path 68"
      />
      <path
        fill="#455a64"
        d="m68.13 265.005-10.77 1.65-4.88 6.4 10.77-1.66Z"
        data-name="Path 69"
      />
      <path
        fill="#455a64"
        d="m58.36 277.805-5.88.9v6.8l1-1.31Z"
        data-name="Path 70"
      />
      <path
        d="m52.48 190.035 118 15.41v12.45l-118-15.4Z"
        data-name="Path 71"
        opacity={0.25}
      />
      <path
        fill="#bd8dbd"
        d="m52.48 187.935 118 15.4v12.45l-118-15.4Z"
        data-name="Path 72"
      />
      <path
        d="m52.48 190.035-3.7-2.17.01 12.46 3.69 2.16Z"
        data-name="Path 73"
        opacity={0.2}
      />
      <path
        fill="#bd8dbd"
        d="m52.48 187.935-4.18-2.45.01 12.46 4.17 2.44Z"
        data-name="Path 74"
      />
      <path
        d="m52.48 187.935-4.18-2.45.01 12.46 4.17 2.44Z"
        data-name="Path 75"
        opacity={0.2}
      />
      <path
        fill="#37474f"
        d="M57.75 191.295s.06 0 .07.09a.54.54 0 0 1 0 .22l-.17 1.15a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08l-1.34-.17-.22 1.48 1.25.16s.06 0 .08.1a.74.74 0 0 1 0 .21l-.17 1.16a.66.66 0 0 1-.06.2s-.07.08-.1.07l-1.25-.16-.34 2.35a.77.77 0 0 1-.07.2.088.088 0 0 1-.1.08l-.59-.07-.07-.1a.49.49 0 0 1 0-.21l1-7a.59.59 0 0 1 .06-.2s.07-.08.1-.08Z"
        data-name="Path 76"
      />
      <path
        fill="#37474f"
        d="M58.15 194.235a7.33 7.33 0 0 1 .27-1.14 4.74 4.74 0 0 1 .41-.9 1.83 1.83 0 0 1 .53-.57.8.8 0 0 1 .59-.11.81.81 0 0 1 .55.31 1.82 1.82 0 0 1 .35.68 4.549 4.549 0 0 1 .13 1 7.806 7.806 0 0 1-.14 2.16c-.05.36-.1.66-.15 1a7.471 7.471 0 0 1-.27 1.13 4.85 4.85 0 0 1-.41.91 2 2 0 0 1-.53.57.86.86 0 0 1-.61.16.84.84 0 0 1-.56-.31 1.88 1.88 0 0 1-.34-.68 4 4 0 0 1-.13-1 7.781 7.781 0 0 1 0-1.16 8.606 8.606 0 0 1 .13-1c.05-.38.12-.77.18-1.05Zm1.69 2.13a.97.97 0 0 1 .07-.43 1.622 1.622 0 0 0 .08-.49 1.659 1.659 0 0 1 .07-.49c.07-.16 0-.29 0-.42a2.9 2.9 0 0 0 0-.5 1.58 1.58 0 0 0-.05-.41.71.71 0 0 0-.13-.29.34.34 0 0 0-.48-.06.74.74 0 0 0-.22.24 2.292 2.292 0 0 0-.16.38 3.332 3.332 0 0 0-.12.5 3.858 3.858 0 0 0-.07.41 1.076 1.076 0 0 1-.08.49c-.08.17 0 .33-.07.49s0 .3 0 .43a2.79 2.79 0 0 0 0 .93.391.391 0 0 0 .36.41.47.47 0 0 0 .46-.31 2.931 2.931 0 0 0 .34-.88Z"
        data-name="Path 77"
      />
      <path
        fill="#37474f"
        d="M63.58 192.035a1 1 0 0 1 .5.22 1.3 1.3 0 0 1 .34.51 2.92 2.92 0 0 1 .13.81 5.59 5.59 0 0 1-.07 1.11 5.42 5.42 0 0 1-.34 1.34 1.91 1.91 0 0 1-.51.77l.18 2.66v.12a.38.38 0 0 1-.06.16.072.072 0 0 1-.08.07l-.61-.08a.13.13 0 0 1-.11-.12.69.69 0 0 1 0-.2l-.16-2.39-.42-.05-.34 2.32a.52.52 0 0 1-.06.2.088.088 0 0 1-.1.08l-.59-.07-.07-.1a.49.49 0 0 1 0-.21l1-7a.59.59 0 0 1 .06-.2c0-.05.07-.08.1-.08Zm-1 3.23.51.07a.37.37 0 0 0 .33-.14 1.11 1.11 0 0 0 .22-.61 1.27 1.27 0 0 0 0-.65.35.35 0 0 0-.29-.21l-.51-.07Z"
        data-name="Path 78"
      />
      <path
        fill="#37474f"
        d="M64.7 199.935a.12.12 0 0 1-.07-.1.48.48 0 0 1 0-.21l1-7a.932.932 0 0 1 .07-.2c0-.05.07-.08.1-.07l1.44.18a.69.69 0 0 1 .48.27 1.37 1.37 0 0 1 .25.56 2.929 2.929 0 0 1 .08.75 7.351 7.351 0 0 1-.07.82 3.19 3.19 0 0 1-.11.54 4 4 0 0 1-.12.4c0 .11-.09.19-.13.26l-.09.15a2 2 0 0 1 .15.71 7.049 7.049 0 0 1-.07.94 6.252 6.252 0 0 1-.18.86 3.27 3.27 0 0 1-.29.73 1.32 1.32 0 0 1-.4.49.63.63 0 0 1-.51.14Zm1-1.6.74.1a.28.28 0 0 0 .24-.17 1.24 1.24 0 0 0 .15-.46 1.26 1.26 0 0 0 0-.48.211.211 0 0 0-.19-.22l-.74-.09Zm1.49-3.49a1.17 1.17 0 0 0 0-.46.2.2 0 0 0-.18-.2l-.7-.09-.18 1.24.7.09a.207.207 0 0 0 .23-.14 1.44 1.44 0 0 0 .09-.44Z"
        data-name="Path 79"
      />
      <path
        fill="#37474f"
        d="M68.22 200.385s-.05 0-.07-.1a.49.49 0 0 1 0-.21l1-7a.59.59 0 0 1 .06-.2c0-.05.07-.08.1-.07l.59.07a.12.12 0 0 1 .07.1.48.48 0 0 1 0 .21l-1 7a.86.86 0 0 1-.07.21c0 .05-.07.08-.1.07Z"
        data-name="Path 80"
      />
      <path
        fill="#37474f"
        d="M72.32 193.155a.83.83 0 0 1 .53.29 1.72 1.72 0 0 1 .35.69 3.94 3.94 0 0 1 .16 1 8.93 8.93 0 0 1-.06 1.24 1.33 1.33 0 0 1-.06.42v.37a.781.781 0 0 1-.06.36 3.548 3.548 0 0 1-.06.4 7.409 7.409 0 0 1-.29 1.21 4.659 4.659 0 0 1-.43.92 1.94 1.94 0 0 1-.52.57.81.81 0 0 1-.59.15l-1.22-.15-.07-.1a.7.7 0 0 1 0-.21l1-7a.59.59 0 0 1 .06-.2c0-.05.07-.08.1-.07Zm.15 3.06c.11-.83 0-1.28-.42-1.32l-.46-.06-.6 4.13.48.06c.37.05.63-.34.77-1.18l.06-.42.06-.4.06-.38c.02-.13.03-.27.05-.43Z"
        data-name="Path 81"
      />
      <path
        fill="#37474f"
        d="M75.95 193.615a.79.79 0 0 1 .53.29 1.75 1.75 0 0 1 .35.68 4.1 4.1 0 0 1 .16 1 8.931 8.931 0 0 1-.06 1.24.954.954 0 0 1-.06.42c-.06.13 0 .25 0 .37a.851.851 0 0 1-.05.35c-.05.12 0 .26-.07.41a7.67 7.67 0 0 1-.29 1.21 4.659 4.659 0 0 1-.43.92 1.94 1.94 0 0 1-.52.57.81.81 0 0 1-.59.15l-1.22-.15-.07-.1a.49.49 0 0 1 0-.21l1-7a.589.589 0 0 1 .06-.2s.07-.08.1-.07Zm.15 3.06c.11-.83 0-1.28-.42-1.33l-.46-.05-.6 4.13.48.06c.37 0 .63-.34.77-1.18l.06-.43.02-.36.06-.38c.02-.16.03-.3.05-.46Z"
        data-name="Path 82"
      />
      <path
        fill="#37474f"
        d="m79.57 200.065.07.09a.49.49 0 0 1 0 .21l-.16 1.16a.93.93 0 0 1-.07.2.14.14 0 0 1-.1.08l-2.13-.28-.07-.1a.67.67 0 0 1 0-.2l1-7a.52.52 0 0 1 .06-.2c0-.06.07-.08.1-.08l2.09.27s.06 0 .08.1a.74.74 0 0 1 0 .21l-.17 1.15a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08l-1.38-.18-.17 1.18 1.28.16a.12.12 0 0 1 .07.1.48.48 0 0 1 0 .21l-.17 1.15a.65.65 0 0 1-.06.21c0 .05-.07.08-.1.07l-1.28-.16-.18 1.21Z"
        data-name="Path 83"
      />
      <path
        fill="#37474f"
        d="M82.34 202.185s-.08 0-.1-.11a.41.41 0 0 1 0-.16l-.49-3.85-.53 3.67a.77.77 0 0 1-.07.2.088.088 0 0 1-.1.08l-.59-.07s-.05 0-.07-.1a.7.7 0 0 1 0-.21l1-7a.59.59 0 0 1 .06-.2c0-.05.07-.08.1-.07l.47.06a.119.119 0 0 1 .11.11.385.385 0 0 1 0 .16l.48 3.85.54-3.68a.59.59 0 0 1 .06-.2c0-.05.07-.08.1-.07l.59.07s.06 0 .07.1a.48.48 0 0 1 0 .21l-1 7a.65.65 0 0 1-.06.21s-.07.08-.1.07Z"
        data-name="Path 84"
      />
      <path
        fill="#37474f"
        d="M86.67 202.735s-.06 0-.08-.09a.741.741 0 0 1 0-.21l.16-1.11-1.41-.18s-.06 0-.08-.1a.74.74 0 0 1 0-.21l.17-1.15a1.4 1.4 0 0 1 .12-.47l1.94-3.92a.42.42 0 0 1 .1-.15.14.14 0 0 1 .09 0l.68.09s.06 0 .07.1a.48.48 0 0 1 0 .21l-.61 4.17.39.05a.12.12 0 0 1 .07.1.48.48 0 0 1 0 .21l-.17 1.15a.48.48 0 0 1-.07.21.078.078 0 0 1-.09.07h-.39l-.16 1.11a.52.52 0 0 1-.07.2c0 .06-.06.08-.1.08Zm.35-3.15.3-2-1 1.92Z"
        data-name="Path 85"
      />
      <path
        fill="#37474f"
        d="M91.04 195.435a.79.79 0 0 1 .54.31 1.88 1.88 0 0 1 .31.7 4.59 4.59 0 0 1 .1 1q.006.536-.04 1.07a7.728 7.728 0 0 1-.14 1c-.05.36-.1.66-.14 1a11.3 11.3 0 0 1-.26 1.11 4.09 4.09 0 0 1-.38.91 1.84 1.84 0 0 1-.49.59.81.81 0 0 1-.61.17.79.79 0 0 1-.55-.31 1.81 1.81 0 0 1-.31-.7 4.59 4.59 0 0 1-.1-1 11.231 11.231 0 0 1 .07-1.13 8.6 8.6 0 0 1 .13-1c.05-.38.1-.67.16-1a9 9 0 0 1 .25-1.11 4.091 4.091 0 0 1 .38-.91 1.84 1.84 0 0 1 .49-.59.82.82 0 0 1 .59-.11Zm-.23 4.89c.1-.62.19-1.22.27-1.83v-.5a2.549 2.549 0 0 0 0-.41.71.71 0 0 0-.11-.28.27.27 0 0 0-.21-.13.3.3 0 0 0-.24.07.7.7 0 0 0-.18.25 2.347 2.347 0 0 0-.14.38 3.675 3.675 0 0 1-.1.5q-.15.9-.27 1.83a4.769 4.769 0 0 0 0 .5 1.76 1.76 0 0 0 0 .41.66.66 0 0 0 .11.28.27.27 0 0 0 .21.13.3.3 0 0 0 .24-.07.7.7 0 0 0 .18-.25 1.68 1.68 0 0 0 .14-.38 4.891 4.891 0 0 0 .1-.5Z"
        data-name="Path 86"
      />
      <path
        fill="#37474f"
        d="M95.46 196.115s.06 0 .07.09a.49.49 0 0 1 0 .21l-.17 1.16a.65.65 0 0 1-.06.21l-.09.16-.73 1.25a1.59 1.59 0 0 1 .46.81 3.34 3.34 0 0 1 0 1.39 3.93 3.93 0 0 1-.62 1.81 1.09 1.09 0 0 1-1.06.49.92.92 0 0 1-.55-.26 1.49 1.49 0 0 1-.35-.54 2.71 2.71 0 0 1-.16-.72 3.56 3.56 0 0 1 0-.8.689.689 0 0 1 0-.17s.05-.06.08-.06l.57.07a.17.17 0 0 1 .13.12 2.17 2.17 0 0 0 .07.24.7.7 0 0 0 .13.24.32.32 0 0 0 .26.14.43.43 0 0 0 .38-.13.84.84 0 0 0 .22-.53.86.86 0 0 0-.06-.58.47.47 0 0 0-.33-.22h-.37a.12.12 0 0 1-.07-.1.48.48 0 0 1 0-.21l.14-.94a.65.65 0 0 1 .06-.21l.09-.17.64-1.1-1.17-.15s-.06 0-.07-.1a.48.48 0 0 1 0-.21l.17-1.15a.4.4 0 0 1 .07-.2.086.086 0 0 1 .09-.08Z"
        data-name="Path 87"
      />
      <path
        fill="#37474f"
        d="M100.05 196.695s.06 0 .08.1a.74.74 0 0 1 0 .21l-.17 1.15a.579.579 0 0 1-.06.21s-.07.08-.1.07l-1.34-.17-.22 1.49 1.25.16s.06 0 .08.1a.74.74 0 0 1 0 .21l-.17 1.15a.65.65 0 0 1-.06.21c0 .05-.07.08-.1.07l-1.29-.14-.34 2.35a.52.52 0 0 1-.07.2c0 .06-.06.08-.1.08l-.59-.08s-.05 0-.07-.09a.54.54 0 0 1 0-.22l1-7a.52.52 0 0 1 .06-.2c0-.06.07-.08.1-.08Z"
        data-name="Path 88"
      />
      <path
        fill="#37474f"
        d="M100.42 199.635a8.908 8.908 0 0 1 .27-1.13 4.74 4.74 0 0 1 .41-.9 2.151 2.151 0 0 1 .53-.58.91.91 0 0 1 .62-.16.86.86 0 0 1 .55.31 2 2 0 0 1 .34.69 3.929 3.929 0 0 1 .13 1 6.629 6.629 0 0 1 0 1.16 7.974 7.974 0 0 1-.14 1c-.05.36-.1.66-.14 1a8.638 8.638 0 0 1-.28 1.14 4.19 4.19 0 0 1-.41.9 1.88 1.88 0 0 1-.53.58.84.84 0 0 1-.61.15.89.89 0 0 1-.56-.3 1.93 1.93 0 0 1-.33-.69 3.9 3.9 0 0 1-.14-1 7.709 7.709 0 0 1 .06-1.16 8.6 8.6 0 0 1 .13-1c.05-.38.05-.7.1-1.01Zm1.69 2.13a2.651 2.651 0 0 1 .08-.43 2.144 2.144 0 0 1 .07-.49 1.689 1.689 0 0 0 .07-.48 1.791 1.791 0 0 1 .05-.42 4.77 4.77 0 0 0 0-.51 2.419 2.419 0 0 0-.05-.41.78.78 0 0 0-.14-.29.34.34 0 0 0-.23-.13.32.32 0 0 0-.25.07.83.83 0 0 0-.21.24 1.759 1.759 0 0 0-.17.39 3.191 3.191 0 0 0-.12.49.971.971 0 0 1-.07.42 1.928 1.928 0 0 0-.08.48l-.06.49a1.046 1.046 0 0 1-.06.44 2.73 2.73 0 0 0 0 .92.391.391 0 0 0 .36.41c.19.02.34-.08.47-.3a3 3 0 0 0 .34-.89Z"
        data-name="Path 89"
      />
      <path
        fill="#37474f"
        d="M105.84 197.435a1 1 0 0 1 .51.22 1.19 1.19 0 0 1 .33.51 2.64 2.64 0 0 1 .13.81 5.589 5.589 0 0 1-.07 1.11 6 6 0 0 1-.33 1.34 2 2 0 0 1-.52.77l.18 2.66a.479.479 0 0 1 0 .12.43.43 0 0 1-.06.17.1.1 0 0 1-.07.06l-.62-.08a.14.14 0 0 1-.11-.11.761.761 0 0 1 0-.21l-.16-2.39h-.41l-.34 2.33a.59.59 0 0 1-.07.2.082.082 0 0 1-.1.07l-.58-.07s-.06 0-.08-.1a.74.74 0 0 1 0-.21l1-7a.481.481 0 0 1 .07-.21s.06-.08.09-.07Zm-1 3.23.51.07a.39.39 0 0 0 .34-.14 1.12 1.12 0 0 0 .21-.61 1.08 1.08 0 0 0 0-.65.35.35 0 0 0-.29-.21l-.5-.07Z"
        data-name="Path 90"
      />
      <path
        fill="#37474f"
        d="M106.95 205.335s-.06 0-.07-.1a.48.48 0 0 1 0-.21l1-7a.65.65 0 0 1 .07-.21s.06-.08.1-.07l1.44.18a.73.73 0 0 1 .48.27 1.37 1.37 0 0 1 .25.56 3 3 0 0 1 .07.75 5.549 5.549 0 0 1-.06.82 5.327 5.327 0 0 1-.11.55 2.537 2.537 0 0 1-.13.39 1.29 1.29 0 0 1-.12.26l-.09.15a1.8 1.8 0 0 1 .14.71 5.5 5.5 0 0 1-.06.94c-.05.3-.11.58-.18.86a3.831 3.831 0 0 1-.29.73 1.47 1.47 0 0 1-.4.5.67.67 0 0 1-.51.14Zm.95-1.6.74.1a.23.23 0 0 0 .25-.16 1.37 1.37 0 0 0 .15-.47 1.25 1.25 0 0 0 0-.48.211.211 0 0 0-.18-.22l-.74-.09Zm1.5-3.49a1.4 1.4 0 0 0 0-.46.2.2 0 0 0-.18-.2l-.7-.09-.18 1.24.7.09a.22.22 0 0 0 .22-.14 1.14 1.14 0 0 0 .16-.44Z"
        data-name="Path 91"
      />
      <path
        fill="#37474f"
        d="M110.5 205.785s-.06 0-.07-.1a.48.48 0 0 1 0-.21l1-7a.52.52 0 0 1 .07-.2c0-.06.06-.08.1-.08l.58.08s.06 0 .08.09a.74.74 0 0 1 0 .21l-1 7a.4.4 0 0 1-.07.2.086.086 0 0 1-.09.08Z"
        data-name="Path 92"
      />
      <path
        fill="#37474f"
        d="M114.61 198.515a.82.82 0 0 1 .53.3 1.75 1.75 0 0 1 .35.68 4.1 4.1 0 0 1 .16 1 9 9 0 0 1-.06 1.24 1.39 1.39 0 0 1-.06.42.851.851 0 0 1-.05.36c-.05.11 0 .24-.05.36s0 .26-.07.4a7.539 7.539 0 0 1-.29 1.22 4.94 4.94 0 0 1-.43.92 1.941 1.941 0 0 1-.52.57.86.86 0 0 1-.59.15l-1.22-.16s-.05 0-.07-.09a.5.5 0 0 1 0-.21l1-7a.52.52 0 0 1 .06-.2c0-.06.07-.08.1-.08Zm.15 3.07c.11-.84 0-1.28-.42-1.33l-.46-.06-.6 4.13.48.07c.37 0 .63-.35.77-1.18l.06-.43.06-.39.06-.39c.02-.09.03-.23.05-.38Z"
        data-name="Path 93"
      />
      <path
        fill="#37474f"
        d="M118.2 199.015a.87.87 0 0 1 .53.3 1.75 1.75 0 0 1 .35.68 3.631 3.631 0 0 1 .15 1 6.71 6.71 0 0 1-.06 1.24 1.155 1.155 0 0 1-.05.42.872.872 0 0 0 0 .36l-.06.36a.975.975 0 0 1-.06.4 6.81 6.81 0 0 1-.3 1.22 4.382 4.382 0 0 1-.42.92 1.94 1.94 0 0 1-.52.57.93.93 0 0 1-.6.15l-1.21-.12s-.06 0-.07-.1a.48.48 0 0 1 0-.21l1-7a.77.77 0 0 1 .07-.2c0-.06.07-.08.1-.08Zm.15 3.07c.1-.84 0-1.28-.43-1.33l-.46-.06-.6 4.13.48.06c.38.05.64-.34.77-1.17a2.758 2.758 0 0 1 .07-.43l.06-.39a1.048 1.048 0 0 1 .05-.39 1.848 1.848 0 0 0 .06-.42Z"
        data-name="Path 94"
      />
      <path
        fill="#37474f"
        d="M121.86 205.515s.05 0 .07.1a.49.49 0 0 1 0 .21l-.16 1.15a.771.771 0 0 1-.07.2.087.087 0 0 1-.1.08l-2.12-.27s-.06 0-.08-.1a.74.74 0 0 1 0-.21l1-7a.48.48 0 0 1 .07-.21.078.078 0 0 1 .09-.07l2.09.27s.06 0 .08.09a.8.8 0 0 1 0 .22l-.17 1.15a.589.589 0 0 1-.06.2.14.14 0 0 1-.1.08l-1.38-.18-.17 1.17 1.28.17s.06 0 .07.1a.48.48 0 0 1 0 .21l-.17 1.15a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08l-1.29-.17-.17 1.22Z"
        data-name="Path 95"
      />
      <path
        fill="#37474f"
        d="M124.62 207.595a.15.15 0 0 1-.11-.12.73.73 0 0 1 0-.16l-.56-3.8-.53 3.68a.4.4 0 0 1-.07.2.086.086 0 0 1-.09.08l-.59-.08s-.06 0-.07-.1a.48.48 0 0 1 0-.21l1-7a.52.52 0 0 1 .07-.2c0-.06.06-.08.1-.08l.46.06a.14.14 0 0 1 .11.11.9.9 0 0 1 0 .16l.49 3.85.53-3.67a.4.4 0 0 1 .07-.2.086.086 0 0 1 .09-.08l.59.07s.06 0 .08.1a.74.74 0 0 1 0 .21l-1 7a.4.4 0 0 1-.07.2.086.086 0 0 1-.09.08Z"
        data-name="Path 96"
      />
      <path
        fill="#37474f"
        d="M128.95 208.145a.12.12 0 0 1-.07-.1.48.48 0 0 1 0-.21l.16-1.11-1.41-.18s-.06 0-.07-.1a.48.48 0 0 1 0-.21l.17-1.15a1.61 1.61 0 0 1 .12-.46l1.94-3.93a.42.42 0 0 1 .1-.15.11.11 0 0 1 .1 0l.67.08s.06 0 .08.1a.74.74 0 0 1 0 .21l-.6 4.18.38.05s.06 0 .07.09a.53.53 0 0 1 0 .22l-.17 1.15a.52.52 0 0 1-.06.2s-.07.08-.1.08l-.39-.05-.16 1.11a.43.43 0 0 1-.07.2.078.078 0 0 1-.09.07Zm.36-3.16.29-2-.95 1.92Z"
        data-name="Path 97"
      />
      <path
        fill="#37474f"
        d="M133.32 200.845a.76.76 0 0 1 .54.31 1.83 1.83 0 0 1 .31.69 4.07 4.07 0 0 1 .1 1 8.94 8.94 0 0 1-.07 1.14 7.849 7.849 0 0 1-.13 1c-.05.36-.1.66-.15.95a8.77 8.77 0 0 1-.26 1.12 4.529 4.529 0 0 1-.37.91 2 2 0 0 1-.5.59.79.79 0 0 1-.61.16.76.76 0 0 1-.55-.31 1.83 1.83 0 0 1-.3-.69 4.63 4.63 0 0 1-.11-1 9 9 0 0 1 .08-1.14 8.6 8.6 0 0 1 .13-1c.05-.38.1-.66.15-1a9 9 0 0 1 .25-1.11 4.53 4.53 0 0 1 .38-.91 2 2 0 0 1 .49-.59.82.82 0 0 1 .62-.12Zm-.23 4.88c.11-.61.19-1.22.27-1.82v-.51a1.671 1.671 0 0 0 0-.4.7.7 0 0 0-.11-.29.28.28 0 0 0-.21-.12.26.26 0 0 0-.23.06.86.86 0 0 0-.19.25 2.491 2.491 0 0 0-.14.39 4.9 4.9 0 0 0-.1.5c-.1.59-.19 1.2-.27 1.82v.5a1.73 1.73 0 0 0 0 .41.7.7 0 0 0 .11.29.28.28 0 0 0 .21.12.29.29 0 0 0 .24-.06.7.7 0 0 0 .18-.25 1.772 1.772 0 0 0 .14-.39 3.152 3.152 0 0 0 .1-.5Z"
        data-name="Path 98"
      />
      <path
        fill="#37474f"
        d="M137.73 201.515s.05 0 .07.1a.7.7 0 0 1 0 .21l-.17 1.15a.649.649 0 0 1-.07.21.791.791 0 0 1-.08.16l-.73 1.26a1.52 1.52 0 0 1 .46.8 3.57 3.57 0 0 1 0 1.4 3.84 3.84 0 0 1-.62 1.8 1.13 1.13 0 0 1-1.07.5 1 1 0 0 1-.54-.26 1.48 1.48 0 0 1-.35-.55 2.54 2.54 0 0 1-.16-.72 3.071 3.071 0 0 1 0-.8.42.42 0 0 1 .05-.17s0-.06.08-.06l.57.08q.09 0 .12.12a.791.791 0 0 0 .08.23.85.85 0 0 0 .13.25.34.34 0 0 0 .26.13.45.45 0 0 0 .38-.12.94.94 0 0 0 .22-.54.84.84 0 0 0-.06-.57.44.44 0 0 0-.33-.22l-.37-.05s-.06 0-.08-.1a.741.741 0 0 1 0-.21l.13-.93a.81.81 0 0 1 .07-.22l.09-.16.64-1.11-1.17-.14a.12.12 0 0 1-.07-.1.48.48 0 0 1 0-.21l.17-1.16a.589.589 0 0 1 .06-.2c0-.05.07-.08.1-.07Z"
        data-name="Path 99"
      />
      <path
        fill="#37474f"
        d="M142.31 202.105s.06 0 .08.09a.81.81 0 0 1 0 .22l-.17 1.15a.59.59 0 0 1-.06.2c0 .05-.07.08-.1.08l-1.34-.18-.22 1.49 1.25.16s.06 0 .08.1a.74.74 0 0 1 0 .21l-.17 1.16a.59.59 0 0 1-.06.2s-.07.08-.1.07l-1.25-.16-.34 2.35a.77.77 0 0 1-.07.2c0 .06-.06.08-.1.08l-.59-.08-.07-.09a.49.49 0 0 1 0-.21l1-7a.589.589 0 0 1 .06-.2c0-.06.07-.08.1-.08Z"
        data-name="Path 100"
      />
      <path
        fill="#37474f"
        d="M142.71 205.045a7.18 7.18 0 0 1 .28-1.13 3.78 3.78 0 0 1 .41-.9 2 2 0 0 1 .52-.58.87.87 0 0 1 .62-.16.84.84 0 0 1 .55.31 1.89 1.89 0 0 1 .34.68 4.06 4.06 0 0 1 .14 1 7.852 7.852 0 0 1-.06 1.16 8.088 8.088 0 0 1-.13 1c-.05.37-.1.66-.15.95a7.6 7.6 0 0 1-.28 1.14 3.939 3.939 0 0 1-.41.9 1.85 1.85 0 0 1-.52.58.88.88 0 0 1-1.17-.15 1.86 1.86 0 0 1-.34-.69 4.521 4.521 0 0 1-.14-1 7.911 7.911 0 0 1 .06-1.17 7.156 7.156 0 0 1 .13-.95c.05-.34.1-.68.15-.99Zm1.7 2.13a.992.992 0 0 1 .07-.43 2.007 2.007 0 0 0 .08-.49l.06-.49a1.156 1.156 0 0 1 .05-.42 3 3 0 0 0 0-.5 1.639 1.639 0 0 0-.05-.41.71.71 0 0 0-.13-.29.32.32 0 0 0-.23-.13.36.36 0 0 0-.26.07.63.63 0 0 0-.21.24 2.7 2.7 0 0 0-.17.38 3.811 3.811 0 0 1-.11.5c-.03.18-.05.26-.08.42a2.062 2.062 0 0 1-.07.48 1.706 1.706 0 0 0-.07.49 2.043 2.043 0 0 1-.05.44 3 3 0 0 0 0 .92c.06.25.18.39.37.41a.47.47 0 0 0 .46-.31 2.83 2.83 0 0 0 .34-.88Z"
        data-name="Path 101"
      />
      <path
        fill="#37474f"
        d="M148.12 202.845a.91.91 0 0 1 .5.22 1.13 1.13 0 0 1 .33.5 2.62 2.62 0 0 1 .14.81 6.519 6.519 0 0 1-.07 1.12 5.54 5.54 0 0 1-.34 1.34 2 2 0 0 1-.51.77l.18 2.65v.12a.43.43 0 0 1-.06.17c0 .05-.05.06-.08.06l-.61-.08c-.06 0-.1 0-.11-.11a.75.75 0 0 1 0-.2l-.16-2.39-.42-.06-.34 2.33a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08l-.59-.08s-.05 0-.07-.09a.79.79 0 0 1 0-.22l1-7a.52.52 0 0 1 .06-.2c0-.06.07-.08.1-.08Zm-1 3.23.51.06a.37.37 0 0 0 .33-.13 1.68 1.68 0 0 0 .19-1.26.38.38 0 0 0-.29-.22l-.51-.06Z"
        data-name="Path 102"
      />
      <path
        fill="#37474f"
        d="M149.24 210.735s-.06 0-.07-.09a.48.48 0 0 1 0-.21l1-7a.769.769 0 0 1 .07-.2c0-.06.06-.08.1-.08l1.44.19a.68.68 0 0 1 .48.26 1.41 1.41 0 0 1 .25.57 2.52 2.52 0 0 1 .07.75 5.549 5.549 0 0 1-.06.82 4.628 4.628 0 0 1-.11.54 2.348 2.348 0 0 1-.13.4 2.167 2.167 0 0 1-.12.26l-.09.14a1.85 1.85 0 0 1 .14.72 5.39 5.39 0 0 1-.06.93 6.086 6.086 0 0 1-.18.87 3.269 3.269 0 0 1-.29.73 1.53 1.53 0 0 1-.4.49.67.67 0 0 1-.51.14Zm1-1.59.75.09c.08 0 .16 0 .24-.16a1.309 1.309 0 0 0 .15-.46 1.5 1.5 0 0 0 0-.48.211.211 0 0 0-.19-.22l-.74-.1Zm1.5-3.5a1.17 1.17 0 0 0 0-.46.188.188 0 0 0-.18-.19l-.7-.09-.18 1.24.7.09a.207.207 0 0 0 .23-.14 1.43 1.43 0 0 0 .08-.45Z"
        data-name="Path 103"
      />
      <path
        fill="#37474f"
        d="M152.78 211.195s-.06 0-.08-.1a.741.741 0 0 1 0-.21l1-7a.43.43 0 0 1 .07-.2.078.078 0 0 1 .09-.07l.59.07s.06 0 .07.1a.48.48 0 0 1 0 .21l-1 7a.52.52 0 0 1-.07.2.083.083 0 0 1-.1.08Z"
        data-name="Path 104"
      />
      <path
        fill="#37474f"
        d="M156.87 203.965a.81.81 0 0 1 .53.29 1.89 1.89 0 0 1 .35.68 4.13 4.13 0 0 1 .15 1 6.679 6.679 0 0 1-.06 1.24v.42l-.06.37a.874.874 0 0 1-.05.35c-.05.12 0 .26-.06.41a7.64 7.64 0 0 1-.3 1.21 4.151 4.151 0 0 1-.42.92 2 2 0 0 1-.53.57.8.8 0 0 1-.59.15l-1.21-.15a.12.12 0 0 1-.07-.1.48.48 0 0 1 0-.21l1-7a.59.59 0 0 1 .07-.2.082.082 0 0 1 .1-.07Zm.15 3.06c.1-.83 0-1.28-.43-1.33l-.46-.06-.6 4.14.48.06c.38.05.64-.35.77-1.18a.908.908 0 0 1 .07-.43l.06-.39v-.38c0-.13.09-.28.11-.43Z"
        data-name="Path 105"
      />
      <path
        fill="#37474f"
        d="M160.46 204.425a.81.81 0 0 1 .54.29 2.06 2.06 0 0 1 .35.68 4.69 4.69 0 0 1 .15 1 6.681 6.681 0 0 1-.06 1.24l-.06.42a.851.851 0 0 1-.05.36c-.05.11 0 .24 0 .36l-.06.4a8.709 8.709 0 0 1-.3 1.22 4.151 4.151 0 0 1-.42.92 2 2 0 0 1-.53.57.81.81 0 0 1-.59.15l-1.21-.16s-.06 0-.08-.09a.741.741 0 0 1 0-.21l1-7a.4.4 0 0 1 .07-.2c0-.06.06-.08.1-.08Zm.16 3.06c.1-.83 0-1.28-.43-1.33l-.46-.06-.6 4.14.48.06c.38 0 .63-.35.77-1.18a.908.908 0 0 1 .07-.43c.07-.14 0-.26.05-.39l.06-.38c.02-.13.04-.28.06-.4Z"
        data-name="Path 106"
      />
      <path
        fill="#37474f"
        d="m164.13 210.875.07.09a.49.49 0 0 1 0 .21l-.16 1.16a.93.93 0 0 1-.07.2.082.082 0 0 1-.1.07l-2.13-.27-.07-.09a.739.739 0 0 1 0-.21l1-7a.4.4 0 0 1 .07-.2.086.086 0 0 1 .09-.08l2.09.27s.06 0 .08.1a.741.741 0 0 1 0 .21l-.17 1.15a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08l-1.38-.18-.17 1.18 1.28.16a.12.12 0 0 1 .07.1.48.48 0 0 1 0 .21l-.17 1.15a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08l-1.29-.16-.17 1.21Z"
        data-name="Path 107"
      />
      <path
        fill="#37474f"
        d="M166.89 212.995c-.05 0-.08 0-.11-.11a.691.691 0 0 1 0-.16l-.49-3.85-.54 3.67a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08l-.59-.08s-.06 0-.07-.09a.48.48 0 0 1 0-.21l1-7a.521.521 0 0 1 .06-.2c0-.06.07-.08.1-.08l.47.06c.05 0 .08.05.1.12a.41.41 0 0 1 0 .16l.49 3.85.53-3.68a.769.769 0 0 1 .07-.2.14.14 0 0 1 .1-.08l.59.08.07.1a.48.48 0 0 1 0 .21l-1 7a.52.52 0 0 1-.06.2c0 .06-.07.08-.1.08Z"
        data-name="Path 108"
      />
      <path
        fill="#bd8dbd"
        d="m52.48 187.935 118 15.4v12.45l-118-15.4Z"
        data-name="Path 178"
      />
      <g data-name="freepik--character--inject-18">
        <path
          fill="#f28f8f"
          d="m354.36 124.705 7.08-.31 4.58 8.68s-2.85 1.48-13.05 1.6Z"
          data-name="Path 109"
        />
        <path
          fill="#bd8dbd"
          d="M309.55 103.815c2.7 1.27 4.17 2.74 7.07 5.1 3.58 2.92 15.93 11.31 18.58 12a170.435 170.435 0 0 0 21.36 3.44s-1.15 7 .34 10.45c-6.34.28-22.5.82-28.14-.51-6-1.42-12.66-4.8-17.78-7.77s-3.98-20.19-1.43-22.71Z"
          data-name="Path 110"
        />
        <g data-name="Group 2" opacity={0.7}>
          <path
            fill="#fff"
            d="M309.55 103.815c2.7 1.27 4.17 2.74 7.07 5.1 3.58 2.92 15.93 11.31 18.58 12a170.435 170.435 0 0 0 21.36 3.44s-1.15 7 .34 10.45c-6.34.28-22.5.82-28.14-.51-6-1.42-12.66-4.8-17.78-7.77s-3.98-20.19-1.43-22.71Z"
            data-name="Path 111"
          />
        </g>
        <path
          d="M310.95 126.575c5.12 3 11.74 6.35 17.78 7.77 4.08 1 13.68.95 21.08.76a118.811 118.811 0 0 1-19-3.06 73.508 73.508 0 0 1-11.77-6.2c-3.33-2.37-3.53-6.62-5.23-12.77-2.26-8.16-4.25-9.26-4.25-9.26-2.56 2.52-3.69 19.79 1.39 22.76Z"
          data-name="Path 112"
          opacity={0.1}
        />
        <path
          fill="#bd8dbd"
          d="M349.84 135.105a14.48 14.48 0 0 1 .31-8.69s-2.06 2-1.62 8.72Z"
          data-name="Path 113"
        />
        <path
          d="M349.84 135.105a14.48 14.48 0 0 1 .31-8.69s-2.06 2-1.62 8.72Z"
          data-name="Path 114"
          opacity={0.1}
        />
        <path
          fill="#ffa8a7"
          d="M364.34 133.225c6-.21 8.23-3.25 9.07-8.52s.69-12.16.76-14-.47-2.46-1.2-2.56c-.27 0-1.43.25-1.56 2.2-.24 3.56-.33 5.75-1 5.72s-.57-2.66-.51-6.25c0-2.42 0-4.46-1.4-4.46s-1.79 1.44-1.86 4.71 0 5.7-.86 5.77c-.7 0-.56-3.43-.72-5.67s-.15-3.58-1.55-3.55-1.61 1.83-1.56 4a86.092 86.092 0 0 1-.23 9c-.39 1.29-1.73-1.89-2.71-3.2-1.61-2.15-3.58-1.17-2.78.79a38.827 38.827 0 0 1 2.18 7.4c.42 2.49 1.43 8.64 5.93 8.62Z"
          data-name="Path 115"
        />
        <path
          fill="#f28f8f"
          d="M364.31 122.335s3.62 3.77 2.32 7.8a5.35 5.35 0 0 0 .65-5.4 4.09 4.09 0 0 0-2.97-2.4Z"
          data-name="Path 116"
        />
        <path
          fill="#263238"
          d="M285.34 311.345c-.13 1.14-.28 4.34-6.07 5.13s-9.44-1.12-11-3.9-1.19-5.06-1.44-7.64-2.37-3.95-3-5.28c-.9-1.95.42-5 .42-5Z"
          data-name="Path 117"
        />
        <path
          fill="#263238"
          d="M327.58 302.285a3.49 3.49 0 0 1-.38 2.8 12.16 12.16 0 0 1-9.28 2.48 22.389 22.389 0 0 1-9.81-3.85 12.92 12.92 0 0 0-7.58-2.18c-2.79-.1-4.82-.72-5-1.45-.37-1.28-.37-2.1.23-2.06Z"
          data-name="Path 118"
        />
        <path
          fill="#455a64"
          d="M284.95 308.715a31.9 31.9 0 0 0-2.66-3.17 28.64 28.64 0 0 1-6-15.36c0-.31-.06-.91-.37-1.1s-.63.08-1 .22a6 6 0 0 1-1.79.47 33.382 33.382 0 0 1-4.83-.19c-.4.05-.52 1.29-.49 2.62-.73-.46-1-1.38-1.62-1.91-.36-.29-.51-.3-.51-.77v-.9c-.52-.15-1 1.87-1.4 3.41-.5 1.78-1.12 3.79-.42 5.6a15.72 15.72 0 0 0 1.63 2.8 11.62 11.62 0 0 1 2.1 5.12c.08.81 0 1.64.13 2.45a7.16 7.16 0 0 0 5.06 5.87c3.52 1.11 8.82.93 11.93-1.53 1.09-.83.72-2.68.24-3.63Z"
          data-name="Path 119"
        />
        <path
          fill="#455a64"
          d="M325.24 298.515c-.77-.24-1.53-.52-2.28-.82a41.849 41.849 0 0 1-6.07-3c-.85-.52-1.69-1.06-2.51-1.63s-1.65-1.27-2.47-1.93a8.809 8.809 0 0 1-1.18-1.26 13 13 0 0 1-1.74-2.82 1 1 0 0 0-.43-.57.84.84 0 0 0-.82.17 4 4 0 0 1-.64.43l-.68.28a7.849 7.849 0 0 1-2.28.56c-2.94.26-3-.33-3.16.62a7.927 7.927 0 0 0 0 1.06c-1.55-.25-2.77-.76-2.84-1.34l-.18-1.88c-2.31-.32-2.08 2.38-2.36 5.34-.23 2.54-.82 4.73-.12 6.58 2.33 2 6.46 1.54 8.81 1.93 3.32.55 4.88 3 9.5 4.61 7.44 2.58 12.15-.4 13.54-1.69.9-1.64 1.08-3.59-2.09-4.64Z"
          data-name="Path 120"
        />
        <path
          fill="#263238"
          d="M269.15 156.655c-.93 9-1.91 22.55-2.29 33.12-.7 19.86-.43 44.69-.43 44.69a68.588 68.588 0 0 0-2.14 12.73c-.47 12.69-.38 44.1-.38 44.1s5.26 5.3 13.13 1.14c0 0 7-42.87 8.92-55.38 1.87-11.93 5.5-38 5.5-38l1.64 36.05a52.369 52.369 0 0 0-1.23 16.26c.42 5.81 3.74 37.44 3.74 37.44 3 2.84 11.84 1.3 13.77-1 0 0 3.09-45.34 3.43-51.47.74-13.26 1-63.83 0-71.5s-43.66-8.18-43.66-8.18Z"
          data-name="Path 121"
        />
        <path
          d="m291.46 199.075 2.31-15.29a30.68 30.68 0 0 0 11-4.64s-1.8 4.08-8.82 7l-2.28 14.23-.55 34.75Z"
          data-name="Path 122"
        />
        <path
          fill="#263238"
          d="M279.95 61.845a1.894 1.894 0 0 0-.54.11 2.48 2.48 0 0 0-1.33-2.8 2.67 2.67 0 0 0-3.45 1.41l3.76 1.73a5.24 5.24 0 0 0-2.86 2.44c-1 2.12.33 6.64 1.7 12.22a79 79 0 0 0 3 10.34c.63 1.63 1.77 1.68 1.77 1.68v-5.57l-.24-4.61s3-4.05 3.29-7.37c.41-4.26-.48-6-.48-6Z"
          data-name="Path 123"
        />
        <path
          fill="#ffa8a7"
          d="M281.95 78.055c-1.11 1-2-1.53-2.95-2.51s-4-2.3-5.5.91 1.39 7.71 3.68 8.77c3.34 1.55 4.84-1.82 4.84-1.82v13.93c3.52 6.35 11.29 6.14 14.89 7a12.329 12.329 0 0 0-.14-6.25v-4.54a16.7 16.7 0 0 0 4.6.25c3-.48 4.67-3 5.58-6.28 1.47-5.29 1.77-14.18-.27-24.45-3.4-2.63-14.92-2.21-22.06 2.32.52 9.13-1.53 11.7-2.67 12.67Z"
          data-name="Path 124"
        />
        <path
          fill="#f28f8f"
          d="M296.77 93.575s-6.79-1.34-9.17-2.6a7.88 7.88 0 0 1-3.29-3.23 10.639 10.639 0 0 0 1.88 3.82c1.74 2.21 10.59 3.82 10.59 3.82Z"
          data-name="Path 125"
        />
        <path
          fill="#263238"
          d="M293.68 76.155a1.45 1.45 0 1 1-1.45-1.45 1.45 1.45 0 0 1 1.45 1.45Z"
          data-name="Path 126"
        />
        <path
          fill="#263238"
          d="M305.19 75.095a1.45 1.45 0 1 1-1.45-1.45 1.45 1.45 0 0 1 1.45 1.45Z"
          data-name="Path 127"
        />
        <path
          fill="#263238"
          d="m291.72 72.295-3 1.59a1.79 1.79 0 0 1 .75-2.37 1.67 1.67 0 0 1 2.25.78Z"
          data-name="Path 128"
        />
        <path
          fill="#b16668"
          d="m294.43 85.115 5.09 1.32a2.57 2.57 0 0 1-3.15 2 2.75 2.75 0 0 1-1.94-3.32Z"
          data-name="Path 129"
        />
        <path
          fill="#f28f8f"
          d="M294.95 86.185a3.05 3.05 0 0 0-.52.05 2.73 2.73 0 0 0 2 2.17 2.41 2.41 0 0 0 1.28 0 2.77 2.77 0 0 0-2.76-2.22Z"
          data-name="Path 130"
        />
        <path
          fill="#263238"
          d="m306.39 72.575-3.12-1.48a1.66 1.66 0 0 1 2.25-.86 1.82 1.82 0 0 1 .87 2.34Z"
          data-name="Path 131"
        />
        <path
          fill="#f28f8f"
          d="m298.18 74.445.91 9.31 4.65-2.09Z"
          data-name="Path 132"
        />
        <path
          fill="#bd8dbd"
          d="M276.03 97.335c-6.18 2.21-10.77 4.37-15.06 8.05 0 0 4.44 19.27 7.22 29.88 2.52 9.67.16 29.37.16 29.37s3.89 5.88 26 5.24c12.5-.36 18.6-4.36 18.6-4.36s1.06-21.73.58-39.9c-.38-14.62-1.44-18.15-3.93-21.81-1.8-1.22-11.75-4.9-11.75-4.9a26.086 26.086 0 0 0-.86 5.45c-3.68-5.66-20.96-7.02-20.96-7.02Z"
          data-name="Path 133"
        />
        <path
          fill="#fff"
          d="M276.03 97.335c-6.18 2.21-10.77 4.37-15.06 8.05 0 0 4.44 19.27 7.22 29.88 2.52 9.67.16 29.37.16 29.37s3.89 5.88 26 5.24c12.5-.36 18.6-4.36 18.6-4.36s1.06-21.73.58-39.9c-.38-14.62-1.44-18.15-3.93-21.81-1.8-1.22-11.75-4.9-11.75-4.9a26.086 26.086 0 0 0-.86 5.45c-3.68-5.66-20.96-7.02-20.96-7.02Z"
          data-name="Path 134"
          opacity={0.3}
        />
        <path
          d="M273.1 147.185c-.26-6.51-1.67-18.85-1.57-28.2l-4.41-6.69-2.57 8.31c1.23 5.1 2.56 10.54 3.64 14.66 2.52 9.67.16 29.37.16 29.37s3.89 5.88 26 5.24h.4a37.07 37.07 0 0 1-11.87-3.36c-6.87-3.4-9.52-12.81-9.78-19.33Z"
          data-name="Path 135"
          opacity={0.1}
        />
        <path
          fill="#bd8dbd"
          d="M282.05 91.445v1.07c.2 1 2.25 3.45 7.41 5.77a17.37 17.37 0 0 1 7.48 6.06s-1.76-2.71-3.93-1.28-4.37 5-5.49 5-4.86-3.57-7.79-6.63c-2.45-2.58-3.7-4.13-3.7-4.13s4.01-5.79 6.02-5.86Z"
          data-name="Path 136"
        />
        <path
          fill="#bd8dbd"
          d="M296.95 104.365c1.57-3.76 4.4-.55 4.58-.53.82.05-1.79-6.86-4.73-8.76v3Z"
          data-name="Path 137"
        />
        <path
          fill="#fff"
          d="M282.05 91.445v1.07c.2 1 2.25 3.45 7.41 5.77a17.37 17.37 0 0 1 7.48 6.06s-1.76-2.71-3.93-1.28-4.37 5-5.49 5-4.86-3.57-7.79-6.63c-2.45-2.58-3.7-4.13-3.7-4.13s4.01-5.79 6.02-5.86Z"
          data-name="Path 138"
          opacity={0.65}
        />
        <path
          fill="#fff"
          d="M296.95 104.365c1.57-3.76 4.4-.55 4.58-.53.82.05-1.79-6.86-4.73-8.76v3Z"
          data-name="Path 139"
          opacity={0.65}
        />
        <path
          fill="#bd8dbd"
          d="M301.06 169.285c.26-13.86.62-49.46-4.12-64.92 0 0 4.47 32.91 2.05 65.19q1.08-.12 2.07-.27Z"
          data-name="Path 140"
        />
        <path
          d="M301.06 169.285c.26-13.86.62-49.46-4.12-64.92 0 0 4.47 32.91 2.05 65.19q1.08-.12 2.07-.27Z"
          data-name="Path 141"
          opacity={0.1}
        />
        <path
          fill="#bd8dbd"
          d="M278.51 120.995a38.54 38.54 0 0 0 14.09-1.7l.22 2.72a35 35 0 0 1-14 1.46Z"
          data-name="Path 142"
        />
        <path
          d="M278.51 120.995a38.54 38.54 0 0 0 14.09-1.7l.22 2.72a35 35 0 0 1-14 1.46Z"
          data-name="Path 143"
          opacity={0.1}
        />
        <path
          fill="#ebebeb"
          d="M313.12 114.625a.2.2 0 0 0-.07-.2 3.282 3.282 0 0 1-.43-.26.38.38 0 0 0-.22.06 3.15 3.15 0 0 1-3.12.34 2.24 2.24 0 0 1-.35-.34.32.32 0 0 0-.09-.08l-.44-.27c-.1-.05-.2 0-.27.14-.93 2.11-2.84 4.22-4.08 4.72a.7.7 0 0 0-.25.17.53.53 0 0 0-.15.35c-.09 2.75 1.27 7.19 4.07 8.76a5.571 5.571 0 0 0 .52.32c4.03-3.97 4.77-10.45 4.88-13.71Z"
          data-name="Path 144"
        />
        <path
          fill="#e0e0e0"
          d="M307.69 127.965c-2.8-1.57-4.16-6-4.07-8.76a.551.551 0 0 1 .06-.22l.43.28a.46.46 0 0 0-.05.21c-.11 2.6 1.12 6.72 3.63 8.49Z"
          data-name="Path 145"
        />
        <path
          fill="#fafafa"
          d="M313.12 114.625c-.11 3.26-.85 9.74-4.91 13.66-2.86-1.53-4.24-6-4.15-8.81a.53.53 0 0 1 .15-.35.74.74 0 0 1 .25-.17c1.24-.5 3.15-2.61 4.08-4.72.07-.15.16-.19.26-.14a.32.32 0 0 1 .09.08 3 3 0 0 0 3.91.28.25.25 0 0 1 .22-.07.2.2 0 0 1 .1.24Z"
          data-name="Path 146"
        />
        <path
          fill="#f0f0f0"
          d="M308.8 114.095c-.1-.05-.19 0-.26.14-.93 2.11-2.84 4.22-4.08 4.72a.74.74 0 0 0-.25.17.53.53 0 0 0-.15.35c-.09 2.77 1.29 7.28 4.15 8.81a30.259 30.259 0 0 0 .59-14.19Z"
          data-name="Path 147"
        />
        <path
          fill="#263238"
          d="M286.52 67.405s9.32 4.54 19.32.61c10.27-4.05 1.27-6.92 1.27-6.92Z"
          data-name="Path 148"
        />
        <path
          fill="#455a64"
          d="M307.37 47.515c-7.75-1.72-16.22-2.63-26.39 1.54s-12.73 10.4-12.73 10.4 9.86 3.38 18.19 1.55 20.93-13.49 20.93-13.49Z"
          data-name="Path 149"
        />
        <path
          fill="#37474f"
          d="M274.08 65.135s-5.45-4.43-5.83-5.73a34.25 34.25 0 0 0 11.82-1.45c6.68-2 13.93-8.7 21.41-10.25s10.4 2 10.4 2a35.419 35.419 0 0 1-4.74 8.65s-2.61 5.09-11.41 6.75-21.65.03-21.65.03Z"
          data-name="Path 150"
        />
        <path
          fill="#bd8dbd"
          d="M274.08 65.135a14.934 14.934 0 0 0 .42 1.74 8.371 8.371 0 0 0 .81 1.47c3.12.61 10.62 1.1 20.51-1.26 6.2-1.49 9.06-3.21 10.24-4.45a3.36 3.36 0 0 0 1.2-2.9l-.12-1.37s-2.06 2.73-12.2 5.26a70.36 70.36 0 0 1-20.86 1.51Z"
          data-name="Path 151"
        />
        <path
          d="M274.08 65.135a14.934 14.934 0 0 0 .42 1.74 8.371 8.371 0 0 0 .81 1.47c3.12.61 10.62 1.1 20.51-1.26 6.2-1.49 9.06-3.21 10.24-4.45a3.36 3.36 0 0 0 1.2-2.9l-.12-1.37s-2.06 2.73-12.2 5.26a70.36 70.36 0 0 1-20.86 1.51Z"
          data-name="Path 152"
          opacity={0.05}
        />
        <path
          fill="#ffa8a7"
          d="M279.48 201.675c-1-1.46-3.31-2.78-4.17-3.64-1.52-1.54-3.74-4.79-3.41-5.39s1.26.18 3.2.17c3 0 3.82-1.18 3.79-2.28 0-.86-1.83-.7-4-1.18-1.91-.61-4.2-2.76-5.45-4.45s-2.26-6.1-2.26-6.1-6.49.09-11 3.16c0 0 1.29 6.7 2.77 11.45 1.65 5.33 5.39 11.21 11.08 12.53a13.83 13.83 0 0 0 7.16-.36 5.29 5.29 0 0 0 1.9-.94 2 2 0 0 0 .39-2.97Z"
          data-name="Path 153"
        />
        <path
          fill="#bd8dbd"
          d="M260.95 105.385s-1-.2-2.68 3.66a61.939 61.939 0 0 0-3.27 11.53c-.67 3.22-3.87 20.13-3.87 27.4s2.17 21.88 5 34.33c3.48.35 9.46-.55 11.46-2.83-1.31-12.42-2.41-18.81-2.11-28.58 2-14.5 7.87-29.69 8.12-31.38.53-3.71-5.33-15.37-12.65-14.13Z"
          data-name="Path 154"
        />
        <path
          fill="#fff"
          d="M260.95 105.385s-1-.2-2.68 3.66a61.939 61.939 0 0 0-3.27 11.53c-.67 3.22-3.87 20.13-3.87 27.4s2.17 21.88 5 34.33c3.48.35 9.46-.55 11.46-2.83-1.31-12.42-2.41-18.81-2.11-28.58 2-14.5 7.87-29.69 8.12-31.38.53-3.71-5.33-15.37-12.65-14.13Z"
          data-name="Path 155"
          opacity={0.65}
        />
        <path
          d="M256.22 166.515c-1.74-8.5-4.59-25.14-2.64-38.23-1.12 6.43-2.43 15-2.43 19.69 0 7.27 2.17 21.88 5 34.33a20.332 20.332 0 0 0 4.16-.08c-1.25-4.11-3.02-10.47-4.09-15.71Z"
          data-name="Path 156"
          opacity={0.1}
        />
        <path
          fill="#bd8dbd"
          d="M254.89 176.205c2.84.18 7.89-.62 10-2 0 0-3.68 1.63-10.19.7Z"
          data-name="Path 157"
        />
        <path
          d="M254.89 176.205c2.84.18 7.89-.62 10-2 0 0-3.68 1.63-10.19.7Z"
          data-name="Path 158"
          opacity={0.1}
        />
        <g data-name="freepik--shield--inject-18">
          <path
            fill="#ebebeb"
            d="M304.74 52.145v-.15l-.32-.2h-.17a2.37 2.37 0 0 1-2.33.25 1.6 1.6 0 0 1-.26-.26.19.19 0 0 0-.07-.06l-.32-.2a.17.17 0 0 0-.19.11 7.61 7.61 0 0 1-3.05 3.52.5.5 0 0 0-.19.13.41.41 0 0 0-.11.26c-.07 2 .95 5.37 3 6.54a3 3 0 0 0 .38.24c3-2.91 3.55-7.74 3.63-10.18Z"
            data-name="Path 159"
          />
          <path
            fill="#e0e0e0"
            d="M300.69 62.105c-2.1-1.17-3.11-4.49-3-6.54a.419.419 0 0 1 0-.16l.33.2a.29.29 0 0 0 0 .16c-.07 1.94.79 5.02 2.67 6.34Z"
            data-name="Path 160"
          />
          <path
            fill="#fafafa"
            d="M304.74 52.145c-.08 2.44-.63 7.27-3.67 10.2-2.13-1.14-3.16-4.51-3.09-6.58a.36.36 0 0 1 .11-.26.59.59 0 0 1 .18-.13 7.5 7.5 0 0 0 3.05-3.52c.05-.11.13-.15.2-.11a.19.19 0 0 1 .07.06 2.19 2.19 0 0 0 2.92.21c.07-.05.12-.06.16 0a.149.149 0 0 1 .07.13Z"
            data-name="Path 161"
          />
          <path
            fill="#f0f0f0"
            d="M301.52 51.745a.182.182 0 0 0-.2.11 7.5 7.5 0 0 1-3.05 3.52.59.59 0 0 0-.18.13.36.36 0 0 0-.11.26c-.07 2.07 1 5.44 3.09 6.58a22.54 22.54 0 0 0 .45-10.6Z"
            data-name="Path 162"
          />
        </g>
      </g>
      <g data-name="freepik--pot--inject-18">
        <path
          fill="#455a64"
          d="M64.65 331.755c8.41-8.69 13.36-44 5.15-50.91H28.03c-8.2 6.88-3.27 42.21 5.15 50.9l.23.24.29.28a8.566 8.566 0 0 0 .76.69l.2.16.63.48a10 10 0 0 0 1.09.7c6.92 4.05 18.15 4.05 25.07 0a10 10 0 0 0 1.09-.7c.21-.15.4-.3.6-.46l.24-.19c.27-.22.51-.44.74-.66l.33-.33Z"
          data-name="Path 163"
        />
        <path
          fill="#455a64"
          d="M31.66 278.065c-9.53 5.57-9.53 14.59 0 20.15s25 5.56 34.51 0 9.53-14.58 0-20.15-24.98-5.55-34.51 0Z"
          data-name="Path 164"
        />
        <g data-name="Group 3" opacity={0.1}>
          <path
            fill="#fff"
            d="M31.66 278.065c-9.53 5.57-9.53 14.59 0 20.15s25 5.56 34.51 0 9.53-14.58 0-20.15-24.98-5.55-34.51 0Z"
            data-name="Path 165"
          />
        </g>
        <path
          fill="#263238"
          d="M36.24 280.745c-7 4.08-7 10.71 0 14.79s18.35 4.09 25.35 0 7-10.71 0-14.79-18.35-4.09-25.35 0Z"
          data-name="Path 166"
        />
        <path
          fill="#f5f5f5"
          d="M61.58 287.765c-7-4.09-18.34-4.09-25.34 0a11.69 11.69 0 0 0-4.19 3.89 11.76 11.76 0 0 0 4.19 3.86c7 4.09 18.35 4.09 25.34 0a11.72 11.72 0 0 0 4.2-3.88 11.65 11.65 0 0 0-4.2-3.87Z"
          data-name="Path 167"
        />
      </g>
      <path
        fill="#bd8dbd"
        d="m25.76 212.285 16.7 11.42-15.79-6a28.78 28.78 0 0 0 3.12 12.14s10.43 5.06 16.1 10.4L32 235.875a15.32 15.32 0 0 0 .43 7.26c.09.32.19.65.29 1 .74 2.22 1.79 6.43 3.56 11.4a73.25 73.25 0 0 0 5.74 12.56 51.3 51.3 0 0 0 3.82 5.64l.26.34q3.87-2.58 8-5.05c1.6-4.1 3.37-14.06 3.69-22.14 1.21-30.93-23.23-46.89-42.31-51.47a54.219 54.219 0 0 1 10.28 16.87Z"
        data-name="Path 168"
      />
      <path
        d="m25.76 212.285 16.7 11.42-15.79-6a28.78 28.78 0 0 0 3.12 12.14s10.43 5.06 16.1 10.4L32 235.875a15.32 15.32 0 0 0 .43 7.26c.09.32.19.65.29 1 .74 2.22 1.79 6.43 3.56 11.4a73.25 73.25 0 0 0 5.74 12.56 51.3 51.3 0 0 0 3.82 5.64l.26.34q3.87-2.58 8-5.05c1.6-4.1 3.37-14.06 3.69-22.14 1.21-30.93-23.23-46.89-42.31-51.47a54.219 54.219 0 0 1 10.28 16.87Z"
        data-name="Path 169"
        opacity={0.3}
      />
      <path
        fill="#fafafa"
        d="M39.08 212.635a22.94 22.94 0 0 1 4.34 5.23 48.281 48.281 0 0 1 3.06 6.1 62.649 62.649 0 0 1 3.74 13.08 57.68 57.68 0 0 1-1.47 26.89.444.444 0 0 0 .85.26 58.35 58.35 0 0 0 1.06-27.23 62.821 62.821 0 0 0-4-13.09 47.867 47.867 0 0 0-3.16-6.07 23.369 23.369 0 0 0-4.42-5.17Z"
        data-name="Path 170"
      />
      <path
        fill="#bd8dbd"
        d="M97.47 228.085c-.38.49-6.29 5.64-15.36 21.07l-18.39 4.62 15 1.35-6.88 12-15.7 3.88 12.44 1.54s-3 12.18-11.61 17.55c-4.78 3-7.15 3.48-8.67 2.15-1.06-.94-1.52-6.71-2.58-11.84s-1.45-13.52 3-23.83a31.46 31.46 0 0 1 4.78-7.64c11.17-13.42 33.03-20.59 43.97-20.85Z"
        data-name="Path 171"
      />
      <path
        d="M97.47 228.085c-.38.49-6.29 5.64-15.36 21.07l-18.39 4.62 15 1.35-6.88 12-15.7 3.88 12.44 1.54s-3 12.18-11.61 17.55c-4.78 3-7.15 3.48-8.67 2.15-1.06-.94-1.52-6.71-2.58-11.84s-1.45-13.52 3-23.83a31.46 31.46 0 0 1 4.78-7.64c11.17-13.42 33.03-20.59 43.97-20.85Z"
        data-name="Path 172"
        opacity={0.15}
      />
      <path
        d="M52.12 261.855a48.67 48.67 0 0 0-3.17 13.47 145.8 145.8 0 0 0 .22 17.45 2.919 2.919 0 0 1-.91-.51c-1.06-.94-1.52-6.71-2.58-11.84s-1.45-13.52 3-23.83a31.46 31.46 0 0 1 4.78-7.64c11.2-13.49 33.06-20.63 44-20.89-16.68 3.39-34.29 15.26-42.7 28.59a34.19 34.19 0 0 0-2.64 5.2Z"
        data-name="Path 173"
        opacity={0.1}
      />
      <path
        fill="#fafafa"
        d="M80.86 233.995c-8.17 4-15.85 9.28-21.77 16.31a43.5 43.5 0 0 0-7.21 11.67 41.81 41.81 0 0 0-3 13.34h.09a53.429 53.429 0 0 1 3.72-13 50.07 50.07 0 0 1 6.94-11.55 57.58 57.58 0 0 1 9.76-9.41 75.9 75.9 0 0 1 11.5-7.28v-.08Z"
        data-name="Path 174"
      />
      <path
        fill="#bd8dbd"
        d="M0 239.765a33.69 33.69 0 0 1 4 0 64.209 64.209 0 0 1 15.67 2.9q2.835.885 5.66 2c.93.39 1.86.8 2.78 1.23a45 45 0 0 1 12.34 8.3 28.44 28.44 0 0 1 4.39 5.5c.16.26.33.52.48.78.39.68.73 1.36 1.07 2a38.81 38.81 0 0 1 4.56 18.87c.09 4.62-1.19 10-2 10.89a2.233 2.233 0 0 1-.38.32c-1.27.91-3.39.54-7.42-1.43-8.07-3.94-11.76-14.86-11.76-14.86l10.78-2.45-14.12-2-6.17-8.54 10.34-2.47-14.27-1.42a209.323 209.323 0 0 0-15.88-19.6Z"
        data-name="Path 175"
      />
      <path
        d="M4.05 239.735a64.209 64.209 0 0 1 15.67 2.9q2.835.885 5.66 2c.93.39 1.86.8 2.78 1.23a45 45 0 0 1 12.34 8.3 28.44 28.44 0 0 1 4.39 5.5c.16.26.33.52.48.78.39.68.73 1.36 1.07 2a38.81 38.81 0 0 1 4.51 18.9c.09 4.62-1.19 10-2 10.89a2.233 2.233 0 0 1-.38.32 2.42 2.42 0 0 1-.85.37c.57-7.2-4-25.13-9.68-32.29-6-7.45-19.73-18-38-20.85a33 33 0 0 1 4.01-.05Z"
        data-name="Path 176"
        opacity={0.05}
      />
      <path
        fill="#fafafa"
        d="M47.76 292.925a61.14 61.14 0 0 0-1.91-15.75 50.94 50.94 0 0 0-6.07-14.66 36 36 0 0 0-5.13-6.15 52.728 52.728 0 0 0-6.14-5.12 59.51 59.51 0 0 0-14.07-7.4h-.06a.06.06 0 0 0 0 .06 78.8 78.8 0 0 1 13.58 8 66.214 66.214 0 0 1 6 5 35.222 35.222 0 0 1 5.15 5.9 50.3 50.3 0 0 1 6.23 14.42 61.24 61.24 0 0 1 2.25 15.64Z"
        data-name="Path 177"
      />
    </g>
  </svg>
)
export default Error401Svg
