import { withStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "../theme/styles";

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";

function SectionHeading(props) {
  const { text, icon } = props;
  return (
    <Box p={2} className="sectionHeading">
      <List>
        <ListItem disableGutters={false} divider={true}>
          <ListItemAvatar>
            <Avatar className="primary-bg">{icon}</Avatar>
          </ListItemAvatar>
          <Typography variant="h2">{text}</Typography>
        </ListItem>
      </List>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(SectionHeading);
