import {
  Button,
  CircularProgress,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as discussionActions from "../../actions/discussion";
import * as supportActions from "../../actions/support";
import * as notificationActions from "../../actions/notification";
import * as projectActions from "../../actions/project";
import styles from "../../theme/styles";
import SupportForm from "../../forms/support/form";

import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import AccessUtil from "../../utils/projectBased/AccessUtil";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";
import CloseIcon from "@material-ui/icons/Close";
import TranslatorUtil from "../../utils/TranslatorUtil";
import SupportCompactMessageItem from "../support/SupportCompactMessageItem";
import SnackBarUtil from "../../utils/SnackBarUtil";
import firestoreApi from "../../firebase";
import * as collections from '../../constants/firebaseCollections';
import EmailUtil from "../../utils/projectBased/EmailUtil";
import CompanyUtil from "../../utils/projectBased/CompanyUtil";
import UsersUtil from "../../utils/projectBased/UsersUtil";
import objectPath from "object-path";
import DateUtil from "../../utils/DateUtil";
import NotificationUtil from "../../utils/projectBased/NotificationUtil";

class HelpMessanger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessangerOpen: false,
      loading: false,
      selectedSupportConversationId: null,
      selectedSupportConversation: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedProject } = this.props;
    const { isMessangerOpen } = this.state;
    const shallReload =
      isMessangerOpen &&
      selectedProject &&
      (prevProps.selectedProject === null ||
        (selectedProject &&
          selectedProject.id !== prevProps.selectedProject.id));
    if (shallReload) {
      this.setState({ isMessangerOpen: false });
    }
  }

  handleResponse = (response, values, conversationId) => {
    const { enqueueSnackbar, selectedProject, profiles } = this.props;
    if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
      enqueueSnackbar(TranslatorUtil.t("Message has been sent"), { variant: "success"});
      const moderatorUid = selectedProject
          ? AccessUtil.getProjectModeratorUid(selectedProject.id, profiles)
          : null;
      this.addMessageNotification(values, moderatorUid, conversationId);
    }
  };

  getUserLatestSupportConversation = () => {
      return firestoreApi
        .collection(collections.SUPPORT)
        .where("userIds", "array-contains", UsersUtil.getCurrentUserUid())
        .limit(1)
        .orderBy("createdTime", "desc")
        .get().then((newConverationResponse) => {
          const newConveration = newConverationResponse.docs && newConverationResponse.docs.length ? newConverationResponse.docs[0] : null;
          return newConveration ? {
            id: newConveration.id,
            ...newConveration.data()
          } : null;
        });
  };

  handleSubmit = (values) => {
    const { actions, selectedProject, profiles, enqueueSnackbar, supportConversations } = this.props;
    const { selectedSupportConversationId, selectedSupportConversation } = this.state;
    const userId = UsersUtil.getCurrentUserUid();
    // console.log(values, selectedSupportConversationId);
    if (selectedProject) {
      //put about message
      values.byUserId = userId;
      values.projectId = selectedProject.id;
      values.directSupportMessage = true;
      
      if (selectedSupportConversationId) {
        return actions.updateSupportConversationByNewMessage(supportConversations.find(x => x.id === selectedSupportConversation.id), values).then(() => actions
          .postSupportConversationMessage(selectedSupportConversationId, values)
          .then((response) => this.handleResponse(response, values, selectedSupportConversationId)));
      } else {
        const moderatorUid = selectedProject
          ? AccessUtil.getProjectModeratorUid(selectedProject.id, profiles)
          : null;
        if (moderatorUid) {
          return actions
            .postSupportConversation({
              userIds: [userId, moderatorUid],
            })
            .then((response) => {
              if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
                this.getUserLatestSupportConversation().then((newConveration) => {
                  if (objectPath.has(newConveration, 'id')) {
                    return actions.updateSupportConversationByNewMessage(newConveration, values).then(() => actions
                      .postSupportConversationMessage(newConveration.id, values)
                      .then((response) => {
                        this.loadProjectSupport();
                        this.handleResponse(response, values, newConveration.id);
                      }
                      ));
                  } else {
                    return SnackBarUtil.showErrorMessage(
                      enqueueSnackbar,
                      TranslatorUtil.t("Please try again later.")
                    );
                  }
                })
              } else {
                return SnackBarUtil.showErrorMessage(
                  enqueueSnackbar,
                  TranslatorUtil.t("Please try again later.")
                );
              }
            });
        } else {
          return SnackBarUtil.showErrorMessage(
            enqueueSnackbar,
            TranslatorUtil.t("Please try again later.")
          );
        }
      }
    } else {
      return Promise.resolve();
    }
  };

  addMessageNotification = async (message, toUserId, conversationId) => {
    const { actions, userInfo } = this.props;
    if(conversationId){
      const isNewNotification = await NotificationUtil.isNewNotification(conversationId);
      const notifObject = EmailUtil.getSupportNotification(
        CompanyUtil.getId(),
        UsersUtil.getCurrentUserUid(),
        UsersUtil.getUserEmailFromStoreById(toUserId),
        message,
        UsersUtil.getUserDisplayNameByUserInfo(userInfo)
        );
      if(isNewNotification){
        notifObject.createdDate = DateUtil.timestamp();
      }
      return actions.putNotification(
        conversationId,
        notifObject
      );
    } else {
      return Promise.resolve();
    }
  }

  loadProjectSupport = () => {
    const { selectedProject, actions, profiles, userInfo } = this.props;
    const moderatorUid = AccessUtil.getProjectModeratorUid(
      selectedProject.id,
      profiles
    );
    this.setState({ loading: true });
    actions
      .getSupportConversations(userInfo.firebase.uid)
      .then((response) => {
        if (response && response.data) {
          // console.log("response", response.data);
          // console.log("moderatorUid", moderatorUid);
          const currentModeratorConversation = response.data.find(
            (x) =>
              x &&
              x.userIds &&
              Array.isArray(x.userIds) &&
              x.userIds.includes(moderatorUid)
          );
          if (currentModeratorConversation) {
            this.setState({
              selectedSupportConversation: currentModeratorConversation,
              selectedSupportConversationId: currentModeratorConversation.id,
              selectedSupportConversationListener: actions.listenSupportConversationMessages(
                currentModeratorConversation.id
              ),
            });
          } else {
            this.clearSupportChat();
          }
        } else {
          this.clearSupportChat();
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    this.setState({ isMessangerOpen: true });
  };

  clearSupportChat = () => {
    this.props.actions.clearSupportConversationMessages();
    // if(selectedSupportConversationListener){
    //   console.log('clearSupportChat',selectedSupportConversationListener);
    //   // selectedSupportConversationListener.unsubscribe();
    // }
    this.setState({
      selectedSupportConversation: null,
      selectedSupportConversationId: null,
      selectedSupportConversationListener: null,
    });
  };

  render() {
    const {
      isMessangerOpen,
      loading,
      selectedSupportConversationId,
    } = this.state;
    const {
      selectedProject,
      history,
      profiles,
      // supportConversations,
      selectedSupportConversationMessages,
    } = this.props;
    const isModerator = AccessUtil.isAtLeastModerator();
    const projectModeratorUid = selectedProject
      ? AccessUtil.getProjectModeratorUid(selectedProject.id, profiles)
      : null;
    if (!projectModeratorUid && !isModerator) {
      return null;
    }
    // console.log(supportConversations);
    // console.log(selectedSupportConversationMessages);

    return (
      <div
        id="helpMessanger"
        className={isModerator ? "moderator" : "notModerator"}
      >
        {isMessangerOpen ? (
          <div id="helpMessangerAskWrap">
            <div className="header">
              <div>
                <Typography variant="body2">
                  {TranslatorUtil.t("Help")}
                </Typography>
              </div>
              <div>
                <IconButton
                  size="small"
                  aria-label="close"
                  onClick={() => this.setState({ isMessangerOpen: false })}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
            <div className="messagesWrap">
              {loading ? (
                <CircularProgress />
              ) : selectedSupportConversationId &&
                selectedSupportConversationMessages &&
                selectedSupportConversationMessages.length > 0 ? (
                selectedSupportConversationMessages.map((x, index) => (
                  <SupportCompactMessageItem
                    key={index}
                    message={x}
                    currentUserId={UsersUtil.getCurrentUserUid()}
                  />
                ))
              ) : (
                <small>{TranslatorUtil.t("Ask project moderator")}</small>
              )}
            </div>
            <div className="footer">
              <SupportForm
                proceedSubmit={this.handleSubmit}
                initialValues={null}
              />
            </div>
          </div>
        ) : (
          <>
            <Hidden xsDown>
              {isModerator ? (
                <Button
                  size="large"
                  onClick={() => history.push(UrlBuilderUtil.getSupport())}
                  variant="contained"
                  color="primary"
                  className="openButton mobile"
                  startIcon={<ContactSupportIcon />}
                >
                  {TranslatorUtil.t("Support")}
                </Button>
              ) : (
                <Button
                  size="large"
                  onClick={() => this.loadProjectSupport()}
                  variant="contained"
                  color="primary"
                  className="openButton mobile"
                  startIcon={<ContactSupportIcon />}
                >
                  {TranslatorUtil.t("Help")}
                </Button>
              )}
            </Hidden>
            <Hidden smUp>
              <IconButton
                aria-label="Help"
                className="openButton desktop"
                onClick={() =>
                  isModerator
                    ? history.push(UrlBuilderUtil.getSupport())
                    : this.loadProjectSupport()
                }
              >
                <ContactSupportIcon fontSize="large" />
              </IconButton>
            </Hidden>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedProject: state.selectedProject,
  userInfo: state.userInfo,
  messages: state.messages,
  profiles: state.profiles,
  discussionLikes: state.discussionLikes,
  supportConversations: state.supportConversations,
  selectedSupportConversationMessages:
    state.selectedSupportConversationMessages,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...supportActions,
      ...projectActions,
      ...discussionActions,
      ...notificationActions,
    },
    dispatch
  ),
});

export default withRouter(
  withSnackbar(
    withStyles(styles, { withTheme: true })(
      connect(mapStateToProps, mapDispatchToProps)(HelpMessanger)
    )
  )
);
