import * as types from "../constants/actionTypes";

function ajaxCallError(error, actionName = null, type = types.AJAX_CALL_ERROR) { 
  return {
    type,
    data: error,
    actionName
  };
}

function ajaxCallSuccess(response, type) {
  // Preprocess before sending to
  return {
    type,
    data: response
  };
}


export {
  ajaxCallError,
  ajaxCallSuccess
}