
export default (theme, themeConfig, themeColors, themeFonts) => {
    return {
appFooter:{
    display: 'block',
    backgroundColor: themeColors.primaryPalette.primary,
    color: themeColors.primaryPalette.white,
    minHeight: themeConfig.toolbarMinHeight,
    position: 'fixed',
    zIndex: 1201,
    [theme.breakpoints.down("md")]: {
      position: 'absolute',
      zIndex: 1,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 0
    },
    bottom: 0,
    left: 0,
    right: 0,
    
    fontFamily: themeFonts.headingFont,
    paddingLeft: themeConfig.commonPadding*1.5,
    paddingRight: themeConfig.commonPadding*1.5,
    fontSize: 12,
    '& > div > div': {
      paddingTop: themeConfig.commonPadding*1.5,
      [theme.breakpoints.down("xs")]: {
        paddingTop: themeConfig.commonPadding,
      },
      paddingBottom: themeConfig.commonPadding,
    }
  },
  appFooterImgWrap: {
    textAlign: 'right',
    paddingRight: themeConfig.commonPadding*6,
    [theme.breakpoints.down("xs")]: {
      textAlign: 'left'
    }
  },

  appFooterImg: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 50
    }
  },
}
}