import * as types from '../constants/actionTypes';

function setContentSubmenuLevel(state = 0, action) {
    if (action.type === types.SET_CONTENT_SUBMENU_LEVEL) {
        return action.level;
    } 
    return state;
}

export {
    setContentSubmenuLevel
}
