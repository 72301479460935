import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Typography, List, ListItem, ListItemAvatar, Paper, ListItemText } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import UserAvatar from '../user/UserAvatar';
import ArrayUtil from '../../utils/ArrayUtil';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';


class CommmentsList extends React.Component {


    renderListText = (comment) =>{
        const { classes } = this.props;
        // <Paper elevation={1} className={"gridItem"}></Paper>
        return <ListItemText
              secondary={
                <Paper elevation={1} className={"gridItem"} component="span" style={{display:"block"}}>
                  <Typography component="span" className={classes.inline} color="textPrimary">
                    
                    {comment.comment}
                  </Typography>
                  <small className="messageDate">{DateUtil.displayTimeFromNow(comment.createdTime, TranslatorUtil.getUserLangFromStore())}</small>
                  </Paper>
              }
            />
      }
    
      renderAvatar = (comment) =>{
        return <ListItemAvatar>
          <UserAvatar userId={comment.byUserId} 
          menuItems={{
            withChat: AccessUtil.isAtLeastObserver()
          }} />
      </ListItemAvatar>
      }


  render () {
  const { items } = this.props;

  return items && items.length ? (
    <List className={"narrowMaxMediumWidthPart"}>
          
        {items ? ArrayUtil.sortByDateCreated(items).map((comment, index) => 
          <ListItem alignItems="flex-start" key={index} disableGutters={true}>
            
            {this.renderAvatar(comment)}
          {this.renderListText(comment)}
          
        </ListItem> 
          
        ) : null}
        </List>
  ) : null;
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(CommmentsList));