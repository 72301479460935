import { fade } from '@material-ui/core/styles/colorManipulator';


export default (theme, themeConfig, themeColors) => {
    const chatFormHeight = "30svh";
    // const chatFormMinHeight = "200px";
    return {
  
        conversationsWrap: {
            position: "fixed",
            top: 0,
            bottom: 0,
            left: themeConfig.drawerWidthLevel_1,
            width: themeConfig.drawerWidthLevel_1,
            zIndex: 2,
            backgroundColor: themeColors.primaryPalette.white,
            overflowY: "auto",
            "& .active":{
                backgroundColor: themeColors.primaryPalette.lightGrey,
                position: "relative",
                left: 0,
                right: -1
            }
        },
        conversationWrap: {
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            
            padding: themeConfig.commonPadding,
            paddingBottom: themeConfig.drawerWidthLevel_1*2,
            left: themeConfig.drawerWidthLevel_1*2,
        },
        conversationInnerWrap: {
            
        },
        chatMessagesWrap: {
            position: "absolute",
            padding: themeConfig.commonPadding,
            top: 0,
            bottom: chatFormHeight,
            left: 0,
            right: 0,
            backgroundColor: fade(themeColors.primaryPalette.grey, 0.1),
            overflowY: "auto"
        },
        supportMessagesWrap: {
            position: "absolute",
            padding: themeConfig.commonPadding,
            top: 0,
            bottom: chatFormHeight,
            left: 0,
            right: 0,
            backgroundColor: fade(themeColors.primaryPalette.grey, 0.1),
            overflowY: "auto"
        },
        chatFormWrap: {
            borderLeft: "1px solid "+ themeColors.primaryPalette.grey,
            position: "absolute",
            paddingLeft: themeConfig.commonPadding,
            paddingRight: themeConfig.commonPadding,
            maxHeight: chatFormHeight,
            height: "100%",
            bottom: 0,
            left: 0,
            right: 0,
        },
        supportFormWrap: {
            borderLeft: "1px solid "+ themeColors.primaryPalette.grey,
            position: "absolute",
            paddingLeft: themeConfig.commonPadding,
            paddingRight: themeConfig.commonPadding,
            maxHeight: chatFormHeight,
            height: "100%",
            bottom: 0,
            left: 0,
            right: 0,
        }
    }
  }