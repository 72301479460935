
import ValidationUtil from '../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "displayName",
    "avatar"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);

  errors = ValidationUtil.checkStringMaxLength(['displayName'],values,errors,50);

  return errors;
};

export default validate;
