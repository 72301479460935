import * as types from '../constants/actionTypes';


export function getChatConversations(state = [], action) {
    if (action.type === types.GET_CHAT_CONVERSATIONS) {
        console.log(types.GET_CHAT_CONVERSATIONS+' set in store');
        return action.data;
    }
    return state;
}

export function getChatConversationMessages(state = [], action) {
    if (action.type === types.GET_CHAT_CONVERSATION_MESSAGES) {
        console.log(types.GET_CHAT_CONVERSATION_MESSAGES+' set in store');
        return action.data;
    }
    return state;
}


export function getChatConversation(state = null, action) {
    if (action.type === types.GET_CHAT_CONVERSATION) {
        console.log(types.GET_CHAT_CONVERSATION+' set in store');
        return action.data;
    }
    return state;
}



