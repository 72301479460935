import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import DashboardIcon from '@material-ui/icons/Dashboard';

import styles from "../../../theme/styles";
import Logo from "../../Logo";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import { Badge } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import ChatIcon from '@material-ui/icons/Chat';
import BusinessIcon from '@material-ui/icons/Business';
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import classnames from 'classnames';

class MobileNavBar extends React.Component {

  renderDashboardMenuItem = (notifications) => {
    var unreadCount = notifications.filter(x => !x.read).length;

      return <NavLink to={'/dashboard/'} className="mobile-navbar-link">{
        unreadCount > 0 ? <Badge className="badge zoominout-badge" badgeContent={unreadCount} color="error"><DashboardIcon color="primary" /></Badge> : <DashboardIcon color="primary" />
      }</NavLink>;
    
  }

  renderChatMenuItem = () => {
      const { selectedProject } = this.props;
      return selectedProject && AccessUtil.isAtLeastModeratorForProject(selectedProject.id) ? <NavLink className="mobile-navbar-link" to={UrlBuilderUtil.getChat(selectedProject.id)}><ChatIcon color="primary" /></NavLink>: null;
    
  }

  renderProjectMenuItem = () => {
    const { selectedProject } = this.props;
    return selectedProject && AccessUtil.isAtLeastRespondentForProject(selectedProject.id) ? <NavLink className="mobile-navbar-link" to={UrlBuilderUtil.getProjectDashboard(selectedProject.id)}><BusinessIcon color="primary" /></NavLink>: null;
}

  render() {
    const { classes, handleDrawerOpen, handleDrawerClose, drawerOpen, notifications } = this.props;

    

    return (
      <AppBar
        position="fixed"
        className={classnames(classes.appBar)+" mobileAppBar"}
      >
        <Toolbar >
      <Logo inNavbar={true} showAppName={true} />
      <div className={classes.menuButton}>
      {this.renderProjectMenuItem()}
      {this.renderChatMenuItem()}
      {this.renderDashboardMenuItem(notifications)}
      <IconButton
            color="inherit"
            aria-label={TranslatorUtil.t("Open drawer")}
            onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton> 
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

MobileNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MobileNavBar);
