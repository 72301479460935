import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';

import { Typography, Grid, Hidden } from '@material-ui/core';

function PageHeading(props) {

  const { classes, heading, mutedHeading, actions, variantClass } = props;


  const getHeadingContent = () => {
    var output = [];
    if(mutedHeading){
      output.push(<Typography key="mutedHeading" variant="h1" className="muted">{ mutedHeading }</Typography>);
      output.push(<Hidden key="lineBreak1" mdUp><br /></Hidden>);
    }
    output.push(<Typography key="mainHeading" variant="h1">{ heading }</Typography>);

    return output;   
  }

  return (
    <Paper className={classnames(classes.pageHeading,"pageHeading fullWidthBgComponent",variantClass)} square elevation={0}>
    <Grid container>
        { actions ? <Grid item xs={12} sm={9} md={7} lg={6}>
            { getHeadingContent() }
        </Grid> : <Grid item xs={12}>
            { getHeadingContent() }
        </Grid> }


        { actions ? <Grid item xs={12} sm={3} md={5} lg={6} className={classnames("hideForPrint",classes.pageHeadingActions)}>
            {actions}
        </Grid> : null }
    </Grid>
        
        
    </Paper>
  );
}


export default withStyles(styles, { withTheme: true })(PageHeading);