import store from '../store';
import objectPath from 'object-path'

export default class InfoTextUtil {

  static getInfoTextByInputName(inputName, helpText){
    let storeInfoText = store ? objectPath.get(store.getState(), 'infoText') : {};
    return storeInfoText.hasOwnProperty(inputName) ? storeInfoText[inputName] : helpText;
  }
}
