import objectPath from "object-path";
import UrlBuilderUtil from "../utils/projectBased/UrlBuilderUtil";
import TranslatorUtil from "../utils/TranslatorUtil";

export const MAIL_TYPES_IDS = {
    INVITATION : 1,
    INVITATION_ACCEPTED : 2,
    REGISTRATION : 3,
    DISCUSSION_ADDED : 4,
    DISCUSSION_REACTION : 5,
    RESPONDENT_PROFILE_UPDATE : 6,
    CUSTOM_PROJECT_NOTIFICATION : 7,
    CHAT_NOTIFICATION : 8,
    DISCUSSION_WILL_LOCK : 9, // will-lock function
    PROJECT_WILL_LOCK : 10, // will-lock function
    DISCUSSION_MODERATOR_REACTION : 11,
    SUPPORT_NOTIFICATION: 12,
    RESPONDENT_FIRST_MESSAGE_IN_DISCUSSION_NOTIFICATION: 13,

};

export const MAIL_TYPES = {
    INVITATION: {
        ID: MAIL_TYPES_IDS.INVITATION,
        BODY : (companyId, project) => {
            return TranslatorUtil.t("name: {:projectName} description: {:projectDescription}", {
                projectName: objectPath.get(project,'name',''),
                projectDescription: objectPath.get(project,'description',''),
                projectUrl: UrlBuilderUtil.createInvitationLink(companyId, objectPath.get(project,'id',0)),
                // roleType: TranslatorUtil.t(roleType)
            });
        },
        BODY_HTML : (companyId, project) => {
            return TranslatorUtil.t("name: {:projectName}<br />description: {:projectDescription}", {
                projectName: objectPath.get(project,'name',''),
                projectDescription: objectPath.get(project,'description',''),
                projectUrl: UrlBuilderUtil.createInvitationLink(companyId, objectPath.get(project,'id',0)),
                // roleType: TranslatorUtil.t(roleType)
            });
        },
        SUBJECT : (project) => {
            return TranslatorUtil.t("Project invitation {:projectName}", {
                projectName: objectPath.get(project,'name','')
            });
        },
        ACTION_URL : (companyId, project) => {
            return UrlBuilderUtil.createInvitationLink(companyId, objectPath.get(project,'id',0), null)
        }
    },

    INVITATION_ACCEPTED: {
        ID: MAIL_TYPES_IDS.INVITATION_ACCEPTED,
        BODY : (project, email, roleType) => {
            return TranslatorUtil.t("name: {:projectName} email: {:email} role: {:roleType}", {
                projectName: objectPath.get(project,'name',''),
                email: email,
                roleType: TranslatorUtil.t(roleType)
            });
        },
        BODY_HTML : (project,email,roleType) => {
            return TranslatorUtil.tb("name: {:projectName}<br />invitation accepted by: {:email}<br />role: {:roleType}", {
                projectName: objectPath.get(project,'name',''),
                email: email,
                projectUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getProjectPreview(objectPath.get(project,'id',0)),
                roleType: TranslatorUtil.t(roleType)
            });
        },
        SUBJECT : (project,roleType) => {
            return TranslatorUtil.t("Invitation accepted - {:projectName}", {
                projectName: objectPath.get(project,'name','')
            });
        },
        ACTION_URL : (project,email,roleType) => {
            return UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getProjectPreview(objectPath.get(project,'id',0));
        }
    },

    REGISTRATION: {
        ID: MAIL_TYPES_IDS.REGISTRATION,
        BODY : (email) => {
            return TranslatorUtil.t("Profile registration : {:email}", {
                email: email,
                profileUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getProfileEdit()
            });
        },
        BODY_HTML : (email) => {
            return TranslatorUtil.tb("Profile registration : {:email}", {
                email: email,
                profileUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getProfileEdit()
            });
        },
        SUBJECT : () => {
            return TranslatorUtil.t("Profile registration");
        },
        ACTION_URL : () => {
            return UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getProfileEdit();
        }
    },

    DISCUSSION_ADDED: {
        ID: MAIL_TYPES_IDS.DISCUSSION_ADDED,
        BODY : (project,discussion) => {
            return TranslatorUtil.t("Discussion {:discussionName} has been added to {:projectName}", {
                discussionName: objectPath.get(discussion,'name',''),
                discussionIntroduction: objectPath.get(discussion,'description',''),
                projectName: objectPath.get(project,'name',''),
                // projectUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getProjectDashboard(objectPath.get(project,'id',0)),
            });
        },
        BODY_HTML : (project,discussion) => {
            return TranslatorUtil.tb("Discussion {:discussionName} has been added to {:projectName} {:discussionIntroduction}", {
                discussionName: objectPath.get(discussion,'name',''),
                discussionIntroduction: objectPath.get(discussion,'description',''),
                projectName: objectPath.get(project,'name','')
            });
        },
        SUBJECT : (project,discussion) => {
            return TranslatorUtil.t("Discussion {:discussionName} has been added",{
                discussionName: objectPath.get(discussion,'name','')
            });
        },
        ACTION_URL : (companyId, project, discussion) => {
            return UrlBuilderUtil.createInvitationLink(companyId, objectPath.get(project,'id',null), objectPath.get(discussion,'id',null));
        }
    },

    DISCUSSION_REACTION: {
        ID: MAIL_TYPES_IDS.DISCUSSION_REACTION,
        BODY : (project,discussion,message, reply) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return TranslatorUtil.t("Your message {:messageText} in {:discussionName} has new reaction {:replyText}", {
                discussionName: objectPath.get(discussion,'name',''),
                messageText: objectPath.get(message,'message',''),
                replyText: objectPath.get(reply,'message',''),
                discussionUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId),
            });
        },
        BODY_HTML : (project,discussion,message, reply) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return TranslatorUtil.tb("Your message {:messageText} in {:discussionName} has new reaction {:replyText}", {
                discussionName: objectPath.get(discussion,'name',''),
                messageText: objectPath.get(message,'message',''),
                replyText: objectPath.get(reply,'message',''),
                discussionUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId),
            });
        },
        SUBJECT : (project,discussion, message, reply) => {
            return TranslatorUtil.t("New reaction {:discussionName} has been added",{
                discussionName: objectPath.get(discussion,'name','')
            });
        },
        ACTION_URL : (project,discussion,message, reply) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId);
        }
    },


    RESPONDENT_PROFILE_UPDATE: {
        ID: MAIL_TYPES_IDS.RESPONDENT_PROFILE_UPDATE,
        BODY : (profile) => {
            return TranslatorUtil.t("Profile updated by user {:profileEmail}", {
                profileEmail: objectPath.get(profile,'email',''),
            });
        },
        BODY_HTML : (profile) => {
            return TranslatorUtil.tb("Profile updated by user {:profileEmail}", {
                profileEmail: objectPath.get(profile,'email',''),
            });
        },
        SUBJECT : (profile) => {
            return TranslatorUtil.t("Profile updated by respondent");
        },
        ACTION_URL : () => {
            return null;
        }
    },

    CUSTOM_PROJECT_NOTIFICATION: {
        ID: MAIL_TYPES_IDS.CUSTOM_PROJECT_NOTIFICATION,
        ACTION_URL : (projectId) => {
            return UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getProjectDashboard(projectId);
        }
    },


    CHAT_NOTIFICATION: {
        ID: MAIL_TYPES_IDS.CHAT_NOTIFICATION,
        BODY : (message,fromName) => {
            return TranslatorUtil.t("New message from user {:fromName} - {:message}", {
                fromName: fromName,
                message: message.message
            });
        },
        BODY_HTML : (message,fromName) => {
            return TranslatorUtil.tb("New message from user {:fromName} - {:message}", {
                fromName: fromName,
                message:message.message
            });
        },
        SUBJECT : () => {
            return TranslatorUtil.t("New message");
        },
        ACTION_URL : (conversationId, projectId) => {
            return UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getChatById(conversationId,projectId);
        }
    },

    DISCUSSION_MODERATOR_REACTION: {
        ID: MAIL_TYPES_IDS.DISCUSSION_MODERATOR_REACTION,
        BODY : (project,discussion,message) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return TranslatorUtil.t("Moderator added message {:messageText} in {:discussionName}", {
                discussionName: objectPath.get(discussion,'name',''),
                messageText: objectPath.get(message,'message',''),
                discussionUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId),
            });
        },
        BODY_HTML : (project,discussion,message) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return TranslatorUtil.tb("Moderator added message <p><strong>{:messageText}</strong></p> in {:discussionName}", {
                discussionName: objectPath.get(discussion,'name',''),
                messageText: objectPath.get(message,'message',''),
                discussionUrl: UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId),
            });
        },
        SUBJECT : (project,discussion, message) => {
            return TranslatorUtil.t("Moderator added message in {:discussionName}",{
                discussionName: objectPath.get(discussion,'name','')
            });
        },
        ACTION_URL : (project,discussion,message) => {
            var projectId = objectPath.get(project,'id',0);
            var discussionId = objectPath.get(discussion,'id',0);
            var messageId = objectPath.get(message,'id',0);
            return UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getDiscussionConversation(projectId,discussionId,messageId);
        }
    },

    SUPPORT_NOTIFICATION: {
        ID: MAIL_TYPES_IDS.SUPPORT_NOTIFICATION,
        BODY : (message,fromName) => {
            return TranslatorUtil.t("New support message from user {:fromName} - {:message}", {
                fromName: fromName,
                message: message.message
            });
        },
        BODY_HTML : (message,fromName) => {
            return TranslatorUtil.tb("New support message from user {:fromName} - {:message}", {
                fromName: fromName,
                message:message.message
            });
        },
        SUBJECT : (message,fromName) => {
            return TranslatorUtil.t("New support message");
        },
        ACTION_URL : (toUserId) => {
            return UrlBuilderUtil.getRequestedHostUrl()+UrlBuilderUtil.getSupport(toUserId);
        }
    },

};
