
export default (theme, themeConfig, themeColors, themeFonts) => {
    return {

pageHeading: {
    '& h1':{
      display: 'inline-block',
      [theme.breakpoints.down("md")]: {
        fontSize: 20
      },
    },
    '& h1.muted':{
      paddingRight: theme.spacing(1)*4
    },
    '&':{
      backgroundColor: themeColors.primaryPalette.primary,
      '& h1, & button':{
        color: themeColors.primaryPalette.white,
      },
    },
    '&.pageHeading.fullWidthBgComponent':{
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(1)+5,
        paddingBottom: theme.spacing(1)+5,
        minHeight: '65px',
        '& h1':{
          paddingTop: theme.spacing(1)/2,
        },
      }
    }
  },
  pageHeadingActions: {
    textAlign: 'right'
  },
}
}