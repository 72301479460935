import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { List, ListItem } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import ArrayUtil from '../../utils/ArrayUtil';
import SupportMessageItem from './SupportMessageItem';


class SupportMessagesList extends React.Component {




  render () {
  const { items, currentUserId, showDialogMessagePreview } = this.props;

  return items && items.length ? (
    <List className={"narrowMaxMediumWidthPart"}>
          
        {items ? ArrayUtil.sortByDateCreated(items).map((message, index) => 
          <ListItem alignItems="flex-start" key={index} disableGutters={true} className="chatMessageWrap">
            <SupportMessageItem currentUserId={currentUserId} message={message} showDialogMessagePreview={showDialogMessagePreview} />
        </ListItem> 
        ) : null}
        </List>
  ) : null;
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(SupportMessagesList));