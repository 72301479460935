// import objectPath from 'object-path';
// import StoreUtil from '../StoreUtil';
// import * as collections from '../../constants/firebaseCollections';
import { MAIL_TYPES } from '../../constants/mails';
import FormatterUtil from '../FormatterUtil';

export default class EmailUtil {

    static getInvitationEmailObject(companyId,project,to,roleType){
      let mailType = MAIL_TYPES.INVITATION;
        return {
            companyId: companyId,
            projectId: project.id,
            to: to,
            type: mailType.ID,
            roleType: roleType,
            actionURL: mailType.ACTION_URL(companyId,project,roleType),
            message: {
              subject: mailType.SUBJECT(project,roleType),
              text: mailType.BODY(companyId,project,roleType),
              html: mailType.BODY_HTML(companyId,project,roleType)
            }
          };
      }

      static getInvitationAcceptedEmailObject(companyId,project,to,email,roleType){
        let mailType = MAIL_TYPES.INVITATION_ACCEPTED;
        return {
            companyId: companyId,
            projectId: project.id,
            to: to,
            type: mailType.ID,
            roleType: roleType,
            actionURL: mailType.ACTION_URL(project,email,roleType),
            message: {
              subject: mailType.SUBJECT(project,roleType),
              text: mailType.BODY(project,email,roleType),
              html: mailType.BODY_HTML(project,email,roleType)
            }
          };
      }

      static getRegisterationNotificationEmailObject(email){
        let mailType = MAIL_TYPES.REGISTRATION;
        return {
            to: email,
            type: mailType.ID,
            actionURL: mailType.ACTION_URL(email),
            message: {
              subject: mailType.SUBJECT(),
              text: mailType.BODY(email),
              html: mailType.BODY_HTML(email)
            }
          };
      }

      static getDiscussionAddedEmailObject(companyId,project,discussion,to){
        let mailType = MAIL_TYPES.DISCUSSION_ADDED;
        return {
          companyId: companyId,
          projectId: project.id,
          to: to,
          type: mailType.ID,
          actionURL: mailType.ACTION_URL(companyId,project,discussion),
          message: {
            subject: mailType.SUBJECT(project,discussion),
            text: mailType.BODY(project,discussion),
            html: mailType.BODY_HTML(project,discussion)
          }
        };
      }


      static getDiscussionReactionEmailObject(companyId,project,discussion,message,reply,to){
        let mailType = MAIL_TYPES.DISCUSSION_REACTION;
        return {
          companyId: companyId,
          projectId: project.id,
          to: to,
          type: mailType.ID,
          actionURL: mailType.ACTION_URL(project,discussion,message,reply),
          message: {
            subject: mailType.SUBJECT(project,discussion,message,reply),
            text: mailType.BODY(project,discussion,message,reply),
            html: mailType.BODY_HTML(project,discussion,message,reply)
          }
        };
      }

      static getDiscussionModeratorNoReplyMessageEmailObject(companyId,project,discussion,message,to){
        let mailType = MAIL_TYPES.DISCUSSION_MODERATOR_REACTION;
        return {
          companyId: companyId,
          projectId: project.id,
          to: to,
          type: mailType.ID,
          actionURL: mailType.ACTION_URL(project,discussion,message),
          message: {
            subject: mailType.SUBJECT(project,discussion,message),
            text: mailType.BODY(project,discussion,message),
            html: mailType.BODY_HTML(project,discussion,message)
          }
        };
      }


      static getRespondentProfileUpdated(companyId,profile,to){
        let mailType = MAIL_TYPES.RESPONDENT_PROFILE_UPDATE;
        return {
          companyId: companyId,
          to: to,
          type: mailType.ID,
          actionURL: mailType.ACTION_URL(profile),
          message: {
            subject: mailType.SUBJECT(profile),
            text: mailType.BODY(profile),
            html: mailType.BODY_HTML(profile)
          }
        };
      }


      static getCustomProjectNotification(companyId,projectId,to,subject,message){
        let mailType = MAIL_TYPES.CUSTOM_PROJECT_NOTIFICATION;
        return {
          companyId: companyId,
          projectId: projectId,
          to: to,
          type: mailType.ID,
          actionURL: mailType.ACTION_URL(projectId),
          message: {
            subject: subject,
            text: FormatterUtil.stripHtml(message),
            html: message
          }
        };
      }


      static getChatNotification(companyId, to, message, fromName, projectId, conversationId, aboutMessageText){
        let mailType = MAIL_TYPES.CHAT_NOTIFICATION;
        return {
          companyId: companyId,
          to: to,
          type: mailType.ID,
          actionURL: mailType.ACTION_URL(conversationId, projectId),
          message: {
            subject: mailType.SUBJECT(),
            text: mailType.BODY(message,fromName,aboutMessageText),
            html: mailType.BODY_HTML(message,fromName,aboutMessageText)
          }
        };
      }

      static getSupportNotification(companyId, toUserId, to, message, fromName){
        let mailType = MAIL_TYPES.SUPPORT_NOTIFICATION;
        return {
          companyId: companyId,
          to: to,
          type: mailType.ID,
          actionURL: mailType.ACTION_URL(toUserId),
          message: {
            subject: mailType.SUBJECT(),
            text: mailType.BODY(message,fromName),
            html: mailType.BODY_HTML(message,fromName)
          }
        };
      }

}
