import React from "react";
import * as colors from '@material-ui/core/colors';
import { Avatar } from "@material-ui/core";

const values = [
    colors.blue[500],
    colors.orange[500],
    colors.purple[500],
    colors.green[500],
    colors.red[500],
    colors.pink[500],
    colors.indigo[500],
    colors.teal[500],
    colors.cyan[500],
    colors.lightGreen[500],
    colors.lime[500],
    colors.amber[500],
    colors.yellow[500],
    colors.grey[500],
    colors.blueGrey[500],
    colors.brown[500]
];

export default values.map(function (color) {return {
    "value": color, "label": <Avatar style={{backgroundColor:color}}>{" "}</Avatar>
}});