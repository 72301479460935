import React from "react";

import { Box } from "@material-ui/core";
import Logo from "../../../components/Logo";
import PageHeading from "../../../components/PageHeading";
import UserLoginWrap from "../../../components/user/UserLoginWrap";
import TranslatorUtil from "../../../utils/TranslatorUtil";


class Login extends React.Component {





  render() {
    

    return (
      <div>

      <div><Box px={1} className="logoOuterWrap narrowMaxMediumWidthPart">
      <div className="logoWrap">
        <Logo showAppName={true} />
      </div>
      <PageHeading heading={TranslatorUtil.t('Login')} />
      </Box>
      <UserLoginWrap /></div>
      </div>
    );
  }
}


export default Login;