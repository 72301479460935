import React from "react";
import { withStyles } from "@material-ui/core/styles";
import UsersUtil from "../../utils/projectBased/UsersUtil";
import styles from "../../theme/styles";
import { withRouter } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import TodayIcon from '@material-ui/icons/Today';
import WcIcon from '@material-ui/icons/Wc';
import DescriptionIcon from '@material-ui/icons/Description';
import { Paper, List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
// import ChatIcon from '@material-ui/icons/Chat';
// import LanguageIcon from '@material-ui/icons/Language';
import TranslatorUtil from "../../utils/TranslatorUtil";
import UrlBuilderUtil from "../../utils/projectBased/UrlBuilderUtil";
import UserTags from "./UserTags";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccessUtil from "../../utils/projectBased/AccessUtil";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

class UserInfo extends React.Component {

    render() {
        const { userId, withDialog, history, closeDialog, selectedProject } = this.props;

        const profile = UsersUtil.getUserProfileByUserId(userId);
        const tags = UsersUtil.getUserTagsByUserId(userId);
        const projectID = selectedProject ? selectedProject.id : null;

        return  profile ? (
            <Paper elevation={3} >
            <List>
        <ListItem>
          <ListItemIcon>
            <UserAvatar userId={userId} 
            menuItems={{
              withDialog: withDialog
            }} />
          </ListItemIcon>
          <ListItemText primary={UsersUtil.getUserDisplayNameById(userId)} />
        </ListItem>
        {(projectID && AccessUtil.isAtLeastObserverForProject(projectID, false)) ? <React.Fragment><Divider /><ListItem>
            <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary={TranslatorUtil.t(AccessUtil.getUserRoleForProject(projectID, profile.email))} />
          </ListItem></React.Fragment> : null}
      </List>
      <Divider />
      <List>
      
        <ListItem>
            <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={profile.email} />
        </ListItem>
        
        {
          profile.phone ? <ListItem>
            <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={profile.phone} />
          </ListItem> : null
        }
        {
          profile.age ? <ListItem>
            <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary={profile.age} />
          </ListItem> : null
        }
        {
          profile.gender ? <ListItem>
            <ListItemIcon>
            <WcIcon />
          </ListItemIcon>
          <ListItemText primary={TranslatorUtil.t(profile.gender)} />
          </ListItem> : null
        }
        {
          profile.description && profile.description.length ? <ListItem>
          <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary={profile.description } />
      </ListItem> : null

        }
        {/* <ListItem>
            <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={profile.langCode } />
        </ListItem> */}
      </List>


      
      <Divider />
      <List>
        <ListItem>
            <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary={<UserTags user={profile} tags={tags} />} />
        </ListItem>
      </List>
      
      
      {/* {projectID && AccessUtil.isAtLeastModeratorForProject(projectID, false) &&
      <>
        <Divider />
        <List>
          <ListItem onClick={() => {
            closeDialog();
            history.push(UrlBuilderUtil.getChat(userId));
          }}>
              <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary={TranslatorUtil.t("Open chat")} />
          </ListItem>
        </List>
      </>} */}
      {AccessUtil.isAtLeastModeratorForProject(projectID, false) &&
      <>
        <Divider />
        <List>
          <ListItem onClick={() => {
            closeDialog();
            history.push(UrlBuilderUtil.getSupport(userId));
          }}>
              <ListItemIcon>
              <ContactSupportIcon />
            </ListItemIcon>
            <ListItemText primary={TranslatorUtil.t("Support")} />
          </ListItem>
        </List>
      </>}
      </Paper>
          ): null;
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(UserInfo));


