import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import * as formNames from "../../constants/forms";
import * as constants from '../../constants/constants';
import {
  TextInput
} from "../../components/inputs";
import CloseIcon from '@material-ui/icons/Close';

import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormGroup from "@material-ui/core/FormGroup";
import { Button, Grid, IconButton, List, ListItem, Tooltip } from "@material-ui/core";
import warnAboutUnsavedForm from "../../hoc/withWarnAboutUnsavedChanges";
import TranslatorUtil from "../../utils/TranslatorUtil";
import MessageItem from "../../components/messages/MessageItem";

const ConversationForm = props => {
  const {
    handleSubmit,
    //pristine,
    submitting,
    classes,
    proceedSubmit,
    reset, 
    replyToMessage,
    removeReplyTo,
    likes,
    initialValues,
    cancelEdit
  } = props;
  
  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><ArrowForwardIcon /></Fab>;



  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values).then(() => reset(formNames.DISCUSSION_CONVERSATION_FORM_NAME)))} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container>
          
          {initialValues && <Grid item className="gridItem" xs={12}><Button onClick={() => {
            reset(formNames.DISCUSSION_CONVERSATION_FORM_NAME);
            cancelEdit();
            
          }} size="small" startIcon={<CloseIcon color="error" />}>{TranslatorUtil.t("Cancel editing of message")}</Button></Grid> }
            
          {replyToMessage ?   <Grid item className="gridItem" xs={12}><List>
            <ListItem disableGutters={true}>{TranslatorUtil.t("Reply to message")} <IconButton onClick={() => removeReplyTo()} size="small"><CloseIcon color="error" /></IconButton> </ListItem>
            <MessageItem message={replyToMessage} likes={likes} disableAvatarActions={true} disableReactions={true} /> </List></Grid> : null}
            
            <Grid item className="gridItem" xs={10} md={10}>
              <FormGroup>  
              <Field name="message" component={TextInput} multiline rowsMax="4" label={TranslatorUtil.t("Message")} />
              </FormGroup>
              
            </Grid>


            
            
            <Grid item className={classnames("gridItem",classes.textRightStrong)}  xs={2} md={2}>
              { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("OK")}>{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
};


export default warnAboutUnsavedForm(withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.DISCUSSION_CONVERSATION_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  validate,
  //asyncValidate
})(ConversationForm)),formNames.DISCUSSION_CONVERSATION_FORM_NAME);
