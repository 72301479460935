import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as discussionActions from "../../../actions/discussion";
import * as notificationActions from "../../../actions/notification";
import * as projectActions from "../../../actions/project";
import PageHeading from "../../../components/PageHeading";
import * as formNames from "../../../constants/forms";
import ConversationForm from "../../../forms/conversation/form";
import styles from "../../../theme/styles";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
// import InsertChartIcon from "@material-ui/icons/InsertChart";
import ListIcon from '@material-ui/icons/List';
import { Alert, AlertTitle } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { change } from "redux-form";
import ComponentLoading from "../../../components/ComponentLoading";
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import HeadingGeneralBtn from "../../../components/buttons/HeadingGeneralBtn";
import DiscussionIntro from "../../../components/discussion/DiscussionIntro";
import { canEditMessageAfterMs } from "../../../components/messages/MessageItem";
import MessagesList from "../../../components/messages/MessagesList";
import firestoreApi from "../../../firebase";
import ArrayUtil from "../../../utils/ArrayUtil";
import DateUtil from "../../../utils/DateUtil";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import ProjectDiscussionsMasonry from "../project/ProjectDiscussionsMasonry";
import * as collections from '../../../constants/firebaseCollections';

const entityFormName = formNames.DISCUSSION_CONVERSATION_FORM_NAME;

class Discussion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussion: null,
      discussionLoading: true,
      isAnonymous: false,
      replyToMessageId: null,
      discussions: [],
      messageToEdit: null,
    };
  }

  componentDidMount() {
    
    this.realodData();
  }

  componentWillReceiveProps(nextProps) {
    this.mountDiscussionData(objectPath.get(nextProps, "match.params.projectId"), objectPath.get(nextProps, "match.params.discussionId"));
  }

  mountDiscussionData = (projectID, discussionID) => {
    const { actions } = this.props;
    const { discussion, discussions } = this.state;
    if(projectID && ArrayUtil.isNonEmptyArray(discussions) && (discussion === null || (discussion && discussionID !== discussion.id))){
      const d = discussions.find((x) => x.id === discussionID && (AccessUtil.isDiscussionPublished(x) || AccessUtil.isAtLeastObserverForProject(projectID)));
      if(d){
        window.scrollTo(0, 0);
        this.setState({
          discussion: d,
        });
        actions.flushDiscussionMessages();
        actions.flushDiscussionLikes();
        this.listenDiscussion(projectID, discussionID);
      } else {
        AccessUtil.redirect();
      }
    }
  }

  scrollToMessageById = (messageId) => {
    setTimeout(() => {
      var scrollTo = document.getElementById(
        messageId
      );
      const yOffset = -70; 
      console.log("scrollTo", scrollTo);
      if (scrollTo) {
        const y = scrollTo.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }, 500);
  }

  realodData = () => {
    const { match } = this.props;
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    Promise.all([
      this.mountProject(projectId),
      this.mountDiscussions(projectId, discussionId),
      this.listenDiscussion(projectId, discussionId),
    ]).then(() => {
      const messageId = objectPath.get(window, "location.hash", "").replace("#", "");
      if(messageId && messageId.length > 0){
        this.scrollToMessageById(messageId);
      }
      return this.setState({
        discussionLoading: false,
      });
    });
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.flushDiscussionMessages();
    actions.flushDiscussionLikes();
  }

  mountProject = (projectId) => {
    const { actions, selectedProject } = this.props;
    if (
      !selectedProject ||
      (selectedProject && selectedProject.id !== projectId)
    ) {
      return actions.getProject(projectId);
    } else {
      return Promise.resolve();
    }
  };

  mountDiscussions = (projectId, discussionId) => {
    const { actions } = this.props;
    const _this = this;
    if (projectId && discussionId) {
      // if (!discussion || (discussion && discussion.id !== discussionId)) {
        AccessUtil.isAtLeastRespondentForProject(projectId, true);
        return actions
          .getDiscussions(projectId)
          .then((response) => {
            const discussions = objectPath.get(response, "data");
            const discussion = discussions.find((d) => d.id === discussionId && (AccessUtil.isDiscussionPublished(d) || AccessUtil.isAtLeastObserverForProject(projectId)));
            if(discussion){
              return _this.setState({
                discussion: discussion,
                discussions: discussions
              });
            } else {
              AccessUtil.redirect();
            }
          });
      // } else {
      //   return Promise.resolve();
      // }
    } else {
      return new Promise((resolve) => {
        _this.setState(
          {
            discussion: null,
          },
          resolve
        );
      });
    }
  };

  listenDiscussion = (projectId, discussionId) => {
    const { actions } = this.props;
    if (projectId && discussionId) {
      actions.listenDiscussionMessages(projectId, discussionId);
      actions.listenDiscussionLikes(projectId, discussionId);
      actions.listenUserDiscussionReadMessageIds(UsersUtil.getCurrentUserUid(), discussionId);
    }
  };

  onLikeDislike = (data, likesBtn = true) => {
    const { actions, match } = this.props;
    console.log("onLikeDislike", data);
    var userId = UsersUtil.getCurrentUserUid();
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");
    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");

    var attrName = likesBtn ? "likes" : "dislikes";

    if (!objectPath.get(data, attrName, []).includes(userId)) {
      data[attrName] = ArrayUtil.addToArrayIfNotExist(data[attrName], userId);
    } else {
      data[attrName] = ArrayUtil.removeFromArrayIfExist(data[attrName], userId);
    }
    //console.log('putDiscussionMessageLikes',data);
    return actions.putDiscussionMessageLikes(projectId, discussionId, data);
  };

  onMessageEdit = (data) => {
    console.log("onMessageEdit", data);
    this.setState({
      messageToEdit: data,
    })
  };

  handleUpdateMessage = (data) => {
    const { enqueueSnackbar, actions, match } = this.props;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");

    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    if(DateUtil.timestamp() - data.createdTime < canEditMessageAfterMs){
      console.log("handleUpdateMessage", data);   
      actions
          .putDiscussionMessage(projectId, discussionId, data.id, data.message)
          .then((response) => {
            if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
              enqueueSnackbar(TranslatorUtil.t("Comment updated"), { variant: "success"});
            }
          })
    } else {
      enqueueSnackbar(TranslatorUtil.t("You can edit message only for minute from its creation"), {
        variant: "error"
      });
    }
    this.setState({
      messageToEdit: null,
    })
    return Promise.resolve();
  };

  onMessageReply = (messageId) => {
    this.setState({
      replyToMessageId: messageId,
    });
  };

  scrollToUserLatestMessage = () => {
    const { messages } = this.props;
    const lastUserMessage = messages && messages.length > 0 ? messages.find(x => objectPath.get(x, 'byUserId') === UsersUtil.getCurrentUserUid()) : undefined; 
    if(lastUserMessage !== undefined){
      this.scrollToMessageById(lastUserMessage.id);
    }
  }

  handleResponse = (response, values) => {
    const { enqueueSnackbar } = this.props;

    if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
      console.log("handleResponse", response, values);
      this.sendReplyNotification(values);
      this.sendModeratorMessageNotification(values);
      enqueueSnackbar(TranslatorUtil.t("Comment added"), { variant: "success"});
      this.scrollToUserLatestMessage();
      this.setState({
        replyToMessageId: null,
      });
    }
  };

  sendModeratorMessageNotification = (message) => {
    const { actions, selectedProject } = this.props;
    const { discussion } = this.state;
    if (selectedProject && selectedProject.id &&
      !message.replyToMessageId && 
      !message.isAnonymous && 
      AccessUtil.isDiscussionPublished(discussion) &&
      AccessUtil.isModeratorForProject(selectedProject.id, false)) {
      
      var respondents = AccessUtil.getRespondentsAccessByProject(selectedProject.id);
      let uniqueMailsToNotify = respondents.filter((x, i, a) => a.indexOf(x) === i);
      // console.log("sendModeratorMessageNotification uniqueMailsToNotify", uniqueMailsToNotify);
      Promise.all(uniqueMailsToNotify.map(mailToNotify => {
      return actions.postNotification(
        EmailUtil.getDiscussionModeratorNoReplyMessageEmailObject(
          CompanyUtil.getId(),
          selectedProject,
          discussion,
          message,
          mailToNotify
        )
      );
    }));
    }
  };

  onDiscussMessageWithModerator = (message) => {
    const { selectedProject, profiles, history } = this.props;
    const { discussion } = this.state;
    
    if (selectedProject && selectedProject.id && profiles && profiles.length && discussion && discussion.id) {
      const moderatorUid = AccessUtil.getProjectModeratorUid(selectedProject.id, profiles);
      if(moderatorUid){
        console.log("onDiscussMessageWithModerator", message);
        history.push(UrlBuilderUtil.getChatAboutMessage(
          moderatorUid,
          selectedProject.id,
          discussion.id,
          message.id
          )
          );
      }
    }
    
  };

  onToggleMessageRead = (message) => {
    const { selectedProject } = this.props;
    const { discussion } = this.state;
    
    if (selectedProject && selectedProject.id && discussion && discussion.id) {
      firestoreApi.collection("profiles/"+UsersUtil.getCurrentUserUid()+"/readMessages/").doc(message.id)
      .set({
        "updatedTime": DateUtil.timestamp(),
        "messageId": message.id,
        "projectId": selectedProject.id,
        "discussionId": discussion.id,
        "isRead": true
      });
    }
    
  };

  onToggleMessageStarred = (message) => {
    const { selectedProject, enqueueSnackbar } = this.props;
    const { discussion } = this.state;
    
    if (selectedProject && selectedProject.id && discussion && discussion.id) {
      firestoreApi.collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [selectedProject.id,collections.DISCUSSIONS,discussion.id,collections.MESSAGES])).doc(message.id)
      .update({
        "isStarred": !objectPath.get(message, "isStarred", false)
      }).then(() => enqueueSnackbar(!objectPath.get(message, "isStarred", false) ? TranslatorUtil.t("Message starred") : TranslatorUtil.t("Message not starred"), { variant: "info"}));
    }
  };

  sendReplyNotification = (message) => {
    if (message.replyToMessageId) {
      const { discussion } = this.state;
      const { actions, selectedProject, messages } = this.props;
      const firstMessage = messages.find(
        (x) => x.id === message.replyToMessageId
      );
      console.log("sendReplyNotification", firstMessage);
      if (firstMessage) {
        const authorEmail = UsersUtil.getUserEmailFromStoreById(
          firstMessage.byUserId
        );
        if (authorEmail) {
          return actions.postNotification(
            EmailUtil.getDiscussionReactionEmailObject(
              CompanyUtil.getId(),
              selectedProject,
              discussion,
              firstMessage,
              message,
              authorEmail
            )
          );
        }
      }
    }
  };

  handleSubmit = (values) => {
    const { actions, match } = this.props;
    const { discussion, isAnonymous, replyToMessageId } = this.state;
    var projectId = UrlBuilderUtil.getRequestedParam(match, "projectId");

    var discussionId = UrlBuilderUtil.getRequestedParam(match, "discussionId");
    values.byUserId = UsersUtil.getCurrentUserUid();
    values.anonymous = isAnonymous;
    values.projectId = projectId;
    values.discussionId = discussionId;
    values.isProjectRespondent = AccessUtil.isRespondentForProject(projectId, false);
    if (replyToMessageId) {
      values.replyToMessageId = replyToMessageId;
    }

    return discussion
      ? actions
          .postDiscussionMessage(projectId, discussionId, values)
          .then((response) => this.handleResponse(response, values))
      : Promise.resolve();
  };

  toggleAnonymity = () => {
    this.setState({
      isAnonymous: !this.state.isAnonymous,
    });
  };

  hasSubmitAtLeastOne = () => {
    const { messages } = this.props;
    var userId = UsersUtil.getCurrentUserUid();
    return ArrayUtil.isNonEmptyArray(messages) &&
      messages.findIndex((x) => x.byUserId === userId) > -1
      ? true
      : false;
  };

  render() {
    const {
      match,
      messages,
      discussionLikes,
      selectedProject,
      discussionUserReadMessageIds,
      profiles,
      userInfo
    } = this.props;
    const {
      discussion,
      discussionLoading,
      isAnonymous,
      replyToMessageId,
      discussions,
      messageToEdit
    } = this.state;
    const projectID = objectPath.get(selectedProject, "id", null);
    const discussionID = objectPath.get(discussion, "id", null);
    const filteredOtherDiscussions = discussions ? discussions.filter((x) => x.id !== discussionID) : [];
    const previewOfOthersAndUserStats = selectedProject && filteredOtherDiscussions && discussion ? <React.Fragment><div className="other-discussions"><ProjectDiscussionsMasonry
    headingText={TranslatorUtil.t("Other discussions")}
    discussions={filteredOtherDiscussions}
    profiles={profiles}
    project={selectedProject}
    userInfo={userInfo}
    /></div><div className="messageFormWrapSpacer"></div></React.Fragment> : null;

    return (
      <div className="discussion-wrap">
        <PageHeading
          heading={discussion ? discussion.name : "..."}
          actions={
            <div>
              <HeadingGeneralBtn title={TranslatorUtil.t("Project")} icon={<ListIcon />} redirectToUrl={UrlBuilderUtil.getProjectDashboard(projectID)} />
              {selectedProject &&
              AccessUtil.isAtLeastModeratorForProject(projectID) ? (
                <React.Fragment>
                  {/* <HeadingGeneralBtn title={TranslatorUtil.t("Export")} icon={<InsertChartIcon />} redirectToUrl={UrlBuilderUtil.getDiscussionExport(
                            projectID,
                            discussionID
                          )} /> */}
                  <HeadingGeneralBtn title={TranslatorUtil.t("Edit")} icon={<EditIcon />} redirectToUrl={UrlBuilderUtil.getDiscussionEdit(
                            projectID,
                            discussionID
                          )} />
                </React.Fragment>
              ) : null}
              <HeadingBackBtn
                redirectToUrl={UrlBuilderUtil.getProjectDashboard(
                  UrlBuilderUtil.getRequestedParam(match, "projectId")
                )}
              />
            </div>
          }
        />
        <Grid container>
          <Grid item xs={12} lg={6} xl={5}>
            {discussion ? (
              <DiscussionIntro
                item={discussion}
                isExpanded={true}
                isLocked={
                  AccessUtil.isProjectLocked(selectedProject) ||
                  AccessUtil.isDiscussionLocked(discussion)
                }
              />
            ) : null}
            <Hidden mdDown>{previewOfOthersAndUserStats}</Hidden>
          </Grid>
          <Grid item xs={12} lg={6} xl={7}>
            {discussionLoading ? (
              <ComponentLoading />
            ) : (
              <MessagesList
                projectId={projectID}
                profiles={profiles}
                items={messages}
                likes={discussionLikes}
                discussionUserReadMessageIds={discussionUserReadMessageIds}
                currentUserId={UsersUtil.getCurrentUserUid()}
                oneSubmitted={this.hasSubmitAtLeastOne()}
                onMessageEdit={this.onMessageEdit}
                isLocked={
                  AccessUtil.isProjectLocked(selectedProject) ||
                  AccessUtil.isDiscussionLocked(discussion)
                }
                onLikeDislike={
                  !AccessUtil.isObserverForProject(projectID, false)
                    ? this.onLikeDislike
                    : null
                }
                onMessageReply={
                  !AccessUtil.isObserverForProject(projectID, false)
                    ? this.onMessageReply
                    : null
                }
                onDiscussMessageWithModerator={
                  AccessUtil.isObserverForProject(projectID, false)
                    ? this.onDiscussMessageWithModerator
                    : null
                }
                onToggleMessageRead={this.onToggleMessageRead}
                onToggleMessageStarred={AccessUtil.isAtLeastModeratorForProject(projectID, false) ? this.onToggleMessageStarred : null}
              />
            )}
            <Hidden mdDown><div className="messageFormWrapSpacer"></div></Hidden>
            {(AccessUtil.isRespondentForProject(projectID, false) ||
              AccessUtil.isAtLeastModeratorForProject(projectID, false)) && (
              <div className="messageFormWrap">
                {discussionLoading ? null : AccessUtil.isProjectLocked(
                    selectedProject
                  ) || AccessUtil.isDiscussionLocked(discussion) ? (
                  <div className={"narrowMaxMediumWidthPart"}>
                    <Alert severity="info">
                      <AlertTitle>
                        {TranslatorUtil.t("Discussion is closed")}
                      </AlertTitle>
                    </Alert>
                  </div>
                ) : (
                  <div className={"narrowMaxMediumWidthPart"}>
                    {messageToEdit ? <ConversationForm
                      cancelEdit={() => this.setState({ messageToEdit: null })}
                      proceedSubmit={this.handleUpdateMessage}
                      initialValues={messageToEdit}
                    /> : <ConversationForm
                    removeReplyTo={() =>
                      this.setState({ replyToMessageId: null })
                    }
                    replyToMessage={
                      replyToMessageId
                        ? messages.find((x) => x.id === replyToMessageId)
                        : null
                    }
                    likes={discussionLikes}
                    proceedSubmit={this.handleSubmit}
                    initialValues={null}
                  />}
                    
                    <FormControlLabel
                      className="px-2"
                      control={
                        <Checkbox
                          checked={isAnonymous}
                          onChange={this.toggleAnonymity}
                          value={isAnonymous}
                        />
                      }
                      label={<small className="muted">{TranslatorUtil.t("as anonym")}</small>}
                    />
                  </div>
                )}
              </div>
            )}
          </Grid>
          <Hidden lgUp>{previewOfOthersAndUserStats}
          <div className="messageFormWrapSpacer"></div>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedProject: state.selectedProject,
  userInfo: state.userInfo,
  messages: state.messages,
  profiles: state.profiles,
  discussionLikes: state.discussionLikes,
  discussionUserReadMessageIds: state.discussionUserReadMessageIds,
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value));
  },
  actions: bindActionCreators(
    {
      ...projectActions,
      ...discussionActions,
      ...notificationActions,
    },
    dispatch
  ),
});

export default withRouter(
  withSnackbar(
    withStyles(styles, { withTheme: true })(
      connect(mapStateToProps, mapDispatchToProps)(Discussion)
    )
  )
);
