import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from '../theme/styles';
import classnames from 'classnames';

function AppCard(props) {

  const { classes, children, type,additionalClasses } = props;

  return (
    <Card className={classnames(classes.card, type === 'error' && classes.cardError, type === 'warning' && classes.cardWarning)+ ' '+ additionalClasses}>
      <CardContent>
        { children }
      </CardContent>
    </Card>
  );
}

AppCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AppCard);