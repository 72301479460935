import * as types from '../constants/actionTypes';


export function getProfiles(state = [], action) {
    if (action.type === types.GET_PROFILES) {
        console.log(types.GET_PROFILES+' set in store');
        return action.data;
    }
    return state;
}


export function getProfile(state = null, action) {
    if (action.type === types.GET_PROFILE) {
        console.log(types.GET_PROFILE+' set in store');
        return action.data;
    }
    return state;
}


