import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PageHeading from "../../../components/PageHeading";

import ProfileForm from "../../../forms/profile/form";
import * as translationActions from "../../../actions/translations.js";
import * as profileActions from "../../../actions/profile.js";
import * as notificationActions from "../../../actions/notification";
import * as formNames from "../../../constants/forms";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";

import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import ComponentLoading from "../../../components/ComponentLoading";
import { change } from 'redux-form';
import FormatterUtil from "../../../utils/FormatterUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";

const entityName = "Profile";
const entityListUrl = "/dashboard/";
const entityFormName = formNames.PROFILE_FORM_NAME;


class EditProfile extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true
    }
  }

  componentDidMount(){
    Promise.all([
      this.mountItem()
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }


  mountItem(){
    const { actions, actionType, userInfo } = this.props;
    const _this = this;
    if(actionType === "edit" && userInfo){
      return actions.getProfile(this.getProfileUserId()).then( response => {
        return _this.setState({
          item: objectPath.get(response, "data"),
        })
      });
    } else {
      return new Promise((resolve) => {
        
        _this.setState({
          item: null,
        }, resolve);
      });
    }
  }

  handleResponse = (response, values) => {
    const { enqueueSnackbar, actionType, history, actions } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      //console.log('handleResponse',response);
      var successMessage = (actionType === "create" ? TranslatorUtil.t("Profile has been added") : TranslatorUtil.t("Profile has been updated"));
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
      
      //related store changes
      actions.setTranslationMessagesByLang(objectPath.get(values,'langCode'));
      
      history.push(entityListUrl)
    }
  }

  getProfileUserId = () => {
    const { userInfo } = this.props;
    //to be able to edit another profile
    return objectPath.get(userInfo,'profile.uid');
  }

  handleSubmit = async (values) => {
    const { actions, userInfo } = this.props;
      values.uid = this.getProfileUserId();
      this.setState({
        dataLoading: true
      });
      if(AccessUtil.isRespondent(false)){
        await this.notifyModeratorAndAdmins(values,objectPath.get(userInfo,'profile',null));
      }
    //has to return promise so submitting flag works!
    return actions.putProfile(values).then(response => this.handleResponse(response, values));
  };


  notifyModeratorAndAdmins = async (newProfile,oldProfile) => {
    const { actions, userInfo, projects } = this.props;
    const userEmail = objectPath.get(userInfo,'firebase.email');
    let propsToCheck = ['age','displayName','description','gender','phone'];
    let hasChange = false;
    propsToCheck.forEach(x => {
      if(!hasChange && objectPath.get(oldProfile,x,null) !== objectPath.get(newProfile,x,null)){
        hasChange = true;
      }
    });
    if(hasChange){
      // console.log('-------notifyModeratorAndAdmins');
      const openProjectIds = projects.filter(x => !AccessUtil.isProjectLocked(x)).map(x => x.id);
      const projectIds = AccessUtil.getProjectIdsUserHasAccessToAsRepondent(userEmail).filter(x => openProjectIds.includes(x));
      // console.log('projectIds',projectIds);
      if(projectIds.length){
        let mailsToNotify = [];
        projectIds.forEach(x => {
          mailsToNotify = mailsToNotify.concat(AccessUtil.getModeratorsAccessByProject(x));
        });
        mailsToNotify = mailsToNotify.filter((x, i, a) => a.indexOf(x) === i);
        // console.log('moderatorEmails',moderatorEmails);
        if(mailsToNotify.length){
          return Promise.all(
            mailsToNotify.map(to => {
              return actions.postNotification(EmailUtil.getRespondentProfileUpdated(
                CompanyUtil.getId(),
                newProfile,
                to
                ));
            })
          );
        }
      }
      
    }
    return Promise.resolve();
  }


  render() {
    const { actionType } = this.props;
    const { item, dataLoading } = this.state;
    

    return (
      <div>
        <PageHeading heading={(actionType === "create" ? TranslatorUtil.t("New") : TranslatorUtil.t("Update"))+" "+TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName))} actions={
          <div>
            <HeadingBackBtn redirectToUrl={entityListUrl} />
        </div>
        } />

      { dataLoading ? <ComponentLoading /> : 
      <ProfileForm 
        proceedSubmit={this.handleSubmit} 
        initialValues={item}

      /> }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  projects: state.projects
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...profileActions,
      ...translationActions,
      ...notificationActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProfile)
));