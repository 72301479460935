import React from "react";
import { Tooltip, IconButton, Button, Hidden, Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import * as constants from "../../constants/constants";

function HeadingGeneralBtn(props) {
  const { redirectToUrl, history, title, icon } = props;

  return (
    <React.Fragment>
      <Hidden xlUp>
        <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={title}>
          <IconButton size="small" onClick={() => history.push(redirectToUrl)}>
            {icon}
          </IconButton>
        </Tooltip>
      </Hidden>
      <Hidden lgDown>
        <Box px={1} className="inline-block">
        <Button startIcon={icon} size="small" onClick={() => history.push(redirectToUrl)}>{title}</Button>
        </Box>
        
      </Hidden>
    </React.Fragment>
  );
}

export default withRouter(HeadingGeneralBtn);
