import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import CompanyUtil from "../utils/projectBased/CompanyUtil";

const collectionName = collections.USER_TAGS;


export function getUserTagsByUserId(userId) {
  return function(dispatch) {
    console.log('getUserTagsByUserId called', userId);
    return firestoreApi
    .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
    .doc(userId)
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_USER_TAGS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_USER_TAGS))
      );
  };
}


export function listenUserTagsByUserId(userId) {
  return function(dispatch) {
    console.log('listenUserTagsByUserId called', userId);

    
    return firestoreApi
    .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
    .where("userIds", "array-contains", userId)
    .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_USER_TAGS)))
    ;
  };
}


export function getCompanyUserTags() {
  return function(dispatch) {
    console.log('getCompanyUserTags');
    return firestoreApi
    .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
    .get()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.GET_COMPANY_USER_TAGS)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_USER_TAGS))
      );
  };
}

export function listenCompanyUserTags() {
  return function(dispatch) {
    console.log('listenCompanyUserTags');
    return firestoreApi
    .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
    .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(
        FirestoreCollectionUtil.parseCollectionData(response),
        types.GET_COMPANY_USER_TAGS)
        ))
    ;
  };
}

  
  export function putUserTag(data) {
    return function(dispatch) {
      console.log('putUserTag');
      return firestoreApi
      .collection(CompanyUtil.getCollectionPathWithCompanyPrefix(collectionName))
      .doc(data.id).set(FirestoreCollectionUtil.removeMetaData(data))
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PUT_USER_TAG)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.PUT_USER_TAG))
        );
    };
  }

