// "DEV"
// "PROD"
const PROD = "PROD";
const DEV = "DEV";
const LOCAL = "LOCAL";



const CURRENT = PROD;



export default {
    CURRENT,
    PROD,
    DEV,
    LOCAL
};