import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';


export function getInvitationDiscussion(invitation) {
    return function(dispatch) {
      console.log('getInvitationDiscussion');
      return firestoreApi.collection(
        [collections.COMPANIES,invitation.companyId,collections.PROJECTS,invitation.projectId,collections.DISCUSSIONS].join('/')
        ).doc(invitation.discussionId).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_INVITATION_DISCUSSION)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_INVITATION_DISCUSSION))
        );
    };
  }


  export function getInvitationProject(invitation) {
    return function(dispatch) {
      console.log('getInvitationProject');
      return firestoreApi.collection(
        [collections.COMPANIES,invitation.companyId,collections.PROJECTS].join('/')
      ).doc(invitation.projectId).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_INVITATION_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_INVITATION_PROJECT))
        );
    };
  }


  export function getInvitationCompany(invitation) {
    return function(dispatch) {
      console.log('getInvitationCompany');
      return firestoreApi.collection(
        [collections.COMPANIES].join('/')
      ).doc(invitation.companyId).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_INVITATION_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_INVITATION_PROJECT))
        );
    };
  }