import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import * as constants from '../../constants/constants';
import {  Avatar,IconButton, Tooltip, Badge, TableCell, TableRow } from '@material-ui/core';
import FormatterUtil from '../../utils/FormatterUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import objectPath from 'object-path';
import DraftsIcon from '@material-ui/icons/Drafts';
import classnames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import IconUtil from '../../utils/projectBased/IconsUtil';
import ReactHtmlParser from 'react-html-parser';

class NotificationRowContent extends React.Component {



  redirectToItem = () => {
    const { item, history } = this.props;  
    var url = objectPath.get(item,'actionURL',null);
      if(url){
        url = FormatterUtil.removeAbsolutePathFromURL(url);
        history.push(url);
      }
  }

  cardClicked = () => {
    const { item, onMarkAsReadClicked } = this.props;  
    if(item.read){
      return this.redirectToItem();
    } else {
      return onMarkAsReadClicked().then(() => this.redirectToItem());
    }
    
  }

  render () {
  const { classes, item, onMarkAsReadClicked } = this.props;

  const notifDateTime =  objectPath.get(item,'updatedTime', 0) > 0 ? objectPath.get(item,'updatedTime', 0) : objectPath.get(item,'createdTime', 0);

  return (
    <TableRow className={"notification "+(item.read ? "read" : "unread")} onClick={() => this.cardClicked()}>
                  <TableCell className="p-2">{
                    item.read ? <Badge className="badge" badgeContent={<DoneIcon size="small"/>}>
                    <Avatar aria-label={item.name} className={classnames(classes.avatar,"avatar")}>
                    {IconUtil.getNotificationIcon(item.type)}
                      </Avatar>
        </Badge>: <Avatar aria-label={item.name} className={classnames(classes.avatar,"avatar")}>
          {IconUtil.getNotificationIcon(item.type)}
                  </Avatar>
                  }</TableCell>
                  <TableCell className="p-2 noWrap"><small className="muted">{DateUtil.displayTimeFromNow(notifDateTime,TranslatorUtil.getUserLangFromStore())}</small></TableCell>
                  <TableCell className="p-2">{item.message.subject}<div><small className="muted">{ ReactHtmlParser(item.message.text) }</small></div></TableCell>
                  <TableCell className="p-2">
                  {!item.read ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Mark as read")}>
                                    <IconButton size="small"  aria-label={TranslatorUtil.t("Mark as read")} onClick={() => onMarkAsReadClicked()}>
                                        <DraftsIcon />
                                    </IconButton>
                                </Tooltip>: null
                                }
                  </TableCell>
                </TableRow>
  );
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(NotificationRowContent));