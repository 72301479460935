import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../theme/styles';
import classnames from 'classnames';
import * as formNames from "../../../constants/forms";
import * as constants from '../../../constants/constants';
import {
  TextInput,
  PasswordInput
} from "../../../components/inputs";


import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Tooltip} from "@material-ui/core";
import TranslatorUtil from "../../../utils/TranslatorUtil";

const LoginForm = props => {
  const {
    handleSubmit,
    //pristine,
    submitting,
    classes,
    proceedSubmit
  } = props;
  
  const disableSubmit = submitting;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><ArrowForwardIcon /></Fab>;


  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container>
            
            
            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="email" component={TextInput} label={TranslatorUtil.t("Email")} inputProps={{maxLength: 50}} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="password" type="password" component={PasswordInput} label={TranslatorUtil.t("Password")}  />
              </FormGroup>
            </Grid>

            
            
            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12} lg={7}>
              { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("OK")}>{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
};


export default withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.LOGIN_FORM_NAME, // a unique identifier for this form
  validate,
  //asyncValidate
})(LoginForm));
