import React from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import * as formNames from "../../constants/forms";
import * as constants from '../../constants/constants';
import colors from '../../constants/colors';
import {
  TextInput, SelectInput, WysiwygInput, FileInput, //InlineCheckboxInput, 
} from "../../components/inputs";

import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormGroup from "@material-ui/core/FormGroup";
import { Box, Divider, Grid, Tooltip, } from "@material-ui/core";
// import warnAboutUnsavedForm from "../../hoc/withWarnAboutUnsavedChanges";
import TranslatorUtil from "../../utils/TranslatorUtil";
import NativeDateInput from "../../components/inputs/NativeDateInput";
import NativeTimeInput from "../../components/inputs/NativeTimeInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";

const ProjectForm = props => {
  const {
    handleSubmit,
    //isLocked,
    //pristine,
    submitting,
    submittingImages,
    classes,
    proceedSubmit
  } = props;
  
  const disableSubmit = submitting || submittingImages;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><ArrowForwardIcon /></Fab>;



  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container>
            
            
          <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="name" component={TextInput} label={TranslatorUtil.t("Name")} inputProps={{maxLength: 50}} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="color" component={SelectInput} label={TranslatorUtil.t("Color")} dataOptions={colors} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="description" component={TextInput} multiline rowsMax="4" label={TranslatorUtil.t("Description")} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="images" component={FileInput} multiple={true} label={TranslatorUtil.t("Images")+" ("+TranslatorUtil.t("max 8MB per file")+")"} fileAccess={"image/*"} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="youtubeEmbedLink" component={TextInput} rowsMax="4" label={TranslatorUtil.t("Youtube Embed Link")} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <Box py={4}>
                <Divider />
              </Box>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="welcomeContent" component={WysiwygInput} label={TranslatorUtil.t("Welcome page content")} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <Box py={4}>
                <Divider />
              </Box>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="termsConditions" component={WysiwygInput} label={TranslatorUtil.t("Terms & Conditions")} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <Box py={2}>
                <Divider />
              </Box>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <Grid item className="gridItem" xs={6}>
                <FormGroup>
                  <Field name="lockDate" component={NativeDateInput} label={TranslatorUtil.t("Lock date")} />
                </FormGroup>
              </Grid>
              <Grid item className="gridItem" xs={6}>
                <FormGroup>
                  <Field name="lockTime" component={NativeTimeInput} label={TranslatorUtil.t("Lock time")} />
                </FormGroup>
              </Grid>
              <Grid item className="gridItem" xs={6}>
                <FormGroup>
                <div className="inlineCheckboxGroup">
                  <Field name="disableDisscussionsLock" component={CheckboxInput} label={TranslatorUtil.t("Disable discussion locking")} />
                  </div>
                </FormGroup>
              </Grid>
            </Grid>

            {/* <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="byEmailInvitationOnly" component={InlineCheckboxInput} label={TranslatorUtil.t("By email invitation only")} />
              </FormGroup>
            </Grid> */}

            
            
            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12} lg={7}>
              { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("OK")}>{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
};


export default withStyles(styles, { withTheme: true })(reduxForm({
  form: formNames.PROJECT_FORM_NAME, // a unique identifier for this form
  validate,
  //asyncValidate
})(ProjectForm),formNames.PROJECT_FORM_NAME);
