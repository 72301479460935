import React from "react";

import ListIcon from '@material-ui/icons/List';

export default {
    primaryMenu: [
        {
            label: "Projects",
            path: "/projects",
            showIcon: true,
            icon: <ListIcon />,
            children: []
        }
    ],
    secondaryMenu: [
        // {
        //     label: TranslatorUtil.t("Items"),
        //     path: "/simple-items",
        //     showIcon: true,
        //     icon: <BinderIcon />,
        //     children: []
        // },
        // {
        //     label: TranslatorUtil.t("Admin"),
        //     path: "/admin/",
        //     showIcon: true,
        //     icon: <AdminIcon />,
        //     children: [
        //         {
        //             label: TranslatorUtil.t("Items"),
        //             path: "/simple-items",
        //         }
        //     ]
        // }
    ],

}