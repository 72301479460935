
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

function SmallDialog(props) {

  const { title, children, handleClose } = props;

  return <Dialog
  fullWidth
  maxWidth="md"
  open={true}
  onClose={handleClose}
>
<DialogTitle disableTypography >
    <Grid container>
        <Grid item xs={10}>
        <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item xs={2} className="text-right">
        {handleClose ? (
        <IconButton aria-label="close" onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      ) : null}
        </Grid>
    </Grid>
      
      
    </DialogTitle>
  <DialogContent>
      {children}
  </DialogContent>
</Dialog>;
}

export default SmallDialog;