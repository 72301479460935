import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import InputHelperText from "./InputHelperText";
import LabelTooltip from "./LabelTooltip";
import TranslatorUtil from "../../utils/TranslatorUtil";

export default ({
  input,
  label,
  meta: { touched, error, form },
  children,
  dataOptions,
  helpText,
  native,
  multiple,
  disableEmpty,
  ...custom
}) => {
  var inputValue = !Array.isArray(input.value) && multiple ? [] : input.value;

  const emptyValue = native ? <option value="">{TranslatorUtil.t("Not selected")}</option> : <MenuItem value=""><em>{TranslatorUtil.t("Not selected")}</em></MenuItem>;
  
  return (
    <FormControl error={touched && error ? true : false} className="textFieldWrap materialUISelectWrap" >
      <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
      <Select classes={{select: "materialUISelect"}} variant="filled" {...input} {...custom} multiple={multiple} native={native} value={inputValue} >
        {multiple ? null : disableEmpty === true ? null :emptyValue}
        {dataOptions && dataOptions.length
          ? dataOptions.map((option, index) => {
              return native ? 
              <option key={index} value={option.value}>{option.label}</option> 
              : 
              <MenuItem key={index} value={option.value}>{option.label}</MenuItem>;
            })
          : null}
      </Select>
      {InputHelperText({ touched, error })}
    </FormControl>
  );
};
