import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';


export function getDiscussionPreview(companyId,projectId,discussionId) {
    return function(dispatch) {
      console.log('getDiscussionPreview');
      return firestoreApi.collection(
        [
            collections.COMPANIES,
            companyId,
            collections.PROJECTS,projectId,
            collections.DISCUSSIONS
        ].join('/')
        ).doc(
            discussionId
            ).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_DISCUSSION_PREVIEW)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_DISCUSSION_PREVIEW))
        );
    };
  }


  export function getProjectPreview(companyId,projectId) {
    return function(dispatch) {
      console.log('getProjectPreview');
      return firestoreApi.collection(
        [collections.COMPANIES,companyId,collections.PROJECTS].join('/')
      ).doc(projectId).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_PROJECT)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_PROJECT))
        );
    };
  }


  export function getCompanyPreview(companyId) {
    return function(dispatch) {
      console.log('getCompanyPreview');
      return firestoreApi.collection(
        [collections.COMPANIES].join('/')
      ).doc(companyId).get()
        .then(
          response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionItemData(response), types.GET_COMPANY_PREVIEW)),
          error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_COMPANY_PREVIEW))
        );
    };
  }