import objectPath from "object-path";
import firestoreApi from "../../firebase";
import DateUtil from "../DateUtil";
import FirestoreCollectionUtil from "../FirestoreCollectionUtil";
import * as collections from '../../constants/firebaseCollections';

export default class NotificationUtil {
  static addMetaDataToNew(data) {
    data.createdTime = DateUtil.unixTime();
    data.read = false;
    return data;
  }

  static isNewNotification(notifId){
    return firestoreApi
      .collection(collections.NOTIFICATION)
      .doc(notifId)
      .get()
      .then((doc) => {
        return doc && doc.exists;
      }); 
  }

  static markConversationAndNotificationAsRead = async function(id, userId, userEmail, chatType = "chat") {
          const readTimeByUser = {};
          readTimeByUser["readTime_"+userId] = DateUtil.timestamp();
          await firestoreApi.collection(chatType).doc(id).update(readTimeByUser);  
          let notificationDoc = await firestoreApi.collection(collections.NOTIFICATION).doc(id).get();
          const notification = FirestoreCollectionUtil.parseCollectionItemData(notificationDoc);
          if(notification){
            if(objectPath.get(notification,'to') === userEmail && !objectPath.get(notification,'read', false)){
              return firestoreApi.collection(collections.NOTIFICATION).doc(id).update({read: true});
            }
          }
          return Promise.resolve();
  };

  static hasConversationBeenReadByRecipient = async function(
    conversationId,
    userId,
    chatType = "chat"
  ) {
    return firestoreApi
      .collection(chatType)
      .doc(conversationId)
      .get()
      .then((response) => {
        const chat = FirestoreCollectionUtil.parseCollectionItemData(response);
        console.log('hasConversationBeenReadByRecipient',chat);
        if (
          objectPath.get(chat, "readTime_" + userId, 0) >
          objectPath.get(chat, "updatedTime", 0)
        ) {
          return true;
        }
        return false;
      });
  };
}
