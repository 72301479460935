import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import classnames from 'classnames';
import * as formNames from "../../constants/forms";
import * as constants from '../../constants/constants';
import colors from '../../constants/colors';
import {
  TextInput, SelectInput, FileInput, WysiwygInput,
  CheckboxInput, 
} from "../../components/inputs";


import validate from "./validate";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormGroup from "@material-ui/core/FormGroup";
import { Box, Divider, Grid, Tooltip } from "@material-ui/core";
//import warnAboutUnsavedForm from "../../hoc/withWarnAboutUnsavedChanges";
import TranslatorUtil from "../../utils/TranslatorUtil";
import NativeDateInput from "../../components/inputs/NativeDateInput";
import NativeTimeInput from "../../components/inputs/NativeTimeInput";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import objectPath from "object-path";

let DiscussionForm = props => {
  const {
    selectedProject,
    handleSubmit,
    //isLocked,
    //pristine,
    submitting,
    submittingImages,
    classes,
    proceedSubmit,
    isPublished,
    respondentsCount
  } = props;
  
  const disableSubmit = submitting || submittingImages;
  const submitButton = <Fab color="secondary" type="submit" size="large" disabled={disableSubmit}><ArrowForwardIcon /></Fab>;

  // const selector = formValueSelector(formNames.DISCUSSION_FORM_NAME);
  // const isPublished = selector(state, 'published');

  return (
    <div>
        <form onSubmit={handleSubmit(values => proceedSubmit(values))} className={classes.customForm}>
           
          <div className="maxWidthPart">
          <Grid container>
            
            
            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="name" component={TextInput} label={TranslatorUtil.t("Name")} inputProps={{maxLength: 50}} />
              </FormGroup>
            </Grid>

            {respondentsCount > 0 ? <Grid item className="gridItem" xs={6}>
                <FormGroup>
                <div className="inlineCheckboxGroup">
                  <Field name="published" component={CheckboxInput} label={TranslatorUtil.t("Published")} />
                  </div>
                </FormGroup>
                
                {!isPublished ? <React.Fragment>
                  <br/>
                  <Divider />
                  <FormGroup>
                  <Field name="publishDate" component={NativeDateInput} label={TranslatorUtil.t("Publish date")} />
                </FormGroup>
                <FormGroup>
                  <Field name="publishTime" component={NativeTimeInput} label={TranslatorUtil.t("Publish time")} />
                </FormGroup>
                <Divider />
                <br/>
                </React.Fragment> : null}
                
              </Grid> : <Box p={1}><Alert severity="error">{TranslatorUtil.t("Discussion cannot be published as project has no respondent")}</Alert></Box> }

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="color" component={SelectInput} label={TranslatorUtil.t("Color")} dataOptions={colors} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="description" component={WysiwygInput} multiline rowsMax="4" label={TranslatorUtil.t("Introduction")} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="images" component={FileInput} multiple={true} label={TranslatorUtil.t("Images")+" ("+TranslatorUtil.t("max 8MB per file")+")"} fileAccess={"image/*"} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <FormGroup>
                <Field name="youtubeEmbedLink" component={TextInput} rowsMax="4" label={TranslatorUtil.t("Youtube Embed Link")} />
              </FormGroup>
            </Grid>

            <Grid item className="gridItem" xs={12} lg={7}>
              <Box py={2}>
                <Divider />
              </Box>
            </Grid>

            {
              objectPath.get(selectedProject, "disableDisscussionsLock", false) ? <Grid item className="gridItem" xs={12} lg={7}>
              <Box py={2}>
                <Alert severity="info">{TranslatorUtil.t("Discussion locking is disabled for this project")}</Alert>
              </Box>
            </Grid> : <Grid item className="gridItem" xs={12} lg={7}>
              <Grid item className="gridItem" xs={6}>
                <FormGroup>
                  <Field name="lockDate" component={NativeDateInput} label={TranslatorUtil.t("Lock date")} />
                </FormGroup>
              </Grid>
              <Grid item className="gridItem" xs={6}>
                <FormGroup>
                  <Field name="lockTime" component={NativeTimeInput} label={TranslatorUtil.t("Lock time")} />
                </FormGroup>
              </Grid>
            </Grid>
            }
            
            
            <Grid item className={classnames("gridItem",classes.textRightStrong)} xs={12} lg={7}>
              { disableSubmit ? submitButton : <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("OK")}>{submitButton}</Tooltip> }
            </Grid>
          </Grid>
          </div>
        </form>
    </div>
  );
};

DiscussionForm = reduxForm({
  form: formNames.DISCUSSION_FORM_NAME, // a unique identifier for this form
  validate,
})(DiscussionForm)

const selector = formValueSelector(formNames.DISCUSSION_FORM_NAME) // <-- same as form name
DiscussionForm = connect(
  state => {
    // can select values individually
    const isPublished = selector(state, 'published');
    return {
      isPublished
    }
  }
)(DiscussionForm)

export default withStyles(styles, { withTheme: true })(DiscussionForm);
