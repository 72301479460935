import {
  Badge,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import objectPath from "object-path";
import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../theme/styles";
import DateUtil from "../../utils/DateUtil";
import AccessUtil from "../../utils/projectBased/AccessUtil";
import TranslatorUtil from "../../utils/TranslatorUtil";
import UserAvatar from "./UserAvatar";
import UserTags from "./UserTags";
import UsersUtil from "../../utils/projectBased/UsersUtil";
import ArrayUtil from "../../utils/ArrayUtil";
import WarningIcon from "@material-ui/icons/Warning";
import { Alert, AlertTitle } from "@material-ui/lab";

class RespondentsProjectStats extends React.Component {

  getCountPropName = () => {
    const { discussionId, projectId } = this.props;
    return discussionId ? "messagesCount_p_" + projectId + "_d_" + discussionId : "messagesCount_p_" + projectId;
  }

  renderRows = () => {
    const { profiles, projectId, discussions, discussionId } = this.props;

    let rows = [];

    if (profiles) {
      let sortedProfiles = ArrayUtil.sortByNumberProp(
        profiles,
        this.getCountPropName(),
        true
      );
      rows = sortedProfiles.map((row, index) => {
        // console.log("sortedProfiles row",row.id);
        return <TableRow key={index} className={row.id}>
            <TableCell>{this.renderUserProfile(row)}</TableCell>
            <TableCell>
              <small className="muted">{row.lastMessageDateTime
                ? DateUtil.displayTimeFromNow(
                    row.lastMessageDateTime,
                    TranslatorUtil.getUserLangFromStore()
                  )
                : " "}</small>
            </TableCell>
            <TableCell>
              {objectPath.get(
                row,
                this.getCountPropName(),
                <WarningIcon fontSize="small" color="error" />
              )}
            </TableCell>
            { !discussionId ? <TableCell>
            {discussions.map((discussion) => (
              <ListItem dense className={"p-0"} key={discussion.id}>
                <ListItemText
                  primary={
                    <Badge
                      color={objectPath.get(
                        row,
                        "messagesCount_p_" + projectId + "_d_" + discussion.id,
                        0
                      ) > 0 ? "primary" : "default"}
                      badgeContent={objectPath.get(
                        row,
                        "messagesCount_p_" + projectId + "_d_" + discussion.id,
                        <WarningIcon fontSize="small" color="error" />
                      )}
                    >
                      {discussion.name}
                    </Badge>
                  }
                />
              </ListItem>
            ))}
            </TableCell> : null }
          </TableRow>
      });
    }

    return rows;
  };

  renderHeaderCell = (content, className = null) => {
    return (
      <TableCell className={className}>
        <strong>{content}</strong>
      </TableCell>
    );
  };

  renderHeader = () => {
    const { discussionId } = this.props;
    return (
      <TableRow>
        {this.renderHeaderCell(TranslatorUtil.t(""))}
        {this.renderHeaderCell(TranslatorUtil.t("Last activity"))}
        { this.renderHeaderCell(discussionId ? TranslatorUtil.t("Reactions count") : TranslatorUtil.t("Project messages count"))}
        { !discussionId && this.renderHeaderCell(TranslatorUtil.t("Discussions"))}
      </TableRow>
    );
  };

  

  renderUserProfile = (user) => {
    const { projectId, profiles, discussionId } = this.props;
    const tags = UsersUtil.getUserTagsByUserId(user.uid);
    const badgeIcon = discussionId ? UsersUtil.getUserProjectDiscussionIcon(user, projectId, discussionId, profiles) : UsersUtil.getUserProjectIcon(user, projectId, profiles);
    return (
      <ListItem dense className={"p-0 user-list-item-wrap"}>
        <ListItemIcon className="user-avatar-wrap">
          <Badge classes={{badge: "bg-white"}} className="user-avatar-badge" invisible={badgeIcon === null} badgeContent={<React.Fragment>{badgeIcon}
            {/* <span> {UsersUtil.getUserProjectMessagesCount(user, projectId)}</span> */}
            </React.Fragment>}>
          <UserAvatar
            userId={user.id}
            menuItems={{
              withChat: AccessUtil.isAtLeastModeratorForProject(projectId),
              withSupport: AccessUtil.isAtLeastModeratorForProject(projectId),
              withDialog: AccessUtil.isAtLeastObserver(),
            }}
          />
          </Badge>
          
        </ListItemIcon>
        <ListItemText
          primary={<span className="user-name-wrap">{user.displayName}</span>}
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            <div className="">
              <Typography
                component="div"
                variant="caption"
                className="border-bottom-primary no-wrap mb-2 user-email-wrap"
              >
                {user.email}
              </Typography>
              {<UserTags user={user} tags={tags} />}
            </div>
          }
        />
      </ListItem>
    );
  };

  render() {
    const { profiles, projectId, classes, invitationsWithoutProfiles } = this.props;

    return projectId ? (<Box className="wide-table-outer-wrap">
      <Alert severity="info">
      <AlertTitle>{TranslatorUtil.t("Total invitations")}: <strong>{profiles.length + invitationsWithoutProfiles.length}</strong></AlertTitle>
        <small>{TranslatorUtil.t("Accepted invitations")}: {profiles.length}<br />
        {TranslatorUtil.t("Unaccepted invitations")}: {invitationsWithoutProfiles.length}
        </small>
        </Alert>
      <Box className="wide-table-wrap">{profiles && 
      profiles.length ? (
        <Table className={classnames(classes.table)}>
        <TableHead>{this.renderHeader()}</TableHead>
        <TableBody>{this.renderRows()}</TableBody>
      </Table>  
      ) : null}
      {invitationsWithoutProfiles && invitationsWithoutProfiles.length ? (
        <Box><Table className={classnames(classes.table)}>
        <TableHead><TableRow>{this.renderHeaderCell(TranslatorUtil.t("Not yet accepted invitations"))}</TableRow></TableHead>
        <TableBody>{invitationsWithoutProfiles.map((email, index) => <TableRow key={index}>
        <TableCell>{email}</TableCell></TableRow>)}</TableBody>
      </Table></Box>  
      ) : null}
      </Box></Box>
      
    ) : null;
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(RespondentsProjectStats)
);
