import React from "react";
import { Avatar } from "@material-ui/core";

const values = [
    '001-man',
    '002-girl',
    '003-boy',
    '004-woman',
    '005-man-1',
    '006-woman-1',
    '007-boy-1',
    '008-clown',
    '009-firefighter',
    '010-girl-1',
    '011-man-2',
    '012-woman-2',
    '013-woman-3',
    '014-man-3',
    '015-woman-4',
    '016-boy-2',
    '017-girl-2',
    '018-boy-3',
    '019-woman-5',
    '020-man-4',
    '021-girl-3',
    '022-man-5',
    '023-man-6',
    '024-woman-6',
    '025-boy-4',
    '026-girl-4',
    '027-man-7',
    '028-woman-7',
    '029-man-8',
    '030-policewoman',
    '031-policeman',
    '032-girl-5',
    '033-superhero',
    '034-woman-8',
    '035-woman-9',
    '036-man-9',
    '037-arab-woman',
    '038-man-10',
    '039-woman-10',
    '040-man-11',
    '041-woman-11',
    '042-vampire',
    '043-chef',
    '044-farmer',
    '045-man-12',
    '046-woman-12',
    '047-man-13',
    '048-boy-5',
    '049-thief',
    '050-catwoman',
    '051-american-football-player',
    '052-witch',
    '053-concierge',
    '054-woman-13',
    '055-bodybuilder',
    '056-girl-6',
    '057-woman-14',
    '058-death',
    '059-doctor',
    '060-doctor-1',
    '061-nun',
    '062-builder',
    '063-girl-7',
    '064-punk',
    '065-pinup',
    '066-boy-6',
    '067-man-14',
    '068-girl-8',
    '069-woman-15',
    '070-man-15'
    ];

    export default values.map(function (avatar) {
        return {
        "value": avatar, "label": <Avatar alt={avatar} src={"/assets/avatars/"+avatar+".png"}></Avatar>
    }});