import { Checkbox, FormControlLabel, FormGroup, List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import objectPath from 'object-path';
import React from 'react';
import { withRouter } from "react-router-dom";
import styles from '../../theme/styles';
import ArrayUtil from '../../utils/ArrayUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import UsersUtil from '../../utils/projectBased/UsersUtil';
import MessageItem from './MessageItem';


class MessagesList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showOnlyUnread: false,
      filterByUserId: null
    }
  }


  renderReplies = (message,replies) => {
    const { likes, currentUserId, onLikeDislike, onMessageReply, projectId, isLocked, onDiscussMessageWithModerator, onToggleMessageRead, profiles, onToggleMessageStarred } = this.props;
    return replies.filter(x => objectPath.get(x,'replyToMessageId') === message.id).length ? 
              <React.Fragment><List component="div" className="replies" disablePadding>
                 {
                   replies.filter(x => objectPath.get(x,'replyToMessageId') === message.id).map((childMessage, childIndex) => <React.Fragment key={childMessage.id}><MessageItem 
                   projectId={projectId}
                   disableAvatarActions={false}
                   disableReactions={isLocked || UsersUtil.getCurrentUserUid() === childMessage.byUserId}
                   key={childIndex}
                   message={childMessage} 
                   likes={likes} 
                   currentUserId={currentUserId} 
                   onLikeDislike={onLikeDislike}
                   onMessageReply={onMessageReply}
                   onReplyText=""
                   onDiscussMessageWithModerator={onDiscussMessageWithModerator}
                   onToggleMessageRead={!this.isRead(childMessage) && onToggleMessageRead ? onToggleMessageRead : null}
                   isRead={this.isRead(childMessage)}
                   avatarBadgeContent={UsersUtil.getUserProjectIcon(profiles.find(x => x.uid === childMessage.byUserId), projectId, profiles)}
                   showCopyMessageLink={AccessUtil.isAtLeastModeratorForProject(projectId)}
                   onToggleMessageStarred={onToggleMessageStarred}
                   />
                   {this.renderReplies(childMessage,replies)}
                    </React.Fragment>
                  )
                 }
               </List>
               
               </React.Fragment> : null;
  }
  
  isRead = (message) => {
    const { discussionUserReadMessageIds } = this.props;
    if(!discussionUserReadMessageIds.includes(message.id)){
      // console.log(message.byUserId,UsersUtil.getCurrentUserUid());
    }
    
    return message && (message.byUserId === UsersUtil.getCurrentUserUid() || discussionUserReadMessageIds.includes(message.id));
  }

  render() {
    const { items, oneSubmitted, likes, currentUserId, onLikeDislike, onMessageReply, projectId, isLocked, onDiscussMessageWithModerator, onToggleMessageRead, profiles, onMessageEdit, onToggleMessageStarred } = this.props;
    const { showOnlyUnread, filterByUserId } = this.state;

    var sortedItems = items || [];
    sortedItems = ArrayUtil.sortByDateCreated(items, true);
    var sortedReplies = sortedItems.filter(x => objectPath.has(x,'replyToMessageId'));
    const isFilterOn = showOnlyUnread || filterByUserId !== null;
    
    const showMessages = oneSubmitted || isLocked || AccessUtil.isAtLeastObserverForProject(projectId);
    const filteredMessages = sortedItems.filter(x => showOnlyUnread ? !this.isRead(x) : true).filter(x => isFilterOn ? true : !objectPath.has(x,'replyToMessageId'));


    return (<div className={"narrowMaxMediumWidthPart ml-auto"}>
      {showMessages && <FormGroup row className="justify-content-end">
      <FormControlLabel
        control={<Checkbox checked={showOnlyUnread} onChange={() => this.setState({ showOnlyUnread: !showOnlyUnread })} name="unreadOnly" />}
        label={TranslatorUtil.t("Show unread only")}
      />
      </FormGroup>}
      {showMessages ? (
        <List>
          {filteredMessages.length === 0 ? <Alert severity="info">{TranslatorUtil.t("No messages")}</Alert> : null}
          {filteredMessages.map((message, index) =>
          <React.Fragment key={index}>
            <MessageItem 
              onMessageEdit={onMessageEdit}
              projectId={projectId}
              disableAvatarActions={false}
              message={message} 
              likes={likes} 
              disableReactions={isLocked || UsersUtil.getCurrentUserUid() === message.byUserId}
              currentUserId={currentUserId} 
              onLikeDislike={onLikeDislike} 
              onMessageReply={onMessageReply}
              onReplyText={TranslatorUtil.t("Reply")}
              onDiscussMessageWithModerator={onDiscussMessageWithModerator}
              onToggleMessageRead={!this.isRead(message) && onToggleMessageRead ? onToggleMessageRead : null}
              isRead={this.isRead(message)}
              avatarBadgeContent={UsersUtil.getUserProjectIcon(profiles.find(x => x.uid === message.byUserId), projectId, profiles)}
              showCopyMessageLink={AccessUtil.isAtLeastModeratorForProject(projectId)}
              onToggleMessageStarred={onToggleMessageStarred}
              />
              
              {!isFilterOn ? this.renderReplies(message,sortedReplies) : null}
               
              
            
            </React.Fragment>)}
        </List>
        
      ) : <Alert severity="info">{TranslatorUtil.t("Please submit a message to see opinions of others")}</Alert>}</div>);
  }
}


export default withRouter(withStyles(styles, { withTheme: true })(MessagesList));