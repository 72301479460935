import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PageHeading from "../../../components/PageHeading";

import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import * as profileActions from "../../../actions/profile.js";
import * as translationActions from "../../../actions/translations.js";

import { Avatar, List, ListItem, ListItemAvatar, Typography } from "@material-ui/core";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ComponentLoading from "../../../components/ComponentLoading";
import FormatterUtil from "../../../utils/FormatterUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";

const entityName = "Company";


class CompanyDetail extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      item: null,
      dataLoading: true
    }
  }

  componentDidMount(){
    Promise.all([
    ]).then(() =>
      this.setState({
        dataLoading: false
      })
      );
  }


  // migrateProfiles = () => {
  //   const { profiles } = this.props;
  //   console.log(profiles);
  //   // Promise.all([
  //     profiles.filter(x => x.id !== x.uid).map(x => {
  //       let {id: _id, ...rest} = x;
        
  //       // console.log(x.uid + ' will set data to ',rest);
  //       // console.log('delete profile by '+_id, rest);
  //       // return firestoreApi.collection("profiles").doc(x.uid).set(rest);
  //       // return firestoreApi.collection("profiles").doc(_id).delete();
  //     })
  //   // ]);
  // }





  render() {
    const { dataLoading } = this.state;
    

    return (
      <div>
        <PageHeading heading={TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName))+ ' - '+ CompanyUtil.getName()}  />

      { dataLoading ? <ComponentLoading /> : 
      <List className={"narrowMaxMediumWidthPart roleUsersList"}>
    
    <ListItem disableGutters={false} divider={true}>
            
          <ListItemAvatar><Avatar className="primary-bg"><SupervisorAccountIcon /></Avatar></ListItemAvatar><Typography variant="h2">{TranslatorUtil.t('Company admins')}</Typography>
          </ListItem> 

    {CompanyUtil.getCurrentCompanyAdminEmails().map((email, index) => 
      <ListItem key={index} disableGutters={true}>
        {email}
    </ListItem> 
      
    )}
    </List>
      
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  profiles: state.profiles,
});

const mapDispatchToProps = dispatch =>
({
  actions: bindActionCreators(
    {
      ...profileActions,
      ...translationActions,
    },
    dispatch
  )
});


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyDetail)
));