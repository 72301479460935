import { primaryPalette } from "../../theme/constants/colors";

export default (theme, themeConfig, themeColors) => {
  return {
      appLoader:{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2000,
      
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& div':{
        backgroundColor: 'transparent !important',
        display: 'block',
        margin: 'auto',
        textAlign: 'center',
        color: primaryPalette.white,
        '& div svg':{
          width: 40,
        }
      },
      '& > div:first-child': {
        marginTop: -2*themeConfig.toolbarMinHeight,
        paddingLeft: 0
      },
      backgroundImage: "url("+themeConfig.publicBackgroundSvg+")",
      backgroundSize: "cover",
    },
    pleaseWait:{
      color: primaryPalette.white,
    },
    componentLoading: {
      maxWidth: 300,
      margin: 'auto',
      padding: '1em',
      paddingTop: '5em',
      
      textAlign: 'center'
    },
  }
}