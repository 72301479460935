function setSpacing (vertical = 0, horizontal = 0, unit = 'px'){
  return vertical+unit+' '+horizontal+unit;
}

function setImportant (value){
    return value+' !important';
}
const drawerWidthLevel_1 = 245;

  export default (theme) => {
    return {
        drawerWidthLevel_1,
        drawerWidthLevel_2 : drawerWidthLevel_1*2,
        drawerWidthLevel_3 : drawerWidthLevel_1*3,
        customShadow : '0 0 17px 0 rgba(8,49,70,0.07)',
        publicBackgroundSvg : '/assets/images/trianglify.svg',
        toolbarMinHeight : 64,
        commonPadding : 15,
        customMaxWidth : 1200,
        customNarrowMaxWidthPart: 500,
        customNarrowMaxMediumWidthPart: 800,
        smallFontSize: 13,
        fullscreenDialogContentMaxWidth: 1200,
        setSpacing,
        setImportant   
  }
}