import React from "react";
import { FormControl, InputBase, Grid, FormGroup } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles'
import classnames from 'classnames';
import InputHelperText from "./InputHelperText";
import LabelTooltip from "./LabelTooltip";

export default withStyles(styles, { withTheme: true })(({
  props,
  label,
  input,
  placeholder,
  helpText,
  meta: { touched, invalid, error, form },
  classes,
  ...custom
}) => {


  return (
    <FormGroup  className={classnames("inputLabelColumnsComponentWrap", (touched && invalid) && 'error')}>
      <FormControl className={classnames(classes.inputLabelColumnsComponentControlWrap, (touched && invalid) && classes.error)} error={touched && invalid}>
      <Grid container>
      <Grid item xs={12} sm={5} md={12} lg={5}>
      <LabelTooltip label={label} inputName={input.name} helpText={helpText} error={error} touched={touched} formName={form} />
      
      </Grid>
      <Grid item xs={12} sm={7} md={12} lg={7}>
      <InputBase
      className="inputLabelColumnsInputWrap"
      error={touched && invalid}
        id={input.name}
          {...input}
          {...custom}
      />
      </Grid>
      </Grid>    
    </FormControl>
    {InputHelperText({ touched, error })}
    </FormGroup>
  )
});
