import store from '../store';
import objectPath from 'object-path';


export default class StoreUtil {

    static isEmptyInStore(key) {
      const storeState = store.getState();
      let storeValue = objectPath.get(storeState,key);
      return (
              (!storeValue ||
              (Array.isArray(storeValue) && storeValue.length))
            ) ? false : true;
    }

    static getValueByKey(key, defaultValue = null) {
      const storeState = store.getState();
      return objectPath.get(storeState,key, defaultValue);
    }

    static hasFormFieldError(formName, key) {
      const formSyncErrors = StoreUtil.getValueByKey('form.'+formName+'.syncErrors');
      const anyTouched = StoreUtil.getValueByKey('form.'+formName+'.anyTouched', false);
      return objectPath.get(formSyncErrors,key, false) && anyTouched ? true : false;
    }

    static hasFormFieldValue(formName, key) {
      const formValues = StoreUtil.getValueByKey('form.'+formName+'.values');
      return objectPath.get(formValues,key, false) ? true : false;
    }
    
  }
  