import FilterUtil from "../utils/projectBased/FilterUtil"


export default {
    userInfo: {},
    drawerOpen: false,
    users: [],
    positions: [],
    strategicPlans: {},
    dashboardMergedData: {
        items : [],
        filter : FilterUtil.getDefaultFilterValues(),
        queryPage: 0
      }
};
