

export default (theme, themeConfig, themeColors, themeFonts) => {
    return {
        submenu: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            backgroundColor: themeColors.primaryPalette.white,
            left: themeConfig.drawerWidthLevel_1,
            width: themeConfig.drawerWidthLevel_1,
            boxShadow: themeConfig.customShadow,
            zIndex: theme.zIndex.drawer,
      
            [theme.breakpoints.down("sm")]: {
              position: 'static',
              width: 'auto',
              marginLeft: -themeConfig.commonPadding,
              marginRight: -themeConfig.commonPadding,
            },
            '& .submenuInnerWrap': {
              position: 'absolute',
              overflowY: 'auto',
              top: themeConfig.toolbarMinHeight,
              bottom: 0,
              borderTop: `1px solid ${themeColors.primaryPalette.grey}`,
              left: 0,
              right: 0,
              [theme.breakpoints.down("sm")]: {
                position: 'static',
              },
            },
            '& .submenuInnerWrap > div': {
              [theme.breakpoints.up("md")]: {
                paddingBottom: theme.spacing(1)*5,
              },
              boxShadow: themeConfig.customShadow,
            },
            '& .submenuInnerWrap > div > .card': {
              boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.25)',
              borderRadius: 0,
              '& .dateColumnWrap, & .positionColumnWrap': {
                flexBasis: '100%',
                maxWidth: '100%',
                '& .muted':{
                  textAlign: themeConfig.setImportant('left')
                }
              },
            },
            '& .submenuInnerWrap > div:last-child': {
              boxShadow: 'none',
            },
            '& h4':{
              paddingTop: themeConfig.commonPadding*2,
              paddingLeft: theme.spacing(1)*3,
              paddingRight: theme.spacing(1)*3,
              
              color: themeColors.primaryPalette.textPrimary
            },
            '& a': {
              paddingLeft: 0
            },
            '& .active': {
              backgroundColor: themeColors.primaryPalette.lightGrey
            }
          },
          submenuLevel_1: {
          },
          submenuLevel_2: {
            left: themeConfig.drawerWidthLevel_1,
            [theme.breakpoints.up("lg")]: {
              left: themeConfig.drawerWidthLevel_1*2,
            }
          },
          submenuBackBtn: {
            position: 'absolute',
            top: themeConfig.commonPadding,
            left: themeConfig.commonPadding
          },
          contentWithSubmenu: {
            marginLeft: themeConfig.drawerWidthLevel_1,
            [theme.breakpoints.down("sm")]: {
              marginLeft: '0 !important'
            }
          },
          contentWithSubmenuLevel_2: {
            marginLeft: themeConfig.drawerWidthLevel_1,
            [theme.breakpoints.up("lg")]: {
              marginLeft: themeConfig.drawerWidthLevel_1*2,
            }
          },
          menuItemButton: {
        
          },
          menuButton: {
            marginLeft: 'auto'
          },
          menuItem: {
            display: "block",
            padding: 0,
            height: 'auto',
            whiteSpace: 'normal',
            "& a, & .menuItemTextBtn": {
              position: "relative",
              textDecoration: "none",
              display: 'block',
              fontFamily: themeFonts.defaultFont,
              padding: themeConfig.commonPadding
            },
            "&.disabled": {
              pointerEvents: 'none !important',
              cursor: 'not-allowed !important',
              userSelect: 'none !important',  
              opacity: 0.5,
              "& a": {
                backgroundColor: 'transparent'
              }
            },
            "&.ri":{
              position: 'relative',
              opacity: 0.5,
              '& .cardRIstateIndicator':{
                display: 'block',
                position: 'absolute',
                left: 2,
                top: 6,
                '& svg': {
                  fontSize: 16,
                  color: themeColors.primaryPalette.black,
                }
              },
              '&.ri-context-type-2:not(.dueState-complete), &.ri-context-type-3.dueCommentaryState-commentarydue, &.ri-context-type-3.dueCommentaryState-quickcommentdue': {
                opacity: 1,
                
                '& .cardRIstateIndicator':{
                  backgroundColor: themeColors.primaryPalette.black,
                  borderRadius: '100%',
                  height: 4,
                  width: 4,
                  top: 18,
                  left: 8,
                  '& svg': {
                    display: 'none'
                  },
                }
              }
            }
          },
          menuItemIcon: {
            position: "absolute",
            top:12,
            left:10,
            "& svg": {
              fontSize: 20
            }
          },
          menuItemAvatar: {
            width: 24,
            height: 24
          },
          menuItemHasChips: {
            '& a > div:first-child':{
              paddingRight: 65
            }
            
          },
          menuItemChipsWrap: {
           position: "absolute",
            top:14,
            right:7,
            fontFamily: themeFonts.headingFont,
            fontWeight: 600,
            fontSize: 12,
            "& .dot":{
              display: 'block',
              height: 8,
              width: 8,
              backgroundColor:themeColors.primaryPalette.secondary,
              borderRadius: '100%',
              position: 'absolute',
              right: 0,
              top: 0
            },
            
            
          },
          menuItemText: {
            fontWeight: 600,
              paddingLeft: theme.spacing(1)*3,
              paddingRight: theme.spacing(1)*3,
            fontSize: 12,
            color: themeColors.primaryPalette.textPrimary,
            textDecoration: "none !important"
          },
          menuItemTextBtn: {
            paddingLeft: (40+theme.spacing(1))
          },
          activeMenuItem: {
            
            
          },
          summaryPageSubmenu: {
            '& ul':{
              paddingTop: 0,
              paddingBottom: 0,
            },
            '& li':{
              borderTop: '1px solid '+themeColors.primaryPalette.grey,
              '& .menuItemText':{
                paddingRight: theme.spacing(1)/2,
                paddingLeft: theme.spacing(1)*3,
                '&.backToBtn':{
                  paddingLeft: 0
                }
              }
            },
            '& li:last-child':{
              borderBottom: '1px solid '+themeColors.primaryPalette.grey
            },
            '& .ri':{
              '& .headerInfoWrap':{
              },
              '& .bodyInfoWrap':{
                paddingTop: theme.spacing(1),
                position: 'relative',
              },
              
            },
            '& .card': {
              [theme.breakpoints.down("sm")]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
              },
              '& h5': {
                fontSize: 16
              },
            }
            
          }

    }
}