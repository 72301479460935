import store from '../store';
import objectPath from 'object-path';
import * as constants from '../constants/constants';
import translations from '../translations';
import StoreUtil from './StoreUtil';
import { replace } from 'lodash';

export default class TranslatorUtil {

  static t(name, params = {}, l = constants.DEFAULT_TRANSLATION_LANG){
    if(name && name.length){
      let storeTranslation = store ? objectPath.get(store.getState(), 'translations') : null;
      let translation = objectPath.get(storeTranslation, name, name);
      let paramsKeys = Object.keys(params);
      if(paramsKeys.length){
        paramsKeys.forEach(paramKey => {
          translation = replace(translation,"{:"+paramKey+"}",params[paramKey]);
        });
      }
      return translation;
    }
    return '';
  }

  static tb(name, params = {}, l = constants.DEFAULT_TRANSLATION_LANG){
    if(name && name.length){
      let storeTranslation = store ? objectPath.get(store.getState(), 'translations') : null;
      let translation = objectPath.get(storeTranslation, name, name);
      let paramsKeys = Object.keys(params);
      if(paramsKeys.length){
        paramsKeys.forEach(paramKey => {
          translation = replace(translation,"{:"+paramKey+"}","<b>"+params[paramKey]+"</b>");
        });
      }
      return translation;
    }
    return '';
  }

  static getLangsOptions(){
    return Object.keys(translations).map(function(x){
      return {"label": x,
      "value": x}
    });
  }

  static getUserLangFromStore(){
    return StoreUtil.getValueByKey('userInfo.profile.langCode', constants.DEFAULT_TRANSLATION_LANG);
  }

}
