import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import { Typography, Box } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog(props) {

  const { closeDialog, children, classes, showBar, title } = props;

  return (
      <Dialog fullScreen open={true} 
      onClose={closeDialog} 
      TransitionComponent={Transition} 
      className={classes.fullscreenDialogWrap}>
        { showBar === false ? null : <AppBar>
          <Toolbar className="fullscreenDialogWrapBar">
          
            
            {title ? <Typography style={{
              flex: 1,
            }} variant="h5">
            {title}
            </Typography> : null }
            <IconButton color="inherit" onClick={closeDialog} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar> }
        <Box className={classes.fullscreenDialogContent}>
          <div>{ children ? children : null }</div>
        </Box>
      </Dialog>
  );
}

export default withStyles(styles)(FullScreenDialog);


