// src/firebase.js
import firebase from 'firebase'
//import firestore from 'firebase/firestore'

import env from './config/env';

import prodConfig from './config/PROD';
import devConfig from './config/DEV';
import localConfig from './config/LOCAL';

const projectConfig = env.CURRENT === env.PROD ? prodConfig : (env.CURRENT === env.DEV ? devConfig : localConfig);

const config = {
    apiKey: projectConfig.api_key,
    authDomain: (projectConfig.project_id+".web.app"),
    databaseURL: "https://"+projectConfig.project_id+".web.app",
    projectId: projectConfig.project_id,
    storageBucket: (projectConfig.project_id+".appspot.com"),
};

const firebaseApp = firebase.initializeApp(config);
const firestoreApi = firebaseApp.firestore();


if(env.CURRENT === env.LOCAL){
    console.log('running local DB');
    firestoreApi.useEmulator("localhost", config.port);
    firestoreApi.settings({ experimentalAutoDetectLongPolling: true });
}

export const auth = firebase.auth();
export const firestorageApi = firebaseApp.storage();
export const firedatabaseApi = firebaseApp.database();
export const Timestamp = firebase.firestore.Timestamp;

export default firestoreApi;