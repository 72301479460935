import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import classnames from "classnames";

function Logo(props) {


    const { classes, inNavbar, showAppName } = props;

  return <div className={classnames(classes.logo, inNavbar && classes.navbarLogo)+" logo-wrap"}>
  {showAppName ? <span className='logo-text'><span className='primary-text'>Chat</span><span className='text-black'>Lab</span></span> : null}
</div>
}

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Logo);