import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import * as constants from '../constants/constants';
import Formatter from "../utils/FormatterUtil";
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from "@material-ui/icons/InsertDriveFile";
import UiHelperUtil from "../utils/UiHelperUtil";
import {
    Avatar,
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    ListItemAvatar,
    Hidden
  } from "@material-ui/core";
import ArrayUtil from '../utils/ArrayUtil';
import FullScreenDialog from './FullScreenDialog';
import ImageFallback from './ImageFallback';
import objectPath from 'object-path';


class FileList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showImage: null,
        }
      }


      renderDialog = () => {
          const { showImage } = this.state;
          if(showImage && this.isImage(showImage)){
              return <FullScreenDialog 
              title={showImage.name} 
              showBar={true} 
              closeDialog={() => this.setState({showImage: null})}>
              <ImageFallback 
                        className="fluid-image"
                        src={this.getFullImagePath(showImage)}
                        initialimg={constants.IMAGE_LOADER_PATH}
                        fallbackimg={constants.BROKEN_IMAGE_PATH}
                        alt={showImage.name} /> 
              </FullScreenDialog>
          }
          return null;
      }

      getThumbnailPath = (f) => {
        return f ? (f.base64String ? f.base64String : objectPath.get(f,'downloadUrl','')) : '';
      }

      getFullImagePath = (f) => {
        return f ? (f.base64String ? f.base64String : objectPath.get(f,'downloadUrl','')) : '';
      }

      isImage = (file) => {
        return file && file.type && file.type.indexOf("image/") !== -1;
      }


      render () {
        const { files, disabled, classes, removeFileFromList } = this.props;
          return ArrayUtil.isNonEmptyArray(files) ? <div><List classes={{root: classes.ImageList}}>

            {files.map((f, index) => {
                return (
                <ListItem key={index}>
                    <ListItemAvatar onClick={()=> this.setState({showImage : f})}>
                    { this.isImage(f) ?
                    <Avatar className={'imgAvatar'}>
                        <ImageFallback 
                        className="fluid-image"
                        src={this.getThumbnailPath(f)}
                        fallbackimg={this.getFullImagePath(f)}
                        initialimg={constants.IMAGE_LOADER_PATH}
                        alt={f.name} />
                    </Avatar> : <Avatar>
                        <FileIcon />
                    </Avatar> }
                    </ListItemAvatar>
                    <Hidden xsDown>
                        <ListItemText onClick={()=> this.setState({showImage : f})}
                        primary={UiHelperUtil.truncateIfNeeded(f.name,200)}
                        classes={{
                            primary: "primaryListText",
                            secondary: "secondaryListText"
                        }}
                        secondary={f.size ? Formatter.bytesToSize(f.size) : ""}
                        />
                    </Hidden>
                    <Hidden smUp>
                        <ListItemText onClick={()=> this.setState({showImage : f})}
                        primary={UiHelperUtil.truncateIfNeeded(f.name,20)}
                        classes={{
                            primary: "primaryListText",
                            secondary: "secondaryListText"
                        }}
                        secondary={f.size ? Formatter.bytesToSize(f.size) : ""}
                        />
                    </Hidden>
                    { !disabled ? <ListItemSecondaryAction>
                        <IconButton aria-label="Delete" onClick={()=>removeFileFromList(index)}>
                            <DeleteIcon color="error" />
                        </IconButton>
                        </ListItemSecondaryAction> : null }
                </ListItem>
                );
            })}
            </List>
            {this.renderDialog()}
            </div> : null;
        }
}

export default withStyles(styles)(FileList);


