import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
import { withSnackbar } from "notistack";
import ServerErrorUtil from '../utils/ServerErrorUtil';

class GetErrorsListener extends React.Component {

    

    componentDidUpdate(){
        const { error, enqueueSnackbar } = this.props;
        
        if(ServerErrorUtil.isGetErrorWithoutCustomHandler(error)){
            enqueueSnackbar(ServerErrorUtil.getErrorMessageFromStore(error), {
                variant: "error"
            });
        }
    }



    render(){
      return null;
    }
  
}




function mapStateToProps(state) {
    return {
      error: state.lastRequestError,
    };
  }

export default withSnackbar(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps
)(GetErrorsListener)));


