
import React from 'react';
import { DialogContent, Dialog, DialogTitle, Typography } from "@material-ui/core";

function AppDialog(props) {
    const { onClose, message, title, actions, warningStyle, maxWidth, children } = props;
  
  
  
    return (
      <Dialog onClose={onClose} open={true} className="appDialog" maxWidth={maxWidth}>
        <DialogTitle><strong>{title}</strong></DialogTitle>
        <DialogContent className="appDialogContent">
            {message ? <Typography component="div" variant="body2" color={warningStyle ? "error" : "default"}>{message}</Typography> : null}
            {children}
        </DialogContent>
       
            {actions}
      </Dialog>
    );
  }
  
  
  
  export default AppDialog;