import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../../actions/notification";
import * as supportActions from "../../../actions/support";
import * as formNames from "../../../constants/forms";
import SupportForm from "../../../forms/support/form";
import styles from '../../../theme/styles';

import { Box, Button, Hidden, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import ContactsIcon from '@material-ui/icons/Contacts';
import { Alert } from '@material-ui/lab';
import classnames from 'classnames';
import { withSnackbar } from "notistack";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { change } from 'redux-form';
import ComponentLoading from "../../../components/ComponentLoading";
import FullScreenDialog from "../../../components/FullScreenDialog";
import SmallDialog from "../../../components/dialogs/SmallDialog";
import MessageItem from "../../../components/messages/MessageItem";
import SupportMessagesList from "../../../components/support/SupportMessagesList";
import UserAvatar from "../../../components/user/UserAvatar";
import ArrayUtil from "../../../utils/ArrayUtil";
import DateUtil from "../../../utils/DateUtil";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UiHelperUtil from "../../../utils/UiHelperUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import NotificationUtil from "../../../utils/projectBased/NotificationUtil";

const entityFormName = formNames.SUPPORT_CONVERSATION_FORM_NAME;


class Support extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      selectedSupportConversation: null,
      showModalContacts: false
    }
  }

  getMessageToUserId = () => {
    const { match, profiles } = this.props;
    var userId = objectPath.get(match,'params.userToId',null);
    return profiles.find(x => x.uid === userId) 
    && userId 
    && UsersUtil.getCurrentUserUid() !== userId //not current user
    ? userId : null;
  }

  getConversationAnotherUserId = (conversation) => {
    var currentUserId = UsersUtil.getCurrentUserUid();
    var userIds = objectPath.get(conversation, 'userIds',[]);
    return userIds.find(x => x !== currentUserId);
  }

  scrollMessagerToTop = (eId = "supportMessagesWrap") => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      if(document.getElementById(eId)){
        document.getElementById(eId).scrollTo(0, 0);
      }
    }, 300);
  }

  componentDidMount(){
    
    this.setState({
      loading: true
    });
    Promise.all([
      this.listenSupportConversations(),
    ]).then(() => {
      Promise.all([
      ]).then(() => {
        this.setState({
          loading: false
        });
        this.scrollMessagerToTop();
      });
    }
    );
  }

  componentWillReceiveProps(nextProps){
    this.redirectToLatest(nextProps);
    this.mountCurrentSupportConversation(nextProps);
  }



  redirectToLatest = (nextProps) => {
    const { supportConversations, match, history } = nextProps || this.props;
    var userToId = objectPath.get(match, 'params.userToId', null);
    var currentUserId = UsersUtil.getCurrentUserUid();
    if(currentUserId && userToId === null && ArrayUtil.isNonEmptyArray(supportConversations)){
      var latest = ArrayUtil.sortByDateUpdated(supportConversations)[0];
      if(latest && ArrayUtil.isNonEmptyArray(latest.userIds)){
        history.push(UrlBuilderUtil.getSupport(latest.userIds.find(x => x !== currentUserId)));
      }
    }
  }

  mountCurrentSupportConversation = (nextProps) => {
    const { supportConversations, match, actions } = nextProps || this.props;
    const { selectedSupportConversation } = this.state;
      if(supportConversations && supportConversations.length){
        var currentUserId = UsersUtil.getCurrentUserUid();
        var userToId = objectPath.get(match, 'params.userToId');
        
        
        var newSelectedSupportConversation = supportConversations.find(
          x => {
            var userIds = objectPath.get(x, 'userIds',[]);
          return userIds.length && userIds.indexOf(currentUserId) > -1 && userIds.indexOf(userToId) > -1
          } 
        );

        //console.log('mountCurrentSupportConversation', {userInfo, supportConversations, match, currentUserId, userToId, newSelectedSupportConversation})

        if(objectPath.get(selectedSupportConversation,'id',null) !== objectPath.get(newSelectedSupportConversation,'id',null)){
          this.scrollMessagerToTop();
          this.setState({
            selectedSupportConversation : newSelectedSupportConversation
          });
          if(newSelectedSupportConversation){
            
            actions.listenSupportConversationMessages(newSelectedSupportConversation.id);
          }
        }
        
      }
    
    
    
  }


  componentWillUnmount(){
    //const { actions } = this.props;
    //maybe remove listening
    //actions.flushDiscussionMessages()
  }


  listenSupportConversations = () => {
    const { actions, userInfo } = this.props;
    if(userInfo){
      actions.listenSupportConversations(UsersUtil.getCurrentUserUid());
    }
  }



  handleResponse = (response, values) => {
    const { enqueueSnackbar } = this.props;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      this.scrollMessagerToTop();
      enqueueSnackbar(TranslatorUtil.t("Message has been sent"), { variant: "success"});
      this.addMessageNotification(values,this.getMessageToUserId());
    }
  }

  addMessageNotification = async (message,toUserId) => {
    const { actions, userInfo } = this.props;
    const { selectedSupportConversation } = this.state;
    const selectedSupportConversationId = objectPath.get(selectedSupportConversation,'id',null);
    if(selectedSupportConversationId){
      const isNewNotification = await NotificationUtil.isNewNotification(selectedSupportConversationId);
      const notifObject = EmailUtil.getSupportNotification(
        CompanyUtil.getId(),
        UsersUtil.getCurrentUserUid(),
        UsersUtil.getUserEmailFromStoreById(toUserId),
        message,
        UsersUtil.getUserDisplayNameByUserInfo(userInfo)
        );
      if(isNewNotification){
        notifObject.createdDate = DateUtil.timestamp();
      }
      return actions.putNotification(selectedSupportConversationId, notifObject);
    } else {
      return Promise.resolve();
    }
  }

  createConversation = () => {
    const { actions } = this.props;
    return actions.postSupportConversation({
      userIds: [UsersUtil.getCurrentUserUid(), this.getMessageToUserId()]
    })
  }


  handleSubmit = values => {
    const { actions, supportConversations } = this.props;
    const { selectedSupportConversation } = this.state;
    values.directSupportMessage = true;
    values.byUserId = UsersUtil.getCurrentUserUid();
    if(selectedSupportConversation){
      //put about message
      return actions.updateSupportConversationByNewMessage(supportConversations.find(x => x.id === selectedSupportConversation.id), values).then(() => actions.postSupportConversationMessage(selectedSupportConversation.id,values).then((response)=>this.handleResponse(response, values)))
    }
    
  };

  prependWithPerson = (conversation, currentUserId) => {
    return (currentUserId === objectPath.get(conversation,'lastMessageBy') ? TranslatorUtil.t('You')+': ': '');
  }

  renderConverations = () => {
    const { supportConversations, history } = this.props;
    var currentUserId = UsersUtil.getCurrentUserUid();
    return <List disablePadding={true}> {supportConversations && supportConversations.length ? 
      ArrayUtil.sortByDateUpdated(supportConversations).map((conversation, index) => {
        
        var anotherUserId = this.getConversationAnotherUserId(conversation);
        //console.log('conversation',conversation, anotherUserId);
        return currentUserId !== anotherUserId ? <ListItem divider={true} className={anotherUserId === this.getMessageToUserId() ? "active":""} key={index} onClick={() => {
          this.setState({showModalContacts:false});
          history.push(UrlBuilderUtil.getSupport(anotherUserId));
        }}>
          <ListItemIcon>
            <UserAvatar userId={anotherUserId} />
          </ListItemIcon>
          <ListItemText primary={UsersUtil.getUserDisplayNameById(anotherUserId)} secondary={conversation.lastMessageText ? <small>{UiHelperUtil.truncateIfNeeded(this.prependWithPerson(conversation,currentUserId) + conversation.lastMessageText, 35)}</small> : null} />
      </ListItem>: null})
    : <ListItem><Alert severity="warning">{TranslatorUtil.t('No conversation started yet')}</Alert></ListItem>}</List>
  }

  



  currentConversationUserPreview = () =>{
    return <div>
    <List className={"narrowMaxMediumWidthPart"} dense={true} disablePadding={true}>
     
     
       <ListItem alignItems="flex-start" dense={true} disableGutters={true}>
         
         <ListItemAvatar>
           <UserAvatar userId={this.getMessageToUserId()} menuItems={{withDialog: AccessUtil.isAtLeastObserver()}} /> 
         </ListItemAvatar>
         <ListItemText 
         primary={
         
         <Box>
          <Hidden mdDown>
          {TranslatorUtil.t('Conversation with')}
          </Hidden>
          <Hidden smUp>
          {UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}
                </Hidden>
           </Box>}
         secondary={
          <Box><Hidden mdDown>
            {UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}
          </Hidden>
          <Hidden smUp>
          <Button color="default" size="small" variant="outlined" startIcon={<ContactsIcon />} className="contactSelector" onClick={() => this.setState({showModalContacts:true})}>
                {TranslatorUtil.t('Select other conversation')}
                </Button>
                </Hidden>
                </Box>
          }>
         </ListItemText>
       
     </ListItem>  
     </List>
     
     
    </div>
  }

  showDialogMessagePreview = (projectId,discussionId,messageId) => {
    const { actions } = this.props;
    if(projectId && discussionId && messageId){
      return actions.getDiscussionMessage(projectId , discussionId , messageId).then((response) => {
        this.setState({
          messageDialogPreview: objectPath.get(response,'data',null)
        });
      });
    }
    return Promise.resolve();
  }

  cancelDialogMessagePreview = () => {
    this.setState({
      messageDialogPreview: null
    });
  }


  render() {
    const { classes, selectedSupportConversationMessages, supportConversations } = this.props;
    const { loading, selectedSupportConversation, messageDialogPreview,showModalContacts } = this.state;


    return (
      <div className={"supportContainer" + (AccessUtil.isAtLeastModerator(false) ? " moderator" : " notModerator")}>
        {AccessUtil.isAtLeastModerator(false) && <Hidden smDown>
        <div className={classnames(classes.conversationsWrap,"conversationsListInnerWrap")}>
        {
          this.renderConverations()
        }
        </div>
        </Hidden>}
        
        <div className={classnames(classes.conversationWrap,"conversationInnerWrap")}>
          <div className={classes.conversationInnerWrap}>
          
          {this.getMessageToUserId() ? ( loading ? <ComponentLoading /> : selectedSupportConversation ? 
      <div>
        <div id="supportMessagesWrap" className={classnames(classes.supportMessagesWrap,"supportMessagesWrap")}>
        <Hidden smDown>
            {this.currentConversationUserPreview()}
          </Hidden>
         
         <SupportMessagesList items={selectedSupportConversationMessages} currentUserId={UsersUtil.getCurrentUserUid()} showDialogMessagePreview={this.showDialogMessagePreview} />
        </div>
        <div  className={classnames(classes.supportFormWrap,"supportFormWrap")}>
          <SupportForm 
            proceedSubmit={this.handleSubmit} 
            initialValues={null}
          />
          <Hidden mdUp>
          {this.currentConversationUserPreview()}
          </Hidden>
        </div>
      </div>
       : <div><Button color="primary" onClick={this.createConversation}>
              <UserAvatar userId={this.getMessageToUserId()} /> {TranslatorUtil.t('Start conversation with')} {UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}
            </Button>
            </div> ) : supportConversations && supportConversations.length ? <Alert severity="info">{TranslatorUtil.t('Select conversation please')}</Alert>: null}
          </div>
      
        </div>
        {
          messageDialogPreview ? <SmallDialog
          title={TranslatorUtil.t("Message preview")}
          handleClose={this.cancelDialogMessagePreview}
          >
            <MessageItem message={messageDialogPreview} currentUserId={UsersUtil.getCurrentUserUid()} disableAvatarActions={true} disableReactions={true} />
          </SmallDialog>
           : null
        }

        {
          showModalContacts ? <FullScreenDialog
          title={TranslatorUtil.t("Select conversation")}
          closeDialog={() => this.setState({showModalContacts:false})}
          >
            {
          this.renderConverations()
        }
          </FullScreenDialog>
           : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  supportConversations: state.supportConversations,
  selectedSupportConversationMessages: state.selectedSupportConversationMessages,
  profiles: state.profiles
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...supportActions,
      ...notificationActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Support)
)));