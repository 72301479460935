import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import ReactSelect from "react-select";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Alert } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import objectPath from "object-path";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as accessActions from "../../../actions/access";
import * as notificationActions from "../../../actions/notification";
import * as previewActions from "../../../actions/preview";
import ComponentLoading from "../../../components/ComponentLoading";
import PageHeading from "../../../components/PageHeading";
import HeadingBackBtn from "../../../components/buttons/HeadingBackBtn";
import RespondentsProjectStats from "../../../components/user/RespondentsProjectStats";
import * as constants from "../../../constants/constants";
import * as collections from "../../../constants/firebaseCollections";
import firestoreApi from "../../../firebase";
import styles from "../../../theme/styles";
import ArrayUtil from "../../../utils/ArrayUtil";
import FirestoreCollectionUtil from "../../../utils/FirestoreCollectionUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import ForumIcon from "@material-ui/icons/Forum";
import PeopleIcon from "@material-ui/icons/People";
import TabPanel from "../../../components/TabPanel";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import MessageItem from "../../../components/messages/MessageItem";
import age from "../../../constants/age";
import gender from "../../../constants/gender";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExportProjectData from "../../../components/project/ExportProjectData";

const entityListUrl = "/projects/";

class ProjectStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussions: [],
      dataLoading: true,
      projectAccess: [],
      tabId: 0,
      projectMessages: [],
      discussionId: null,
      messagesFilterRespondentId: null,
      userFilterTags: [],
      filteredUserIdsByTags: [],
      onlyStarredMessages: false,
      tabLoading: false,
    };
  }

  getProject = () => {
    const { actions, match } = this.props;
    return actions.getProjectPreview(
      CompanyUtil.getId(),
      objectPath.get(match, "params.projectId", null)
    );
  };

  componentDidMount() {
    var _this = this;
    const { match } = this.props;

    if (
      AccessUtil.isAtLeastObserverForProject(
        objectPath.get(match, "params.projectId", null),
        true
      )
    ) {
      Promise.all([
        this.getProject(),
        this.mountDiscussions(),
        this.getProjectAccessItems(),
      ]).then(() => _this.setState({ dataLoading: false }));
    }
  }

  mountDiscussions = async () => {
    const { match } = this.props;
    return firestoreApi
      .collection(
        CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [
          objectPath.get(match, "params.projectId", null),
          collections.DISCUSSIONS,
        ])
      )
      .get()
      .then((querySnapshot) => {
        this.setState({
          discussions: FirestoreCollectionUtil.parseCollectionData(
            querySnapshot
          ),
        });
      });
  };

  componentWillUnmount() {}

  mountProjectMessages = async () => {
    const { selectedProject } = this.props;
    if (selectedProject) {
      return firestoreApi
        .collectionGroup(collections.MESSAGES)
        .where("projectId", "==", selectedProject.id)
        .where("isProjectRespondent", "==", true)
        .get()
        .then((querySnapshot) => {
          this.setState({
            projectMessages: ArrayUtil.sortByDateCreated(
              FirestoreCollectionUtil.parseCollectionData(querySnapshot)
            ),
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  changeTab = (event, tabId) => {
    this.setState({ tabId: tabId });
    if (tabId === 1 && this.state.projectMessages.length === 0) {
      this.setState({ tabLoading: true });
      this.mountProjectMessages().finally(() =>
        this.setState({ tabLoading: false })
      );
    }
  };

  getProjectAccessItems = () => {
    const { actions, match } = this.props;
    return actions
      .getCompanyProjectAccessItems(
        CompanyUtil.getId(),
        objectPath.get(match, "params.projectId", null)
      )
      .then((response) =>
        this.setState({ projectAccess: objectPath.get(response, "data") })
      );
  };

  getProjectProfiles = (roleType) => {
    const { projectAccess } = this.state;
    const { profiles } = this.props;
    var projectRoleProfiles = [];
    if (
      ArrayUtil.isNonEmptyArray(projectAccess) &&
      ArrayUtil.isNonEmptyArray(profiles)
    ) {
      var roleEmails = objectPath.get(
        projectAccess.find((x) => x.roleType === roleType),
        "userEmails",
        []
      );
      if (ArrayUtil.isNonEmptyArray(roleEmails)) {
        projectRoleProfiles = profiles.filter((profile) =>
          roleEmails.includes(profile.email)
        );
      }
    }
    let uniqueProjectRoleProfiles = projectRoleProfiles.filter(
      (item, index, array) =>
        array.map((x) => x.email).indexOf(item.email) === index
    );
    // console.log("uniqueProjectRoleProfiles",uniqueProjectRoleProfiles.map(x => { return {email: x.email,id: x.id}}));
    return uniqueProjectRoleProfiles;
  };

  onTagsFilterChanged = (tags) => {
    const { profiles, userTags, selectedProject } = this.props;
    this.setState({ userFilterTags: tags });
    if(ArrayUtil.isNonEmptyArray(profiles) && ArrayUtil.isNonEmptyArray(userTags) && ArrayUtil.isNonEmptyArray(tags)){
      this.setState({ messagesFilterRespondentId: null });
      const filteredUserIds = profiles.filter((profile) => {
        let profileTags = objectPath.get(
          userTags.find((x) => x.id === profile.id+"_p_"+selectedProject.id),
          "tags",
          []
        );
        if(objectPath.has(profile,"age")){
          profileTags.push("__profile__age__"+objectPath.get(profile,"age"));
        }
        if(objectPath.has(profile,"gender")){
          profileTags.push("__profile__gender__"+objectPath.get(profile,"gender"));
        }
        return tags.every((tag) => profileTags.includes(tag));
      }).map(x => x.id); 
      this.setState({ filteredUserIdsByTags: filteredUserIds });
    } else {
      this.setState({ filteredUserIdsByTags: [] });
    }
    
  };

  getFilterTags = () => {
    const { selectedProject } = this.props;
    var profileAge = age.map(x=>{
      if(x.value.indexOf("__profile__") === -1){
        x.value = "__profile__age__"+x.value;
        x.label = "("+TranslatorUtil.t("Age")+"*) "+x.label;
      }
      return x;
    });
    var profileGender = gender.map(x=>{
      if(x.value.indexOf("__profile__") === -1){
        x.value = "__profile__gender__"+x.value;
        x.label = "("+TranslatorUtil.t("Gender")+"*) "+x.label;
      }
      return x;
    });
    return profileAge.concat(
      profileGender,
      CompanyUtil.getCompanyUserTags(selectedProject ? selectedProject.id : null, true).map(x => ({
        label: x,
        value: x
      }))
    );
  }

  onUserFilterChagne = (event) => {
    this.setState({
      messagesFilterRespondentId:
        event.target.value !== "all"
          ? event.target.value
          : null,
    });
    this.onTagsFilterChanged([]);
  }

  renderRespondentFilter = () => {
    const { profiles } = this.props;
    
    const { messagesFilterRespondentId, dataLoading, projectAccess } = this.state;
      const projectRespondents = !dataLoading
      ? AccessUtil.getProjectProfiles(constants.RESPONDENTS, projectAccess, profiles)
      : [];
    return <Grid item xs={12} md={6}>
                      <FormControl className="w-100">
                        <InputLabel id="users-select-label">
                          {TranslatorUtil.t("Filter by respondent")}
                        </InputLabel>
                        <Select
                          labelId="users-select-label"
                          // readOnly={!ArrayUtil.isNonEmptyArray(userFilterTags)}
                          value={
                            messagesFilterRespondentId
                              ? messagesFilterRespondentId
                              : "all"
                          }
                          onChange={this.onUserFilterChagne}
                        >
                          <MenuItem value={"all"} key={0}>
                            <em>{TranslatorUtil.t("All")}</em>
                          </MenuItem>
                          {projectRespondents.map((profile) => (
                            <MenuItem value={profile.id} key={profile.id}>
                              {profile.displayName + "(" + profile.email + ")"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
  }

  renderStarFilter = () => {
    const { onlyStarredMessages } = this.state;
    return <Grid item xs={12} md={6}>
                      <FormControl className="w-100">
                      <FormGroup row >
                      <FormControlLabel
                        control={<Checkbox checked={onlyStarredMessages} onChange={() => this.setState({ onlyStarredMessages: !onlyStarredMessages })} name="unreadOnly" />}
                        label={TranslatorUtil.t("Only starred messages")}
                      />
                      </FormGroup>
                        
                      </FormControl>
                    </Grid>
  }

  render() {
    const {
      dataLoading,
      discussions,
      tabId,
      discussionId,
      projectMessages,
      messagesFilterRespondentId,
      userFilterTags,
      filteredUserIdsByTags,
      tabLoading,
      projectAccess,
      onlyStarredMessages
    } = this.state;
    const { selectedProject, history, profiles } = this.props;
    const projectRespondents = !dataLoading
      ? AccessUtil.getProjectProfiles(constants.RESPONDENTS, projectAccess, profiles)
      : [];
    const filterTags = !dataLoading ? this.getFilterTags() : [];
    let filteredProjectMessages = [];
    if(tabId === 1){
      filteredProjectMessages = projectMessages.filter(
        (x) => (discussionId === null || x.discussionId === discussionId) &&
          (messagesFilterRespondentId === null ||
            messagesFilterRespondentId === x.byUserId) && (onlyStarredMessages !== true ||
              onlyStarredMessages === objectPath.get(x, "isStarred", false))
      );
      if(userFilterTags.length > 0){
        filteredProjectMessages = filteredProjectMessages.filter((x) => 
          filteredUserIdsByTags.includes(x.byUserId)
        );
      }

    }  

    return (
      <div>
        <PageHeading
          heading={
            TranslatorUtil.t("Analytics of") +
            (dataLoading
              ? "..."
              : selectedProject
              ? " - " + selectedProject.name
              : TranslatorUtil.t("Not found"))
          }
          actions={
            <div>
              <HeadingBackBtn redirectToUrl={entityListUrl} />
            </div>
          }
        />
        <div>
          {dataLoading ? (
            <ComponentLoading />
          ) : selectedProject && projectRespondents.length ? (
            <Box pt={2}>
              <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                    <FormControl className="w-100">
                  <InputLabel id="dicussions-select-label">
                    {TranslatorUtil.t("Filter by discussion")}
                  </InputLabel>
                  <Select
                    labelId="dicussions-select-label"
                    value={discussionId ? discussionId : "all"}
                    onChange={(event) =>
                      this.setState({
                        discussionId:
                          event.target.value !== "all"
                            ? event.target.value
                            : null,
                        discussion:
                          event.target.value !== "all"
                            ? discussions.find(
                                (x) => x.id === event.target.value
                              )
                            : null,
                      })
                    }
                  >
                    <MenuItem value={"all"} key={0}>
                      <em>{TranslatorUtil.t("All")}</em>
                    </MenuItem>
                    {discussions.map((discussion) => (
                      <MenuItem value={discussion.id} key={discussion.id}>
                        {discussion.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl className="w-100 selectSuggestionWrap react-select__outer-wrap">
                        <Box pt={1}>
                          <ReactSelect
                            labelId="tags-select-label"
                            placeholder={TranslatorUtil.t("Filter by tags")}
                            classNamePrefix="react-select"
                            isMulti
                            isDisabled={messagesFilterRespondentId !== null}
                            // defaultValue={null}
                            isClearable
                            isSearchable
                            name="tags"
                            value={filterTags.filter(x => userFilterTags.includes(x.value))}
                            onChange={(values) => this.onTagsFilterChanged(ArrayUtil.isNonEmptyArray(values) ? values.map(x => x.value) : [])}
                            onClear={() => this.onTagsFilterChanged([])}
                            options={filterTags}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                    
                  </Grid>
                  <Box pt={2}>
              <Tabs
                value={tabId}
                onChange={this.changeTab}
                className="tabs-wrap"
                color="primary"
                indicatorColor="primary"
              >
                <Tab
                  color="primary"
                  label={
                    <Box>
                      <PeopleIcon /><Hidden xsDown> &nbsp; {TranslatorUtil.t("Respondents")}</Hidden>
                    </Box>
                  }
                />
                <Tab
                  color="primary"
                  label={
                    <Box>
                      <ForumIcon /><Hidden xsDown> &nbsp; {TranslatorUtil.t("Discussions")}</Hidden>
                    </Box>
                  }
                />
                <Tab
                  color="primary"
                  label={
                    <Box>
                      <CloudDownloadIcon /><Hidden xsDown> &nbsp; {TranslatorUtil.t("Export")}</Hidden>
                    </Box>
                  }
                />
              </Tabs>
              
              <TabPanel value={tabId} index={0}>
                <Paper>
                  <RespondentsProjectStats
                    discussionId={discussionId}
                    discussions={discussions.filter(
                      (x) => discussionId === null || x.id === discussionId
                    )}
                    profiles={userFilterTags.length > 0 ? projectRespondents.filter((x) => 
                      filteredUserIdsByTags.includes(x.id)
                    ): projectRespondents}
                    invitationsWithoutProfiles={AccessUtil.getProjectInvitationsWithoutProfiles(constants.RESPONDENTS, projectAccess, profiles)}
                    projectId={selectedProject.id}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={tabId} index={1}>
                {tabLoading ? <ComponentLoading /> : <React.Fragment>
                  <div className="mb-2">
                  <Grid container spacing={2}>
                  {this.renderRespondentFilter()}
                  {this.renderStarFilter()}
                  </Grid>
                  </div>
                  
                <div></div>
                {filteredProjectMessages.length === 0 ? (
                  <Alert severity="info">
                    {TranslatorUtil.t("No messages")}
                  </Alert>
                ) : (
                  <Alert severity="info">
                    {TranslatorUtil.t("Reactions count")}{" "}
                    {filteredProjectMessages.length !== projectMessages.length
                      ? filteredProjectMessages.length +
                        " / " +
                        projectMessages.length
                      : projectMessages.length}
                  </Alert>
                )}
                {filteredProjectMessages.map((projectMessage) => (
                  <MessageItem
                    key={projectMessage.id}
                    projectId={selectedProject.id}
                    discussionId={projectMessage.discussionId}
                    message={projectMessage}
                    currentUserId={UsersUtil.getCurrentUserUid()}
                    disableAvatarActions={false}
                    disableReactions={true}
                    avatarBadgeContent={UsersUtil.getUserProjectIcon(
                      profiles.find((x) => x.uid === projectMessage.byUserId),
                      selectedProject.id,
                      profiles
                    )}
                  />
                ))}
              </React.Fragment>}
              </TabPanel>
              <TabPanel value={tabId} index={2}>
              <div className="mb-2">
                  <Grid container spacing={2}>
                  {this.renderRespondentFilter()}
                  {this.renderStarFilter()}
                  </Grid>
                  </div>
                <ExportProjectData
                  selectedProject={selectedProject}
                  profiles={profiles}
                  messagesFilterRespondentId={messagesFilterRespondentId}
                  onlyStarredMessages={onlyStarredMessages}
                  userFilterTags={userFilterTags}
                  filteredUserIdsByTags={userFilterTags.length > 0 ? filteredUserIdsByTags : null}
                  userTags={filterTags}
                  discussions={ArrayUtil.sortByDateCreated(discussions, false)}
                  discussionId={discussionId}
                />
              </TabPanel>
              </Box>
            </Box>
          ) : (
            <Box>
              <Alert severity="info">
                {TranslatorUtil.t("No respondents found")}
              </Alert>
              <Box pt={4}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    history.push(
                      UrlBuilderUtil.getProjectUsersEdit(selectedProject.id)
                    )
                  }
                  startIcon={<AddIcon />}
                >
                  {TranslatorUtil.t("Add respondents")}
                </Button>
              </Box>
            </Box>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    profiles: state.profiles,
    selectedProject: state.selectedProject,
    userTags: state.userTags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...previewActions,
        ...accessActions,
        ...notificationActions,
      },
      dispatch
    ),
  };
}

export default withRouter(
  withSnackbar(
    withStyles(styles, { withTheme: true })(
      connect(mapStateToProps, mapDispatchToProps)(ProjectStats)
    )
  )
);
