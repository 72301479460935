import { Grid, ListItemAvatar, ListItemText, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from "react-router-dom";
import styles from '../../theme/styles';
import DateUtil from '../../utils/DateUtil';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import UserAvatar from '../user/UserAvatar';


class SupportMessageItem extends React.Component {


    renderListText = (message) =>{
        const { classes, currentUserId } = this.props;
        const notCurrent = currentUserId !== message.byUserId;

        return <ListItemText
        secondaryTypographyProps={{ component: "div" }}
              secondary={
                <Paper elevation={1} className={"gridItem supportMessageItem" + (notCurrent ? " otherMessage" : " myMessage")} component="span" style={{display:"block"}}>
                  <Typography component="span" className={classes.inline} color="textPrimary">
                    
                    {message.message}
                  </Typography>
                  <Grid container>
            <Grid item xs={6}>
              <small className="messageDate">{DateUtil.displayTimeFromNow(message.createdTime, TranslatorUtil.getUserLangFromStore())}</small>
            </Grid>
            
          </Grid>
                  </Paper>
              }
            />
      }
    
      renderAvatar = (message) =>{
        const { currentUserId } = this.props;
        return <ListItemAvatar>
          <UserAvatar userId={message.byUserId} menuItems={{
            withDialog: currentUserId !== message.byUserId && AccessUtil.isAtLeastObserver()
          }} />
      </ListItemAvatar>
      }

      


  render () {
  const { message, currentUserId } = this.props;

  return message && currentUserId ? (<React.Fragment>
            
            {this.renderAvatar(message)}
          {this.renderListText(message)}
          
          </React.Fragment>
          
        ) : null
}
}


export default withRouter(withStyles(styles, { withTheme: true })(SupportMessageItem));