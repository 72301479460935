export default {
    "type": "service_account",
    "project_id": "opinions-wizard-dev",
    "private_key_id": "a1b70fd8171946725f3b2bb66f3a005fe2e711ab",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC4FQpw+lnElR6u\nmR95ndS3L2q461BykOC5DvvJMpVGNWmlNm80mdwvq4DUqa+M8wq07ZAaa/kU7jwR\nJprD5p71pPVmh2iD5x8M92hp67TTz7pMsrPq/OZFfm6j2wWyp80t9tAXRh00sFME\njeExIfbHbiC7cQv1LyxJuSqvQwpfSztvdjsoYdgxkCVT5mBw1HdAqFcqin4bt/IR\nt29NtXsQuVCpyDneL3rJMqCu6R67LmH1rJWLNxkNODwBQyVFT3HzuI3uzQzWu2rL\nmdfe+YHMB8aZr51wWQvplA0hFyjqU69MLYFG/0MVq1MC7e7eQOD7TBoq2yrnRciu\nCXuSYsu5AgMBAAECggEADolBevzB+ZyJljcAPch2ri/VM8ddNxbKsBex+UgrHSaw\nR3wTbv9sBur6RZDQDId6rnaIp8Aj+gD6saNZ8E31+nwtZrca9o4vLQUdHn7Jl4+d\nQgY7NTta7FzuBra6bfUDO6B0UTTXrzvx6kz73eHf4g1dEgAndhPktuj408CDAVWb\nUCvYcbfZ6mhJGGsjkmpEo0K3CgQfjhAkul/TFMzVp5kmwlIktBdgfccbv1wEFMK8\n4Y9eXFmzT9atrM9BsCJJg0Lz34N/103obxnqluJ1L0JOmzumGFd0mx9oXiITyPKJ\n+NtvS+wZJwcsyrvkek9ykAquilqlPwR8nPRk8wezawKBgQDpYuowlo5O6S4fchb4\nuSU5Ct37l1LLtD3aFXrLycr7+l1O3rslQ1RxizUUeGy9lt5VRtT2cZfsydTKUsBj\n0QXMGTIjEtPxY2NGK71M8DdA7ZW+/vWCUFCd59P/kj7YB+e/JlMMBpEuadUD7Zot\n9FtvbIg0fBwSyMwUYPmuXdOJtwKBgQDJ6yYiL931fL31D7qMCkT15WwPC+JhGJE2\nnvj0HZkAdINQyxjecYOCJfo9i589epvNHqAXJ2dO8ifmz9i+9iofM7c+MTYGWJpz\nNekbK/rqF9PbfHDHlYXW47b+I4VSlwBcDH1YYvJaETQdkkNxl24irME/JFQd6kSY\nUnQI5BgWDwKBgDlZPQLDfCSX1+WSO2LS2/qwWOvBu26i07ALJyl8ZIyWcOc4g/Qc\nH1vnQRvOm6nasLdzuFD2iMZ+sfMlcnEUo8ALUO6daccfDP3pMZL+VBV4TBuqutlS\nsvyaQtXCMf8TsjaeTDngQzyMdPOKJyCzsuQmXzHGbX+QJ9W6HqBiHeDxAoGAH4S7\nQR7jsMw2Aqjf37hCp3eWdIrQXYOuNy59ge2U0oNddCEf0qk5c/llpHHE+53F8DDV\noIegEqyuGdNb7AMU9CXodqbL3hW6lj1WE827rwrlIR+9ownuCGsnR2hYCVpUzgmN\nD1zyo6iH0A3ixTp0EKmSyL5s+vZ0mqpUPocMU3UCgYEAyt9dVPkUJeJYXlSEw3R7\nXaLhFX5lHdNRPN8DRIv+0JKagR7/MOeNe4TPi7rfSLLQKPZW4n7Whwo7noNaQe5u\nLRAQiMKs3oojMY+r+yRiJhrBCki3cNuyBB1Dto7dUX7+E0JscoP9gnklgvDEYOl/\nO2XI+B9lED9bl6CCC4S+tkQ=\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-gsf1t@opinions-wizard-dev.iam.gserviceaccount.com",
    "client_id": "116561686846156170299",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-gsf1t%40opinions-wizard-dev.iam.gserviceaccount.com",
    "api_key": "AIzaSyDhZZijQ0Ho-Hr3kqBBFwSQYbpKTGVLcV0",
    "project_number": "286078944091"
  }
  