export default (theme, themeConfig, themeColors) => {
    return {
        table:{
            // marginTop: theme.spacing(4),
            tableLayout: 'fixed',
              // '& td,th':{
              //   // padding: theme.spacing(1)/2,
              // },
              // '& td.expandCellWidth, & th.expandCellWidth': {
              //     // paddingLeft: theme.spacing(1)
              // },
              '& td.posNameCellWidth, & th.posNameCellWidth': {
                width: 100,
                [theme.breakpoints.up('sm')]: {
                  width: 200,
                },
                // padding: theme.spacing(1)/2
              },
              '& tr.spacerRow':{
                height: 30,
                '& td':{
                  padding: themeConfig.setImportant(0)
                }
              },
              '&.childProjectsTable':{
                marginTop: 0
              },
            '& .displayColumnsToRows': {
              [theme.breakpoints.down('md')]: {
                '&,& td':{
                  display: 'block',
                  height: 'auto'
                },
                '& > td':{
                  borderBottom: 0
                },
                '& > td:last-child':{
                  borderBottom: '2px solid '+themeColors.primaryPalette.grey,
                  // marginBottom: theme.spacing(1)*3  
                }

              }
            },
            '&.activitySummaryResultsSection':{
              '& td:not(.expandCellWidth), & th:not(.expandCellWidth)':{
                [theme.breakpoints.up('lg')]: {
                  width: 90,
                }
              },
              '& td.expandCellWidth':{
                paddingLeft: 4
              },
              '& td':{
                verticalAlign: 'top',
              },
              '& td.projectLinkColumn, & th.projectLinkColumn':{
                '& a, & button':{
                  padding:0,
                  '&:hover':{
                    backgroundColor: 'transparent'
                  }
                },
                [theme.breakpoints.up('lg')]: {
                  
                  width: themeConfig.setImportant(150+'px'),
                }
              },
              '& td.resultsIndicatorColumnWrap, & th.resultsIndicatorColumnWrap':{
                position: 'relative',
                verticalAlign: 'middle',
                paddingLeft: 20,
                [theme.breakpoints.up('lg')]: {
                  width: themeConfig.setImportant(115+'px'),
                  paddingRight: 0
                },
                '& .indicator':{
                  left: 4,
                  width: 8,
                  borderRadius: 4,
                  top: 4,
                  bottom: 4
                }
            },
            }
          },
          tableCellProjectId: {
            width: '65px !important',
            '& svg':{
              fontSize: 14,
              opacity: 0.4,
              display: 'block',
              margin: 'auto'
            }
          },
          tableCellProjectName: {
            [theme.breakpoints.down(1024)]: {
              width: '245px !important',
            },
            [theme.breakpoints.down('sm')]: {
              width: '115px !important',
            }
          },
          tableCellProjectDesc: {
            width: '40% !important',
            [theme.breakpoints.up("lg")]: {
              width: '60% !important',
            },
            '& p':{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            },
          },
          tableCellActionWrap: {
            width: '200px !important',
            // paddingRight: theme.spacing(1)+'px !important',
            '& svg':{
              fontSize: 20,
            }
          },
          tablePaginationWrapper: {
            '& td':{
              borderBottom: 0,
            },
            // '& td > div':{
            //   // paddingLeft: theme.spacing(1)
            // },
            '& span,& div,& svg': {
              color: themeColors.primaryPalette.primary
            },
            '& [disabled] span': {
              opacity: 0.25
            },
            '& .paginationInput': {
              transform: 'translateY(2px)'
            },
            '& .paginationInput svg': {
              fontSize: 16,
              top: 5
            }
          },
          expandedAllTable: {
            //borderSpacing: '0 10px',
            borderCollapse: 'separate',
            '& .expandedAdditionalRow': {
              //transform:'translateY(-10px)' //IE does not work
            }
          },
          expandedRow: {
            boxShadow: themeConfig.customShadow,
            '& td':{
              borderBottom: 0,
            },
            '& td p':{
              color: themeColors.primaryPalette.white,
            },
            '& td:first-child':{
              borderTopLeftRadius: 4 
            },
            '& td:last-child':{
              borderTopRightRadius: 4 
            },
            '& button':{
              color: themeColors.primaryPalette.white, 
            }
          },
          expandedAdditionalRow: {
            boxShadow: themeConfig.customShadow,
            backgroundColor: themeColors.primaryPalette.white,
            
            '& td':{
              borderBottom: 0,
              boxShadow: themeConfig.customShadow
            },
            
            '& td:first-child':{
              borderBottomLeftRadius: 4 
            },
            '& td:last-child':{
              borderBottomRightRadius: 4 
            }
          },
          tableFixedHeader: {
            [theme.breakpoints.up("md")]: {
              position: 'fixed',
              // top: theme.spacing(1)*1,
              // right: theme.spacing(1)*3,
              zIndex: 1101,
            },
            '& span': {
              color: themeColors.primaryPalette.primary
            }
          },

          systemTresholdsWrap: {
            paddingTop: 100,
            '& td':{
              border: '2px solid '+themeColors.primaryPalette.white
            },
            '& .paper':{
              boxShadow: 'none',
            },
            '& .notMet':{
              color: themeColors.primaryPalette.white,
              background: themeColors.primaryPalette.secondary,
            },
            '& .partialMet':{
              background: themeColors.primaryPalette.warning,
            },
            '& .met':{
              background: themeColors.primaryPalette.success,
            }
          }      
}}