
export default (theme, themeConfig, themeColors) => {
    return {
        fullscreenDialogWrap:{
            paddingRight: themeConfig.setImportant(0),
            '& > div':{
                backgroundColor: themeConfig.setImportant(themeColors.primaryPalette.grey),
            },
        },
        fullscreenDialogContent:{
            minHeight: '100vh',
            
            '& > div':{
                maxWidth: themeConfig.fullscreenDialogContentMaxWidth,
                margin: 'auto',
                padding: theme.spacing(1)*3,
                paddingTop: themeConfig.setImportant('100px'),
                [theme.breakpoints.down("xs")]: {
                    padding: theme.spacing(1),
                },
            },
            '& .pageHeading':{
                position: 'fixed',
                top:0,
                left: 0,
                right: 0,
                zIndex: 1301,
                background: themeColors.primaryPalette.primary,
                '& > div':{
                    maxWidth:themeConfig.fullscreenDialogContentMaxWidth,
                    margin: 'auto',
                    padding: themeConfig.setSpacing(theme.spacing(1)*2),
                    '& h1, & button, & svg':{
                        color: themeConfig.setImportant(themeColors.primaryPalette.white)
                    }
                    
                },
                [theme.breakpoints.down("xs")]: {
                    margin: themeConfig.setImportant(0),
                    padding: themeConfig.setSpacing((theme.spacing(1)*1.5), (themeConfig.commonPadding*1.5)),
                  },
                
            }
        }
    }
}