

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import { Chip } from '@material-ui/core';
import objectPath from 'object-path';
import TranslatorUtil from '../../utils/TranslatorUtil';
import TodayIcon from '@material-ui/icons/Today';
import WcIcon from '@material-ui/icons/Wc';

class UserTags extends React.Component {

  render () {
  const { user, tags } = this.props;

  return user ? (
    <div className="chipsWrap">
        {objectPath.get(user,'gender',null) ? <Chip size="small" icon={<WcIcon />} label={TranslatorUtil.t(user.gender)} /> : null }
        {objectPath.get(user,'age',null) ? <Chip size="small" icon={<TodayIcon />} label={user.age} /> : null }
        {
          tags.filter(t => !t.includes('__profile__age__') && !t.includes('__profile__gender__')).map((t, index) => <Chip key={index} size="small" label={t} />)
        }
        
    </div>
    ) : null;
    }
}


export default withStyles(styles, { withTheme: true })(UserTags);