
import ValidationUtil from '../../utils/ValidationUtil';

const validate = values => {
  let errors = {};
  const requiredFields = [
    "message"
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);


  return errors;
};

export default validate;
