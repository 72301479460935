import React from 'react';
import { Button } from '@material-ui/core';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeftRounded";
import { withRouter } from "react-router-dom";  
import TranslatorUtil from '../../utils/TranslatorUtil';
import StoreUtil from '../../utils/StoreUtil';

function HeadingBackBtn(props) {

  const { redirectToUrl, history, onBackBtn } = props;

  const hasHistory = StoreUtil.getValueByKey('routerLocations.1',null);

  return <Button variant="text" size="small" onClick={() => hasHistory ? history.goBack() : (onBackBtn ? onBackBtn() : history.push(redirectToUrl))}>
        <ChevronLeftIcon />
        {TranslatorUtil.t("Back")}
      </Button>;
}

export default withRouter(HeadingBackBtn);