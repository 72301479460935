import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/styles';
// import { Switch, Route } from "react-router-dom";
// import DashboardFilter from './dashboard/DashboardFilter';
// import { Hidden } from '@material-ui/core';
import Masonry from 'react-masonry-component';
import objectPath from 'object-path';
import { Box, Paper } from '@material-ui/core';
import FullScreenDialog from './FullScreenDialog';
import ImageFallback from './ImageFallback';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import * as constants from '../constants/constants';

class ImagesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showImage: null,
        }
      }


      shouldComponentUpdate(nextProps, nextState) {
        return objectPath.get(this,'props.item.id') !== objectPath.get(nextProps,'item.id') || objectPath.get(this,'props.images.length') !== objectPath.get(nextProps,'images.length') || objectPath.get(this,'state.showImage.downloadUrl') !== objectPath.get(nextState,'showImage.downloadUrl');
    }

      renderDialog = () => {
        const { showImage } = this.state;
        const { images } = this.props;
        if(showImage){
            var imageOrderNum = (images.findIndex(x => objectPath.get(x,'downloadUrl','') === objectPath.get(showImage,'downloadUrl',''))+1);
            var imagesCountText = images.length > 1 ? "("+imageOrderNum+"/"+images.length+") " : "";
            var nextImage = images.length >= imageOrderNum ? images[imageOrderNum] : images[0]; 

            return <FullScreenDialog 
            title={imagesCountText+showImage.name} 
            showBar={true} 
            closeDialog={() => this.setState({showImage: null})}>
              <div onClick={() => this.setState({showImage: nextImage})}>
            <ImageFallback 
                      className="fluid-image"
                      src={objectPath.get(showImage,'downloadUrl','')}
                      initialimg={constants.IMAGE_LOADER_PATH}
                      fallbackimg={constants.BROKEN_IMAGE_PATH}
                      alt={showImage.name} /> 
                      </div>
            </FullScreenDialog>
        }
        return null;
    }


      render(){
        const masonryOptions = {
            itemSelector: '.masonry-item',
            columnWidth: '.masonry-item-sizer',
            percentPosition: true,
            horizontalOrder: true
          };
    
        const { images } = this.props;
        const ClicableImage = ({image}) => {
            return <Box className="masonry-item" onClick={()=> this.setState({showImage : image})}>
                    <Paper square elevation={1} className="clickableImageWrap">
                        <img className={"fluid-image"} src={objectPath.get(image,'downloadUrl','')} alt={objectPath.get(image,'name','')} />
                        <div className={"hidden imageHoverWrapper"}>
                            <ZoomInIcon />
                        </div>
                        </Paper>
                    </Box>
        }

        return images && images.length ? <Box py={2} className="narrowMaxMediumWidthPart">
          <Box pb={1} px={1}>
      {<ClicableImage image={images[0]} />}
      </Box>
      <Masonry
            //ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
            className={'masonry-grid-wrap'} // default ''
            options={masonryOptions} // default {}
          >
              <div className="masonry-item-sizer"></div>
            {
                images.slice(1).map((image,index) => <ClicableImage image={image} key={index} />)
            }
        </Masonry>
        {this.renderDialog()}
        </Box> : null

      }

  
}


export default withStyles(styles, { withTheme: true })(ImagesList);