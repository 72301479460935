import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function userInfo(state = initialState.userInfo, action) {
    if (action.type === types.SET_USER_INFO_SUCCESS) {
        console.log('userInfo ',types.SET_USER_INFO_SUCCESS)
        return {
            firebase: action.data,
            profile: action.data.profile,
            company: action.data.company
        };
    }
    if (action.type === types.PUT_PROFILE) {
        console.log('put profile',action.data)
        return {
            ...state,
            profile: action.data
          }
    }
    if (action.type === types.SET_USER_INFO_ERROR) {
        return 'error';
    }
    return state;
}
