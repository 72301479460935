import React from "react";
import objectPath from 'object-path';
import objectHash from 'object-hash';
import StoreUtil from '../StoreUtil';
import avatars from "../../constants/avatars";
import ArrayUtil from '../ArrayUtil';
import TranslatorUtil from '../TranslatorUtil';
import * as constants from '../../constants/constants';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import { yellow, grey, brown } from "@material-ui/core/colors";


export default class UsersUtil {


  static formatForUserSelector(){
    var users = StoreUtil.getValueByKey('users',[]);
    return users.map(x => {return {
      value: x.userId,
      label: x.firstName+" "+x.surname,
    }});
  }

  static getProfilesOptions(profiles){
    return profiles.map(x => ({
      value: x.email,
      label: (x.email+' ('+x.displayName+')')
    }))
  }

  static userExistsAndNotCurrent(userId){
    return userId && userId !== StoreUtil.getValueByKey('userInfo.firebase.uid',null)
  }

  static createDefaultProfileFromUser(user){
    return {
      uid: user.uid,
      email: user.email,
      displayName: UsersUtil.getDefaultDisplayName(user),
      avatar: UsersUtil.getRandomAvatar(),
      langCode: constants.DEFAULT_TRANSLATION_LANG
    }
  }

  static getUserProfileByUserId(id, profiles = []){
    profiles = profiles.length ? profiles : StoreUtil.getValueByKey('profiles',[]);
    return id ? profiles.find(x => x.uid===id) : null;
  }

  static getUserById(items,id){
    return id ? items.find(x => x.uid===id) : null;
  }

  static getNameFromStoreById(id, defaultValue = 'User not found'){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('users',[]),id);
    return user ? objectPath.get(user,'firstName')+" "+objectPath.get(user,'surname') : defaultValue;
  }

  static getEmailFromStoreById(id, defaultValue = null){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('profiles',[]),id);
    return objectPath.get(user,'email', defaultValue);
  }

  static getUserFromStoreById(id){
    return StoreUtil.getValueByKey('profiles',[]).find(x => x.uid===id);
  }

  static getUserEmailFromStoreById(id){
    var user = UsersUtil.getUserFromStoreById(id);
    return objectPath.get(user,'email', null);
  }

  static getDefaultDisplayName(user){
    var displayName = objectPath.get(user,'email', '');
    return displayName.indexOf('@') > 0 ? displayName.substring(0, displayName.indexOf("@")) : objectHash.MD5(user).substr(0,9);
  }

  static getUserDisplayName(user, anonymous = false){
    var displayName = objectPath.get(user,'displayName', TranslatorUtil.t('unknown'));
    return anonymous ? objectHash.MD5(displayName).substr(0,9) : displayName;
  }

  static getUserDisplayNameByUserInfo(userInfo, anonymous = false){
    return UsersUtil.getUserDisplayName(objectPath.get(userInfo,'profile'), anonymous);
  }

  static getUserDisplayNameById(userId, anonymous = false){
    return UsersUtil.getUserDisplayName(
      UsersUtil.getUserById(StoreUtil.getValueByKey('profiles',[]),userId)
    );
  }

  static getRandomAvatar(){
    return ArrayUtil.getRandItemIfExist(avatars.map(avatar => avatar.value));
  }

  static getAvatarUrlByAvatarId(avatarId){
    avatarId = avatarId ? avatarId : UsersUtil.getRandomAvatar();
    return "/assets/avatars/"+avatarId+".png";
  }

  static getAvatarUrlById(userId){
    var user = UsersUtil.getUserById(StoreUtil.getValueByKey('profiles',[]),userId);
    return UsersUtil.getAvatarUrlByAvatarId(objectPath.get(user,"avatar",null));
  }

  static getUserTagsByUserId(userId){
    var companyUserTags = StoreUtil.getValueByKey('userTags',[]);
    var selectedProject = StoreUtil.getValueByKey('selectedProject',null);
    return selectedProject ? objectPath.get(companyUserTags.find(x => x.id === userId+"_p_"+selectedProject.id),'tags',[]) : [];
  }

  static getCurrentUserEmail(){
    return objectPath.get(
      StoreUtil.getValueByKey('userInfo',null),
      "firebase.email",
      null
      ); 
  }

  static getCurrentUserUid(){
    return objectPath.get(
      StoreUtil.getValueByKey('userInfo',null),
      "firebase.uid",
      null
      );
  }

  static getUserProjectMessagesCount(user, projectId){
    let userCount = objectPath.get(user,"messagesCount_p_"+projectId,0);
    // if(user.uid === "1nS4KwbAlXWk529k6yoLuQG6rMj1"){
    //   userCount = 4;
    // }
    // if(user.uid === "nz7gLikcNFZZhKSdd35wEO72QWv1"){
    //   userCount = 4;
    // }
    return userCount;
  }

  static getUserProjectDiscussionMessagesCount(user, projectId, discussionId){
    let userCount = objectPath.get(user,"messagesCount_p_"+projectId+"_d_"+discussionId,0);
    // if(user.uid === "1nS4KwbAlXWk529k6yoLuQG6rMj1"){
    //   userCount = 4;
    // }
    // if(user.uid === "nz7gLikcNFZZhKSdd35wEO72QWv1"){
    //   userCount = 4;
    // }
    return userCount;
  }

  static getUserProjectIcon(user, projectId, profiles){
    let firstCount = 0;
    let secondCount = 0;
    let thirdCount = 0;
    const userCount = UsersUtil.getUserProjectMessagesCount(user, projectId);
    if(userCount > 0 && ArrayUtil.isNonEmptyArray(profiles)){
      const profilesSortedByCount = profiles.sort((a,b) => UsersUtil.getUserProjectMessagesCount(b, projectId) - UsersUtil.getUserProjectMessagesCount(a, projectId));
      profilesSortedByCount.forEach(p => {
        const pCount = UsersUtil.getUserProjectMessagesCount(p, projectId);
        if(pCount > 0){
          if(pCount > firstCount){
            firstCount = pCount;
          } 
          if(pCount > secondCount && pCount < firstCount){
            secondCount = pCount;
          } 
          if(pCount > thirdCount && pCount < secondCount && pCount < firstCount){
            thirdCount = pCount;
          }
        }
      });
    }
    if(firstCount === userCount && firstCount > 0){
      return <LooksOneIcon fontSize="small" style={{ color: yellow[700] }}/>;
    } else if(secondCount === userCount && secondCount > 0){
      return <LooksTwoIcon fontSize="small" style={{ color: grey[500] }} />;
    } else if(thirdCount === userCount && thirdCount > 0){
      return <Looks3Icon fontSize="small" style={{ color: brown[400] }} />;
    } else {
      return null;
    }

  }

  static getUserProjectDiscussionIcon(user, projectId, disucssionId, profiles){
    let firstCount = 0;
    let secondCount = 0;
    let thirdCount = 0;
    const userCount = UsersUtil.getUserProjectDiscussionMessagesCount(user, projectId, disucssionId);
    if(userCount > 0 && ArrayUtil.isNonEmptyArray(profiles)){
      const profilesSortedByCount = profiles.sort((a,b) => UsersUtil.getUserProjectDiscussionMessagesCount(b, projectId, disucssionId) - UsersUtil.getUserProjectDiscussionMessagesCount(a, projectId, disucssionId));
      profilesSortedByCount.forEach(p => {
        const pCount = UsersUtil.getUserProjectDiscussionMessagesCount(p, projectId, disucssionId);
        if(pCount > 0){
          if(pCount > firstCount){
            firstCount = pCount;
          } 
          if(pCount > secondCount && pCount < firstCount){
            secondCount = pCount;
          } 
          if(pCount > thirdCount && pCount < secondCount && pCount < firstCount){
            thirdCount = pCount;
          }
        }
      });
    }
    if(firstCount === userCount && firstCount > 0){
      return <LooksOneIcon fontSize="small" style={{ color: yellow[700] }}/>;
    } else if(secondCount === userCount && secondCount > 0){
      return <LooksTwoIcon fontSize="small" style={{ color: grey[500] }} />;
    } else if(thirdCount === userCount && thirdCount > 0){
      return <Looks3Icon fontSize="small" style={{ color: brown[400] }} />;
    } else {
      return null;
    }

  }

  static getTotalDiscussionMessagesCount(profiles, projectId, discussionId){
    let total = 0;
    if(ArrayUtil.isNonEmptyArray(profiles)){
      profiles.forEach(p => {
        total += UsersUtil.getUserProjectDiscussionMessagesCount(p, projectId, discussionId);
      });
    }
    return total;
  }


}
