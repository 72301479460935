export default (theme, themeConfig, themeColors) => {
    return {

card: {
    // width: 300,
    // [theme.breakpoints.up("md")]: {
    //   width: 450
    // },
    // margin: "auto"
  },
  cardError: {
    backgroundColor: themeColors.primaryPalette.error,
    marginTop: themeConfig.commonPadding*2,
    "& h1,& p": {
      color: themeColors.primaryPalette.white
    }
  },
  cardWarning: {
    backgroundColor: themeColors.primaryPalette.warning,
    marginTop: themeConfig.commonPadding*2,
    "& h1,& p": {
      color: themeColors.primaryPalette.white
    }
  },
}
}