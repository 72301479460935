
import ValidationUtil from '../../../utils/ValidationUtil';


const validate = values => {
  let errors = {};
  const requiredFields = [
    "email",
  ];
  errors = ValidationUtil.checkRequiredFields(requiredFields,values,errors);


  errors = ValidationUtil.isEmailFormat(['email'],values,errors);

  return errors;
};

export default validate;
