import * as types from '../constants/actionTypes';


export function getUserTags(state = [], action) {
    if (action.type === types.GET_COMPANY_USER_TAGS) {
        console.log(types.GET_COMPANY_USER_TAGS+' set in store');
        return action.data;
    }
    return state;
}
