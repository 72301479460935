
import translations from "../translations"
import * as constants from '../constants/constants';
import * as types from '../constants/actionTypes';
import objectPath from "object-path";

export default function setTranslationMessagesByLang(state = objectPath.get(translations,constants.DEFAULT_TRANSLATION_LANG), action) {
    var newLang = objectPath.get(action, 'l');
    if (newLang && action.type === types.SET_TRANSLATIONS && objectPath.get(state, 'langCode') !== newLang && objectPath.get(translations,newLang)) {
        return objectPath.get(translations,newLang);
    }
    return state;
}