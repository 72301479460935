import React from 'react'
import { Typography, Button } from '@material-ui/core';
import TranslatorUtil from '../../utils/TranslatorUtil';
import Error401Svg from './svgs/Error401Svg';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeftRounded";

const UnauthorisedAccessPage = ({ location }) => {
  // let searchParams = queryString.parse(location.search);
  // let requestedPath = objectPath.get(searchParams, 'request', null);
  return (
    <div className='p-4'>
    
    <Typography color="primary" variant="h1">{TranslatorUtil.t("Unauthorised")}</Typography>
    <Typography variant="body2" className='py-2'>
    Ujistěte se, že jste přihlášeni pod svým uživatelským účtem.<br /><br /> V případě nejasností pište na<br/> <strong>info@contentwizards.cz</strong> <br/>nebo volejte na<br/> <strong>+420 731 369 250</strong>.
    </Typography>
    <Button href="/" variant="text" startIcon={<ChevronLeftIcon />} color="default" className='pl-0'>{TranslatorUtil.t("Home page")}</Button>
    <div className="svgWrap p-4" style={{maxWidth:"100%",width:"500px"}}>
    <Error401Svg />
    </div>
    
</div>
  )
} 


export default UnauthorisedAccessPage
